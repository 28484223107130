import * as React from "react";
import {Box, Grid} from "@material-ui/core";
import {useNavigate} from "react-router";

import classes from "./Page4.module.css";

import Page4FeaturesCap1 from "../../../../../Assets/Home/HomePage4Svgs/cap1.svg";
import Page4Features2nd1 from "../../../../../Assets/Home/HomePage4Svgs/2nd1.svg";
import Page4FeaturesExp1 from "../../../../../Assets/Home/HomePage4Svgs/exp1.svg";
import Page4FeaturesAdd1 from "../../../../../Assets/Home/HomePage4Svgs/add1.svg";
import Page4FeaturesKeeps1 from "../../../../../Assets/Home/HomePage4Svgs/keeps1.svg";
import Page4FeaturesKeep1 from "../../../../../Assets/Home/HomePage4Svgs/keep1.svg";
import Page4FeaturesOrg1 from "../../../../../Assets/Home/HomePage4Svgs/org1.svg";
import Page4FeaturesHas1 from "../../../../../Assets/Home/HomePage4Svgs/has1.svg";
import Page4FeaturesUnlim1 from "../../../../../Assets/Home/HomePage4Svgs/unlim1.svg";

function Page4(props) {
	const navigate = useNavigate();
	const handleClickTool = () => {
		navigate("/hotqrcodegenerator");
	};
	return (
		<Box className={classes.box}>
			<Grid container spacing={2} className="">
				<Grid item xs={12} className="d-flex justify-content-between">
					<label onClick={handleClickTool} className={classes.labelTitle}>
						HOT QR Code Generator
					</label>
					<Box onClick={handleClickTool} className={classes.btnTool}>
						<span className={classes.btnToolText}>Tool</span>
					</Box>
				</Grid>
				<Grid item xs={12}>
					<label className={classes.labelFeatures}> Features </label>
				</Grid>
				<Grid item container xs={12} spacing={4}>
					<Grid
						item
						xs={4}
						className="d-flex justify-content-center align-items-center"
					>
						<Box className={classes.boxFeaturesSub}>
							<img
								className={classes.boxFeaturesImg}
								alt="ImgFeatures"
								src={Page4FeaturesCap1}
							/>
							<label className={classes.boxFeaturesText}>
								<span style={{color: "#000000"}}>
									Capture contact information of target audience
								</span>
								<span className="mt-2">
									Lead Generation feature helps you capture lead’s information
									to build a list of potential customers or target audience.
								</span>
							</label>
						</Box>
					</Grid>
					<Grid
						item
						xs={4}
						className="d-flex justify-content-center align-items-center"
					>
						<Box className={classes.boxFeaturesSub}>
							<img
								className={classes.boxFeaturesImg}
								alt="ImgFeatures"
								src={Page4Features2nd1}
							/>
							<label className={classes.boxFeaturesText}>
								<span style={{color: "#000000"}}>
									Create the type of QR Code you need
								</span>
								<span className="mt-2">
									There are various categories of QR Codes e.g. webpage, pdf,
									google maps and VcardQR codes.
								</span>
							</label>
						</Box>
					</Grid>
					<Grid
						item
						xs={4}
						className="d-flex justify-content-center align-items-center"
					>
						<Box className={classes.boxFeaturesSub}>
							<img
								className={classes.boxFeaturesImg}
								alt="ImgFeatures"
								src={Page4FeaturesExp1}
							/>
							<label className={classes.boxFeaturesText}>
								<span style={{color: "#000000"}}>
									Export vector formats of the QR Code image
								</span>
								<span className="mt-2">
									QR Code makes the most sense when added to print media which
									require a high-resolution QR Code image in vector format.
								</span>
							</label>
						</Box>
					</Grid>
					<Grid
						item
						xs={4}
						className="d-flex justify-content-center align-items-center"
					>
						<Box className={classes.boxFeaturesSub}>
							<img
								className={classes.boxFeaturesImg}
								alt="ImgFeatures"
								src={Page4FeaturesAdd1}
							/>
							<label className={classes.boxFeaturesText}>
								<span style={{color: "#000000"}}>
									Add error correction to QR Codes
								</span>
								<span className="mt-2">
									Error correction ensures that your QR Code remains scannable.
									No matter if it is a little damaged or dirty.
								</span>
							</label>
						</Box>
					</Grid>
					<Grid
						item
						xs={4}
						className="d-flex justify-content-center align-items-center"
					>
						<Box className={classes.boxFeaturesSub}>
							<img
								className={classes.boxFeaturesImg}
								alt="ImgFeatures"
								src={Page4FeaturesKeep1}
							/>
							<label className={classes.boxFeaturesText}>
								<span style={{color: "#000000"}}>
									Keep QR Codes and landing pages ad-free
								</span>
								<span className="mt-2">
									Choose a QR Code Generator that keeps landing pages completely
									ad-free as users will certainly not be pleased to see ads.
								</span>
							</label>
						</Box>
					</Grid>
					<Grid
						item
						xs={4}
						className="d-flex justify-content-center align-items-center"
					>
						<Box className={classes.boxFeaturesSub}>
							<img
								className={classes.boxFeaturesImg}
								alt="ImgFeatures"
								src={Page4FeaturesOrg1}
							/>
							<label className={classes.boxFeaturesText}>
								<span style={{color: "#000000"}}>
									Organize QR Codes in Campaigns
								</span>
								<span className="mt-2">
									QR Code Campaign Management allows systematic organization and
									analytics monitoring at campaign-level instead of QR
									Code-level.
								</span>
							</label>
						</Box>
					</Grid>
					<Grid
						item
						xs={4}
						className="d-flex justify-content-center align-items-center"
					>
						<Box className={classes.boxFeaturesSub}>
							<img
								className={classes.boxFeaturesImg}
								alt="ImgFeatures"
								src={Page4FeaturesHas1}
							/>
							<label className={classes.boxFeaturesText}>
								<span style={{color: "#000000"}}>
									Has flexible payment options
								</span>
								<span className="mt-2">
									You can subscribe to HOTqron a need-basis.
								</span>
							</label>
						</Box>
					</Grid>
					<Grid
						item
						xs={4}
						className="d-flex justify-content-center align-items-center"
					>
						<Box className={classes.boxFeaturesSub}>
							<img
								className={classes.boxFeaturesImg}
								alt="ImgFeatures"
								src={Page4FeaturesUnlim1}
							/>
							<label className={classes.boxFeaturesText}>
								<span style={{color: "#000000"}}>
									Unlimited number of scans
								</span>
								<span className="mt-2">
									The design of subscription plans should be based on the number
									of QR Codes and not on the number of scans.
								</span>
							</label>
						</Box>
					</Grid>
					<Grid
						item
						xs={4}
						className="d-flex justify-content-center align-items-center"
					>
						<Box className={classes.boxFeaturesSub}>
							<img
								className={classes.boxFeaturesImg}
								alt="ImgFeatures"
								src={Page4FeaturesKeeps1}
							/>
							<label className={classes.boxFeaturesText}>
								<span style={{color: "#000000"}}>Keeps your data secured</span>
								<span className="mt-2">
									HOTqr has an advanced security signature, this ensures highest
									level of data security within the technology.
								</span>
							</label>
						</Box>
					</Grid>
				</Grid>
				{/* <Grid
          item
          xs={2}
          className="d-flex justify-content-center align-items-center"
        >
          <Box className={classes.boxFeaturesSub}>
            <img
              className={classes.boxFeaturesImg}
              alt="ImgFeatures"
              src={Page4FeaturesKeeps1}
            />
            <label className={classes.boxFeaturesText}>
              <span style={{ color: "#000000" }}>Keeps your data secured</span>
              <span className="mt-2">
                HOTqr has an advanced security signature, this ensures highest
                level of data security within the technology.
              </span>
            </label>
          </Box>
        </Grid> */}
				<Grid item xs={12} className="d-flex justify-content-center mt-3">
					<Box className={classes.boxTable}>
						<Box className={classes.boxMiddleBar} />
						<Box className={classes.boxTableTitleBar}>
							<label className={classes.boxTableTitleText}> Use Cases </label>
						</Box>
						<label
							className={classes.boxTableContentText}
							style={{left: "128px", top: "77px"}}
						>
							Retail Store
						</label>
						<label
							className={classes.boxTableContentText}
							style={{left: "602px", top: "77px"}}
						>
							Business Cards
						</label>
						<Box className={classes.boxTableRowBar} style={{top: "115px"}} />
						<label
							className={classes.boxTableContentText}
							style={{left: "128px", top: "142px"}}
						>
							Real Estate
						</label>
						<label
							className={classes.boxTableContentText}
							style={{left: "602px", top: "142px"}}
						>
							Social Network
						</label>
						<Box className={classes.boxTableRowBar} style={{top: "184px"}} />
						<label
							className={classes.boxTableContentText}
							style={{left: "128px", top: "209px"}}
						>
							Education
						</label>
						<label
							className={classes.boxTableContentText}
							style={{left: "602px", top: "209px"}}
						>
							Product Information
						</label>
						<Box className={classes.boxTableRowBar} style={{top: "252px"}} />
						<label
							className={classes.boxTableContentText}
							style={{left: "128px", top: "281px"}}
						>
							Advertisement
						</label>
						<label
							className={classes.boxTableContentText}
							style={{left: "602px", top: "281px"}}
						>
							Newspaper & Magazine
						</label>
						<Box className={classes.boxTableRowBar} style={{top: "319.23px"}} />
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
}

export default Page4;
