import React from "react";
import AuthContext from "../../Context/AuthContext";
import {findAllQrType} from "../Pages/Main/Services/QrTypeServices/ApiUrl";
import {getLicense} from "../Pages/Main/Services/LicenseServices/ApiUrl";
// import {licenseApi} from "../Pages/Session/Login/SignupLoginService/Service";
import {getUserQrCount} from "../Pages/Main/Services/DashboardServices/ApiUrl";
const SubMain = (props) => {
	const context = React.useContext(AuthContext);
	const {
		qrTypes,
		license,
		qrCount,
		geoInfo,
		login,
		category,
		qrData,
		goBackModal,
		additionalContent,
		stepModel,
	} = context;
	const [contextQrTypesData, setContextQrTypesData] = React.useState(qrTypes);
	const [contextQrCountData, setContextQrCountData] = React.useState(qrCount);
	const [contextLicenseData, setContextLicenseData] = React.useState(license);
	const [contextGeoInfoData, setContextGeoInfoData] = React.useState(geoInfo);
	const [contextLoginData, setContextLoginData] = React.useState(login);
	const [additionalContentContext, setAdditionalContentContext] =
		React.useState(additionalContent);
	const [categoryContext, setCategoryContext] = React.useState(category);
	const [qrDataContext, setQrDataContext] = React.useState(qrData);
	const [stepModelContext, setStepModelContext] = React.useState(stepModel);
	const [goBackModalContext, setGoBackModalContext] =
		React.useState(goBackModal);
	const contextQrTypesHandler = React.useCallback(() => {
		findAllQrType()
			.then((res) => {
				setContextQrTypesData(res.Items);
			})
			.catch((err) => {
				console.error("Set Context Qr Types Api Call Error", err);
			});
	}, []);
	const contextLicenseHandler = React.useCallback(() => {
		getLicense()
			.then((res) => {
				setContextLicenseData(res);
			})
			.catch((err) => {
				console.error("Set Context License Api Call Error", err);
			});
		// licenseApi().then((res) => console.log(res));
	}, []);
	const contextQrCountHandler = React.useCallback((data) => {
		getUserQrCount()
			.then((res) => {
				setContextQrCountData(res);
			})
			.catch((err) => {
				console.error("Set Context total Qr Count Api Call Error", err);
			});
	}, []);
	const contextGeoInfoHandler = React.useCallback((data) => {
		setContextGeoInfoData(data);
	}, []);
	const contextLoginHandler = React.useCallback((data) => {
		setContextLoginData(data);
	}, []);
	const contextAdditionalContentHandler = React.useCallback((data) => {
		setAdditionalContentContext(data);
	}, []);
	const contextCategoryHandler = React.useCallback((data) => {
		setCategoryContext(data);
	}, []);
	const contextQrDataHandler = React.useCallback((data) => {
		setQrDataContext(data);
	}, []);
	const contextStepModelHandler = React.useCallback((data) => {
		setStepModelContext(data);
	}, []);
	const contextGoBackModalHandler = React.useCallback((data) => {
		setGoBackModalContext(data);
	}, []);

	return (
		<AuthContext.Provider
			value={{
				qrTypes: contextQrTypesData,
				setQrTypes: contextQrTypesHandler,
				license: contextLicenseData,
				setLicense: contextLicenseHandler,
				qrCount: contextQrCountData,
				setQrCount: contextQrCountHandler,
				geoInfo: contextGeoInfoData,
				setGeoInfo: contextGeoInfoHandler,
				login: contextLoginData,
				setLogin: contextLoginHandler,
				additionalContent: additionalContentContext,
				setAdditionalContent: contextAdditionalContentHandler,
				category: categoryContext,
				setCategory: contextCategoryHandler,
				qrData: qrDataContext,
				setQrData: contextQrDataHandler,
				stepModel: stepModelContext,
				setStepModel: contextStepModelHandler,
				goBackModal: goBackModalContext,
				setGoBackModal: contextGoBackModalHandler,
			}}
		>
			{props.children}
		</AuthContext.Provider>
	);
};
export default SubMain;
