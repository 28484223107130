import React from "react";

// ------------------------ import Components --------------------------- //

import {Container, Box, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core-v4";
import SwitchSelector from "react-switch-selector";
import jwt_decode from "jwt-decode";

import {getUser, updateProfile} from "./Services/Services";

import UserInformation from "./Components/UserInformation";
// import TaxInformation from "./Components/TaxInformation";
import LoginMethod from "./Components/LoginMethod";
import AccountComp from "./Components/Account";

// ------------------------ Custome makeStyle --------------------------- //

const useStyles = makeStyles((theme) => ({
	iconInfo: {
		color: "#999",
		"&:hover": {
			color: "#C86409",
		},
	},
	center: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	selectSwitch: {
		width: 800,
		height: 42,
		marginTop: "30px",
		"& div": {
			borderRadius: "6px",
		},
	},
}));

function Account(props) {
	const classes = useStyles();

	const [selected, setSelected] = React.useState(0);

	const [userState, setUserState] = React.useState(false);

	const token = localStorage.getItem("token")
		? localStorage.getItem("token")
		: "";
	const {externalReferenceId} = jwt_decode(token);

	const getUserDataApiCall = React.useCallback(() => {
		if (externalReferenceId) {
			getUser(externalReferenceId)
				.then((res) => setUserState(res))
				.catch((err) => console.error(err));
		}
	}, [externalReferenceId]);

	React.useEffect(() => {
		getUserDataApiCall();
	}, [getUserDataApiCall]);

	const updateProfileApiCall = (data) => {
		updateProfile({...userState, ...data})
			.then((res) => getUserDataApiCall())
			.catch((err) => console.error(err));
	};

	const RendorComp = () => {
		switch (selected) {
			case 0:
				return (
					<UserInformation
						userState={userState}
						handleSubmit={updateProfileApiCall}
					/>
				);
			// case 1:
			// 	return (
			// 		<TaxInformation
			// 			userState={userState}
			// 			handleSubmit={updateProfileApiCall}
			// 		/>
			// 	);
			case 1:
				return (
					<LoginMethod
						userState={userState}
						handleSubmit={updateProfileApiCall}
					/>
				);
			case 2:
				return <AccountComp />;
			default:
				return null;
		}
	};

	if (userState) {
		return (
			<>
				<Container maxWidth="xl" style={{margin: "30px auto"}}>
					<Box>
						<Typography variant="h2" style={{color: "#666"}}>
							<b>Profile</b>
							<br />
						</Typography>
						<Typography variant="h5" style={{color: "#666", marginTop: "5px"}}>
							<>Add, View and Update Profile Information.</>
						</Typography>
						<div className={classes.selectSwitch}>
							<SwitchSelector
								onChange={(e) => {
									setSelected(e);
								}}
								options={[
									{label: "USER INFORMATION", value: 0},
									// {label: "TAX INFORMATION", value: 1},
									{label: "LOGIN METHOD", value: 1},
									{label: "ACCOUNT", value: 2},
								]}
								initialSelectedIndex={selected}
								forcedSelectedIndex={selected}
								fontColor="#000"
								optionBorderRadius="6"
								selectedBackgroundColor="#FF800C"
							/>
						</div>
					</Box>
					<RendorComp />
				</Container>
			</>
		);
	} else return null;
}

export default Account;
