import Home from "./Components/Pages/StaticUiPages/Home/Home";
import HotQrCodeGenerator from "./Components/Pages/StaticUiPages/CodeGenerator";
import HotQrBatchGenerator from "./Components/Pages/StaticUiPages/BatchGenerator";
import HotQrAPIForStaticQrCodes from "./Components/Pages/StaticUiPages/APIForStaticQRCodes";
import HOTQrCodeGenerationandDesignSDK from "./Components/Pages/StaticUiPages/Generation&DesignSDK";

import Login from "./Components/Pages/Session/Login/Login";
// import SignUp from './Components/Pages/Session/SignUp/SignUpRouting';

import ListQr from "./Components/Pages/Main/ManageQr/ListQr/ListQrCodes";
import ViewQr from "./Components/Pages/Main/ManageQr/ViewQr/ViewQr";
import EditQrContent from "./Components/Pages/Main/ManageQr/EditQr/EditQrContent";
import EditQrSettings from "./Components/Pages/Main/ManageQr/EditQr/EditQrSettings";
import CustomDesign from "./Components/Pages/Main/ManageQr/CustomizeQr/CustomDesign";
import CustomBackground from "./Components/Pages/Main/ManageQr/CustomizeQr/CustomBackground";

import CreateQR from "./Components/Pages/Main/CreateQR/CreateQR";
import Trash from "./Components/Pages/Main/Trash/Trash";

import Subscription from "./Components/Pages/Main/Subscription/Subscription";
import Account from "./Components/Pages/Main/Account/Account";
import Analytics from "./Components/Pages/Main/Analytics/Analytics";
import ListLeads from "./Components/Pages/Main/Leads/ListLeads";
import CreateLead from "./Components/Pages/Main/Leads/CreateLead";
import ManageLead from "./Components/Pages/Main/Leads/ManageLead";
import Settings from "./Components/Pages/Main/Settings/Settings";

import Layout from "./Components/Main/MainLayout";

import {useRoutes} from "react-router";

export default function Router() {
	return useRoutes([
		{
			path: "/",
			element: <Home />,
		},
		{
			path: "/hotqrcodegenerator",
			element: <HotQrCodeGenerator />,
		},
		{
			path: "/hotqrbatchgenerator",
			label: "Main",
			element: <HotQrBatchGenerator />,
		},
		{
			path: "/hotqrapiforstaticqrcodes",
			label: "Main",
			element: <HotQrAPIForStaticQrCodes />,
		},
		{
			path: "/hotqrcodegeneration&designsdk",
			label: "Main",
			element: <HOTQrCodeGenerationandDesignSDK />,
		},
		{
			path: "/login",
			label: "Login",
			element: <Login />,
		},
		{
			path: "/qr",
			element: <Layout />,
			children: [
				{
					index: true,
					element: <ListQr />,
				},
				{
					path: "view/:qrId",
					element: <ViewQr />,
				},
				{
					path: "analytics/:id",
					element: <Analytics />,
				},
				{
					path: "edit/content/:qrId",
					element: <EditQrContent />,
				},
				{
					path: "edit/settings/:qrId",
					element: <EditQrSettings />,
				},
				{
					path: "customize/design/:qrId",
					element: <CustomDesign />,
				},
				{
					path: "customize/background/:qrId",
					element: <CustomBackground />,
				},
				{
					path: "leads",
					children: [
						{
							index: true,
							element: <ListLeads />,
						},
						{
							path: "create",
							element: <CreateLead />,
						},
						{
							path: "manage/:leadId",
							element: <ManageLead />,
						},
					],
				},
				{
					path: "create",
					element: <CreateQR />,
				},
				{
					path: "trash",
					element: <Trash />,
				},
				{
					path: "analytics",
					element: <Analytics />,
				},
				{
					path: "subscription",
					element: <Subscription />,
				},
				{
					path: "account",
					element: <Account />,
				},
				{
					path: "settings",
					element: <Settings />,
				},
			],
		},
	]);
}
