import * as React from "react";
import {Box, Grid} from "@material-ui/core";

import classes from "./Page3.module.css";

import toolIcon1 from "../../../../../Assets/BatchGenerator/toolIcon1.svg";
import toolIcon2 from "../../../../../Assets/BatchGenerator/toolIcon2.svg";
import toolIcon3 from "../../../../../Assets/BatchGenerator/toolIcon3.svg";
import toolIcon4 from "../../../../../Assets/BatchGenerator/toolIcon4.svg";
import toolIcon5 from "../../../../../Assets/BatchGenerator/toolIcon5.svg";
import toolIcon6 from "../../../../../Assets/BatchGenerator/toolIcon6.svg";
import toolIcon7 from "../../../../../Assets/BatchGenerator/toolIcon7.svg";
import toolIcon8 from "../../../../../Assets/BatchGenerator/toolIcon8.svg";

const gridArray = [
	{
		img: toolIcon1,
		title: "Business Cards",
		text: "Add a unique Vcard QR Code on the business card of each member of your company",
	},
	{
		img: toolIcon2,
		title: "Labels",
		text: "Generate printable QR Code Labels (PDF, DXF) for inventory management or product information",
	},
	{
		img: toolIcon3,
		title: "Trails",
		text: "Mark point-of-interests on nature/botanical trails with unique URL QR Codes linked to engaging information",
	},
	{
		img: toolIcon4,
		title: "City Buildings",
		text: "Mark city buildings or tourist spots across city with unique QR Codes that share important information to administrators or tourists",
	},
	{
		img: toolIcon5,
		title: "Identification",
		text: "Add a QR Code with a unique serial or random code on company ID cards for digital verification",
	},
	{
		img: toolIcon6,
		title: "Survey",
		text: "Share printed flyers with unique QR Codes that link to online surveys",
	},
	{
		img: toolIcon7,
		title: "Product Authentication",
		text: "Add unique QR Code with activation code on each product to prevent counterfeiting",
	},
	{
		img: toolIcon8,
		title: "Your Use Case",
		text: "Bulk QR Code Generator can be used for any use case that requires large number of QR Codes",
	},
];

function Page3() {
	return (
		<Box className={classes.box}>
			<Grid container>
				<Grid item xs={12} className="d-flex flex-column align-items-center">
					<label className={classes.title}>
						{" "}
						Powerful Tool for Multiple QR Code Use Cases{" "}
					</label>
					<span className={classes.subTitle}>
						{" "}
						HOT QR's Code Batch Generator is useful for many QR Code-based
						applications{" "}
					</span>
				</Grid>
				<Grid item container xs={12} spacing={4} className="mt-2">
					{gridArray.map((ele, index) => (
						<Grid
							key={index}
							item
							xs={index > 5 ? 6 : 4}
							className="d-flex justify-content-center"
						>
							<Box className={classes.boxContent}>
								<img
									className={classes.toolIcon}
									alt="ToolIcon"
									src={ele.img}
								/>
								<Box className={classes.toolText}>
									<span style={{color: "#000000"}} className="mt-4">
										{ele.title}
									</span>
									<span className="mt-3">{ele.text}</span>
								</Box>
							</Box>
						</Grid>
					))}
				</Grid>
			</Grid>
		</Box>
	);
}

export default Page3;
