import React from "react";
import {Box, Typography, Button, Grid} from "@material-ui/core";

import SwitchSelector from "react-switch-selector";
import {makeStyles} from "@material-ui/core-v4";

const useStyles = makeStyles((theme) => ({
	iconInfo: {
		color: "#999",
		"&:hover": {
			color: "#C86409",
		},
	},
	center: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	selectSwitch: {
		width: 400,
		height: 38,
		"& div": {
			borderRadius: "4px",
		},
	},
}));

function Background(props) {
	const classes = useStyles();

	const {handleData, handleDeleteData, apiCall, data} = props;

	const [bgSelect, setBgSelect] = React.useState(1);
	const [state, setState] = React.useState("#E0E0E0");

	React.useEffect(() => {
		setBgSelect(
			data.filter((ele) => ele.index === 4).length > 0
				? data.filter((ele) => ele.index === 4)[0].backgroundColour
					? 0
					: 1
				: 1
		);
		setState(
			data.filter((ele) => ele.index === 4).length > 0
				? data.filter((ele) => ele.index === 4)[0].backgroundColour
				: "#E0E0E0"
		);
	}, [data]);

	const Comp = () => {
		switch (bgSelect) {
			case 0:
				return (
					<React.Fragment>
						<Box>
							<Grid container spacing={3}>
								<Grid item md={4} lg={4} xl={3}>
									<Box
										style={{
											height: "40px",
											background: "#E0E0E0",
											border: "1px solid #999",
											borderRadius: "2px",
											display: "flex",
										}}
									>
										<Box
											style={{
												height: "40px",
												width: "40px",
												borderRight: "1px solid #999",
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
											}}
										>
											#
										</Box>
										<Typography
											style={{
												height: "40px",
												width: "100%",
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
											}}
										>
											{state && state.replace("#", "").toUpperCase()}
										</Typography>
									</Box>
								</Grid>
								<Grid item md={4} lg={4} xl={3}>
									<input
										style={{
											width: "100%",
											height: "40px",
										}}
										type="color"
										onChange={(e) => {
											setState(e.target.value);
										}}
										value={state}
									/>
								</Grid>
								<Grid item md={4} lg={4} xl={3}>
									<Button
										variant="outlined"
										onClick={() => {
											handleData({backgroundColour: state}, 4);
											apiCall({backgroundColour: state});
										}}
										style={{marginTop: "2px"}}
									>
										Apply Color
									</Button>
								</Grid>
							</Grid>
						</Box>
						<Typography
							variant="h5"
							style={{color: "#666", fontSize: "18px", marginTop: "20px"}}
						>
							<b>Note: </b>
							<span style={{color: "#888", fontSize: "16px"}}>
								Some colors may not be available to ensure that the QR Code
								remains scannable
							</span>
						</Typography>
					</React.Fragment>
				);
			case 1:
				return (
					<Typography
						variant="h5"
						style={{
							color: "#666",
							display: "flex",
							flexDirection: "column",
							fontSize: "16px",
						}}
					>
						<b>QR Code background is transparent now.</b>
						<p style={{marginTop: "10px"}}>
							<b>Warning: </b>
							<span style={{color: "#888", fontSize: "14px"}}>
								Please ensure high contrast between transparent QR Code and the
								background it’ll be placed on i.e., the background should be
								light if the QR Code is dark. Thoroughly test scannability by
								placing QR Code on the intended surface. We do not take
								responsibility for the QR Code not being scannable.
							</span>
						</p>
					</Typography>
				);
			default:
				return null;
		}
	};

	return (
		<Box
			style={{
				marginTop: "10px",
				border: "1px solid #E0E0E0",
				padding: "20px",
				borderRadius: "8px",
			}}
		>
			<div className={classes.selectSwitch}>
				<SwitchSelector
					onChange={(e) => {
						setBgSelect(e);
						if (e === 1) {
							handleDeleteData(4);
						}
					}}
					options={[
						{label: "Add Color", value: 0},
						{label: "Make Transparent", value: 1},
					]}
					initialSelectedIndex={bgSelect}
					forcedSelectedIndex={bgSelect}
					fontColor="#444"
					fontSize="18"
					optionBorderRadius="4"
					selectedBackgroundColor="#FF800C"
				/>
			</div>
			<Box style={{marginTop: "30px"}}>{Comp()}</Box>
		</Box>
	);
}

export default Background;
