import * as React from "react";
import {Box} from "@material-ui/core";

import classes from "./Page1.module.css";

import HOTQr from "../../../../../Assets/HOTQr.png";

function Page1({scrollToPage3, scrollToSupport}) {
	return (
		<Box className={classes.box}>
			<Box className={classes.boxContent}>
				<Box
					onClick={() => window.location.reload()}
					className={classes.boxLogo}
				>
					<img className={classes.HOTQr} alt="logo" src={HOTQr} />
				</Box>
				<Box className={classes.boxMenu}>
					<label
						className={classes.boxMenuItems}
						onClick={scrollToPage3}
						style={{left: "2.93rem"}}
					>
						Products
					</label>
					<label className={classes.boxMenuItems} style={{left: "14.37rem"}}>
						Blog
					</label>
					<label
						className={classes.boxMenuItems}
						onClick={scrollToSupport}
						style={{right: "2.25rem"}}
					>
						Support
					</label>
				</Box>
				<Box className="d-flex flex-column align-items-center">
					<label className={classes.labelTitle}>HOT QR Code Solutions</label>
					<label className={classes.labelText}>
						QR codes are among those emerging technologies that can have a
						significant influence in the buying decision-making process and can
						contribute to improve the shopping experience of the modern
						consumer.
						<br />
						<br />
						QR codes are described in literature as tools that provide adequate,
						accurate, and customizableinformation and that allow the user to
						access the information anywhere and anytime.
					</label>
				</Box>
			</Box>
		</Box>
	);
}

export default Page1;
