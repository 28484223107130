import React from "react";
import { Grid } from '@material-ui/core';

function CompanyBrandingComp(props){

    const { data } = props

    const { image } = data

    if(image.length > 0){
        return(
            <Grid container>
                <Grid item xs={12} style={{ display: 'flex', alignItems :'center' }}>
                    <img src={image.length > 0 && image[0].url} alt='' style={{ width: '100%', height: '150px', objectFit: 'contain' }} />
                </Grid>
            </Grid>
        )
    } else {
        return null
    }
}
export default CompanyBrandingComp;