import React from "react";
import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Typography,
	Box,
	Grid,
	TextField,
	IconButton,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core-v4";
import {ExpandMore, Delete} from "@material-ui/icons";

import LocationComp from "./Components/LocationComp";

import BorderColorComp from "./ColorComponents/BorderColor";
import BgColorComp from "./ColorComponents/BgColor";
import TextColorComp from "./ColorComponents/TextColor";
import QrContext from "../../../QrContext/QrContext";

const useStyles = makeStyles((theme) => ({
	box: {
		"& .MuiPaper-root": {
			boxShadow: "none !important",
		},
		"& .MuiPaper-root > .MuiButtonBase-root": {
			border: "1px solid #000",
			borderRadius: "2px",
			background: "#F0F0F0",
			height: "50px !important",
		},
		"& .MuiPaper-root > .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded":
			{
				minHeight: "50px !important",
			},
		"& .MuiPaper-root > .MuiCollapse-root": {
			border: "1px solid #D8D8D8",
			margin: "10px 0",
			borderRadius: "2px",
		},
	},
	center: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	card: {
		width: "100%",
		"& div > div": {
			height: "40px",
			borderRadius: "0 2px 2px 0",
		},
		"& .MuiFormControl-root > .MuiOutlinedInput-root > input ": {
			marginTop: "-3px",
		},
		"& .MuiFormControl-root > .MuiOutlinedInput-root > .MuiInputAdornment-root > .MuiButtonBase-root ":
			{
				marginTop: "-7px",
			},
	},
}));

function MapLocationComp(props) {
	const classes = useStyles();
	const {setContent} = React.useContext(QrContext);

	const [expanded, setExpanded] = React.useState(false);

	// const [ place, setPlace ] = React.useState([])

	// const timeZoneUrl = 'http://worldtimeapi.org/api/timezone'

	// const placeSearchApiCall = React.useCallback((payload) => {
	//     if(payload !== '' && payload !== undefined && payload !== null){
	//         axios.get('https://znxmcz8e57.execute-api.ap-southeast-1.amazonaws.com/dev/' + payload).then(res => {
	//             setPlace(res.data.data.predictions)
	//         }).catch(err => {
	//             console.error(err)
	//         })
	//     }
	// },[])

	if (props.data) {
		const {data, dataIndex} = props;

		const {bgColor, borderColor, label, textColor} = data;

		const handleChange = (key, value) => {
			setContent((prev) =>
				prev.map((ele, index) => {
					if (index === dataIndex) {
						return {
							...ele,
							[key]: value,
						};
					} else return ele;
				})
			);
		};
		const handleDelete = () => {
			setContent((prev) =>
				[...prev].filter((ele, index) => dataIndex !== index)
			);
		};
		return (
			<div className={classes.box}>
				<Accordion onChange={(e, data) => setExpanded(data)}>
					<AccordionSummary expandIcon={<ExpandMore />}>
						<Box
							style={{
								display: "flex",
								justifyContent: "space-between",
								width: "100%",
								alignItems: "center",
							}}
						>
							<Typography
								style={{color: "#615757", fontSize: "20px", fontWeight: "600"}}
							>
								Map Location
							</Typography>
							{expanded && (
								<IconButton
									style={{marginRight: "15px"}}
									onClick={() => handleDelete()}
								>
									<Delete />
								</IconButton>
							)}
						</Box>
					</AccordionSummary>
					<AccordionDetails style={{display: "flex", alignItems: "center"}}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Grid container spacing={1}>
									<Grid item xs={12}>
										<Typography variant="h5">Button Text</Typography>
									</Grid>
									<Grid item xs={12}>
										<Box className={classes.card}>
											<TextField
												type="text"
												color="primary"
												name="label"
												onChange={(e) => {
													handleChange(e.target.name, e.target.value);
												}}
												fullWidth
												value={label}
											/>
										</Box>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={12}>
								<Box
									style={{
										position: "relative",
										height: "450px",
										border: "1px solid #999",
									}}
								>
									<LocationComp
										height="350px"
										handleChange={(key, value) => handleChange(key, value)}
									/>
								</Box>
							</Grid>
							<Grid item xs={12}>
								<BgColorComp
									bgColor={bgColor}
									handleChange={(key, value) => handleChange(key, value)}
									name="Button Background Color"
								/>
							</Grid>
							<Grid item xs={12}>
								<TextColorComp
									textColor={textColor}
									handleChange={(key, value) => handleChange(key, value)}
									name="Button Text Color"
								/>
							</Grid>
							<Grid item xs={12}>
								<BorderColorComp
									borderColor={borderColor}
									handleChange={(key, value) => handleChange(key, value)}
									name="Button Border Color"
								/>
							</Grid>
						</Grid>
					</AccordionDetails>
				</Accordion>
			</div>
		);
	} else {
		return null;
	}
}
export default MapLocationComp;
