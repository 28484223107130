import {TextField} from "@material-ui/core";
import {experimentalStyled as styles} from "@material-ui/core/styles";

const CssTextField = styles(TextField)({
	"& label.MuiFormLabel-root": {
		color: "#FFF",
		width: "auto",
		fontFamily: "Raleway",
		fontStyle: "normal",
		fontWeight: "500",
		fontSize: "18px",
		textAlign: "center",
		lineHeight: "16px",
		backgroundColor: "#FF800C",
	},
	"& label.Mui-focused": {
		color: "#FFF",
		width: "auto",
		backgroundColor: "#FF800C",
		textAlign: "left !important",
		left: "15px",
	},

	"& label.MuiFormLabel-filled": {
		textAlign: "left !important",
		left: "15px",
	},
	"& .MuiOutlinedInput-notchedOutline": {
		padding: "0 20px",
	},
	"& .MuiInput-underline:after": {
		borderBottomColor: "#FF800C",
		borderWidth: 6,
	},
	"& .MuiOutlinedInput-root": {
		color: "#FFF",
		"& fieldset": {
			borderColor: "#FFF",
			borderWidth: "2px",
			borderRadius: "14px",
			color: "#FFF",
		},
		"&:hover fieldset": {
			borderColor: "#FFF",
			borderWidth: "2px",
			borderRadius: "14px",
			color: "#FFF",
		},
		"&.Mui-focused fieldset": {
			borderColor: "#FFF",
			borderWidth: "2px",
			borderRadius: "14px",
			color: "#FFF",
		},
	},
	"& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
		color: "#FFF !important",
		fontSize: "18px",
	},
});

export default CssTextField;
// #E8F0FE
