import React from "react";
import { Button } from '@material-ui/core'

function ButtonComp(props){

    const { data } = props 
    const { btnTextColor, btnBgColor, btnBorderColor, btnText, files } = data

    return(
        <Button
            style={{
                marginTop: '10px',
                color: btnTextColor,
                background: btnBgColor,
                border: '1px solid ' + btnBorderColor
            }}
            variant='contained'
            fullWidth
            onClick={() => {
                var a = document.createElement("a");
                a.href = files.length > 0 && files[0].url;
                a.download = files.length > 0 && files[0].name ? files[0].name : 'dowload';
                a.click();
            }}
        >
            {btnText}
        </Button>
    )
}
export default ButtonComp;