import * as React from "react";
import {Box, Grid} from "@material-ui/core";

import classes from "./Page3.module.css";

const tableArray = [
	{
		title: "Create Dynamic QR codes",
		text: "With HotQr's Dynamic QR Codes, you can edit the content (such as links) anytime without the need to change the QR Code. The best marketers worldwide use Dynamic QR Codes on print promotions to gain flexibility and save printing costs. Unlike Static QR Codes, these QR Codes can also be tracked, allowing you to assess the performance of your QR Code campaigns.",
	},
	{
		title: "Custom QR Code Designs",
		text: "HotQr's QR Code designer allows you generate highly personalized QR Code designs. You can design the QR Code by editing colors and patterns, and by adding your brand logo either in the centre or as a background image with HotQr's easy-to-use DIY tool without the need of a graphic designer. These branded QR Codes will stand out from the crowd of plain black-and-white QR Codes and attract more scans from your target audience.",
	},
	{
		title: "Scan Tracking & Analytics",
		text: "Track the performance of your QR Code campaigns with HotQr's Scan Tracking & Analytics feature. On creating Dynamic QR Codes, get insightful data such as number of scans by date, location coordinates, city, country and device via detailed info-graphics. You can also export specific data in CSV and PDF formats. Furthermore, you can integrate HotQr Analytics with Google Analytics or Webhooks, allowing you to view all data in one place.",
	},
	{
		title: "Link Engaging Content",
		text: "With HotQr's QR Code Generator, you can generate any type of QR Code you need, depending upon the content you want to share or action you want users to take. Examples include Website URL, Social Media, Vcard Profile, PDF, Image, Google Maps, Product Description, Wedding, Audio, Coupon, App Store, and Text.",
	},
	{
		title: "Create Mobile-Optimized Pages",
		text: "Don't have a website setup yet? Need a quick promotional page? Create and link custom mobile landing pages in minutes with HotQr's QR Codes without the need of a web developer. Page templates include Social Media Profile Links, Product Description, Coupon, Vcard Profile, Wedding Invitation, and Rich Text. These landing pages are mobile-optimized and give a good user experience.",
	},
	{
		title: "Export High-Resolution QR Codes",
		text: "Export QR Code images in high-resolution vector formats for printing or editing. Vector images do not blur or pixelate when you increase the image size which makes them perfect for large prints. Export the images in PNG, JPG, SVG, EPS, PS, and PDF, i.e., all popular formats accepted by designers and printers.",
	},
	{
		title: "Get Leads",
		text: "HotQr can not only track QR Code scans but also allows you to capture the contact information of your target audience members, allowing you to build high-quality leads list. The leads form can be easily customized with required fields allowing you to be GDPR compliant.The captured leads information can be exported in CSV format or integrated with your own CRM via webhooks. These sales leads can be used for remarketing via future campaigns.",
	},
	{
		title: "Use Enterprise Features",
		text: "For enterprise-level requirements, HotQr provides features such as tags, team access, and white-labeling. With tags, you can mark QR Codes with custom tags and get tag-level analytics. With the Users feature, give account access to collaborators based on permission-level—Viewer, Manager, Administrator. Through white-labeling, remove all HotQr branding from QR Codes and landing pages",
	},
];

function Page3() {
	return (
		<Box className={classes.box}>
			<Grid container>
				<Grid item xs={12} className="d-flex flex-column align-items-center">
					<label className={classes.title}>
						Do more with HOT QR Code Generator
					</label>
					<span className={classes.subTitle}>
						HOT QR’s advanced features will help you create, design, manage, and
						track QR Codes in no time
					</span>
				</Grid>
				<Grid item xs={12} className="d-flex justify-content-center">
					<Box className={classes.boxTable}>
						<Box>
							<Grid container>
								{tableArray.map((ele, index) => (
									<React.Fragment>
										<Grid
											item
											key={index}
											xs={5}
											className="d-flex justify-content-center align-items-center"
										>
											<span className={classes.tableLeftText}>{ele.title}</span>
										</Grid>
										<Grid
											item
											xs={1}
											className="d-flex justify-content-center align-items-center"
										>
											<Box className={classes.tableMiddleColumn}></Box>
										</Grid>
										<Grid
											item
											xs={6}
											className="d-flex justify-content-center align-items-center py-4 px-2"
										>
											<span
												className={classes.tableRightText}
												style={{top: "30px"}}
											>
												{ele.text}
											</span>
										</Grid>
										<Grid item xs={12}>
											<Box className={classes.tableRow}></Box>
										</Grid>
									</React.Fragment>
								))}
							</Grid>
						</Box>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
}

export default Page3;
