import React from "react";

// ----------------- import Components ------------------ //
import {Box, Grid, CircularProgress} from "@material-ui/core";
// import Lottie from "lottie-react";

// ----------------- import Files ------------------ //
// import error from '../../../../../Assets/Animation/error.json';
// import notFound from '../../../../../Assets/Animation/notFound.json';

// ----------------- import icons ------------------ //
import IconQr from "../../../../../Assets/Dashboard/qr.svg";

// ----------------- import Styles ------------------ //
import classes from "./style.module.css";

// ----------------- import Context ------------------ //
import AuthContext from "../../../../../Context/AuthContext";
import ContentArray from "./ContentComponents/ContentArray";
import AdditionalFieldsArray from "./ContentComponents/AdditionalFieldsArray";
import QrContext from "../QrContext/QrContext";

function HotQrTypes(props) {
	const {qrTypes, setCategory, setAdditionalContent, setStepModel} =
		React.useContext(AuthContext);
	const {setContent} = React.useContext(QrContext);

	const handleSelectCategory = (ele) => {
		setContent(ContentArray(ele.title));
		setAdditionalContent(AdditionalFieldsArray(ele.title));
		setCategory(ele);
		setStepModel((prev) => prev.next());
	};

	if (qrTypes && qrTypes.length > 0) {
		return (
			<Box style={{height: "auto", width: "auto", marginBottom: "50px"}}>
				<Grid container>
					{qrTypes.map((ele, index) => {
						if (ele.title === "App Deep Link QR code") return null;
						return (
							<Grid
								key={index}
								className={classes.gridItems}
								item
								xl={2}
								lg={3}
								md={3}
								sm={6}
								xs={12}
							>
								<Box
									className={classes.boxContent}
									style={{marginRight: "10px"}}
									onClick={() => handleSelectCategory(ele)}
								>
									<label className={classes.labelTitle}>{ele.title}</label>
									<img
										className={classes.Icon}
										alt="Icons"
										src={ele.displayImageUrl}
									/>
									<img alt="" className={classes.IconQr} src={IconQr} />
								</Box>
							</Grid>
						);
					})}
				</Grid>
			</Box>
		);
	} else {
		return (
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					height: "80vh",
					width: "100%",
				}}
			>
				<CircularProgress />
			</div>
		);
	}
}

export default HotQrTypes;
