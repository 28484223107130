import axios from "axios";
import {urls} from "../Environment/ApiUrl";
import Setting from "../Environment/Setting";

let setting = new Setting();

let body = {
	tid: "",
	sid: "",
	payload: {},
};

let http = (method) => {
	switch (method) {
		case "PUT":
			return axios.put;
		case "POST":
			return axios.post;
		default:
			return null;
	}
};

let postApiCall = (url, actionCode, payload, method, noToken) => {
	let promise = new Promise((resolve, reject) => {
		body.tid = Date.now() + 30000;
		body.sid = setting.setSecurity(
			urls.headers["itpl-client-id"],
			Date.now() + 30000
		);
		// body.actionCode = actionCode;
		body.payload = payload;
		if (actionCode !== "") {
			body = {...body, actionCode: actionCode};
		}
		http(method)(
			url,
			{...body.payload},
			{
				headers: {
					Authorization: localStorage.getItem("token")
						? "Bearer " + localStorage.getItem("token")
						: "Basic aXRwbDppd2FudHVubGltaXRlZA==",
					// "itpl-app-name": "Merchant-Registration-App",
					// "itpl-app-version": "1.0-101",
					// "itpl-app-type": "web",
					// 'itpl-client-id':"SS7052021-001",
					// 'itpl-app-os': 'web',
				},
			}
		)
			.then((response) => {
				resolve(response);
			})
			.catch((err) => {
				console.error(`[${actionCode}]Api Call Failed : ${err}`);
				reject(err);
			});
	});
	return promise;
};

let executePost = (url, actionCode, payload, method, noToken) => {
	let promise = new Promise((resolve, reject) => {
		postApiCall(url, actionCode, payload, method, noToken)
			.then((response) => {
				let {statusCode, data, message} = response.data;
				if (statusCode === 0) {
					resolve(data);
				} else {
					reject(statusCode + ":" + message);
				}
			})
			.catch((err) => {
				console.error(err);
				reject(err);
			});
	});

	return promise;
};

export default postApiCall;
export {executePost};
