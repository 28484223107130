import * as React from "react";
import {Box, Grid} from "@material-ui/core";

import classes from "./Page7.module.css";

import Feature1Icon from "../../../../../Assets/Home/Page7Svg/Feature1.svg";
import Feature2Icon from "../../../../../Assets/Home/Page7Svg/Feature2.svg";
import Feature3Icon from "../../../../../Assets/Home/Page7Svg/Feature3.svg";
import Feature4Icon from "../../../../../Assets/Home/Page7Svg/Feature4.svg";
import Feature5Icon from "../../../../../Assets/Home/Page7Svg/Feature5.svg";
import Feature6Icon from "../../../../../Assets/Home/Page7Svg/Feature6.svg";
import {useNavigate} from "react-router";

function Page7(props) {
	const navigate = useNavigate();
	const handleClickTool = () => {
		navigate("/hotqrcodegeneration&designsdk");
	};
	return (
		<Box className={classes.box}>
			<Grid container spacing={2}>
				<Grid item xs={12} className="d-flex justify-content-between">
					<label className={classes.labelTitle}>
						{" "}
						HOT QR Generation & Design SDK{" "}
					</label>
					<Box className={classes.btnTool} onClick={handleClickTool}>
						<span className={classes.btnToolText}> Tool </span>
					</Box>
				</Grid>
				<Grid item xs={12}>
					<label className={classes.labelFeatures}> Features </label>
				</Grid>
				<Grid
					item
					xs={4}
					className="d-flex justify-content-center align-items-center"
				>
					<Box className={classes.boxFeaturesSub}>
						<img
							className={classes.boxFeaturesIcon}
							alt="Feature1Icon"
							src={Feature1Icon}
						/>
						<label className={classes.boxFeaturesText}>
							<span style={{color: "#000000"}}> Static QR Codes </span>
							<br />
							<br /> Encode static QR Codes with URL, text, Vcard, WiFi, Phone,
							Email, and SMS content
						</label>
					</Box>
				</Grid>
				<Grid
					item
					xs={4}
					className="d-flex justify-content-center align-items-center"
				>
					<Box className={classes.boxFeaturesSub}>
						<img
							className={classes.boxFeaturesIcon}
							alt="Feature1Icon"
							src={Feature2Icon}
						/>
						<label className={classes.boxFeaturesText}>
							<span style={{color: "#000000"}}> Real-time Generation </span>
							<br /> Generate QR Codes programmatically using your own servers
							in your own system via REST API calls
						</label>
					</Box>
				</Grid>
				<Grid
					item
					xs={4}
					className="d-flex justify-content-center align-items-center"
				>
					<Box className={classes.boxFeaturesSub}>
						<img
							className={classes.boxFeaturesIcon}
							alt="Feature1Icon"
							src={Feature3Icon}
						/>
						<label className={classes.boxFeaturesText}>
							<span style={{color: "#000000"}}> Unlimited QR Codes/month </span>
							<br />
							<br /> Generate any number of QR Codes on demand
						</label>
					</Box>
				</Grid>
				<Grid
					item
					xs={4}
					className="d-flex justify-content-center align-items-center mt-3"
				>
					<Box className={classes.boxFeaturesSub}>
						<img
							className={classes.boxFeaturesIcon}
							alt="Feature1Icon"
							src={Feature4Icon}
						/>
						<label className={classes.boxFeaturesText}>
							<span style={{color: "#000000"}}> Customize QR Code Design </span>
							<br />
							<br /> Add brand colors, patterns, and logo to QR Code design
						</label>
					</Box>
				</Grid>
				<Grid
					item
					xs={4}
					className="d-flex justify-content-center align-items-center mt-3"
				>
					<Box className={classes.boxFeaturesSub}>
						<img
							className={classes.boxFeaturesIcon}
							alt="Feature1Icon"
							src={Feature5Icon}
						/>
						<label className={classes.boxFeaturesText}>
							<span style={{color: "#000000"}}> Print-ready QR Codes </span>
							<br />
							<br /> Export QR Code images in PNG, JPG, and SVG
						</label>
					</Box>
				</Grid>
				<Grid
					item
					xs={4}
					className="d-flex justify-content-center align-items-center mt-3"
				>
					<Box className={classes.boxFeaturesSub}>
						<img
							className={classes.boxFeaturesIcon}
							alt="Feature1Icon"
							src={Feature6Icon}
							style={{width: "56px", height: "30px", left: "99px"}}
						/>
						<label className={classes.boxFeaturesText}>
							<span style={{color: "#000000"}}>
								{" "}
								Lifetime License and Support{" "}
							</span>
							<br />
							<br /> Get lifetime worldwide license and 6 months support
						</label>
					</Box>
				</Grid>
				<Grid item xs={12} className="d-flex justify-content-center mt-5">
					<Box className={classes.boxTable}>
						<Box className={classes.boxMiddleBar} />
						<Box className={classes.boxTableTitleBar}>
							<label className={classes.boxTableTitleText}> Use Cases </label>
						</Box>
						<label
							className={classes.boxTableContentText}
							style={{left: "108px", top: "98px"}}
						>
							{" "}
							QR Code Payments{" "}
						</label>
						<label
							className={classes.boxTableContentText}
							style={{left: "552px", top: "98px"}}
						>
							{" "}
							Inventory Management{" "}
						</label>
						<Box className={classes.boxTableRowBar} style={{top: "149px"}} />
						<label
							className={classes.boxTableContentText}
							style={{left: "108px", top: "180px"}}
						>
							{" "}
							Boarding Passes{" "}
						</label>
						<label
							className={classes.boxTableContentText}
							style={{left: "552px", top: "180px"}}
						>
							{" "}
							Social Media Interactions{" "}
						</label>
						<Box className={classes.boxTableRowBar} style={{top: "238px"}} />
						<label
							className={classes.boxTableContentText}
							style={{left: "312px", top: "272px"}}
						>
							{" "}
							Product Tags and Packaging{" "}
						</label>
						<Box className={classes.boxTableRowBar} style={{top: "327px"}} />
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
}

export default Page7;
