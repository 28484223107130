import * as React from "react";
import {Box, Grid, Card} from "@material-ui/core";

import classes from "./Page4.module.css";

const gridArray = [
	{
		title: "Add Design to QR Codes",
		text: "People often confuse plain black-and-white marketing QR Codes with barcodes used for operational use cases. By adding design and branding, give life to your QR Codes and make them visually appealing. Not only are designer QR Codes pleasing to look at but they also do not mess up creative designs on print media. Personalized QR Codes can attract upto 50-200% more scans than standard black-and-white QR Codes.",
	},
	{
		title: "Add Call-to-Action Text",
		text: 'If you are planning to add a QR Code to your print promotions, you should add call-to-action (CTA) text that informs the target audience of what the QR Code does. For example: "Scan to Watch Trailer", "Scan to Donate", "Scan to Read Full Article", "Scan for Details", "Follow on Instagram", etc. Only curious users will scan a QR Code if there is no CTA. If you want your target audience to scan the QR Code, give them an incentive. Pro Tip: Add CTA image in the centre of the QR Code if youre short of space',
	},
	{
		title: "Ensure Size and Scannability",
		text: "It is frustrating for the users if they have to point their cameras on a QR Code and nothing happens. Scannability of a QR Code depends on size, color contrast, scanning distance, and lighting. For example, the minimum size of a QR should at least be 1 inch or 3 cm or 115 pixels (in width and height). Always test the QR Code before placing it on your promotional material. Pro Tip: Use Dynamic QR Codes that convert lengthy URLs into short URLs to make the QR Code simpler and less dense.",
	},
	{
		title: "Ensure Accessibility",
		text: "A QR Code can only be scanned if it is easily accessible to the target audience. You need to ensure both safe scanning distance and easy access to mobile internet. For example, it will be difficult to get scans on QR Codes that are placed on moving vehicles, far-off billboards, and bottom of printed material reaching ground-level. Similarly, promotions placed in areas with limited access to fast mobile network/Wifi—inflight magazines and underground subway stations—will also get no scans. So make it easy for your target audience to reach the QR Code.",
	},
	{
		title: "Link to Mobile-Optimized Content",
		text: "Nobody scans a QR Code using a laptop. QR Codes are meant to be scanned by mobile devices. If the landing page is not mobile-optimized, it will definitely be the end of the customers engagement.",
		text1:
			"With increasing use of mobile applications, consumers are used to mobile-optimized UIs. And that is what they expect from QR Codes–an easy mobile viewing experience. Pro Tip: Create a custom mobile page using HotQr if you dont have one",
	},
];

function Page4() {
	return (
		<Box className={classes.box}>
			<Grid container>
				<Grid item xs={12} className="d-flex flex-column align-items-center">
					<label className={classes.title}> QR Code Best Practices </label>
					<span className={classes.subTitle}>
						Tips to increase scans by over 100%
					</span>
				</Grid>
				<Grid
					item
					container
					xs={12}
					spacing={4}
					style={{marginTop: "10px", display: "flex", justifyContent: "center"}}
				>
					{gridArray.map((ele, index) => (
						<Grid
							item
							xs={4}
							key={index}
							className="d-flex justify-content-center align-items-center"
						>
							<Card
								className={classes.labelText}
								style={{width: "100%", height: "100%"}}
							>
								<Box style={{marginBottom: "10px"}}>
									<span
										style={{
											color: "#000000",
											fontSize: "20px",
											fontWeight: "500",
										}}
									>
										{ele.title}
									</span>
								</Box>
								<Box>
									{ele.text1 ? (
										<span className="mt-3">
											{ele.text}
											<br /> <br />
											{ele.text1}
										</span>
									) : (
										<span className="mt-3">{ele.text}</span>
									)}
								</Box>
							</Card>
						</Grid>
					))}
					{/* {gridArray.map((ele, index) => (
            <Grid
              item
              xs={index < 3 ? 4 : 6}
              className="d-flex justify-content-center align-items-center mt-4"
            >
              <Box className={classes.boxContent}>
                <Box className={classes.labelText}>
                  <span style={{ color: "#000000" }}>{ele.title}</span>
                  {ele.text1 ? (
                    <span className="mt-3">
                      {ele.text}
                      <br /> <br />
                      {ele.text1}
                    </span>
                  ) : (
                    <span className="mt-3">{ele.text}</span>
                  )}
                </Box>
              </Box>
            </Grid>
          ))} */}
				</Grid>
			</Grid>
		</Box>
	);
}

export default Page4;
