import React from "react";
import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Typography,
	Box,
	Grid,
	IconButton,
	Button,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core-v4";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import TimePicker from "@mui/lab/TimePicker";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import {ExpandMore, Delete} from "@material-ui/icons";

import BgColorComp from "./ColorComponents/BgColor";
import TextColorComp from "./ColorComponents/TextColor";
import QrContext from "../../../QrContext/QrContext";

const useStyles = makeStyles((theme) => ({
	box: {
		"& .MuiPaper-root": {
			boxShadow: "none !important",
		},
		"& .MuiPaper-root > .MuiButtonBase-root": {
			border: "1px solid #000",
			borderRadius: "2px",
			background: "#F0F0F0",
			height: "50px !important",
		},
		"& .MuiPaper-root > .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded":
			{
				minHeight: "50px !important",
			},
		"& .MuiPaper-root > .MuiCollapse-root": {
			border: "1px solid #D8D8D8",
			margin: "10px 0",
			borderRadius: "2px",
		},
	},
	center: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	card: {
		width: "100%",
		"& div > div": {
			height: "40px",
			borderRadius: "0 2px 2px 0",
		},
		"& .MuiFormControl-root > .MuiOutlinedInput-root > input ": {
			marginTop: "-3px",
		},
		"& .MuiFormControl-root > .MuiOutlinedInput-root > .MuiInputAdornment-root > .MuiButtonBase-root ":
			{
				marginTop: "-7px",
			},
	},
}));

function EventScheduleComp(props) {
	const classes = useStyles();

	const {setContent} = React.useContext(QrContext);

	const {data, dataIndex} = props;

	const {bgColor, textColor, schedule} = data;

	const [scheduleFields, setScheduleFields] = React.useState(
		schedule && schedule
	);

	const [expanded, setExpanded] = React.useState(false);

	const handleChange = (key, value) => {
		setContent((prev) =>
			prev.map((ele, index) => {
				if (index === dataIndex) {
					return {
						...ele,
						[key]: value,
					};
				} else return ele;
			})
		);
	};

	const handleDelete = () => {
		setContent((prev) => [...prev].filter((ele, index) => dataIndex !== index));
	};

	const handleDateChange = (key, value, index) => {
		setScheduleFields((prev) =>
			[...prev].map((ele, eleIndex) => {
				if (eleIndex === index) {
					return {
						...ele,
						[key]: value,
					};
				} else {
					return ele;
				}
			})
		);
	};

	const handleDeleteField = (index) => {
		setScheduleFields((prev) =>
			[...prev].filter((ele, eleIndex) => eleIndex !== index)
		);
	};

	const handleSave = () => {
		handleChange("schedule", scheduleFields);
	};

	if (props.data) {
		return (
			<div className={classes.box}>
				<Accordion onChange={(e, data) => setExpanded(data)}>
					<AccordionSummary expandIcon={<ExpandMore />}>
						<Box
							style={{
								display: "flex",
								justifyContent: "space-between",
								width: "100%",
								alignItems: "center",
							}}
						>
							<Typography
								style={{color: "#615757", fontSize: "20px", fontWeight: "600"}}
							>
								Event Schedule
							</Typography>
							{expanded && (
								<IconButton
									style={{marginRight: "15px"}}
									onClick={() => handleDelete()}
								>
									<Delete />
								</IconButton>
							)}
						</Box>
					</AccordionSummary>
					<AccordionDetails style={{display: "flex", alignItems: "center"}}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<LocalizationProvider dateAdapter={AdapterDateFns}>
									<Grid container spacing={1}>
										<Grid item xs={12}>
											<Typography variant="h5">Event Date</Typography>
										</Grid>
										{scheduleFields &&
											scheduleFields.length > 0 &&
											scheduleFields.map((ele, index) => {
												return (
													<React.Fragment key={index}>
														<Grid item xs={5}>
															<Grid container spacing={1}>
																<Grid item xs={12}>
																	<Typography variant="h5">
																		For Date (Day {index + 1})
																	</Typography>
																</Grid>
																<Grid item xs={12}>
																	<Box className={classes.card}>
																		<DesktopDatePicker
																			value={ele.date}
																			variant="contained"
																			inputFormat="dd/MM/yyyy"
																			onChange={(e) =>
																				handleDateChange("date", e, index)
																			}
																			renderInput={(params) => (
																				<TextField {...params} fullWidth />
																			)}
																		/>
																	</Box>
																</Grid>
															</Grid>
														</Grid>
														<Grid item xs={3}>
															<Grid container spacing={1}>
																<Grid item xs={12}>
																	<Typography variant="h5">Time</Typography>
																</Grid>
																<Grid item xs={12}>
																	<Box className={classes.card}>
																		<TimePicker
																			value={ele.time}
																			onChange={(e) =>
																				handleDateChange("time", e, index)
																			}
																			renderInput={(params) => (
																				<TextField {...params} fullWidth />
																			)}
																		/>
																	</Box>
																</Grid>
															</Grid>
														</Grid>
														{index !== 0 && (
															<Grid
																item
																xs={1}
																style={{display: "flex", alignItems: "center"}}
															>
																<IconButton
																	style={{marginTop: "20px"}}
																	onClick={() => handleDeleteField(index)}
																>
																	<Delete />
																</IconButton>
															</Grid>
														)}
													</React.Fragment>
												);
											})}
									</Grid>
								</LocalizationProvider>
							</Grid>
							<Grid item xs={12}>
								<Button
									style={{color: "#555"}}
									onClick={() => {
										setScheduleFields((prev) => [
											...prev,
											{
												date: new Date(),
												time: new Date(),
											},
										]);
									}}
								>
									Add New Event Date
								</Button>
								<Button color="primary" onClick={handleSave}>
									Save Event Dates
								</Button>
							</Grid>
							{/* <Grid item xs={12} style={{ height: '500px' }}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography variant='h5'>
                                            Time Zone
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Select
                                            onChange={(data) => console.log(data)}
                                            placeholder='Search Address'
                                            isClearable={true}
                                            menuShouldScrollIntoView={true}
                                            closeMenuOnScroll={true}
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    text: '#FFF',
                                                    primary25: '#FF800C',
                                                    primary: '#FF800C'
                                                }
                                            })}
                                            options={
                                                timeZone && timeZone.length > 0 ? timeZone.map(ele => {
                                                    return {label: ele}
                                                }) : []
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </Grid> */}
							<Grid item xs={12}>
								<BgColorComp
									bgColor={bgColor}
									handleChange={(key, value) => handleChange(key, value)}
									name="Background Color"
								/>
							</Grid>
							<Grid item xs={12}>
								<TextColorComp
									textColor={textColor}
									handleChange={(key, value) => handleChange(key, value)}
									name="Text Color"
								/>
							</Grid>
						</Grid>
					</AccordionDetails>
				</Accordion>
			</div>
		);
	} else {
		return null;
	}
}
export default EventScheduleComp;
