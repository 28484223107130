import React from "react";

// ----------------- import Components ------------------ //
import {
	Box,
	CircularProgress,
	Container,
	Typography,
	Button,
	Paper,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TablePagination,
	useTheme,
	Tooltip,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Slide,
	Modal,
	Backdrop,
	Fade,
	Snackbar,
	Alert,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core-v4";
import Lottie from "lottie-react";
import Moment from "moment";

// ----------------- import Files ------------------ //
import error from "../../../../Assets/Animation/error.json";
import notFound from "../../../../Assets/Animation/notFound.json";
import empty from "../../../../Assets/Animation/empty.json";

// ----------------- import icons ------------------ //
import {MdEdit, ImDownload2, BsTrashFill, CgFormatSlash} from "react-icons/all";
import {
	Visibility,
	FirstPage,
	LastPage,
	Cancel,
	KeyboardArrowLeft,
	KeyboardArrowRight,
} from "@material-ui/icons";

// ----------------- import API's ------------------ //
import {filterAllLead, deleteLead} from "../Services/LeadServices/ApiUrl";

import PageTitle from "../../../UI/PageTitle";
import {useNavigate} from "react-router";

const useStyles = makeStyles((theme) => ({
	boxTextField: {
		"& div > div": {
			height: "35px",
		},
		"& div > div > input": {
			marginTop: "-4px",
		},
		"& div > label": {
			marginTop: "-12px",
		},
		"& div > .MuiInputLabel-shrink": {
			marginTop: "-15px",
		},
	},
	root: {
		"& > *": {
			borderBottom: "unset",
		},
	},
	gridItem: {
		height: "70px",
	},
	gridItemTitle: {
		height: "70px",
		padding: "7px 0",
	},
	gridItemTypo: {
		height: "100%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		color: "#858585",
		fontWeight: "bold",
	},
	gray: {
		color: theme.palette.primary.main,
	},
	graylight: {
		color: "#787878",
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 150,
	},
	tableCell: {
		// padding: '10px 16px !important'
		width: "305px",
	},

	// Pagination CSS

	rootPage: {
		flexShrink: 0,
		display: "flex",
		alignItems: "center",
	},

	modalStyles: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		bgcolor: "background.paper",
		boxShadow: 24,
		p: 4,
	},
	tableRowPagination: {
		"& td": {
			border: "0",
		},
		"& td > div > .MuiTablePagination-selectLabel": {
			marginTop: "10px",
		},
		"& td > div > div": {
			marginTop: "-4px",
			marginRight: "12px",
		},
		"& td > div > .MuiTablePagination-displayedRows": {
			marginTop: "12px",
			marginRight: "8px",
		},
	},
}));

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

function ListLeads(props) {
	const navigate = useNavigate();
	const classes = useStyles();
	// ---------------------------------------- Manage All React State Hooks ------------------------------------ //

	const [loadPage, setLoadPage] = React.useState();
	const [leadData, setLeadData] = React.useState();
	const [count, setCount] = React.useState(0);
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const [openMaxQrDialog, setOpenMaxQrDialog] = React.useState(false);
	const [openLoadingModal, setOpenLoadingModal] = React.useState(false);
	const [openTrashDialog, setOpenTrashDialog] = React.useState(false);
	const [snackbar, setSnackbar] = React.useState({
		open: false,
		message: "",
		severity: "success",
	});
	const [trashId, setTrashId] = React.useState(false);
	const [openViewModal, setOpenViewModal] = React.useState(false);

	// ---------------------------------------- Get All Qr Code by Filter Api Call ------------------------------------ //

	const filterQrCodeApiCall = React.useCallback((page, pageSize) => {
		setLoadPage();
		setOpenLoadingModal(false);
		setOpenTrashDialog(false);
		filterAllLead({
			payload: {
				page: page,
				pageSize: pageSize,
			},
		})
			.then((res) => {
				setLeadData(res);
				setCount(res.Count);
				if (res.Items && res.Items.length > 0) {
					setLoadPage("Load");
				} else {
					setLoadPage("NoData");
				}
				setSnackbar({
					open: true,
					message:
						res.Count > 0 ? "Qr Codes Load Successfully" : "Create New Qr Code",
					severity: res.Count > 0 ? "success" : "info",
				});
			})
			.catch((err) => {
				setLeadData();
				setLoadPage("NotFound");
				setSnackbar({
					open: true,
					message: "Error when Load Qr Codes",
					severity: "error",
				});
			});
	}, []);

	// ---------------------------------------- Check Login ------------------------------------ //

	const checkLogin = React.useCallback(() => {
		if (!localStorage.getItem("token")) {
			navigate("/login");
		}
	}, [navigate]);

	// ---------------------------------------- Use Effect ------------------------------------ //

	React.useEffect(() => {
		checkLogin();
		filterQrCodeApiCall(0, 10);
	}, [checkLogin, filterQrCodeApiCall]);

	// ---------------------------------------- Move to Trash Api Call ------------------------------------ //

	const moveToTrash = React.useCallback(() => {
		setOpenLoadingModal(true);
		deleteLead(trashId)
			.then((res) => {
				filterQrCodeApiCall(page, rowsPerPage);
				setSnackbar({
					open: true,
					message: "Lead list deleted successfully",
					severity: "success",
				});
			})
			.catch((err) => {
				console.error(err);
				setSnackbar({
					open: true,
					message: "Error while delete lead list",
					severity: "error",
				});
			});
	}, [trashId, filterQrCodeApiCall, page, rowsPerPage]);

	// ---------------------------------------- Change Page Function ------------------------------------ //

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	// ---------------------------------------- Change Row per Page Function Call API ------------------------------------ //

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(event.target.value);
		filterQrCodeApiCall(page, event.target.value);
	};

	// ----------------------------------------------------------- Pagination Component Start -------------------------------------------------- //

	const TablePaginationActions = (props) => {
		const theme = useTheme();
		const {count, page, rowsPerPage, onPageChange} = props;

		const handleFirstPageButtonClick = (event) => {
			onPageChange(event, 0);
			filterQrCodeApiCall(0, rowsPerPage);
		};

		const handleBackButtonClick = (event) => {
			onPageChange(event, page - 1);
			filterQrCodeApiCall(page - 1, rowsPerPage);
		};

		const handleNextButtonClick = (event) => {
			onPageChange(event, page + 1);
			filterQrCodeApiCall(page + 1, rowsPerPage);
		};

		const handleLastPageButtonClick = (event) => {
			onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
			filterQrCodeApiCall(
				Math.max(0, Math.ceil(count / rowsPerPage) - 1),
				rowsPerPage
			);
		};

		return (
			<div className={classes.rootPage}>
				<IconButton
					onClick={handleFirstPageButtonClick}
					disabled={page === 0}
					aria-label="first page"
				>
					{theme.direction === "rtl" ? <LastPage /> : <FirstPage />}
				</IconButton>
				<IconButton
					onClick={handleBackButtonClick}
					disabled={page === 0}
					aria-label="previous page"
				>
					{theme.direction === "rtl" ? (
						<KeyboardArrowRight />
					) : (
						<KeyboardArrowLeft />
					)}
				</IconButton>
				<IconButton
					onClick={handleNextButtonClick}
					disabled={page >= Math.ceil(count / rowsPerPage) - 1}
					aria-label="next page"
				>
					{theme.direction === "rtl" ? (
						<KeyboardArrowLeft />
					) : (
						<KeyboardArrowRight />
					)}
				</IconButton>
				<IconButton
					onClick={handleLastPageButtonClick}
					disabled={page >= Math.ceil(count / rowsPerPage) - 1}
					aria-label="last page"
				>
					{theme.direction === "rtl" ? <FirstPage /> : <LastPage />}
				</IconButton>
			</div>
		);
	};

	// ----------------------------------------------------------- Pagination Component End -------------------------------------------------- //

	// ----------------------------------------------------------- Page Title Comp Start -------------------------------------------------- //

	const PageTitleComp = () => {
		return (
			<React.Fragment>
				<PageTitle
					backBtn={true}
					title="Leads"
					subTitle={
						<div style={{display: "flex", flexDirection: "column"}}>
							<span>
								Lead Generation feature allows you to get contact and other
								information when people scan your QR Codes.
							</span>
							<span>
								Use this section to setup lead lists and forms, and to export
								captured leads data
							</span>
						</div>
					}
				/>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
						p: "1.5rem",
						background: "#FFF",
					}}
				>
					<Box>
						<Typography variant="h6" sx={{color: "#555", fontSize: "20px"}}>
							Lists
						</Typography>
						<Typography sx={{color: "#555", fontSize: "16px"}}>
							A list stores data of leads captured via linked QR Code/s.
							Typically, a new list is created for each new campaign
						</Typography>
					</Box>
					<Button
						color="primary"
						variant="contained"
						onClick={() => navigate("/qr/leads/create")}
						style={{marginTop: "20px"}}
					>
						Create New List
					</Button>
				</Box>
			</React.Fragment>
		);
	};

	// ----------------------------------------------------------- Header Component End -------------------------------------------------- //

	// ----------------------------------------------------------- Switch Case Main Return Start -------------------------------------------------- //

	const renderComp = () => {
		switch (loadPage) {
			case "Load":
				return (
					<>
						<PageTitleComp />
						<Paper style={{padding: "0px 0"}}>
							{leadData ? (
								leadData.Items.length === 0 ? (
									<div
										style={{
											height: "500px",
											width: "100%",
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											flexDirection: "column",
										}}
									>
										<Typography style={{fontSize: "18px", color: "#666"}}>
											Looks like you haven’t created any lists yet.
										</Typography>
										<Button
											color="primary"
											variant="contained"
											onClick={() => navigate("/qr/create")}
											style={{marginTop: "20px"}}
										>
											Create New List
										</Button>
									</div>
								) : (
									<TableContainer style={{width: "100%"}}>
										<Table aria-label="collapsible table">
											<TableHead>
												<TableRow>
													<TableCell width="700">
														<Typography variant="h5" color="primary">
															<b>List Name</b>
														</Typography>
													</TableCell>
													<TableCell width="200" style={{textAlign: "center"}}>
														<Typography variant="h5" color="primary">
															<b>List Size</b>
														</Typography>
													</TableCell>
													<TableCell width="200" style={{textAlign: "center"}}>
														<Typography variant="h5" color="primary">
															<b>Qr Codes</b>
														</Typography>
													</TableCell>
													<TableCell width="300" style={{textAlign: "center"}}>
														<Typography variant="h5" color="primary">
															<b>Created On</b>
														</Typography>
													</TableCell>
													<TableCell width="250" style={{textAlign: "center"}}>
														<Typography variant="h5" color="primary">
															<b>Actions</b>
														</Typography>
													</TableCell>
													<TableCell />
												</TableRow>
											</TableHead>
											<TableBody>
												{leadData?.Items?.map((ele, index) => (
													<React.Fragment>
														<TableRow className={classes.root}>
															<TableCell width="700" style={{padding: "8px"}}>
																<Typography
																	variant="body1"
																	className={classes.graylight}
																	sx={{px: 1}}
																>
																	<b>{ele?.title}</b>
																</Typography>
															</TableCell>
															<TableCell
																width="200"
																style={{
																	padding: "8px",
																	textAlign: "center",
																}}
															>
																<Typography
																	variant="body1"
																	className={classes.graylight}
																>
																	<b>{ele?.leadCount || 0}</b>
																</Typography>
															</TableCell>
															<TableCell
																width="200"
																style={{
																	padding: "8px",
																	textAlign: "center",
																}}
															>
																<Typography
																	variant="body1"
																	className={classes.graylight}
																>
																	<b>{ele?.totalQrCount || 0}</b>
																</Typography>
															</TableCell>
															<TableCell
																width="300"
																style={{
																	padding: "8px",
																	textAlign: "center",
																}}
															>
																<Typography
																	variant="body1"
																	className={classes.graylight}
																>
																	<b>{Moment(ele.createdOn).format("l")}</b>
																</Typography>
															</TableCell>

															<TableCell
																width="250"
																style={{
																	padding: "8px",
																	textAlign: "center",
																}}
															>
																<Box
																	style={{
																		display: "flex",
																		justifyContent: "space-between",
																	}}
																>
																	<IconButton
																		// onClick={() => setOpenViewModal(item.qr)}
																		onClick={() =>
																			navigate("/qr/leads/manage/" + ele.id)
																		}
																		style={{
																			border: "1px solid #888",
																			borderRadius: "8px",
																		}}
																	>
																		<Visibility /> <CgFormatSlash />{" "}
																		<MdEdit style={{}} />
																	</IconButton>
																	<IconButton
																		style={{
																			border: "1px solid #888",
																			borderRadius: "8px",
																		}}
																		// onClick={() => donwloadQrCode(item.qr)}
																	>
																		<ImDownload2 />
																	</IconButton>
																	<IconButton
																		style={{
																			border: "1px solid #888",
																			borderRadius: "8px",
																		}}
																		onClick={() => {
																			setOpenTrashDialog(true);
																			setTrashId(ele.id);
																		}}
																	>
																		<BsTrashFill />
																	</IconButton>
																</Box>
															</TableCell>
														</TableRow>
													</React.Fragment>
												))}
											</TableBody>
										</Table>
										<TableRow
											className={classes.tableRowPagination}
											style={{
												display: "flex",
												justifyContent: "flex-end",
												width: "98%",
												marginTop: "8px",
												alignItems: "center",
											}}
										>
											<TablePagination
												rowsPerPageOptions={[5, 10, 25]}
												colSpan={3}
												count={count}
												rowsPerPage={rowsPerPage}
												page={page}
												SelectProps={{
													inputProps: {"aria-label": "rows per page"},
													native: true,
												}}
												onPageChange={handleChangePage}
												onRowsPerPageChange={handleChangeRowsPerPage}
												ActionsComponent={TablePaginationActions}
											/>
										</TableRow>
									</TableContainer>
								)
							) : (
								<div
									style={{
										height: "500px",
										width: "100%",
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									<CircularProgress />
								</div>
							)}
						</Paper>

						<Dialog
							open={openMaxQrDialog}
							TransitionComponent={Transition}
							keepMounted
							onClose={() => {
								setOpenMaxQrDialog(false);
							}}
							aria-describedby="alert-dialog-slide-description"
						>
							<DialogTitle>{"You Reach Your Max Qr Limit"}</DialogTitle>
							<DialogContent>
								<DialogContentText id="alert-dialog-slide-description">
									You have successfully create your maximum qr codes.
								</DialogContentText>
							</DialogContent>
							<DialogActions>
								<Button
									onClick={() => {
										setOpenMaxQrDialog(false);
									}}
									variant="contained"
									style={{color: "#FFF", background: "#1d8f48"}}
								>
									Buy Plan
								</Button>
								<Button
									onClick={() => {
										setOpenMaxQrDialog(false);
									}}
									variant="contained"
									style={{color: "#FFF", background: "#c72a2a"}}
								>
									Ok
								</Button>
							</DialogActions>
						</Dialog>

						<Dialog open={openTrashDialog}>
							{openLoadingModal ? (
								<DialogContent>
									<Box
										style={{
											height: "100px",
											width: "100px",
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
										}}
									>
										<CircularProgress />
									</Box>
								</DialogContent>
							) : (
								<>
									<DialogTitle>{"Move To Trash"}</DialogTitle>
									<DialogContent>
										<DialogContentText>
											Can you move this qr code in to trash ?
										</DialogContentText>
									</DialogContent>
									<DialogActions>
										<Button
											onClick={() => {
												setOpenTrashDialog(false);
											}}
											variant="contained"
											style={{color: "#FFF", background: "#c72a2a"}}
										>
											Cancle
										</Button>
										<Button
											onClick={moveToTrash}
											variant="contained"
											style={{color: "#FFF", background: "#1d8f48"}}
										>
											Yes, I'm Sure
										</Button>
									</DialogActions>
								</>
							)}
						</Dialog>

						<Modal
							open={openViewModal === false ? false : true}
							onClose={() => {
								setOpenViewModal(false);
							}}
							closeAfterTransition
							BackdropComponent={Backdrop}
							BackdropProps={{
								timeout: 500,
							}}
						>
							<Fade in={openViewModal === false ? false : true}>
								<Box className={classes.modalStyles}>
									<Box
										style={{
											width: "100%",
											display: "flex",
											justifyContent: "flex-end",
											marginBottom: "10px",
										}}
									>
										<Tooltip title="Close QR Code" placement="right-start">
											<IconButton
												style={{position: "relative"}}
												onClick={() => setOpenViewModal(false)}
											>
												<Cancel style={{color: "#FFF", fontSize: "30px"}} />
											</IconButton>
										</Tooltip>
									</Box>
									<Box style={{position: "relative"}}>
										<img
											alt=""
											src={openViewModal !== false && openViewModal}
											style={{height: "400px", objectFit: "contain"}}
										/>
									</Box>
								</Box>
							</Fade>
						</Modal>

						<Snackbar
							open={snackbar.open}
							anchorOrigin={{vertical: "top", horizontal: "right"}}
							autoHideDuration={5000}
							onClose={() => {
								setSnackbar({open: false});
							}}
						>
							<Alert
								onClose={() => {
									setSnackbar({open: false});
								}}
								severity={snackbar.severity}
								sx={{width: "100%"}}
							>
								{snackbar.message}
							</Alert>
						</Snackbar>

						{/* ---------------------------------------------------------- Table End ----------------------------------------------------------------- */}
					</>
				);
			case "NoData":
				return (
					<>
						<PageTitleComp />
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								height: "80vh",
								width: "100%",
								flexDirection: "column",
							}}
						>
							<Lottie
								animationData={empty}
								style={{height: 300, color: "red"}}
							/>
							<Typography style={{fontSize: "22px"}} color="primary">
								<b>
									Looks like you haven’t created any lists yet. Create your
									first list now.
								</b>
							</Typography>
							<Button
								color="primary"
								variant="contained"
								onClick={() => navigate("/qr/leads/create")}
								style={{marginTop: "20px"}}
							>
								Create New List
							</Button>
						</div>
					</>
				);
			case "NotFound":
				return (
					<>
						<PageTitleComp />
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								height: "80vh",
								width: "100%",
							}}
						>
							<Lottie animationData={notFound} style={{height: 300}} />
						</div>
					</>
				);
			case "Error":
				return (
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							height: "80vh",
							width: "100%",
						}}
					>
						<Lottie animationData={error} style={{height: 300}} />
					</div>
				);
			default:
				return (
					<>
						<PageTitleComp />
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								height: "80vh",
								width: "100%",
							}}
						>
							<CircularProgress />
						</div>
					</>
				);
		}
	};

	return (
		<Container maxWidth="xl" style={{minHeight: "80vh", marginBottom: "5px"}}>
			{renderComp()}
		</Container>
	);

	// ----------------------------------------------------------- Switch Case Main Return Start -------------------------------------------------- //
}

export default ListLeads;
