import {urls} from "../../../../../Environment/ApiUrl";
import {executePost} from "../../../../../Services/PUT_OR_POST";
import {executeGet} from "../../../../../Services/iwant-rest-generic";

export const validateOtp = (requestLoad) =>{
    let url = urls.USER.VALIDATE_OTP;
    let actionCode = 'ACTION_VALIDATE_OTP'
    let payload = requestLoad;
    let method = 'POST';
    return executePost(url, actionCode , payload, method, true)
}

export const login = (requestLoad) =>{
    let url = urls.LOGIN;
    let actionCode = 'ACTION_VALIDATE_OTP'
    let payload = requestLoad;
    let method = 'POST';
    return executePost(url, actionCode , payload, method, true)
}
export const signupapi = (requestLoad) =>{
    let url = urls.USER.REGISTRATION;
    let actionCode = 'ACTION_CREATE_PROFILE'
    let payload = requestLoad;
    let method = 'POST';
    return executePost(url, actionCode , payload, method, true)
}
export const validateUser = (requestLoad) =>{
    let url = urls.USER.EXITS_USER;
    let actionCode = 'ACTION_VALIDATE_USER'
    let payload = requestLoad;
    let method = 'POST';
    return executePost(url, actionCode , payload, method, true)
}

export const signupWithOtp = (requestLoad) =>{
    let url = urls.USER.OTP_SEND_REGISTER;
    let actionCode = 'ACTION_CREATE_OTP'
    let payload = requestLoad;
    let method = 'POST';
    return executePost(url, actionCode , payload, method, true)
}
export const changePassword = (requestLoad) =>{
    let url = urls.USER.FORGOTPASSWORD;
    let actionCode = 'ACTION_FORGOTPASSWORD_USER'
    let payload = requestLoad;
    let method = 'POST';
    return executePost(url, actionCode , payload, method, true)
}

export const licenseApi = (requestLoad) =>{
    let url = urls.LICENSE.CREATE;
    let actionCode = '';
    let payload = {};
    let method = 'GET';
    return executeGet(url, actionCode , payload, method)
}
