import React from "react";
import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Typography,
	Box,
	Grid,
	TextField,
	IconButton,
} from "@material-ui/core";
import {ExpandMore, Delete} from "@material-ui/icons";
import Select from "react-select";
import {makeStyles} from "@material-ui/core-v4";

import BorderColorComp from "./ColorComponents/BorderColor";
import BgColorComp from "./ColorComponents/BgColor";
import TextColorComp from "./ColorComponents/TextColor";
import QrContext from "../../../QrContext/QrContext";

const useStyles = makeStyles((theme) => ({
	box: {
		"& .MuiPaper-root": {
			boxShadow: "none !important",
		},
		"& .MuiPaper-root > .MuiButtonBase-root": {
			border: "1px solid #000",
			borderRadius: "2px",
			background: "#F0F0F0",
			height: "50px !important",
		},
		"& .MuiPaper-root > .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded":
			{
				minHeight: "50px !important",
			},
		"& .MuiPaper-root > .MuiCollapse-root": {
			border: "1px solid #D8D8D8",
			margin: "10px 0",
			borderRadius: "2px",
		},
	},
	center: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	card: {
		width: "100%",
		"& div > div": {
			height: "40px",
			borderRadius: "0 2px 2px 0",
		},
		"& .MuiFormControl-root > .MuiOutlinedInput-root > input ": {
			marginTop: "-3px",
		},
		"& .MuiFormControl-root > .MuiOutlinedInput-root > .MuiInputAdornment-root > .MuiButtonBase-root ":
			{
				marginTop: "-7px",
			},
	},
}));

function ButtonComp(props) {
	const classes = useStyles();
	const {setContent} = React.useContext(QrContext);

	const customStyles = {
		control: (base) => ({
			...base,
			marginTop: "-5px",
			height: 45,
			width: 120,
			minHeight: 40,
			borderRadius: "2px 0 0 2px",
			borderRight: "0",
		}),
	};

	const [expanded, setExpanded] = React.useState(false);

	if (props.data) {
		const {data, dataIndex} = props;

		const {
			bgColor,
			borderColor,
			label,
			textColor,
			linkType,
			phone,
			email,
			url,
			deletable,
		} = data;

		const handleChange = (key, value) => {
			setContent((prev) =>
				prev.map((ele, index) => {
					if (index === dataIndex) {
						return {
							...ele,
							[key]: value,
						};
					} else return ele;
				})
			);
		};

		const handleDelete = () => {
			setContent((prev) =>
				[...prev].filter((ele, index) => dataIndex !== index)
			);
		};
		return (
			<div className={classes.box}>
				<Accordion onChange={(e, data) => setExpanded(data)}>
					<AccordionSummary expandIcon={<ExpandMore />}>
						<Box
							style={{
								display: "flex",
								justifyContent: "space-between",
								width: "100%",
								alignItems: "center",
							}}
						>
							<Typography
								style={{color: "#615757", fontSize: "20px", fontWeight: "600"}}
							>
								Button
							</Typography>
							{expanded && !deletable && (
								<IconButton
									style={{marginRight: "15px"}}
									onClick={() => handleDelete()}
								>
									<Delete />
								</IconButton>
							)}
						</Box>
					</AccordionSummary>
					<AccordionDetails style={{display: "flex", alignItems: "center"}}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Grid container spacing={1}>
									{linkType && (
										<React.Fragment>
											<Grid item xs={12}>
												<Typography variant="h5">Link</Typography>
											</Grid>
											<Grid item xs={12}>
												<Box style={{display: "flex"}}>
													<Select
														onChange={(e) => handleChange("linkType", e.value)}
														width="250px"
														maxMenuHeight="240px"
														placeholder="Link"
														menuShouldScrollIntoView={true}
														closeMenuOnScroll={true}
														value={{
															label:
																linkType[0].toUpperCase() +
																linkType
																	.slice(1, linkType.length)
																	.toLowerCase(),
															value: linkType,
														}}
														theme={(theme) => ({
															...theme,
															colors: {
																...theme.colors,
																text: "#FFF",
																primary25: "#FF800C",
																primary: "#FF800C",
															},
														})}
														defaultValue={{label: "URL", value: 0}}
														styles={customStyles}
														options={[
															{label: "Url", value: "url"},
															{label: "Email", value: "email"},
															{label: "Phone", value: "phone"},
														]}
													/>
													<Box className={classes.card}>
														<TextField
															type="text"
															color="primary"
															name={linkType}
															onChange={(e) => {
																handleChange(e.target.name, e.target.value);
															}}
															fullWidth
															value={
																linkType === "url"
																	? url
																	: linkType === "phone"
																	? phone
																	: linkType === "email"
																	? email
																	: ""
															}
														/>
													</Box>
												</Box>
											</Grid>
										</React.Fragment>
									)}
								</Grid>
							</Grid>
							<Grid item xs={12}>
								<Grid container spacing={1}>
									<Grid item xs={12}>
										<Typography variant="h5">Button Text</Typography>
									</Grid>
									<Grid item xs={12}>
										<Box className={classes.card}>
											<TextField
												type="text"
												color="primary"
												name="label"
												onChange={(e) => {
													handleChange(e.target.name, e.target.value);
												}}
												fullWidth
												value={label}
											/>
										</Box>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={12}>
								<BgColorComp
									bgColor={bgColor}
									handleChange={(key, value) => handleChange(key, value)}
									name="Button Background Color"
								/>
							</Grid>
							<Grid item xs={12}>
								<TextColorComp
									textColor={textColor}
									handleChange={(key, value) => handleChange(key, value)}
									name="Button Text Color"
								/>
							</Grid>
							<Grid item xs={12}>
								<BorderColorComp
									borderColor={borderColor}
									handleChange={(key, value) => handleChange(key, value)}
									name="Button Border Color"
								/>
							</Grid>
						</Grid>
					</AccordionDetails>
				</Accordion>
			</div>
		);
	} else {
		return null;
	}
}
export default ButtonComp;
