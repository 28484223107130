import React from "react";
import {
	Typography,
	IconButton,
	Snackbar,
	Alert,
	Box,
	LinearProgress,
} from "@material-ui/core";
import {Cancel} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core-v4";
import {useDropzone} from "react-dropzone";
import styled from "styled-components";
import {FileIcon, defaultStyles} from "react-file-icon";

import {uploader} from "../../../../Services/QrGenerateServices/ApiUrl";
import QrContext from "../../../QrContext/QrContext";

const useStyles = makeStyles((theme) => ({
	box: {
		"& .MuiPaper-root": {
			boxShadow: "none !important",
		},
		"& .MuiPaper-root > .MuiButtonBase-root": {
			border: "1px solid #000",
			borderRadius: "2px",
			background: "#F0F0F0",
			height: "50px !important",
		},
		"& .MuiPaper-root > .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded":
			{
				minHeight: "50px !important",
			},
		"& .MuiPaper-root > .MuiCollapse-root": {
			border: "1px solid #D8D8D8",
			margin: "10px 0",
			borderRadius: "2px",
		},
	},
	center: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	card: {
		width: "100%",
		"& div > div": {
			height: "40px",
			borderRadius: "0 2px 2px 0",
		},
		"& .MuiFormControl-root > .MuiOutlinedInput-root > input ": {
			marginTop: "-3px",
		},
		"& .MuiFormControl-root > .MuiOutlinedInput-root > .MuiInputAdornment-root > .MuiButtonBase-root ":
			{
				marginTop: "-7px",
			},
	},
	divExtensionLogo: {
		marginTop: "5px",
		"& svg": {
			height: "75px",
			width: "100px",
		},
	},
}));

const thumbsContainer = {
	display: "flex",
	flexDirection: "row",
	flexWrap: "wrap",
	marginTop: 16,
};

const thumb = {
	display: "inline-flex",
	borderRadius: 2,
	border: "1px solid #eaeaea",
	marginBottom: 8,
	marginRight: 8,
	width: 110,
	height: 110,
	padding: 4,
	boxSizing: "border-box",
};

const thumbInner = {
	display: "flex",
	minWidth: 0,
	overflow: "hidden",
	objectFit: "contain",
	position: "relative",
};

const getColor = (props) => {
	if (props.isDragAccept) {
		return "#00e676";
	}
	if (props.isDragReject) {
		return "#ff1744";
	}
	if (props.isDragActive) {
		return "#2196f3";
	}
	return "#E0E0E0";
};

const Container = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px;
	border-width: 2px;
	border-radius: 2px;
	border-color: ${(props) => getColor(props)};
	border-style: dashed;
	background-color: #fafafa;
	color: #bdbdbd;
	outline: none;
	transition: border 0.24s ease-in-out;
`;

function ImageComp(props) {
	const classes = useStyles();

	const {setContent} = React.useContext(QrContext);

	const {data, dataIndex} = props;

	const {max, label, documents} = data;

	const [snackbar, setSnackbar] = React.useState({
		open: false,
		severity: "success",
		message: "here",
	});

	const [loader, setLoader] = React.useState(false);

	const maxSize = 20971520;

	const handleChange = (key, value) => {
		setContent((prev) =>
			prev.map((ele, index) => {
				if (index === dataIndex) {
					return {
						...ele,
						[key]: value,
					};
				} else return ele;
			})
		);
	};

	function getThe64StringSize(base64String) {
		if (base64String) {
			const string = base64String.split(",")[1];
			let x =
				string.length * (3 / 4) -
				(string.slice(string.length - 2, string.length) !== "=="
					? string.slice(string.length - 1, string.length) === "="
						? 1
						: 0
					: 2);
			return x;
		}
	}

	const handleFile = (imageData) => {
		handleChange("documents", imageData);
	};

	const uploadFile = (files) => {
		Object.values(files).forEach((file) => {
			var reader = new FileReader();
			if (file) {
				reader.readAsDataURL(file);
				reader.onload = () => {
					uploader({
						payload: [
							{
								name: file.name,
								size: file.size,
								type: file.type,
								base64String: reader.result.split(",")[1],
								base64StringSize: getThe64StringSize(reader.result),
							},
						],
					})
						.then((res) => {
							setLoader(false);
							documents.push(...res);
							handleFile(documents);
							setSnackbar({
								open: true,
								message: "File Upload Successfully",
								severity: "success",
							});
						})
						.catch((err) => {
							setLoader(false);
							console.error(err);
							setSnackbar({
								open: true,
								message: "Error while uplaod files",
								severity: "error",
							});
						});
				};
				reader.onerror = () => {
					setLoader(false);
					setSnackbar({
						open: true,
						message: "Error while uplaod files",
						severity: "error",
					});
				};
			}
		});
	};

	const {
		getRootProps,
		getInputProps,
		isDragActive,
		isDragAccept,
		isDragReject,
	} = useDropzone({
		maxFiles: 20,
		accept: "application/*",
		minSize: 0,
		maxSize,
		onDrop: (acceptedFiles) => {
			if (documents.length < max) {
				if (
					acceptedFiles &&
					acceptedFiles.length > 0 &&
					acceptedFiles[0].size < 20971520
				) {
					setLoader(acceptedFiles.length);
					let imageDataPayload = acceptedFiles.map((file) =>
						Object.assign(file, {
							preview: URL.createObjectURL(file),
						})
					);
					uploadFile(imageDataPayload);
				} else {
					setSnackbar({
						open: true,
						message: "File Size more then 20 MB",
						severity: "warning",
					});
				}
			} else {
				setSnackbar({
					open: true,
					message: "You have upload max 20 Ducuments",
					severity: "warning",
				});
			}
		},
	});

	const handleDeleteImage = (index) => {
		handleChange(
			"documents",
			documents.filter((ele, eleIndex) => eleIndex !== index)
		);
	};

	if (props.data) {
		const thumbs =
			documents &&
			documents.length > 0 &&
			documents.map((file, index) => {
				const fileType = file.name.split(".")[1];
				const fileName = file.name.split(".")[0];
				return (
					<div style={thumb} key={index}>
						<div style={thumbInner}>
							<IconButton
								style={{
									position: "absolute",
									padding: "0",
									margin: "0",
									right: "0",
									border: "0",
									background: "transparent",
									height: "1px !important",
								}}
								onClick={() => handleDeleteImage(index)}
							>
								<Cancel />
							</IconButton>
							<div className={classes.divExtensionLogo}>
								<FileIcon extension={fileType} {...defaultStyles[fileType]} />
								<Typography
									variant="caption"
									style={{
										width: "100%",
										display: "flex",
										justifyContent: "center",
										marginTop: "5px",
									}}
								>
									{fileName}
								</Typography>
							</div>
						</div>
					</div>
				);
			});

		return (
			<div className={classes.box}>
				<Typography
					variant="h4"
					style={{color: "#666", margin: "0 0 20px 15px"}}
				>
					{label}
				</Typography>
				<Typography
					variant="h6"
					style={{
						color: "#666",
						margin: "-10px 0 20px 15px",
						display: "flex",
						flexDirection: "column",
					}}
				>
					<span>
						1. Document formats— PDF, DOC, DOCX, XLS, XLSX, PPT, PPTX, ODT, ODS,
						ODP, TXT, CSV
					</span>
					<span>2. Each document should not be greater than 20 MB</span>
					<span>3. Upload maximum 20 documents</span>
				</Typography>
				<section className="container">
					<Container
						{...getRootProps({isDragActive, isDragAccept, isDragReject})}
					>
						<input {...getInputProps()} />
						<p>Drag 'n' drop some files here, or click to select files</p>
					</Container>
					{/* <aside style={thumbsContainer}>
                        <ul>{acceptedFileItems}</ul>
                    </aside> */}
					<aside style={thumbsContainer}>
						{thumbs}
						{loader !== false &&
							[...Array(loader)].map((item, index) => (
								<div style={thumb} key={index}>
									<div style={thumbInner}>
										<Box
											key={index}
											variant="rectangular"
											width={110}
											height={110}
											style={{marginTop: "90px"}}
										>
											<LinearProgress />
										</Box>
									</div>
								</div>
							))}
					</aside>
				</section>
				<Snackbar
					open={snackbar.open}
					anchorOrigin={{vertical: "top", horizontal: "right"}}
					autoHideDuration={5000}
					onClose={() => setSnackbar({open: false})}
				>
					<Alert
						onClose={() => setSnackbar({open: false})}
						severity={snackbar.severity}
						sx={{width: "100%"}}
					>
						{snackbar.message}
					</Alert>
				</Snackbar>
			</div>
		);
	} else {
		return null;
	}
}
export default ImageComp;
