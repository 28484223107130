import React from "react";

// ------------------------ import Components --------------------------- //

import {Box, Container, Typography, Button} from "@material-ui/core";

// ----------------- import icons ------------------ //

import {RiUserAddLine} from "react-icons/all";

function Users(props) {
	// if(plans){
	return (
		<Container maxWidth="xl" style={{margin: "30px auto"}}>
			<Box
				style={{
					width: "100%",
					margin: "30px 0 0 0",
					display: "flex",
					flexDirection: "column",
					color: "#666",
				}}
			>
				<Typography variant="h5">
					<b>
						Using this feature, the account owner can invite users to access and
						manage account by assigning default or custom roles
					</b>
				</Typography>
				<Button
					style={{width: "250px", height: "40px", marginTop: "20px"}}
					variant="outlined"
					color="primary"
					startIcon={<RiUserAddLine />}
				>
					Invite Users
				</Button>
			</Box>
		</Container>
	);
	// } else {
	//     return (
	//         <Box style={{ marginTop: '10%', width: '100%', textAlign: 'center' }}>
	//             <CircularProgress />
	//         </Box>
	//     )
	// }
}

export default Users;
