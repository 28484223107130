import * as React from "react";
import {Box, Grid} from "@material-ui/core";

import classes from "./Page1.module.css";

import HOTQr from "../../../../../Assets/HOTQr.png";
import StarIcon from "../../../../../Assets/BatchGenerator/StarIcon.svg";
import VectorIcon from "../../../../../Assets/BatchGenerator/VectorIcon.svg";
import QrIcon from "../../../../../Assets/BatchGenerator/qr.svg";
import Icon1 from "../../../../../Assets/BatchGenerator/icon1.svg";
import Icon2 from "../../../../../Assets/BatchGenerator/icon2.svg";
import Icon3 from "../../../../../Assets/BatchGenerator/icon3.svg";
import Icon4 from "../../../../../Assets/BatchGenerator/icon4.svg";
import {useNavigate} from "react-router";

function Page1(props) {
	const navigate = useNavigate();
	return (
		<Box className={classes.box}>
			<Box onClick={() => navigate("/")} className={classes.boxLogo}>
				<img className={classes.HOTQr} alt="logo" src={HOTQr} />
			</Box>
			<Grid container>
				<Grid item xs={12} className="d-flex justify-content-center">
					<Box className={classes.boxHead}>
						<label className={classes.boxHeadTitle}>
							HOT QR Code Batch Generator
						</label>
						<Box
							className={classes.boxHeadButton}
							onClick={() => navigate("/qr")}
						>
							<span className={classes.boxHeadButtonText}>
								Get started with HOT QR Batch
							</span>
						</Box>
					</Box>
				</Grid>
				<Grid item xs={12} className="d-flex justify-content-center">
					<label className={classes.labelTitle}>
						HOT QR code batch generator creates qr codes in the following
						forms/designs:
					</label>
				</Grid>
				<Grid item xs={12} className="d-flex justify-content-center">
					<Box className={classes.boxStar}>
						<Box className={classes.boxStarVerticalLine} />
						<img className={classes.starImg} alt="ImgStar" src={StarIcon} />
						<img className={classes.vectorImg} alt="ImgStar" src={VectorIcon} />

						<span
							className={classes.starText}
							style={{left: "254px", top: "115px"}}
						>
							Plain text
						</span>
						<Box
							className={classes.boxRound}
							style={{left: "290px", top: "234px"}}
						/>
						<Box className={classes.boxImg1}>
							<img className={classes.qrIcon} alt="ImgStar" src={QrIcon} />
							<img
								className={classes.icon}
								alt="ImgStar"
								src={Icon1}
								style={{left: "14px", top: "28px"}}
							/>
						</Box>
						<span
							className={classes.starText}
							style={{left: "409px", top: "290px"}}
						>
							Random QR code
						</span>
						<Box
							className={classes.boxRound}
							style={{left: "439px", top: "358px"}}
						/>
						<Box className={classes.boxImg2}>
							<img className={classes.qrIcon} alt="ImgStar" src={QrIcon} />
						</Box>
						<span
							className={classes.starText}
							style={{left: "377px", top: "545px"}}
						>
							Website URL
						</span>
						<Box
							className={classes.boxRound}
							style={{left: "386px", top: "522px"}}
						/>
						<Box className={classes.boxImg3}>
							<img className={classes.qrIcon} alt="ImgStar" src={QrIcon} />
							<img
								className={classes.icon}
								alt="ImgStar"
								src={Icon2}
								style={{left: "24px", top: "25px"}}
							/>
						</Box>
						<span
							className={classes.starText}
							style={{left: "134px", top: "545px"}}
						>
							VCard
						</span>
						<Box
							className={classes.boxRound}
							style={{left: "188px", top: "520px"}}
						/>
						<Box className={classes.boxImg4}>
							<img className={classes.qrIcon} alt="ImgStar" src={QrIcon} />
							<img
								className={classes.icon}
								alt="ImgStar"
								src={Icon3}
								style={{left: "28px", top: "28px"}}
							/>
						</Box>
						<span
							className={classes.starText}
							style={{left: "32px", top: "290px"}}
						>
							Serial code
						</span>
						<Box
							className={classes.boxRound}
							style={{left: "129px", top: "358px"}}
						/>
						<Box className={classes.boxImg5}>
							<img className={classes.qrIcon} alt="ImgStar" src={QrIcon} />
							<img
								className={classes.icon}
								alt="ImgStar"
								src={Icon4}
								style={{left: "21px", top: "23px"}}
							/>
						</Box>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
}

export default Page1;
