import React from "react";
import {useNavigate} from "react-router";
// import {Button} from "@mui/material";
import {ArrowBack} from "@material-ui/icons";
import {Box, IconButton, Typography} from "@material-ui/core";

function GoBackButton({backBtn, history, handleClick, title, subTitle}) {
	const navigate = useNavigate();
	return (
		// <Button
		// 	variant="outlined"
		// 	sx={{
		// 		borderWidth: "2px",
		// 		borderRadius: "8px",
		// 		fontWeight: 500,
		// 		marginRight: "20px",
		// 		"&:hover": {
		// 			borderWidth: "2px",
		// 		},
		// 	}}
		// 	startIcon={<ArrowBack style={{fontWeight: 900}} />}
		// 	onClick={() => {
		// 		if (handleClick) {
		// 			handleClick();
		// 		} else {
		// 			navigate(-1);
		// 		}
		// 	}}
		// >
		// 	Back
		// </Button>
		<React.Fragment>
			<Box
				style={{
					display: "flex",
					alignItems: "center",
					backgroundColor: "#f8f8f8",
					padding: "1.5rem",
				}}
			>
				{!backBtn && (
					<IconButton
						sx={{
							fontWeight: 500,
							marginRight: "20px",
						}}
						onClick={() => {
							if (handleClick) {
								handleClick();
							} else {
								navigate(-1);
							}
						}}
					>
						<ArrowBack
							color="primary"
							style={{fontWeight: 900, fontSize: "30px"}}
						/>
					</IconButton>
				)}
				<Box>
					<Typography style={{color: "#666"}} variant="h2">
						{title || "Qr Code"}
					</Typography>
					<Typography style={{color: "#666"}}>{subTitle || ""}</Typography>
				</Box>
			</Box>
		</React.Fragment>
	);
}

export default GoBackButton;
