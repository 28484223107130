import React from "react";
import {Box} from "@material-ui/core";
import {Swiper, SwiperSlide} from "swiper/react";

import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

import SwiperCore, {Autoplay} from "swiper";

SwiperCore.use([Autoplay]);

function BannerImageComp(props) {
	const {data} = props;
	const {images, bgColor} = data;

	return (
		<React.Fragment>
			<Box style={{marginTop: "5px"}}>
				<Swiper
					slidesPerView={1}
					spaceBetween={30}
					loop={true}
					autoplay={{
						delay: 2500,
						disableOnInteraction: false,
					}}
					className="mySwiper"
				>
					{images &&
						images.length > 0 &&
						images.map((file, index) => (
							<SwiperSlide
								key={index}
								style={{
									borderRadius: "8px",
									padding: "5px",
									background: bgColor,
								}}
							>
								<img
									alt=""
									src={file.url}
									style={{
										height: "120px",
										width: "100%",
										objectFit: "contain",
										borderRadius: "8px",
									}}
								/>
							</SwiperSlide>
						))}
				</Swiper>
			</Box>
		</React.Fragment>
	);
}
export default BannerImageComp;
