import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router";
import {makeStyles} from "@material-ui/styles";
import {Box, List} from "@material-ui/core";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import clsx from "clsx";
import NavItem from "./NavItem";
import {topItems, bottomItems} from "./NavItemsList";

import HOTQr from "../../Assets/HOTQr.png";
import Logout from "../Pages/Session/Logout/Logout";
import AuthContext from "../../Context/AuthContext";

function getTopItems() {
	let json = {
		list: topItems,
	};
	return json;
}

function getBottomItems() {
	let json = {
		list: bottomItems,
	};
	return json;
}

const useStyles = makeStyles((theme) => ({
	show: {
		display: "block",
	},
	hide: {
		display: "none",
		visibility: "hidden",
	},
}));

function Sidebar(props) {
	const navigate = useNavigate();
	const location = useLocation();
	const {login} = React.useContext(AuthContext);
	const classes = useStyles();

	const topItems = getTopItems();

	const bottomItems = getBottomItems();

	const {staticContext, ...other} = props;

	const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

	const [showContent, setShowContent] = useState(true);

	useEffect(() => {
		if (
			location.pathname === "/login" ||
			location.pathname === "/forgotpassword"
		) {
			setShowContent(false);
		} else {
			setShowContent(true);
		}
	}, [location.pathname]);

	const content = (
		<Box
			className={clsx({
				[classes.show]: showContent,
				[classes.hide]: !showContent,
			})}
			sx={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "space-between",
				height: "100vh",
				backgroundColor: "#FF800C",
				paddingBottom: "50px",
			}}
		>
			<Box>
				<Box
					onClick={() => navigate("/")}
					style={{margin: "15px 0 0 0", height: "100px", width: "100%"}}
				>
					<img
						alt="logo"
						src={HOTQr}
						style={{
							filter: "grayscale(1) brightness(2)",
							height: "100%",
							width: "100%",
							objectFit: "contain",
						}}
					/>
				</Box>
				<Box sx={{p: 2}}>
					<List>
						{topItems.list.map((ele) => (
							<NavItem
								tag={ele.tag}
								link={ele.link}
								key={ele.id}
								title={ele.title}
								icon={ele.icon}
							/>
						))}
					</List>
				</Box>
			</Box>

			<Box sx={{p: 2, borderTop: "0.5px solid #FFF"}}>
				<List>
					{bottomItems.list.map((ele) => (
						<NavItem
							tag={ele.tag}
							link={ele.link}
							key={ele.id}
							title={ele.title}
							icon={ele.icon}
						/>
					))}
				</List>
			</Box>
		</Box>
	);

	return (
		<React.Fragment>
			<SwipeableDrawer
				className={clsx({
					[classes.show]: showContent,
					[classes.hide]: !showContent,
				})}
				disableBackdropTransition={!iOS}
				disableDiscovery={iOS}
				anchor="left"
				onClose={() => {}}
				onOpen={() => {}}
				variant="temporary"
				{...other}
			>
				{content}
			</SwipeableDrawer>
			{login && <Logout />}
		</React.Fragment>
	);
}

export default Sidebar;
