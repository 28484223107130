// import React from "react";

function WebsiteUrlComp(props) {
	// const {data} = props;
	// const {url} = data;
	// return (
	// 	<iframe
	// 		title="showcase"
	// 		// src={"https://" + url}
	// 		src={"https://google.com"}
	// 		style={{
	// 			width: "100%",
	// 			height: "100%",
	// 			margin: 0,
	// 			borderRadius: "2px 2px 20px 20px",
	// 			visibility: "visible",
	// 		}}
	// 	/>
	// );
	return null;
}

export default WebsiteUrlComp;
