import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import Alert from '@material-ui/core/Alert';

const SnackbarComponent = (props) => {
  return (
    <>
      <Snackbar 
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={props.open}
        style={{width:'100%'}}
        autoHideDuration={3000}
        onClose={props.handleClose}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={props.handleClose}>
              {/* <CloseIcon fontSize="small" /> */}
            </IconButton>
          </React.Fragment>
        }
      >
        <Alert onClose={props.handleClose} severity={props.severity}>
            {props.message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default SnackbarComponent