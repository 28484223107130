import React from "react";
import { Box } from '@material-ui/core';
import renderHTML from 'react-render-html';

function PageTitleComp(props){

    const { data } = props 
    const { textColor, bgColor, title } = data

    if(title && title !== ''){
        return(
            <Box
                style={{
                    padding: '10px 15px 0px 15px',
                    background: bgColor,
                    borderRadius: '5px',
                    color: textColor,
                    marginTop: '10px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'justify',
                    overflowWrap: 'anywhere',
                    fontSize: '18px'
                }}
            >
                {renderHTML(title)}
            </Box>
        )
    } else {
        return null
    }
}
export default PageTitleComp;