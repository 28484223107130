import {urls} from "../../../../../Environment/ApiUrl";
import {executePost} from "../../../../../Services/iwant-rest-http-post";
import {executeGet} from "../../../../../Services/iwant-rest-generic";

export const findByIdQrType = (requestLoad) => {
	let url = urls.QRTYPE.FINDBYID + requestLoad;
	let actionCode = "";
	let payload = {};
	let method = "GET";
	return executeGet(url, actionCode, payload, method);
};

export const findAllQrCode = (requestLoad) => {
	let url = urls.QRCODE.FINDALL;
	let actionCode = "";
	let payload = {};
	let method = "GET";
	return executeGet(url, actionCode, payload, method);
};

export const filterQrCode = (requestLoad) => {
	let url = urls.QRCODE.FILTER;
	let actionCode = "";
	let payload = requestLoad;
	let method = "POST";
	return executePost(url, actionCode, payload, method);
};
export const analyticsQrCode = (requestLoad) => {
	let url = urls.QRCODE.ANALYTICS;
	let actionCode = "";
	let payload = requestLoad;
	let method = "POST";
	return executePost(url, actionCode, payload, method);
};

export const findByIdQrCode = (requestLoad) => {
	let url = urls.QRCODE.FINDBYID + requestLoad;
	let actionCode = "";
	let payload = {};
	let method = "GET";
	return executeGet(url, actionCode, payload, method);
};

export const qrDeactive = (requestLoad) => {
	let url = urls.QRCODE.DEACTIVE + requestLoad;
	let actionCode = "";
	let payload = {};
	let method = "GET";
	return executeGet(url, actionCode, payload, method);
};

export const qrActive = (requestLoad) => {
	let url = urls.QRCODE.ACTIVE + requestLoad;
	let actionCode = "";
	let payload = {};
	let method = "GET";
	return executeGet(url, actionCode, payload, method);
};

export const updateQrCode = (requestLoad, id) => {
	let url = urls.QRCODE.UPDATE + id;
	let actionCode = "";
	let payload = requestLoad;
	let method = "PUT";
	return executePost(url, actionCode, payload, method);
};

export const deleteQrCode = (id) => {
	let url = urls.QRCODE.DELETE + id;
	let actionCode = "";
	let payload = {};
	let method = "DELETE";
	return executeGet(url, actionCode, payload, method);
};

export const activeQrCode = (requestLoad) => {
	let url = urls.QRCODE.ACTIVE + requestLoad;
	let actionCode = "";
	let payload = {};
	let method = "GET";
	return executeGet(url, actionCode, payload, method);
};

export const deactiveQrCode = (requestLoad) => {
	let url = urls.QRCODE.DEACTIVE + requestLoad;
	let actionCode = "";
	let payload = {};
	let method = "GET";
	return executeGet(url, actionCode, payload, method);
};

export const getUserQrCount = () => {
	let url = urls.QRCODE.COUNT;
	let actionCode = "";
	let payload = {};
	let method = "GET";
	return executeGet(url, actionCode, payload, method);
};
