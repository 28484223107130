function AdditionalFieldsArray (props) {
    switch (props) {
        case 'Wedding QR code':
            return ['Button', 'DescriptionBox', 'MapLocation', 'File']
        case 'Document QR code':
            return []
        case 'Image QR code':
            return []
        case 'Event QR code':
            return ['Button', 'DescriptionBox', 'MapLocation', 'File']
        case 'Social media QR code':
            return ['MapLocation', 'File']
        case 'Coupon QR code':
            return ['Button', 'DescriptionBox', 'MapLocation', 'File']
        case 'Custom page QR code':
            return ['Button', 'DescriptionBox', 'MapLocation', 'File']
        case 'Google maps QR code':
            return []
        case 'PayPal QR code':
            return []
        case 'App store QR code':
            return []
        case 'Product QR code':
            return ['Button', 'DescriptionBox', 'MapLocation', 'File']
        case 'Audio QR code':
            return []
        case 'VCard profile QR code':
            return ['DescriptionBox', 'MapLocation', 'File']
        case 'Website URL QR code':
            return []
        default:
            return []
    }
}

export default AdditionalFieldsArray