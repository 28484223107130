import * as React from "react";
import { Box, Grid } from "@material-ui/core";

import classes from "./Page2.module.css";

import qr1Icon from "../../../../../Assets/Generation&DesignSDK/qr1.svg";
import qr2Icon from "../../../../../Assets/Generation&DesignSDK/qr2.svg";

function Page2() {
  return (
    <Box className={classes.box}>
      <Grid container>
        <Grid item xs={12} className="d-flex flex-column align-items-center">
          <span className={classes.boxTitle}> Why HOT QR? </span>
          <span className={classes.boxsubTitle}>
            HOT QR’s SDK is a powerful toolbox that helps developers generate
          </span>
          <span className={classes.boxsubTitle}>
            highly attractive and branded QR Codes in their application
          </span>
          <span className={classes.boxTitle2}> QR Code design comparison </span>
        </Grid>
        <Grid item container xs={12} spacing={15}>
          <Grid item xs={6} className="d-flex flex-column align-items-end mt-4">
            <Box className="d-flex flex-column align-items-center">
              <span className={classes.labelText}>
                Standard black & white QR code
              </span>
              <img className={classes.Icon} alt="QrIcon" src={qr1Icon} />
            </Box>
          </Grid>
          <Grid
            item
            xs={6}
            className="d-flex flex-column align-items-start mt-4"
          >
            <Box className="d-flex flex-column align-items-center">
              <span className={classes.labelText}>Designed QR code</span>
              <img className={classes.Icon} alt="QrIcon" src={qr2Icon} />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Page2;
