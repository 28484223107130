import React from "react";
import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Typography,
	// Box,
	Grid,
	// TextField
} from "@material-ui/core";
import {ExpandMore} from "@material-ui/icons";
// import Select from 'react-select';
import {makeStyles} from "@material-ui/core-v4";

import BgColorComp from "./ColorComponents/BgColor";
import QrContext from "../../../QrContext/QrContext";

const useStyles = makeStyles((theme) => ({
	box: {
		"& .MuiPaper-root": {
			boxShadow: "none !important",
		},
		"& .MuiPaper-root > .MuiButtonBase-root": {
			border: "1px solid #000",
			borderRadius: "2px",
			background: "#F0F0F0",
			height: "50px !important",
		},
		"& .MuiPaper-root > .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded":
			{
				minHeight: "50px !important",
			},
		"& .MuiPaper-root > .MuiCollapse-root": {
			border: "1px solid #D8D8D8",
			margin: "10px 0",
			borderRadius: "2px",
		},
	},
	center: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	card: {
		width: "100%",
		"& div > div": {
			height: "40px",
			borderRadius: "0 2px 2px 0",
		},
		"& .MuiFormControl-root > .MuiOutlinedInput-root > input ": {
			marginTop: "-3px",
		},
		"& .MuiFormControl-root > .MuiOutlinedInput-root > .MuiInputAdornment-root > .MuiButtonBase-root ":
			{
				marginTop: "-7px",
			},
	},
}));

function BackgroundColorComp(props) {
	const {data, dataIndex} = props;
	const {bgColor} = data;
	const classes = useStyles();
	const {setContent} = React.useContext(QrContext);

	if (props.data) {
		const handleChange = (key, value) => {
			setContent((prev) =>
				prev.map((ele, index) => {
					if (index === dataIndex) {
						return {
							...ele,
							[key]: value,
						};
					} else return ele;
				})
			);
		};

		return (
			<div className={classes.box}>
				<Accordion>
					<AccordionSummary expandIcon={<ExpandMore />}>
						<Typography
							style={{color: "#615757", fontSize: "20px", fontWeight: "600"}}
						>
							Background Color
						</Typography>
					</AccordionSummary>
					<AccordionDetails style={{display: "flex", alignItems: "center"}}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<BgColorComp
									bgColor={bgColor}
									handleChange={(key, value) => handleChange(key, value)}
									name="Background Color"
								/>
							</Grid>
						</Grid>
					</AccordionDetails>
				</Accordion>
			</div>
		);
	} else {
		return null;
	}
}
export default BackgroundColorComp;
