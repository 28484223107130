import React from "react";
import {experimentalStyled} from "@material-ui/core";
import {Hidden} from "@material-ui/core-v4";
import {AppProvider} from "@shopify/polaris";
import en from "@shopify/polaris/locales/en.json";
import Sidebar from "../Sidebar1/Sidebar";
import BussinessHandler from "./BussinessHandler";
import SubMain from "./SubMain";
import SubscriptionHeader from "./SubscriptionHeader";
import {Outlet, useNavigate} from "react-router";
// import Header from '../Header/Header';

function MainLayout() {
	const navigate = useNavigate();

	const [state, setState] = React.useState({
		mobileOpen: false,
		active: false,
	});

	const checkingLogin = React.useCallback(() => {
		if (!localStorage.getItem("token")) {
			navigate("/");
		}
	}, [navigate]);

	React.useEffect(() => {
		checkingLogin();
	}, [checkingLogin]);

	const handleDrawerToggle = () => {
		setState((prev) => ({...prev, mobileOpen: !state.mobileOpen}));
	};

	const LayoutRoot = experimentalStyled("div")(({theme}) => ({
		backgroundColor: theme.palette.background.default,
		display: "flex",
		height: "100%",
		overflow: "hidden",
		width: "100%",
	}));
	const LayoutWrapper = experimentalStyled("div")(({theme}) => ({
		display: "flex",
		flex: "1 1 auto",
		overflow: "hidden",
		[theme.breakpoints.up("lg")]: {
			paddingLeft: 250,
		},
	}));
	const LayoutContainer = experimentalStyled("div")({
		display: "flex",
		flex: "1 1 auto",
		overflow: "hidden",
	});

	const LayoutContent = experimentalStyled("div")({
		flex: "1 1 auto",
		height: "100%",
		overflow: "auto",
	});

	return (
		<AppProvider i18n={en}>
			<LayoutRoot>
				{/* <Header onDrawerToggle={this.handleDrawerToggle} /> */}
				<nav>
					<Hidden lgUp implementation="js">
						<Sidebar
							open={state.mobileOpen}
							onClose={handleDrawerToggle}
							onClick={handleDrawerToggle}
							anchor="left"
							variant="temporary"
							PaperProps={{
								sx: {
									width: 250,
								},
							}}
						/>
					</Hidden>
					<Hidden mdDown implementation="css">
						<Sidebar
							anchor="left"
							open
							variant="persistent"
							PaperProps={{
								sx: {
									width: 250,
									height: 9240,
								},
							}}
						/>
					</Hidden>
				</nav>

				<LayoutWrapper>
					<LayoutContainer>
						<LayoutContent>
							<SubscriptionHeader />
							<Outlet />
						</LayoutContent>
					</LayoutContainer>
				</LayoutWrapper>
			</LayoutRoot>
		</AppProvider>
	);
}

export default MainLayout;
