import React from "react";

import {
	Grid,
	Box,
	Card,
	CardContent,
	IconButton,
	Typography,
	Menu,
	MenuItem,
	Tooltip,
	ButtonGroup,
	CircularProgress,
} from "@mui/material";
import LineComponent from "./LineComponent";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import TableChartIcon from "@mui/icons-material/TableChart";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { DataGrid } from "@mui/x-data-grid";
export default function LineComponentWrapper({
	title,
	data,
	dataType,
	showData,
	analyticsData,
}) {
	const [type, setType] = React.useState("");
	const [state, setState] = React.useState({
		series: [],
		options: {},
	});

	const { series } = state;

	React.useEffect(() => {
		setState(() => ({
			...data.graph,
		}));
	}, [data]);

	const [anchorEl, setAnchorEl] = React.useState({
		filter: false,
	});

	const [filter, setFilter] = React.useState({
		line: false,
		stats: false,
	});

	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl((prev) => ({
			...prev,
			filter: event.currentTarget,
		}));
	};
	const handleClose = () => {
		setAnchorEl((prev) => ({
			...prev,
			filter: null,
		}));
	};

	const handleType = (filter, arr) => {
		switch (filter) {
			case "date":
				return arr.getDate();
			case "month":
				return arr.getMonth();
			case "year":
				return arr.getYear() + 1900;
			default:
				return arr;
		}
	};
	const handleFilter = (filter) => {
		setState(() => ({
			...getDateData(analyticsData, filter),
		}));
	};

	function getDateData(x, filter) {
		let d = x
			.map((ele) => ele.scanDevices)
			.flat()
			.map((ele) =>
				ele.scanOn
					? ele.scanOn.length > 10
						? handleType(
								filter,
								new Date(...ele.scanOn.slice(0, 10).split("-"))
						  )
						: handleType(filter, new Date(...ele.scanOn.split("-")))
					: ""
			)
			.sort()
			.filter((ele) => ele);

		return {
			options: {
				xaxis: {
					categories: [...new Set(d)],
				},
				chart: {
					type: filter.line ? "line" : "bar",
				},
			},
			series: x.map((ele) => {
				return {
					name: ele.title,
					data: Object.entries(
						ele.scanDevices
							.map((ele) =>
								ele.scanOn
									? ele.scanOn.length > 10
										? handleType(
												filter,
												new Date(...ele.scanOn.slice(0, 10).split("-"))
										  )
										: handleType(filter, new Date(...ele.scanOn.split("-")))
									: ""
							)
							.sort()
							.filter((ele) => ele)
							.reduce(function (prev, cur) {
								prev[cur] = (prev[cur] || 0) + 1;
								return prev;
							}, {})
					).map(([key, value]) => value),
				};
			}),
		};
	}

	const renderMenu = (
		<Menu
			id="basic-menu"
			anchorEl={anchorEl.filter}
			open={anchorEl.filter}
			onClose={handleClose}
			MenuListProps={{
				"aria-labelledby": "basic-button",
			}}
		>
			<MenuItem
				disabled={series && series.length === 0}
				sx={{
					color: type === "date" ? "primary.main" : "text.secondary",
					borderColor: type === "date" ? "primary.main" : "text.secondary",
				}}
				onClick={() => {
					setType("date");
					handleFilter("date");
					handleClose();
				}}
			>
				Date
			</MenuItem>
			<MenuItem
				disabled={series && series.length === 0}
				sx={{
					color: type === "month" ? "primary.main" : "text.secondary",
					borderColor: type === "month" ? "primary.main" : "text.secondary",
				}}
				onClick={() => {
					setType("month");
					handleFilter("month");
					handleClose();
				}}
			>
				Month
			</MenuItem>
			<MenuItem
				disabled={series && series.length === 0}
				sx={{
					color: type === "year" ? "primary.main" : "text.secondary",
					borderColor: type === "year" ? "primary.main" : "text.secondary",
				}}
				onClick={() => {
					setType("year");
					handleFilter("year");
					handleClose();
				}}
			>
				Year
			</MenuItem>
		</Menu>
	);

	const handleGraphChange = (type) => {
		setState((prev) => ({
			...prev,
			series: [],
		}));
		setTimeout(() => {
			setState((prev) => ({
				series: data.graph.series,
				options: {
					...data.graph.options,
					chart: {
						type: type,
					},
				},
			}));
		}, 100);
	};

	return (
		<Card
			sx={{
				backgroundColor: "#f8f8f8",
				border: "1px solid lightgrey",
				borderRadius: "4px",
				boxShadow: "0px 20px 27px 0px rgba(0, 0, 0, 0.05)",
			}}
			elevation={6}
		>
			<CardContent sx={{ paddingBottom: "16px !important" }}>
				<Grid container>
					<Grid item xs={10}>
						<Typography
							variant="h4"
							className="customLabelData"
							color="textSecondary"
							sx={{
								padding: "1rem",
							}}
						>
							{title}
						</Typography>
					</Grid>
					<Grid
						item
						xs={2}
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}
					>
						<ButtonGroup
							sx={{
								".MuiButtonGroup-grouped": {
									borderRight: "none",
								},
							}}
							size="small"
							variant="text"
							aria-label="text button group"
						>
							<Tooltip title={"Stats"}>
								<IconButton
									disabled={series && series.length === 0}
									sx={{
										color: filter.stats ? "primary.main" : "text.secondary",
									}}
									onClick={() => {
										setFilter((prev) => ({ ...prev, stats: !prev.stats }));
									}}
								>
									<TableChartIcon />
								</IconButton>
							</Tooltip>
							<Tooltip title="Line">
								<IconButton
									disabled={series && series.length === 0}
									sx={{
										color: filter.line ? "primary.main" : "text.secondary",
									}}
									onClick={() => {
										setFilter((prev) => ({
											...prev,
											line: true,
											stats: false,
										}));
										handleGraphChange("line");
									}}
								>
									<ShowChartIcon />
								</IconButton>
							</Tooltip>
							<Tooltip title="Bar">
								<IconButton
									disabled={series && series.length === 0}
									sx={{
										color: !filter.line ? "primary.main" : "text.secondary",
									}}
									onClick={() => {
										setFilter((prev) => ({
											...prev,
											line: false,
											stats: false,
										}));
										handleGraphChange("bar");
									}}
								>
									<EqualizerIcon />
								</IconButton>
							</Tooltip>

							{dataType === "date" && (
								<Tooltip title="Filter">
									<IconButton
										aria-expanded={open ? "true" : undefined}
										onClick={handleClick}
									>
										<FilterAltIcon />
									</IconButton>
								</Tooltip>
							)}
						</ButtonGroup>
					</Grid>
				</Grid>
				<hr />
				<Box
					sx={{
						height: "300px",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						backgroundColor: "transparent",
					}}
					id={title}
				>
					{showData ? (
						<React.Fragment>
							{series && series.length > 0 ? (
								<React.Fragment>
									{!filter.stats ? (
										<LineComponent data={state} />
									) : (
										<DataGrid
											rows={data.table.content}
											columns={data.table.columns.map((ele) => {
												return {
													...ele,
													field: ele.value,
													headerName: ele.label,
												};
											})}
											hideFooterPagination={true}
											disableSelectionOnClick={true}
										/>
									)}
								</React.Fragment>
							) : (
								<Typography
									variant="h4"
									color="textSecondary"
									sx={{
										padding: "1rem",
									}}
								>
									No data for current selection
								</Typography>
							)}
						</React.Fragment>
					) : (
						<CircularProgress />
					)}
				</Box>
			</CardContent>
			{renderMenu}
		</Card>
	);
}
