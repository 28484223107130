import React from "react";

import {
	Container,
	Box,
	Card,
	Typography,
	Button,
	TextField,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	InputAdornment,
	FormGroup,
	FormControlLabel,
	Checkbox,
	Slide,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core-v4";
import Select from "react-select";
import SwitchSelector from "react-switch-selector";

import {updateQrCode} from "../../Services/DashboardServices/ApiUrl";
import {filterAllLead} from "../../Services/LeadServices/ApiUrl";

// --------------- import icons ---------------------- //

import {FcLock} from "react-icons/all";
import PageTitle from "../../../../UI/PageTitle";
import {useParams, useLocation, useNavigate} from "react-router";

const useStyles = makeStyles((theme) => ({
	selectSwitch: {
		width: 300,
		height: 38,
		border: "1px solid #e0e0E0",
		borderRadius: "6px",
		"& div": {
			borderRadius: "6px",
		},
	},
}));

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

function EditQrSettings(props) {
	const navigate = useNavigate();
	const params = useParams();
	const classes = useStyles();

	const location = useLocation();

	const title = location
		? location.state
			? location.state.title
				? location.state.title
				: ""
			: ""
		: "";

	const qrData = location
		? location.state
			? location.state.qrData
				? location.state.qrData
				: ""
			: ""
		: "";

	const {passwordProtected, type, captureLeads, leadId} = qrData;

	const qrId = params.qrId;

	const [data, setData] = React.useState({
		type: type,
		passwordProtected: passwordProtected,
		password: "",
		confPassword: "",
		captureLeads: captureLeads,
		leadId: leadId,
	});

	const [passDialog, setPassDialog] = React.useState(data.password);
	const [leadList, setLeadList] = React.useState([]);
	const [passMatch, setPassMatch] = React.useState(false);
	const [btnDisable, setBtnDisable] = React.useState(false);

	const updateQrCodeApiCall = (payload) => {
		updateQrCode({payload: payload}, qrId)
			.then((res) => {
				setBtnDisable(false);
				navigate(-1);
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const handleSubmit = () => {
		setBtnDisable(true);
		if (data.passwordProtected) {
			delete data.confPassword;
		} else {
			delete data.confPassword;
		}
		if (!data.captureLeads) {
			delete data.leadId;
		}
		let payload = {
			qrData: {
				...data,
			},
		};
		updateQrCodeApiCall(payload);
	};

	const findAllLeadApiCall = React.useCallback(() => {
		filterAllLead({payload: {}})
			.then((res) => {
				setLeadList(res.Items);
			})
			.catch((err) => {
				console.error(err);
			});
	}, []);

	React.useEffect(() => {
		findAllLeadApiCall();
	}, [findAllLeadApiCall]);

	return (
		<Container maxWidth="xl" style={{marginTop: "30px"}}>
			<PageTitle
				title={`My QR Code > ${title || "Qr Code"}`}
				subTitle="View, edit, design, and download QR Code"
			/>
			<Card style={{margin: "0px 0 10px 0", padding: "30px"}}>
				<Box>
					<Typography style={{color: "#666"}}>
						<b>QR Code Type</b>
					</Typography>
					<Box style={{width: "300px", marginTop: "10px"}}>
						<div
							className={classes.selectSwitch}
							style={{width: 300, height: 38}}
						>
							<SwitchSelector
								onChange={(e) => setData((prev) => ({...prev, type: e}))}
								options={[
									{label: "STATIC", value: "STATIC"},
									{label: "DYNAMIC", value: "DYNAMIC"},
								]}
								initialSelectedIndex={type === "STATIC" ? 0 : 1}
								fontColor="#000"
								optionBorderRadius="6"
								selectedBackgroundColor="#FF800C"
							/>
						</div>
					</Box>
					<Typography
						style={{
							color: "#666",
							fontSize: "15px",
							paddingLeft: "0px",
							marginTop: "5px",
						}}
					>
						[Recommended] Dynamic QR Codes have useful capabilities-content
						editing, scan tracking, and landing pages-but need an ongoing
						subscription with HotQR to work
					</Typography>
				</Box>
				<Box style={{marginTop: "20px"}}>
					<Typography style={{color: "#666", fontSize: "20px"}}>
						<b>Advance Settings</b>
					</Typography>
					<Box>
						<FormGroup>
							<FormControlLabel
								control={
									<Checkbox
										checked={data.passwordProtected}
										onChange={(e) => {
											setData((prev) => ({
												...prev,
												passwordProtected: e.target.checked,
											}));
											!data.passwordProtected && setPassDialog(true);
											data.passwordProtected &&
												setData((prev) => ({
													...prev,
													confPassword: "",
													password: "",
												}));
										}}
									/>
								}
								label={
									<React.Fragment>
										<b style={{color: "#666", fontSize: "18px"}}>
											Add Password Protection
										</b>
										<FcLock
											style={{
												marginLeft: "5px",
												fontSize: "22px",
												marginTop: "-5px",
											}}
										/>
									</React.Fragment>
								}
							/>
						</FormGroup>
						<Typography
							style={{color: "#666", fontSize: "15px", paddingLeft: "30px"}}
						>
							Use this option to add password protection to Dynamic QR Codes.
							Password will be required to access QR Code content after scanning
						</Typography>
					</Box>
					<Box>
						<Box>
							<FormGroup>
								<FormControlLabel
									control={
										<Checkbox
											checked={data.captureLeads}
											onChange={(e) => {
												setData((prev) => ({
													...prev,
													captureLeads: e.target.checked,
												}));
											}}
										/>
									}
									label={
										<React.Fragment>
											<b style={{color: "#666", fontSize: "18px"}}>
												Capture Leads
											</b>
										</React.Fragment>
									}
								/>
							</FormGroup>
							<Typography
								style={{color: "#666", fontSize: "15px", paddingLeft: "30px"}}
							>
								Use this option to get contact information of people who scan
								the QR Code. They will get a quick form to fill before seeing
								the QR Code content
							</Typography>
						</Box>
						{data.captureLeads && (
							<Box sx={{pl: "30px", width: "50%", mt: 1.5}}>
								<Typography
									style={{color: "#444", fontSize: "15px", marginBottom: "7px"}}
								>
									Choose Lead List :
								</Typography>
								<Select
									onChange={(e) =>
										setData((prev) => ({...prev, leadId: e.value}))
									}
									width="300px"
									maxMenuHeight="240px"
									placeholder="Choose list from dropdown"
									isClearable={false}
									menuShouldScrollIntoView={true}
									closeMenuOnScroll={true}
									value={
										leadList
											?.map((ele) => {
												if (ele.id === data.leadId) {
													return {
														label: ele.title,
														value: ele.id,
													};
												} else return null;
											})
											?.filter((ele) => ele !== null) || []
									}
									theme={(theme) => ({
										...theme,
										colors: {
											...theme.colors,
											text: "#FFF",
											primary25: "#FF800C",
											primary: "#FF800C",
										},
									})}
									options={leadList.map((ele) => {
										return {
											label: ele.title,
											value: ele.id,
										};
									})}
								/>
							</Box>
						)}
					</Box>
				</Box>
				<Button
					variant="contained"
					color="primary"
					style={{marginTop: "30px"}}
					onClick={handleSubmit}
					disabled={btnDisable}
				>
					Update
				</Button>
			</Card>

			<Dialog
				open={passDialog}
				TransitionComponent={Transition}
				keepMounted
				aria-describedby="alert-dialog-slide-description"
			>
				<DialogTitle>{"Add Password Protection"}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-slide-description">
						<Typography style={{color: "#666"}}>
							<b>Enter Password</b>
						</Typography>
						<Box className={classes.card} style={{marginTop: "5px"}}>
							<TextField
								type="text"
								color="primary"
								onChange={(e) =>
									setData((prev) => ({...prev, password: e.target.value}))
								}
								fullWidth
								value={data.password}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<FcLock style={{fontSize: "28px"}} />
										</InputAdornment>
									),
								}}
							/>
						</Box>
						<Typography style={{color: "#666", marginTop: "10px"}}>
							<b>Confirm Password</b>
						</Typography>
						<Box className={classes.card} style={{marginTop: "5px"}}>
							<TextField
								type="text"
								color="primary"
								onChange={(e) =>
									setData((prev) => ({...prev, confPassword: e.target.value}))
								}
								fullWidth
								value={data.confPassword}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<FcLock style={{fontSize: "28px"}} />
										</InputAdornment>
									),
								}}
							/>
						</Box>
						{passMatch && (
							<Typography style={{color: "red", fontSize: "12px"}}>
								Password Doesn't Match
							</Typography>
						)}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => {
							setPassDialog(false);
							setData((prev) => ({...prev, passwordProtected: false}));
						}}
						variant="contained"
						style={{color: "#FFF", background: "#1d8f48"}}
					>
						Cancel
					</Button>
					<Button
						onClick={() => {
							if (data.password === data.confPassword) {
								setPassMatch(false);
								setPassDialog(false);
							} else {
								setPassMatch(true);
								setData((prev) => ({...prev, password: "", confPassword: ""}));
							}
						}}
						variant="contained"
						style={{color: "#FFF", background: "#c72a2a"}}
					>
						Submit
					</Button>
				</DialogActions>
			</Dialog>
		</Container>
	);
}

export default EditQrSettings;
