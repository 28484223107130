import React from "react";
import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Typography,
	Box,
	Grid,
	TextField,
	IconButton,
	InputAdornment,
} from "@material-ui/core";
import {ExpandMore, Delete} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core-v4";
import QrContext from "../../../QrContext/QrContext";

const useStyles = makeStyles((theme) => ({
	box: {
		"& .MuiPaper-root": {
			boxShadow: "none !important",
		},
		"& .MuiPaper-root > .MuiButtonBase-root": {
			border: (props) => (props ? "2px solid #ff3333" : "1px solid #000"),
			borderRadius: "2px",
			background: "#F0F0F0",
			height: "50px !important",
		},
		"& .MuiPaper-root > .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded":
			{
				minHeight: "50px !important",
			},
		"& .MuiPaper-root > .MuiCollapse-root": {
			border: "1px solid #D8D8D8",
			margin: "10px 0",
			borderRadius: "2px",
		},
	},
	center: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	cardIcon: {
		width: "100%",
		"& div > div": {
			height: "40px",
			borderRadius: "2px",
		},
		"& .MuiFormControl-root > .MuiOutlinedInput-root > input ": {
			marginTop: "-5px",
			padding: "10px",
			borderLeft: "1px solid #C4C4C4",
		},
		"& .MuiFormControl-root > .MuiOutlinedInput-root > .MuiInputAdornment-root":
			{
				marginRight: "8px",
			},
		"& .MuiFormControl-root > .MuiOutlinedInput-root > .MuiInputAdornment-root > img ":
			{
				marginTop: "-5px",
				marginLeft: "-5px",
			},
	},
	card: {
		width: "100%",
		"& div > div": {
			height: "40px",
			borderRadius: "3px",
		},
		"& .MuiFormControl-root > .MuiOutlinedInput-root > input ": {
			marginTop: "-3px",
		},
		"& .MuiFormControl-root > .MuiOutlinedInput-root > .MuiInputAdornment-root > .MuiButtonBase-root ":
			{
				marginTop: "-7px",
			},
	},
}));

function SocialMedia(props) {
	const {setContent} = React.useContext(QrContext);

	const {data, dataIndex, required} = props;
	const classes = useStyles(required === "indicate" ? true : false);

	const {socialLinks} = data;

	const [expanded, setExpanded] = React.useState(false);

	const [iconArray, setIconArray] = React.useState([
		// {
		// 	name: "facebook",
		// 	link: "",
		// 	logo: "https://cdn1.iconfinder.com/data/icons/logotypes/32/square-facebook-128.png",
		// 	text: "",
		// },
		{
			name: "youtube",
			link: "",
			logo: "https://cdn2.iconfinder.com/data/icons/social-media-2285/512/1_Youtube_colored_svg-128.png",
			text: "",
		},
		{
			name: "twitter",
			link: "",
			logo: "https://cdn2.iconfinder.com/data/icons/social-media-2285/512/1_Twitter3_colored_svg-128.png",
			text: "",
		},
		{
			name: "linkedin",
			link: "",
			logo: "https://cdn1.iconfinder.com/data/icons/logotypes/32/square-linkedin-128.png",
			text: "",
		},
		{
			name: "pinterest",
			link: "",
			logo: "https://cdn3.iconfinder.com/data/icons/social-media-chat-1/512/Pinterest-128.png",
			text: "",
		},
		{
			name: "instagram",
			link: "",
			logo: "https://cdn3.iconfinder.com/data/icons/2018-social-media-logotypes/1000/2018_social_media_popular_app_logo_instagram-128.png",
			text: "",
		},
		{
			name: "wechat",
			link: "",
			logo: "https://cdn2.iconfinder.com/data/icons/social-media-applications/64/social_media_applications_9-wechat-128.png",
			text: "",
		},
		{
			name: "whatsapp",
			link: "",
			logo: "https://cdn2.iconfinder.com/data/icons/social-media-2285/512/1_Whatsapp2_colored_svg-128.png",
			text: "",
		},
		{
			name: "telegram",
			link: "",
			logo: "https://cdn3.iconfinder.com/data/icons/social-media-chamfered-corner/154/telegram-128.png",
			text: "",
		},
		{
			name: "line",
			link: "",
			logo: "https://cdn2.iconfinder.com/data/icons/social-media-applications/64/social_media_applications_5-line-128.png",
			text: "",
		},
		{
			name: "tumbir",
			link: "",
			logo: "https://cdn1.iconfinder.com/data/icons/social-media-rounded-corners/512/Rounded_Tumblr5_svg-128.png",
			text: "",
		},
		{
			name: "skype",
			link: "",
			logo: "https://cdn4.iconfinder.com/data/icons/social-media-logos-6/512/41-skype_chat-128.png",
			text: "",
		},
		{
			name: "snapchat",
			link: "",
			logo: "https://cdn2.iconfinder.com/data/icons/social-media-2285/512/1_Snapchat_colored_svg-128.png",
			text: "",
		},
		{
			name: "reddit",
			link: "",
			logo: "https://cdn2.iconfinder.com/data/icons/social-media-2285/512/1_Reddit3_colored_svg-128.png",
			text: "",
		},
		{
			name: "meetup",
			link: "",
			logo: "https://cdn4.iconfinder.com/data/icons/logos-and-brands/512/213_Meetup_logo_logos-128.png",
			text: "",
		},
		{
			name: "quara",
			link: "",
			logo: "https://cdn0.iconfinder.com/data/icons/social-flat-rounded-rects/512/quora-128.png",
			text: "",
		},
		{
			name: "yelp",
			link: "",
			logo: "https://cdn2.iconfinder.com/data/icons/social-flat-buttons-3/512/yelp-128.png",
			text: "",
		},
		{
			name: "medium",
			link: "",
			logo: "https://cdn1.iconfinder.com/data/icons/social-media-rounded-corners/512/Rounded_Medium3_svg-128.png",
			text: "",
		},
		{
			name: "qq",
			link: "",
			logo: "https://cdn2.iconfinder.com/data/icons/social-media-applications/64/social_media_applications_10-qq-128.png",
			text: "",
		},
		{
			name: "tiktok",
			link: "",
			logo: "https://cdn4.iconfinder.com/data/icons/logos-brands-in-colors/2840/tiktok-logo-128.png",
			text: "",
		},
		{
			name: "url",
			link: "",
			logo: "https://cdn3.iconfinder.com/data/icons/pixel-perfect-at-16px-volume-3-1/16/5044-128.png",
			text: "",
		},
	]);

	const handleChange = (key, value) => {
		setContent((prev) =>
			prev.map((ele, index) => {
				if (index === dataIndex) {
					return {
						...ele,
						[key]: value,
					};
				} else return ele;
			})
		);
	};

	const handleDelete = () => {
		setContent((prev) => [...prev].filter((ele, index) => dataIndex !== index));
	};
	const handleClickDelete = (eleIndex, ele) => {
		handleChange(
			"socialLinks",
			socialLinks.filter((ele, index) => index !== eleIndex)
		);
		setIconArray((prev) => [...prev, ele]);
	};

	const handleClickIcon = (obj) => {
		handleChange("socialLinks", [...socialLinks, obj]);
		setIconArray((prev) => prev.filter((ele) => ele.name !== obj.name));
	};
	const handleChnageText = (e, eleIndex) => {
		handleChange(
			"socialLinks",
			socialLinks.map((ele, index) => {
				if (index === eleIndex) {
					return {
						...ele,
						[e.target.name]: e.target.value,
					};
				} else return ele;
			})
		);
	};

	return (
		<div className={classes.box}>
			<Accordion onChange={(e, data) => setExpanded(data)}>
				<AccordionSummary expandIcon={<ExpandMore />}>
					<Box
						style={{
							display: "flex",
							justifyContent: "space-between",
							width: "100%",
							alignItems: "center",
						}}
					>
						<Typography
							style={{color: "#615757", fontSize: "20px", fontWeight: "600"}}
						>
							Social Media *
						</Typography>
						{expanded && (
							<IconButton
								style={{marginRight: "15px"}}
								onClick={() => handleDelete()}
							>
								<Delete />
							</IconButton>
						)}
					</Box>
				</AccordionSummary>
				<AccordionDetails
					style={{
						display: "flex",
						alignItems: "center",
						flexDirection: "column",
						justifyContent: "center",
					}}
				>
					<Grid container>
						{socialLinks &&
							socialLinks.length > 0 &&
							socialLinks.map((ele, index) => (
								<Grid
									item
									xs={12}
									key={index}
									style={{
										border: "1px solid #E0E0E0",
										padding: "15px",
										margin: "7px 0",
									}}
								>
									<Grid container spacing={1}>
										<Grid item xs={12}>
											<Box
												className={classes.cardIcon}
												style={{
													marginTop: "5px",
													display: "flex",
													alignItems: "center",
												}}
											>
												<TextField
													type="text"
													color="primary"
													name="text"
													onChange={(e) => {
														handleChnageText(e, index);
													}}
													fullWidth
													value={ele.text}
													InputProps={{
														startAdornment: (
															<InputAdornment position="start">
																<img
																	src={ele.logo}
																	alt=""
																	style={{
																		height: "30px",
																		width: "34px",
																		objectFit: "contain",
																	}}
																/>
															</InputAdornment>
														),
													}}
												/>
												<IconButton
													onClick={() => {
														handleClickDelete(index, ele);
													}}
												>
													<Delete />
												</IconButton>
											</Box>
										</Grid>
										<Grid item xs={12}>
											<Box
												className={classes.cardIcon}
												style={{
													marginTop: "5px",
													display: "flex",
													alignItems: "center",
												}}
											>
												<TextField
													type="text"
													color="primary"
													name="link"
													onChange={(e) => {
														handleChnageText(e, index);
													}}
													fullWidth
													value={ele.link}
													InputProps={{
														startAdornment: (
															<InputAdornment position="start">
																<span>URL</span>
															</InputAdornment>
														),
													}}
												/>
											</Box>
										</Grid>
									</Grid>
								</Grid>
							))}
					</Grid>

					<Box
						style={{
							marginTop: "10px",
							border: "1px solid #E0E0E0",
							width: "100%",
							padding: "0 20px 20px 20px",
						}}
					>
						<Grid container>
							{iconArray
								.sort((a, b) => {
									var nameA = a.name.toUpperCase();
									var nameB = b.name.toUpperCase();
									if (nameA < nameB) {
										return -1;
									} else {
										return 1;
									}
								})
								.map((ele, index) => (
									<Grid item xs={1} key={index}>
										<IconButton
											style={{
												border: "1px solid #999",
												borderRadius: "8px",
												padding: "8px",
												marginTop: "20px",
											}}
											onClick={() => handleClickIcon(ele)}
										>
											<img
												alt=""
												src={ele.logo}
												style={{
													height: "40px",
													width: "40px",
													objectFit: "contain",
												}}
											/>
										</IconButton>
									</Grid>
								))}
						</Grid>
					</Box>
				</AccordionDetails>
			</Accordion>
		</div>
	);
}

export default SocialMedia;
