import React from "react";

// ------------------------ import Components --------------------------- //

import {
	Box,
	Typography,
	Card,
	Button,
	IconButton,
	Modal,
	Backdrop,
	Fade,
	TextField,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core-v4";
import SwitchSelector from "react-switch-selector";

// ----------------- import icons ------------------ //

import {Close, LocalOffer, CurrencyRupee} from "@mui/icons-material";
import {subscribePlan} from "../../Services/LicenseServices/ApiUrl";
import AuthContext from "../../../../../Context/AuthContext";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	bgcolor: "background.paper",
	boxShadow: 24,
	p: 4,
	width: "500px",
	paddingBottom: "20px",
};
const useStyles = makeStyles((theme) => ({
	card: {
		width: "100%",
		"& div > div": {
			height: "40px",
			borderRadius: "8px",
		},
		"& .MuiFormControl-root > .MuiOutlinedInput-root > input ": {
			marginTop: "-3px",
			borderLeft: "1px solid #999",
			padding: "5px 0 5px 10px",
		},
		"& .MuiFormControl-root > .MuiOutlinedInput-root > .MuiInputAdornment-root > .MuiButtonBase-root ":
			{
				marginTop: "-7px",
			},
	},
}));

function SubscribeModal(props) {
	const classes = useStyles();
	const {setLicense} = React.useContext(AuthContext);
	const {
		subscribeModalState: {open, data},
		handleClose,
		goToSubscription,
	} = props;
	const [planType, setPlanType] = React.useState("MONTHLY");
	const [payNow, setPayNow] = React.useState(false);
	const [password, setPassword] = React.useState(false);
	const [error, setError] = React.useState(false);
	const [btn, setBtn] = React.useState(false);

	const handlePayNow = () => {
		if (password === "iWant@321") {
			setBtn(true);
			subscribePlan({
				payload: {
					subscriptionPlanType: planType,
					subscriptionPlan: data.id,
				},
			})
				.then((res) => {
					console.log(res);
					setLicense();
					setTimeout(() => {
						setBtn(true);
						handleClose();
						goToSubscription();
					}, 3000);
				})
				.catch((err) => {
					console.error(err);
				});
		} else {
			setError(true);
		}
	};

	return (
		<Modal
			open={open}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={open}>
				<Card style={style}>
					<Box
						style={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							padding: "0 0 0 20px",
							background: "#e8e8e8",
						}}
					>
						<Typography variant="h4" color="primary" style={{}}>
							Perhcase Plan
						</Typography>
						<IconButton onClick={handleClose}>
							<Close
								style={{color: "#444", fontSize: "30px", fontWeight: 800}}
							/>
						</IconButton>
					</Box>
					<Box
						style={{
							width: "100%",
							padding: "20px 20px 0px 20px",
						}}
					>
						<Box
							style={{
								width: "100%",
								padding: "10px",
								display: "flex",
								flexDirection: "column",
								color: "#666",
								background: "#e8e8e8",
								border: "1px solid #D0D0D0",
							}}
						>
							<Typography variant="h5">Choose Billing Period</Typography>
							<div
								className="your-required-wrapper"
								style={{width: "300px", height: 30, marginTop: "10px"}}
							>
								<SwitchSelector
									onChange={(e) => {
										setPlanType(e);
									}}
									options={[
										{label: "Monthly", value: "MONTHLY"},
										{label: "Annually", value: "ANNUALLY"},
									]}
									initialSelectedIndex={0}
									backgroundColor="#666"
									fontColor="#f5f6fa"
									selectedBackgroundColor="#FF800C"
									wrapperBorderRadius={5}
									optionBorderRadius={5}
								/>
							</div>
						</Box>
					</Box>
					<Box
						sx={{
							margin: "20px",
							background: "#e8e8e8",
							border: "1px solid #D0D0D0",
						}}
					>
						<Typography
							sx={{
								display: "flex",
								flexDirection: "column",
								color: "#222",
								padding: "10px",
							}}
						>
							<span>{`Plan: ${data?.title} - ${
								planType === "MONTHLY"
									? "1 Month (28 Days)"
									: "1 Year (365 Days)"
							}`}</span>
							<span
								style={{
									display: "flex",
									alignItems: "center",
									marginTop: "5px",
								}}
							>
								Price:
								{` KSh ${
									planType === "MONTHLY"
										? data?.monthly_cost
										: data?.total_year_cost
								} per ${planType === "MONTHLY" ? "month" : "year"}`}
								{planType === "ANNUALLY" && (
									<span
										style={{
											display: "flex",
											alignItems: "center",
											marginLeft: "5px",
										}}
									>
										{`(KSh ${data?.annual_cost} per month)`}
									</span>
								)}
							</span>
						</Typography>
						<Box sx={{borderTop: "1px solid #D0D0D0", padding: "5px"}}>
							<Button size="small" startIcon={<LocalOffer />}>
								Apply Promo Code
							</Button>
						</Box>
					</Box>
					{payNow && (
						<Box sx={{padding: "10px 20px 20px 20px"}} className={classes.card}>
							<TextField
								type="password"
								fullWidth
								onChange={(e) => setPassword(e.target.value)}
								placeholder="Only admin can pechase plan at this moment"
							/>
							{error && (
								<Typography sx={{color: "red"}}>
									Please enter correct password
								</Typography>
							)}
						</Box>
					)}
					<Box sx={{padding: "0 20px"}}>
						<Button
							variant="contained"
							onClick={() => {
								if (payNow) {
									handlePayNow();
								} else {
									setPayNow(true);
								}
							}}
							disabled={btn}
						>
							Pay Now
						</Button>
					</Box>
				</Card>
			</Fade>
		</Modal>
	);
}

export default SubscribeModal;
