import React from "react";
import {Typography, Box, TextField, InputAdornment} from "@material-ui/core";
import {makeStyles} from "@material-ui/core-v4";
import validator from "validator";

import {Check} from "@material-ui/icons";
import QrContext from "../../../QrContext/QrContext";

const useStyles = makeStyles((theme) => ({
	box: {
		"& .MuiPaper-root": {
			boxShadow: "none !important",
		},
		"& .MuiPaper-root > .MuiButtonBase-root": {
			border: "1px solid #000",
			borderRadius: "2px",
			background: "#F0F0F0",
			height: "50px !important",
		},
		"& .MuiPaper-root > .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded":
			{
				minHeight: "50px !important",
			},
		"& .MuiPaper-root > .MuiCollapse-root": {
			border: "1px solid #D8D8D8",
			margin: "10px 0",
			borderRadius: "2px",
		},
	},
	center: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	card: {
		width: "100%",
		"& div > div": {
			height: "40px",
			borderRadius: "5px",
		},
		"& .MuiFormControl-root > .MuiOutlinedInput-root > input ": {
			marginTop: "-3px",
		},
		"& .MuiFormControl-root > .MuiOutlinedInput-root > .MuiInputAdornment-root > span ":
			{
				marginTop: "-3px",
			},
	},
}));

function WebsiteUrlComp(props) {
	const classes = useStyles();
	const {setContent} = React.useContext(QrContext);

	const [state, setState] = React.useState(false);

	if (props.data) {
		const {data, dataIndex} = props;

		const {url, label} = data;

		const handleChange = (key, value) => {
			setContent((prev) =>
				prev.map((ele, index) => {
					if (index === dataIndex) {
						return {
							...ele,
							[key]: value,
						};
					} else return ele;
				})
			);
		};

		const validate = (name, value) => {
			const url = value.includes("https://");

			const newUrl = url ? value.split("https://")[1] : value;

			if (validator.isURL(newUrl)) {
				handleChange(name, newUrl);
				handleChange("validate", true);
				setState(true);
			} else {
				handleChange(name, newUrl);
				handleChange("validate", false);
				setState(false);
			}
		};

		return (
			<Box style={{display: "flex", flexDirection: "column"}}>
				<Typography variant="h5" style={{marginBottom: "10px"}}>
					{label}
				</Typography>
				<Box
					className={classes.card}
					style={{
						marginTop: "5px",
					}}
				>
					<TextField
						type="text"
						color="primary"
						name="url"
						onChange={(e) => {
							validate(e.target.name, e.target.value);
						}}
						fullWidth
						value={url}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<span>https://</span>
								</InputAdornment>
							),
							endAdornment: (
								<InputAdornment position="end">
									{state && <Check style={{color: "green"}} />}
								</InputAdornment>
							),
						}}
					/>
				</Box>
				{state && (
					<a
						rel="noreferrer"
						target="_blank"
						href={`https://${url}`}
						style={{margin: "10px 0 10px 10px"}}
					>
						View website
					</a>
				)}
			</Box>
		);
	} else {
		return null;
	}
}
export default WebsiteUrlComp;
