import React from "react";
import {StepsModel} from "awesome-react-steps";

const AuthContext = React.createContext({
	qrTypes: {},
	setQrTypes: () => {},
	license: {},
	setLicense: () => {},
	qrCount: 0,
	setQrCount: () => {},
	geoInfo: {},
	setGeoInfo: () => {},
	login: false,
	setLogin: () => {},
	additionalContent: [],
	setAdditionalContent: () => [],
	category: {},
	setCategory: () => {},
	qrData: {},
	setQrData: () => {},
	stepModel: new StepsModel([
		{label: "Choose QR Code Category"},
		{label: "Enter Content"},
		{label: "Create QR Code"},
	]),
	setStepModel: () => {},
	goBackModal: false,
	setGoBackModal: () => {},
});

export default AuthContext;
