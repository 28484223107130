import React from "react";

// ------------------------ import Components --------------------------- //

import {Box, Grid, Container, Typography} from "@material-ui/core";

import SettingCustomDomain from "../../../../../Assets/settingCustomDomain.png";

function CustomDomain(props) {
	// if(plans){
	return (
		<Container maxWidth="xl" style={{margin: "30px auto"}}>
			<Box
				style={{
					width: "100%",
					margin: "30px 0 0 0",
					display: "flex",
					flexDirection: "column",
					color: "#666",
				}}
			>
				<Grid container>
					<Grid item container xs={12}>
						<Grid item xs={8}>
							<Typography variant="h5" className="d-flex flex-column">
								<b>
									Using this feature, you can replace the default re-directing
									URL with your custom sub-domain.
								</b>
								<span style={{marginTop: "20px"}}>
									A custom short URL is helpful when you want to show your
									personal brand to users who scan your QR Code. HotQr offers
									this feature where you can customize your re-directing URL
									without showing any HotQr branding to users.
								</span>
							</Typography>
						</Grid>
					</Grid>
					<Grid item xs={8} style={{marginTop: "40px"}}>
						<img
							src={SettingCustomDomain}
							alt=""
							style={{height: "300px", width: "auto"}}
						/>
					</Grid>
					<Grid item container xs={12} style={{marginTop: "30px"}}>
						<Grid item xs={8}>
							<Typography variant="h5" className="d-flex flex-column">
								<b>Add Custom Sub-domain</b>
								<span style={{marginTop: "5px"}}>
									In this section, you can setup your own custom sub-domain by
									following a quick step-by-step process.
								</span>
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Box>
		</Container>
	);
	// } else {
	//     return (
	//         <Box style={{ marginTop: '10%', width: '100%', textAlign: 'center' }}>
	//             <CircularProgress />
	//         </Box>
	//     )
	// }
}

export default CustomDomain;
