import {
	Container,
	Box,
	Typography,
	Grid,
	Card,
	Button,
	Paper,
	IconButton,
	Tooltip,
	CircularProgress,
} from "@material-ui/core";
import React from "react";

import {AiOutlineCloseCircle, MdFileDownloadDone} from "react-icons/all";

import {updateCustomizedQr} from "../../Services/QrCustomizeServices/ApiUrl";
import axios from "axios";

import Template from "./Components/Template";
import Logo from "./Components/Logo";
import Eyes from "./Components/Eyes";
import Data from "./Components/Data";
import Background from "./Components/Background";
import ErrorCorrection from "./Components/ErrorCorrection";
import {updateQrCode} from "../../Services/DashboardServices/ApiUrl";
import PageTitle from "../../../../UI/PageTitle";
import {useLocation, useNavigate, useParams} from "react-router";

const menuArray = [
	// {name: "Templates"},
	// {name: "Logo"},
	{name: "Eyes"},
	{name: "Data"},
	{name: "Background"},
	{name: "Error Correction"},
];

function CustomDesign(props) {
	const navigate = useNavigate();
	const location = useLocation();
	const appUrl = location
		? location?.state
			? location?.state.url
				? location?.state.url
				: ""
			: ""
		: "" || "";
	const oldQr = location
		? location.state
			? location.state.qr
				? location.state.qr
				: ""
			: ""
		: "" || "";
	const qrDesignData = location
		? location.state
			? location.state.qrDesignData
				? location.state.qrDesignData
				: false
			: false
		: false || false;

	const params = useParams();
	const qrCodeId = params.qrId;

	const [activeMenu, setActiveMenu] = React.useState(0);
	const [loadQr, setLoadQr] = React.useState(false);

	const [data, setData] = React.useState([]);
	const [btnDisable, setBtnDisable] = React.useState(false);

	const [qrCode, setQrCode] = React.useState("");

	const handleData = (ele, index) => {
		if (
			data.length > 0 &&
			data.filter((ele) => ele.index === index).length > 0
		) {
			setData((prev) =>
				prev.map((item, itemIndex) => {
					if (item.index === index) {
						return {
							...item,
							...ele,
						};
					} else return item;
				})
			);
		} else {
			setData((prev) => [...prev, {index, ...ele}]);
		}
	};

	const handleDeleteData = (index) => {
		setData((prev) => prev.filter((ele) => ele.index !== index));
	};

	const getLogoQrCodeApiCall = React.useCallback(
		(values) => {
			setLoadQr(true);
			axios
				.post("http://136.232.113.214:10004/api/generate", {
					data: appUrl ? appUrl : "",
					logo:
						data.filter((ele) => ele.index === 1).length > 0
							? data.filter((ele) => ele.index === 1)[0].logo !== "" ||
							  data.filter((ele) => ele.index === 1)[0].logo !== null
								? data.filter((ele) => ele.index === 1)[0].logo.url
									? data.filter((ele) => ele.index === 1)[0].logo.url
									: ""
								: ""
							: "",
					logoBkgColorTransparency: "0.5F",
					logoBackgroundColor:
						data.filter((ele) => ele.index === 1).length > 0
							? data.filter((ele) => ele.index === 1)[0].logoBackgroundColor
							: "#E0E0E0",
					bDrawLogoBackground:
						data.filter((ele) => ele.index === 1).length > 0
							? data.filter((ele) => ele.index === 1)[0].logo !== "" ||
							  data.filter((ele) => ele.index === 1)[0].logo !== null
								? data.filter((ele) => ele.index === 1)[0].logo.blocks
									? data.filter((ele) => ele.index === 1)[0].logo.blocks
									: true
								: true
							: true,

					finderColour:
						data.filter((ele) => ele.index === 2).length > 0
							? data.filter((ele) => ele.index === 2)[0].finderColour
							: "#000000",
					finderBallColour:
						data.filter((ele) => ele.index === 2).length > 0
							? data.filter((ele) => ele.index === 2)[0].finderBallColour
							: "#000000",
					topLeftFinderShape:
						data.filter((ele) => ele.index === 2).length > 0
							? data.filter((ele) => ele.index === 2)[0].topLeftFinderShape
							: 1,
					topRightFinderShape:
						data.filter((ele) => ele.index === 2).length > 0
							? data.filter((ele) => ele.index === 2)[0].topRightFinderShape
							: 1,
					bottomLeftFinderShape:
						data.filter((ele) => ele.index === 2).length > 0
							? data.filter((ele) => ele.index === 2)[0].bottomLeftFinderShape
							: 1,
					finderBallShape:
						data.filter((ele) => ele.index === 2).length > 0
							? data.filter((ele) => ele.index === 2)[0].finderBallShape
							: 0,

					dataBlockShape:
						data.filter((ele) => ele.index === 3).length > 0
							? data.filter((ele) => ele.index === 3)[0].dataBlockShape
							: 0,
					dataColorMode: 1,
					dataColourPrimary:
						data.filter((ele) => ele.index === 3).length > 0
							? data.filter((ele) => ele.index === 3)[0].dataColourPrimary
							: "#000000",
					dataColourSecondary:
						data.filter((ele) => ele.index === 3).length > 0
							? data.filter((ele) => ele.index === 3)[0].dataColourSecondary
							: "#000000",

					backgroundColour:
						data.filter((ele) => ele.index === 4).length > 0
							? data.filter((ele) => ele.index === 4)[0].backgroundColour
							: "#FFFFFF",

					...values,
				})
				.then((res) => {
					setQrCode(res);
					setLoadQr(false);
				})
				.catch((err) => {
					console.error(err);
					setLoadQr(false);
				});
		},
		[appUrl, setQrCode, data]
	);

	const updateQrCodeS3BucketApiCall = () => {
		setBtnDisable(true);
		const payload = {
			qrData: {
				qrDesignData: data,
			},
		};
		updateQrCode({payload: payload}, qrCodeId)
			.then((res) => {
				updateCustomizedQr(qrCodeId, {qrBase64: qrCode.data})
					.then((res) => {
						setBtnDisable(false);
						navigate(-1);
					})
					.catch((err) => {
						console.error(err);
					});
			})
			.catch((err) => {
				console.error(err);
			});
	};

	React.useEffect(() => {
		if (qrDesignData) {
			setData((prev) => [...prev, ...qrDesignData]);
		} else {
			setData((prev) => [
				...prev,
				{
					bottomLeftFinderShape: 1,
					finderBallColour: "#000000",
					finderBallShape: 0,
					finderColour: "#000000",
					index: 2,
					pattern: 1,
					topLeftFinderShape: 1,
				},
				{
					dataBlockShape: 3,
					dataColorMode: 1,
					dataColourPrimary: "#000000",
					dataColourSecondary: "#404040",
					index: 3,
				},
			]);
		}
	}, [qrDesignData]);

	const DesignComp = () => {
		switch (activeMenu + 2) {
			case 0:
				return (
					<Template
						setLoadQr={setLoadQr}
						loadQr={loadQr}
						data={data}
						handleData={handleData}
						setQrCode={(data) => setQrCode(data)}
						handleDeleteData={handleDeleteData}
						apiCall={(data) => getLogoQrCodeApiCall(data)}
					/>
				);
			case 1:
				return (
					<Logo
						setLoadQr={setLoadQr}
						loadQr={loadQr}
						data={data}
						handleData={handleData}
						setQrCode={(data) => setQrCode(data)}
						handleDeleteData={handleDeleteData}
						appUrl={appUrl ? appUrl : ""}
						apiCall={(data) => getLogoQrCodeApiCall(data)}
					/>
				);
			case 2:
				return (
					<Eyes
						setLoadQr={setLoadQr}
						loadQr={loadQr}
						data={data}
						setQrCode={(data) => setQrCode(data)}
						handleData={handleData}
						handleDeleteData={handleDeleteData}
						appUrl={appUrl ? appUrl : ""}
						apiCall={(data) => getLogoQrCodeApiCall(data)}
					/>
				);
			case 3:
				return (
					<Data
						setLoadQr={setLoadQr}
						loadQr={loadQr}
						setQrCode={(data) => setQrCode(data)}
						data={data}
						handleData={handleData}
						handleDeleteData={handleDeleteData}
						apiCall={(data) => getLogoQrCodeApiCall(data)}
					/>
				);
			case 4:
				return (
					<Background
						setLoadQr={setLoadQr}
						loadQr={loadQr}
						data={data}
						handleData={handleData}
						handleDeleteData={handleDeleteData}
						apiCall={(data) => getLogoQrCodeApiCall(data)}
					/>
				);
			case 5:
				return (
					<ErrorCorrection
						setLoadQr={setLoadQr}
						loadQr={loadQr}
						data={data}
						handleData={handleData}
						handleDeleteData={handleDeleteData}
					/>
				);
			default:
				return null;
		}
	};

	return (
		<Container maxWidth="xl">
			<PageTitle
				title="Custom Design"
				subTitle="Add colors, patterns, and brand image to QR Code design"
			/>
			<Box sx={{marginBottom: "10px"}}>
				<Grid container spacing={2}>
					<Grid item xs={9}>
						<Grid container spacing={2}>
							<Grid item xs={2}>
								<Card style={{padding: "5px 10px", width: "100%"}}>
									<Grid container>
										{menuArray.map((item, index) => (
											<Grid key={index} item xs={12}>
												<Button
													fullWidth
													color="primary"
													variant={index === activeMenu ? "contained" : "text"}
													style={{margin: "5px 0"}}
													onClick={() => setActiveMenu(index)}
												>
													{item.name}
												</Button>
											</Grid>
										))}
									</Grid>
								</Card>
							</Grid>
							<Grid item xs={10}>
								<Paper style={{height: "auto", padding: "10px"}}>
									<Typography
										variant="h3"
										color="primary"
										style={{paddingLeft: "3px"}}
									>
										{menuArray[activeMenu].name}
									</Typography>
									{DesignComp()}
								</Paper>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={3} style={{position: "relative"}}>
						<Paper
							style={{
								padding: "10px",
								opacity: loadQr ? "40%" : "100%",
								pointerEvents: loadQr ? "none" : "all",
							}}
						>
							<Box
								style={{
									border: "1px solid #E0E0E0",
									borderRadius: "5px",
									display: "flex",
									alignItems: "center",
									flexDirection: "column",
									padding: "2px",
								}}
							>
								<img
									src={
										qrCode.data ? "data:image/png;base64," + qrCode.data : oldQr
									}
									alt=""
									style={{height: "100%", width: "100%", objectFit: "contain"}}
								/>
								<Tooltip
									title={<span style={{fontSize: "16px"}}>Clear Design</span>}
									placement="top"
								>
									<IconButton
										style={{marginBottom: "10px"}}
										onClick={() => {
											setData([]);
											getLogoQrCodeApiCall();
										}}
									>
										<AiOutlineCloseCircle style={{fontSize: "30px"}} />
									</IconButton>
								</Tooltip>
							</Box>
							<Box style={{marginTop: "20px"}}>
								{/* <Button
									color="primary"
									variant="outlined"
									fullWidth
									style={{marginBottom: "10px"}}
									startIcon={<ImQrcode />}
								>
									Save Template
								</Button> */}
								<Button
									color="primary"
									variant="contained"
									fullWidth
									startIcon={<MdFileDownloadDone />}
									onClick={updateQrCodeS3BucketApiCall}
									disabled={btnDisable}
								>
									Done Editing
								</Button>
							</Box>
						</Paper>
						{loadQr && (
							<Box style={{position: "absolute", top: "45%", left: "47%"}}>
								<CircularProgress />
							</Box>
						)}
					</Grid>
				</Grid>
			</Box>
		</Container>
	);
}

export default CustomDesign;
