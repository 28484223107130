import {urls} from "../../../../../Environment/ApiUrl";
import {executePost} from "../../../../../Services/iwant-rest-http-post";
import {executeGet} from "../../../../../Services/iwant-rest-generic";

export const createLead = (requestLoad) => {
	let url = urls.LEAD.CREATE;
	let actionCode = "";
	let payload = requestLoad;
	let method = "POST";
	return executePost(url, actionCode, payload, method);
};

export const leadFindById = (requestLoad) => {
	let url = urls.LEAD.FINDBYID + requestLoad;
	let actionCode = "";
	let payload = {};
	let method = "GET";
	return executeGet(url, actionCode, payload, method);
};

export const filterAllLead = (requestLoad) => {
	let url = urls.LEAD.FILTER;
	let actionCode = "";
	let payload = requestLoad;
	let method = "POST";
	return executePost(url, actionCode, payload, method);
};

export const updateLead = (id, requestLoad) => {
	let url = urls.LEAD.UPDATE + id;
	let actionCode = "";
	let payload = requestLoad;
	let method = "PUT";
	return executePost(url, actionCode, payload, method);
};

export const deleteLead = (id) => {
	let url = urls.LEAD.DELETE + id;
	let actionCode = "";
	let payload = {};
	let method = "DELETE";
	return executeGet(url, actionCode, payload, method);
};

export const leadAnalytics = (requestLoad) => {
	let url = urls.LEAD.ANALYTICS;
	let actionCode = "";
	let payload = requestLoad;
	let method = "POST";
	return executePost(url, actionCode, payload, method);
};
