function optionsFunction(props) {
	switch (props) {
		case "multipleChoice":
		case "checkboxes":
		case "dropdownList":
			return {
				option: "",
			};
		default:
			return {};
	}
}

export default optionsFunction;
