import React from "react";
import {
	Typography,
	Box,
	Grid,
	TextField,
	InputAdornment,
} from "@material-ui/core";
import Select from "react-select";
import {
	MdEmail,
	SiGooglemessages,
	GiCardboardBoxClosed,
	AiFillDollarCircle,
} from "react-icons/all";
import {makeStyles} from "@material-ui/core-v4";
import QrContext from "../../../QrContext/QrContext";

const useStyles = makeStyles((theme) => ({
	box: {
		border: "1px solid #E0E0E0",
		borderRadius: "8px",
		padding: "20px 30px 30px 30px",
	},
	center: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	card: {
		width: "100%",
		"& div > div": {
			height: "40px",
			borderRadius: "2px",
		},
		"& .MuiFormControl-root > .MuiOutlinedInput-root > input ": {
			marginTop: "-5px",
			padding: "10px",
			borderLeft: "1px solid #C4C4C4",
		},
		"& .MuiFormControl-root > .MuiOutlinedInput-root > .MuiInputAdornment-root":
			{
				marginRight: "8px",
			},
		"& .MuiFormControl-root > .MuiOutlinedInput-root > .MuiInputAdornment-root > svg ":
			{
				marginTop: "-5px",
				marginLeft: "-5px",
				fontSize: "25px",
			},
	},
}));

function PaypalComp(props) {
	const classes = useStyles();
	const {setContent} = React.useContext(QrContext);

	if (props.data) {
		const {data, dataIndex} = props;

		const {email, description, productCode, price, currency} = data;

		const handleChange = (key, value) => {
			setContent((prev) =>
				prev.map((ele, index) => {
					if (index === dataIndex) {
						return {
							...ele,
							[key]: value,
						};
					} else return ele;
				})
			);
		};

		return (
			<Box className={classes.box}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Grid container spacing={1}>
							<Grid item xs={12}>
								<Typography variant="h5">Email Address</Typography>
							</Grid>
							<Grid item xs={12}>
								<Box className={classes.card}>
									<TextField
										type="email"
										color="primary"
										name="email"
										onChange={(e) => {
											handleChange(e.target.name, e.target.value);
										}}
										fullWidth
										value={email}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<MdEmail />
												</InputAdornment>
											),
										}}
									/>
								</Box>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Grid container spacing={1}>
							<Grid item xs={12}>
								<Typography variant="h5">Description</Typography>
							</Grid>
							<Grid item xs={12}>
								<Box className={classes.card}>
									<TextField
										type="text"
										color="primary"
										name="description"
										onChange={(e) => {
											handleChange(e.target.name, e.target.value);
										}}
										fullWidth
										value={description}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<SiGooglemessages />
												</InputAdornment>
											),
										}}
									/>
								</Box>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Grid container spacing={1}>
							<Grid item xs={12}>
								<Typography variant="h5">Product Code (optional)</Typography>
							</Grid>
							<Grid item xs={12}>
								<Box className={classes.card}>
									<TextField
										type="text"
										color="primary"
										name="productCode"
										onChange={(e) => {
											handleChange(e.target.name, e.target.value);
										}}
										fullWidth
										value={productCode}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<GiCardboardBoxClosed />
												</InputAdornment>
											),
										}}
									/>
								</Box>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Grid container spacing={1}>
							<Grid item xs={12}>
								<Typography variant="h5">Price</Typography>
							</Grid>
							<Grid item xs={12}>
								<Box className={classes.card}>
									<TextField
										type="text"
										color="primary"
										name="price"
										onChange={(e) => {
											handleChange(e.target.name, e.target.value);
										}}
										fullWidth
										value={price}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<AiFillDollarCircle />
												</InputAdornment>
											),
										}}
									/>
								</Box>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Grid container spacing={1}>
							<Grid item xs={12}>
								<Typography variant="h5">Currency</Typography>
							</Grid>
							<Grid item xs={12}>
								<Box style={{width: "100%"}}>
									<Select
										onChange={(e) => handleChange("currency", e.value)}
										width="100%"
										maxMenuHeight="240px"
										placeholder="Link"
										menuShouldScrollIntoView={true}
										closeMenuOnScroll={true}
										value={{label: currency.toUpperCase(), value: currency}}
										theme={(theme) => ({
											...theme,
											colors: {
												...theme.colors,
												text: "#FFF",
												primary25: "#FF800C",
												primary: "#FF800C",
											},
										})}
										defaultValue={{label: "USD", value: "USD"}}
										options={[
											{label: "USD", value: "USD"},
											{label: "AUD", value: "AUD"},
											{label: "CAD", value: "CAD"},
											{label: "CHF", value: "CHF"},
											{label: "EUR", value: "EUR"},
											{label: "GBP", value: "GBP"},
											{label: "HKD", value: "HKD"},
											{label: "JPE", value: "JPE"},
											{label: "NZD", value: "NZD"},
											{label: "SGD", value: "SGD"},
											{label: "TWD", value: "TWD"},
										]}
									/>
								</Box>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Box>
		);
	} else {
		return null;
	}
}
export default PaypalComp;
