import React from "react";
import {Typography, Card} from "@material-ui/core";
import {makeStyles} from "@material-ui/core-v4";

const useStyles = makeStyles((theme) => ({
	key: {
		color: "#222",
	},
	value: {
		color: "#444",
	},
}));

function ContactDetailsComp(props) {
	const classes = useStyles();

	const {data} = props;

	const {primaryPhone, primaryEmail, website, fax, address} = data;

	if (
		primaryPhone.length > 0 ||
		primaryEmail.length > 0 ||
		website.length > 0 ||
		fax.length > 0
	) {
		return (
			<Card style={{padding: "20px 20px", marginTop: "10px"}}>
				{primaryPhone.length > 0 && (
					<Typography
						variant="h5"
						style={{
							marginBottom: "8px",
							display: "flex",
							flexDirection: "column",
						}}
					>
						<span className={classes.key}>Phone: </span>
						<span className={classes.value}>{primaryPhone}</span>
					</Typography>
				)}
				{primaryEmail.length > 0 && (
					<Typography
						variant="h5"
						style={{
							marginBottom: "8px",
							display: "flex",
							flexDirection: "column",
						}}
					>
						<span className={classes.key}>Email: </span>
						<span className={classes.value}>{primaryEmail}</span>
					</Typography>
				)}
				{website.length > 0 && (
					<Typography
						variant="h5"
						style={{
							marginBottom: "8px",
							display: "flex",
							flexDirection: "column",
						}}
					>
						<span className={classes.key}>Website: </span>
						<span className={classes.value}>{website}</span>
					</Typography>
				)}
				{fax.length > 0 && (
					<Typography
						variant="h5"
						style={{
							marginBottom: "8px",
							display: "flex",
							flexDirection: "column",
						}}
					>
						<span className={classes.key}>Fax: </span>
						<span className={classes.value}>{fax}</span>
					</Typography>
				)}
				{address.length > 0 &&
					address.map((ele, index) => (
						<React.Fragment key={index}>
							<Typography
								variant="h5"
								style={{
									marginBottom: "8px",
									display: "flex",
									flexDirection: "column",
								}}
							>
								<span
									className={classes.key}
									style={{fontSize: "17px", marginBottom: "3px"}}
								>
									Address : {ele.type}
								</span>
								{ele.street.length > 0 && (
									<span className={classes.value}>{ele.street},</span>
								)}
								{ele.city.length > 0 && (
									<span className={classes.value}>{ele.city},</span>
								)}
								{ele.state.length > 0 && (
									<span className={classes.value}>{ele.state},</span>
								)}
								{ele.country.length > 0 && (
									<span className={classes.value}>
										{ele.country}
										{ele.postalCode.length > 0 && " - " + ele.postalCode}.
									</span>
								)}
							</Typography>
						</React.Fragment>
					))}
			</Card>
		);
	} else {
		return null;
	}
}
export default ContactDetailsComp;
