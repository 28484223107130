import * as React from "react";
import { Box, Grid } from "@material-ui/core";

import classes from "./Page3.module.css";

import qrIcon from "../../../../../Assets/Generation&DesignSDK/qr3.svg";

function Page3() {
  return (
    <Box className={classes.box}>
      <Grid container>
        <Grid
          item
          xs={5}
          className="d-flex justify-content-center align-items-center"
        >
          <img className={classes.qrIcon} alt="QrIcon" src={qrIcon} />
        </Grid>
        <Grid item xs={2} className="d-flex justify-content-center">
          <div className={classes.middleLine}></div>
        </Grid>
        <Grid
          item
          xs={5}
          className="d-flex justify-content-center align-items-center"
        >
          <Box className={classes.Text}>
            <span>Personalize QR Code design with brand colors and logo</span>
            <span className="mt-2">
              Edit color and pattern of QR Code elements
            </span>
            <span className="mt-2">Add image in the centre</span>
            <span className="mt-2">REST based calls to create QR Codes</span>

            <span className="mt-2">
              High scannability and compatibility with all QR Code readers
            </span>

            <span className="mt-2">
              Documentation including basics of QR Code, installation
              instructions, API Documentation, and best practices
            </span>

            <span className="mt-2">
              Render QR Code image (PNG) in any size (pixels)
            </span>

            <span className="mt-2">
              Lifetime worldwide license and 6 month support
            </span>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Page3;
