import * as React from "react";
import {Box, Grid} from "@material-ui/core";
import {useNavigate} from "react-router";

import classes from "./Page5.module.css";

import Feature1Icon from "../../../../../Assets/Home/Page5Svg/Feature1.svg";
import Feature2Icon from "../../../../../Assets/Home/Page5Svg/Feature2.svg";
import Feature3Icon from "../../../../../Assets/Home/Page5Svg/Feature3.svg";
import Feature4Icon from "../../../../../Assets/Home/Page5Svg/Feature4.svg";
import Feature5Icon from "../../../../../Assets/Home/Page5Svg/Feature5.svg";
import Feature6Icon from "../../../../../Assets/Home/Page5Svg/Feature6.svg";

function Page5(props) {
	const navigate = useNavigate();
	const handleClickTool = () => {
		navigate("/hotqrbatchgenerator");
	};
	return (
		<Box className={classes.box}>
			<Grid container spacing={2}>
				<Grid item xs={12} className="d-flex justify-content-between">
					<label className={classes.labelTitle}> HOT QR Batch Generator </label>
					<Box className={classes.btnTool} onClick={handleClickTool}>
						<span className={classes.btnToolText}>Tool</span>
					</Box>
				</Grid>
				<Grid item xs={12}>
					<label className={classes.labelTitleFeatures}> Features </label>
				</Grid>
				<Grid
					item
					xs={4}
					className="d-flex justify-content-center align-items-center"
				>
					<Box className={classes.boxFeaturesSub}>
						<img
							className={classes.boxFeaturesIcon}
							alt="Feature1Icon"
							src={Feature1Icon}
						/>
						<label className={classes.boxFeaturesText}>
							<span style={{color: "#000000"}}>
								{" "}
								Multiple QR Code Categories{" "}
							</span>
							<br /> Encode static QR Codes with Website URL, Plain Text, Serial
							Code, and VCard
						</label>
					</Box>
				</Grid>
				<Grid
					item
					xs={4}
					className="d-flex justify-content-center align-items-center"
				>
					<Box className={classes.boxFeaturesSub}>
						<img
							className={classes.boxFeaturesIcon}
							alt="Feature1Icon"
							src={Feature2Icon}
						/>
						<label className={classes.boxFeaturesText}>
							<span style={{color: "#000000"}}> Custom QR Code Design </span>
							<br />
							<br /> Add brand colors, patterns, and logo to QR Code design
						</label>
					</Box>
				</Grid>
				<Grid
					item
					xs={4}
					className="d-flex justify-content-center align-items-center"
				>
					<Box className={classes.boxFeaturesSub}>
						<img
							className={classes.boxFeaturesIcon}
							alt="Feature1Icon"
							src={Feature3Icon}
						/>
						<label className={classes.boxFeaturesText}>
							<span style={{color: "#000000"}}> Fast Generation </span>
							<br />
							<br /> Generate large number of QR Codes in minutes
						</label>
					</Box>
				</Grid>
				<Grid
					item
					xs={4}
					className="d-flex justify-content-center align-items-center mt-3"
				>
					<Box className={classes.boxFeaturesSub}>
						<img
							className={classes.boxFeaturesIcon}
							alt="Feature1Icon"
							src={Feature4Icon}
						/>
						<label className={classes.boxFeaturesText}>
							<span style={{color: "#000000"}}> Custom Size </span>
							<br />
							<br /> Specify exact size of QR Code images in pixels for printing
						</label>
					</Box>
				</Grid>
				<Grid
					item
					xs={4}
					className="d-flex justify-content-center align-items-center mt-3"
				>
					<Box className={classes.boxFeaturesSub}>
						<img
							className={classes.boxFeaturesIcon}
							alt="Feature1Icon"
							src={Feature5Icon}
						/>
						<label className={classes.boxFeaturesText}>
							<span style={{color: "#000000"}}> High Resolution QR Codes </span>
							<br />
							<br /> Export QR Code images in PNG, JPG, and SVG
						</label>
					</Box>
				</Grid>
				<Grid
					item
					xs={4}
					className="d-flex justify-content-center align-items-center mt-3"
				>
					<Box className={classes.boxFeaturesSub}>
						<img
							className={classes.boxFeaturesIcon}
							alt="Feature1Icon"
							src={Feature6Icon}
						/>
						<label className={classes.boxFeaturesText}>
							<span style={{color: "#000000"}}> Sensible Pricing </span>
							<br />
							<br /> Pay per QR Code — larger the batch, cheaper the rate
						</label>
					</Box>
				</Grid>
				<Grid item xs={12} className="d-flex justify-content-center mt-3">
					<Box className={classes.boxTable}>
						<Box className={classes.boxMiddleBar} />
						<Box className={classes.boxTableTitleBar}>
							<label className={classes.boxTableTitleText}> Use Cases </label>
						</Box>
						<label
							className={classes.boxTableContentText}
							style={{left: "128px", top: "84px"}}
						>
							{" "}
							Labels{" "}
						</label>
						<label
							className={classes.boxTableContentText}
							style={{left: "602px", top: "84px"}}
						>
							{" "}
							Trails{" "}
						</label>
						<Box className={classes.boxTableRowBar} style={{top: "128px"}} />
						<label
							className={classes.boxTableContentText}
							style={{left: "128px", top: "158px"}}
						>
							{" "}
							Business Cards{" "}
						</label>
						<label
							className={classes.boxTableContentText}
							style={{left: "602px", top: "158px"}}
						>
							{" "}
							City Buildings{" "}
						</label>
						<Box className={classes.boxTableRowBar} style={{top: "205px"}} />
						<label
							className={classes.boxTableContentText}
							style={{left: "128px", top: "234px"}}
						>
							{" "}
							City Buildings{" "}
						</label>
						<label
							className={classes.boxTableContentText}
							style={{left: "602px", top: "234px"}}
						>
							{" "}
							Survey{" "}
						</label>
						<Box className={classes.boxTableRowBar} style={{top: "282px"}} />
						<label
							className={classes.boxTableContentText}
							style={{left: "128px", top: "312px"}}
						>
							{" "}
							Product Authentication{" "}
						</label>
						<label
							className={classes.boxTableContentText}
							style={{left: "602px", top: "312px"}}
						>
							{" "}
							Identification{" "}
						</label>
						<Box className={classes.boxTableRowBar} style={{bottom: "0px"}} />
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
}

export default Page5;
