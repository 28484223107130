import React from "react";
import {Box, Grid, Typography, Button} from "@material-ui/core";

import pattern0 from "../../../../../../Assets/CustomQr/Design/patterns/data/data0.png";
import pattern1 from "../../../../../../Assets/CustomQr/Design/patterns/data/data1.png";
import pattern2 from "../../../../../../Assets/CustomQr/Design/patterns/data/data2.png";
import pattern3 from "../../../../../../Assets/CustomQr/Design/patterns/data/data3.png";
import pattern4 from "../../../../../../Assets/CustomQr/Design/patterns/data/data4.png";
import pattern5 from "../../../../../../Assets/CustomQr/Design/patterns/data/data5.png";

const patternsArray = [
	{pattern: pattern0},
	{pattern: pattern1},
	{pattern: pattern2},
	{pattern: pattern3},
	{pattern: pattern4},
	{pattern: pattern5},
];

function Data(props) {
	const {handleData, apiCall, data} = props;

	const [state, setState] = React.useState({
		gradient: false,
		primaryColor: "#000000",
		secondaryColor: "#404040",
		pattern: 3,
	});

	React.useEffect(() => {
		setState((prev) => ({
			...prev,
			gradient: false,
			primaryColor:
				data.filter((ele) => ele.index === 3).length > 0
					? data.filter((ele) => ele.index === 3)[0].dataColourPrimary
					: "#000000",
			secondaryColor:
				data.filter((ele) => ele.index === 3).length > 0
					? data.filter((ele) => ele.index === 3)[0].dataColourSecondary
					: "#000000",
			pattern:
				data.filter((ele) => ele.index === 3).length > 0
					? data.filter((ele) => ele.index === 3)[0].dataBlockShape
					: 0,
		}));
	}, [data]);

	return (
		<Box
			style={{
				marginTop: "10px",
				border: "1px solid #E0E0E0",
				padding: "20px",
				borderRadius: "8px",
			}}
		>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Typography
						variant="h5"
						style={{color: "#666", marginBottom: "10px"}}
					>
						Primary Color
					</Typography>
					<Grid container spacing={3}>
						<Grid item md={4} lg={4} xl={3}>
							<Box
								style={{
									height: "40px",
									background: "#E0E0E0",
									border: "1px solid #999",
									borderRadius: "2px",
									display: "flex",
								}}
							>
								<Box
									style={{
										height: "40px",
										width: "40px",
										borderRight: "1px solid #999",
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									#
								</Box>
								<Typography
									style={{
										height: "40px",
										width: "100%",
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									{state &&
										state.primaryColor &&
										state.primaryColor.replace("#", "").toUpperCase()}
								</Typography>
							</Box>
						</Grid>
						<Grid item md={4} lg={4} xl={3}>
							<input
								style={{
									width: "100%",
									height: "40px",
								}}
								type="color"
								onChange={(e) => {
									setState((prev) => ({
										...prev,
										primaryColor: e.target.value,
									}));
								}}
								value={state.primaryColor}
							/>
						</Grid>{" "}
						<Grid item md={4} lg={4} xl={3}>
							<Button
								variant="outlined"
								onClick={() => {
									handleData(
										{
											dataColourPrimary: state.primaryColor,
											dataColourSecondary: state.secondaryColor,
											dataBlockShape: state.pattern,
											dataColorMode: 1,
										},
										3
									);
									apiCall({
										dataColourPrimary: state.primaryColor,
										dataColorMode: 1,
									});
								}}
								style={{marginTop: "2px"}}
							>
								Apply Color
							</Button>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Typography
						variant="h5"
						style={{color: "#666", marginBottom: "10px"}}
					>
						Secondary Color
					</Typography>
					<Grid container spacing={3}>
						<Grid item md={4} lg={4} xl={3}>
							<Box
								style={{
									height: "40px",
									background: "#E0E0E0",
									border: "1px solid #999",
									borderRadius: "2px",
									display: "flex",
								}}
							>
								<Box
									style={{
										height: "40px",
										width: "40px",
										borderRight: "1px solid #999",
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									#
								</Box>
								<Typography
									style={{
										height: "40px",
										width: "100%",
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									{state &&
										state.secondaryColor &&
										state.secondaryColor.replace("#", "").toUpperCase()}
								</Typography>
							</Box>
						</Grid>
						<Grid item md={4} lg={4} xl={3}>
							<input
								style={{
									width: "100%",
									height: "40px",
								}}
								type="color"
								onChange={(e) => {
									setState((prev) => ({
										...prev,
										secondaryColor: e.target.value,
									}));
								}}
								value={state.secondaryColor}
							/>
						</Grid>{" "}
						<Grid item md={4} lg={4} xl={3}>
							<Button
								variant="outlined"
								onClick={() => {
									handleData(
										{
											dataColourPrimary: state.primaryColor,
											dataColourSecondary: state.secondaryColor,
											dataBlockShape: state.pattern,
											dataColorMode: 1,
										},
										3
									);
									apiCall({
										dataColourSecondary: state.secondaryColor,
										dataColorMode: 1,
									});
								}}
								style={{marginTop: "2px"}}
							>
								Apply Color
							</Button>
						</Grid>
					</Grid>
				</Grid>
				<Grid item={12} style={{marginTop: "10px"}}>
					<Typography variant="h5" style={{color: "#666"}}>
						Choose Pattern
					</Typography>
				</Grid>
				<Grid item={12}>
					<Grid container spacing={1} style={{padding: "0 30px"}}>
						{patternsArray.map((ele, index) => (
							<Grid item xs={4} key={index}>
								<Button
									style={{
										height: "auto",
										width: "70%",
										padding: "10px",
										boxShadow:
											state.pattern === index
												? "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px"
												: "",
									}}
									onClick={() => {
										setState((prev) => ({...prev, pattern: index}));
										handleData(
											{
												dataColourPrimary: state.primaryColor,
												dataColourSecondary: state.secondaryColor,
												dataBlockShape: index,
												dataColorMode: 1,
											},
											3
										);
										apiCall({dataBlockShape: index});
									}}
									disableRipple
								>
									<img
										alt=""
										src={ele.pattern}
										style={{
											height: "100%",
											width: "100%",
											objectFit: "contain",
										}}
									/>
								</Button>
							</Grid>
						))}
					</Grid>
				</Grid>
			</Grid>
		</Box>
	);
}

export default Data;
