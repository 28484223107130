import React from "react";
import {
	Container,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button,
	Slide,
} from "@material-ui/core";
import {useNavigate} from "react-router";

import Page1 from "./Page1/Page1";
import Page2 from "./Page2/Page2";
import Page3 from "./Page3/Page3";
import Page4 from "./Page4/Page4";
import Page5 from "./Page5/Page5";
import Page6 from "./Page6/Page6";
import Page7 from "./Page7/Page7";
import Footer from "../Footer/Footer";

import qrBackground from "../../../../Assets/qr1.svg";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

function Home(props) {
	const navigate = useNavigate();
	const scrollToPage3 = () => {
		document
			.getElementById("page3")
			.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
	};

	const scrollToSupport = () => {
		document
			.getElementById("footer")
			.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
	};

	const [dialog, setDialog] = React.useState(false);

	React.useEffect(() => {
		if (localStorage.getItem("token")) {
			setDialog(true);
		}
	}, []);

	window.scrollTo({
		top: 0,
		left: 0,
	});

	return (
		<>
			<img
				style={{
					position: "absolute",
					objectFit: "cover",
					height: "64rem",
					width: "100%",
				}}
				alt=""
				src={qrBackground}
			/>
			<Container style={{maxWidth: "100%", padding: "0px"}}>
				{/* ---------------------------------------------------------Page 1------------------------------------------------------------------ */}

				<Page1
					scrollToPage3={scrollToPage3}
					scrollToSupport={scrollToSupport}
				/>

				{/* ---------------------------------------------------------Page 2------------------------------------------------------------------ */}

				<Page2 />

				{/* ---------------------------------------------------------Page 3------------------------------------------------------------------ */}

				<Page3 />

				{/* ---------------------------------------------------------Page 4------------------------------------------------------------------ */}

				<Page4 />

				{/* ---------------------------------------------------------Page 5------------------------------------------------------------------ */}

				<Page5 />

				{/* ---------------------------------------------------------Page 6------------------------------------------------------------------ */}

				<Page6 />

				{/* ---------------------------------------------------------Page 7------------------------------------------------------------------ */}

				<Page7 />

				{/* ---------------------------------------------------------Page 8------------------------------------------------------------------ */}

				<Footer />

				{/* ---------------------------------------------------------Pages end------------------------------------------------------------------ */}
				<Dialog
					open={dialog}
					TransitionComponent={Transition}
					keepMounted
					onClose={() => {
						setDialog(false);
					}}
					aria-describedby="alert-dialog-slide-description"
				>
					<DialogTitle>Login</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-slide-description">
							You already have a login.
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							onClick={() => {
								setDialog(false);
							}}
							variant="contained"
							style={{color: "#FFF", background: "#c72a2a"}}
						>
							No
						</Button>
						<Button
							onClick={() => {
								navigate("/qr");
							}}
							variant="contained"
							style={{color: "#FFF", background: "#1d8f48"}}
						>
							Take me in Dashboard
						</Button>
					</DialogActions>
				</Dialog>
			</Container>
		</>
	);
}

export default Home;

// {
/* <div
        style={{
          position: "absolute",
          height: "64rem",
          width: "100%",
          top: "64rem",
          backgroundColor: "#FFFFFF",
        }}
      ></div>
      <div
        style={{
          position: "absolute",
          height: "64rem",
          width: "100%",
          top: "128rem",
          backgroundColor: "#F9F9F9",
        }}
      ></div>
      <div
        style={{
          position: "absolute",
          height: "64rem",
          width: "100%",
          top: "192rem",
          backgroundColor: "#FFFFFF",
        }}
      ></div>
      <div
        style={{
          position: "absolute",
          height: "64rem",
          width: "100%",
          top: "256rem",
          backgroundColor: "#F9F9F9",
        }}
      ></div>
      <div
        style={{
          position: "absolute",
          height: "64rem",
          width: "100%",
          top: "320rem",
          backgroundColor: "#FFFFFF",
        }}
      ></div>
      <div
        style={{
          position: "absolute",
          height: "64rem",
          width: "100%",
          top: "384rem",
          backgroundColor: "#F9F9F9",
        }}
      ></div>
      <div
        style={{
          position: "absolute",
          height: "940px",
          width: "100%",
          top: "445rem",
          backgroundColor: "#FF800C",
        }}
      ></div> */
// }
