import React from "react";
import {useNavigate} from "react-router";
import {Box, Typography, Button} from "@material-ui/core";
import AuthContext from "../../Context/AuthContext";

function SubscriptionHeader(props) {
	const navigate = useNavigate();
	const {license, qrCount} = React.useContext(AuthContext);
	if (license && license.subscriptionPlan) {
		let expiredOn = new Date(license.expiredOn);
		let today = new Date();

		const daysFun = (date_1, date_2) => {
			let difference = date_1.getTime() - date_2.getTime();
			let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
			return TotalDays;
		};

		const days = Math.abs(daysFun(expiredOn, today));

		const planName = license.planDetail.title.toUpperCase();

		let licenseName;

		if (planName === "FREE") {
			licenseName = "free trial";
		} else if (planName === "LITE") {
			licenseName = "lite plan";
		} else if (planName === "STANDARD") {
			licenseName = "standard plan";
		} else if (planName === "PRO") {
			licenseName = "pro plan";
		}

		if (
			planName !== "ULTRA LITE" &&
			(planName === "FREE" || parseInt(days) < 16)
		) {
			return (
				<Box
					sx={{
						height: "50px",
						width: "100%",
						background: "#F7D3B1",
						display: "flex",
						pl: 4,
						alignItems: "center",
					}}
				>
					<Typography sx={{color: "#444", fontWeight: 600}}>
						{expiredOn > today
							? `Your ${licenseName} license expires in ${days} days.`
							: `Your free trial license expired ${days} days ago.`}{" "}
						{`Start a subscription to keep your existing ${qrCount} Dynamic QR Codes active.`}
					</Typography>
					<Button sx={{ml: 5}} onClick={() => navigate("/qr/subscription")}>
						Start Subscription
					</Button>
				</Box>
			);
		} else return null;
	} else return null;
}

export default SubscriptionHeader;
