import {urls} from "../../../../../Environment/ApiUrl";
import {executeGet} from "../../../../../Services/iwant-rest-generic";
import {executePost} from "../../../../../Services/PUT_OR_POST";

export const getUser = (id) => {
	let url = urls.USER.GET_USER + "/" + id;
	let method = "GET";
	let payload = {};
	let actionCode = "ACTION_FIND_USERPROFILE";
	return executeGet(url, actionCode, payload, method);
};

export const updateProfile = (requestLoad) => {
	let url = urls.PROFILE.PROFILE_UPDATE;
	let method = "POST";
	let payload = requestLoad;
	let actionCode = "ACTION_UPDATE_PROFILE";
	return executePost(url, actionCode, payload, method);
};
