import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  Grid,
  TextField,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core-v4";
import FontPicker from "font-picker-react";

import TextColorComp from "./ColorComponents/TextColor";
import QrContext from "../../../QrContext/QrContext";

const useStyles = makeStyles((theme) => {
  return {
    box: {
      "& .MuiPaper-root": {
        boxShadow: "none !important",
      },
      "& .MuiPaper-root > .MuiButtonBase-root": {
        border: (props) => (props ? "2px solid #ff3333" : "1px solid #000"),
        borderRadius: "2px",
        background: "#F0F0F0",
        height: "50px !important",
      },
      "& .MuiPaper-root > .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded":
        {
          minHeight: "50px !important",
        },
      "& .MuiPaper-root > .MuiCollapse-root": {
        border: "1px solid #D8D8D8",
        margin: "10px 0",
        borderRadius: "2px",
      },
    },
    center: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    card: {
      width: "100%",
      "& div > div": {
        height: "40px",
        borderRadius: "0 2px 2px 0",
      },
      "& .MuiFormControl-root > .MuiOutlinedInput-root > input ": {
        marginTop: "-3px",
      },
      "& .MuiFormControl-root > .MuiOutlinedInput-root > .MuiInputAdornment-root > .MuiButtonBase-root ":
        {
          marginTop: "-7px",
        },
    },
  };
});

function CoupleNameComp(props) {
  const { data, dataIndex } = props;

  const { textColor, firstName, secondName, required } = data;

  const classes = useStyles(required === "indicate" ? true : false);

  const { setContent } = React.useContext(QrContext);

  if (props.data) {
    const handleChange = (key, value) => {
      setContent((prev) =>
        prev.map((ele, index) => {
          if (index === dataIndex) {
            return {
              ...ele,
              [key]: value,
            };
          } else return ele;
        })
      );
    };

    return (
      <div className={classes.box}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
              }}
            >
              <Typography
                style={{
                  color: "#615757",
                  fontSize: "20px",
                  fontWeight: "600",
                }}
              >
                Couple Name *
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails style={{ display: "flex", alignItems: "center" }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="h5">Enter First Name*</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Box className={classes.card}>
                      <TextField
                        type="text"
                        color="primary"
                        name="firstName"
                        onChange={(e) => {
                          handleChange(e.target.name, e.target.value);
                        }}
                        fullWidth
                        value={firstName}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <Typography variant="h5">Weds</Typography>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="h5">Enter Second Name*</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Box className={classes.card}>
                      <TextField
                        type="text"
                        color="primary"
                        name="secondName"
                        onChange={(e) => {
                          handleChange(e.target.name, e.target.value);
                        }}
                        fullWidth
                        value={secondName}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="h5">{"Select Font Family"}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <FontPicker
                      apiKey="AIzaSyBqW8iaz-_qlaTMc1ynbj9f7mpfmbVUcW4"
                      activeFontFamily={"Bebas Neue"}
                      onChange={(nextFont) =>
                        handleChange("fontFamily", nextFont.family)
                      }
                      style={{ width: "100% !important" }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {/* <Grid item xs={12}>
                                <BgColorComp bgColor={bgColor} handleChange={(key, value) => handleChange(key, value)} name='Background Color' />
                            </Grid> */}
              <Grid item xs={12}>
                <TextColorComp
                  textColor={textColor}
                  handleChange={(key, value) => handleChange(key, value)}
                  name="Text Color"
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  } else {
    return null;
  }
}
export default CoupleNameComp;
