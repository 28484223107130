import React from "react";
import {Box, Grid, Typography, Button} from "@material-ui/core";

import pattern1 from "../../../../../../Assets/CustomQr/Design/patterns/eyes/1.svg";
import pattern2 from "../../../../../../Assets/CustomQr/Design/patterns/eyes/2.svg";
// import pattern3 from "../../../../../../Assets/CustomQr/Design/patterns/eyes/3.svg";
import pattern4 from "../../../../../../Assets/CustomQr/Design/patterns/eyes/4.svg";
import pattern5 from "../../../../../../Assets/CustomQr/Design/patterns/eyes/5.svg";
// import pattern6 from "../../../../../../Assets/CustomQr/Design/patterns/eyes/6.svg";
// import pattern7 from "../../../../../../Assets/CustomQr/Design/patterns/eyes/7.svg";
// import pattern8 from "../../../../../../Assets/CustomQr/Design/patterns/eyes/8.svg";
// import pattern9 from "../../../../../../Assets/CustomQr/Design/patterns/eyes/9.svg";
// import pattern10 from "../../../../../../Assets/CustomQr/Design/patterns/eyes/10.svg";
// import pattern11 from "../../../../../../Assets/CustomQr/Design/patterns/eyes/11.svg";
// import pattern12 from "../../../../../../Assets/CustomQr/Design/patterns/eyes/12.svg";
// import pattern13 from "../../../../../../Assets/CustomQr/Design/patterns/eyes/13.svg";
// import pattern14 from "../../../../../../Assets/CustomQr/Design/patterns/eyes/14.svg";
// import pattern15 from "../../../../../../Assets/CustomQr/Design/patterns/eyes/15.svg";
// import pattern16 from "../../../../../../Assets/CustomQr/Design/patterns/eyes/16.svg";
// import pattern17 from "../../../../../../Assets/CustomQr/Design/patterns/eyes/17.svg";
// import pattern18 from "../../../../../../Assets/CustomQr/Design/patterns/eyes/18.svg";
// import pattern19 from "../../../../../../Assets/CustomQr/Design/patterns/eyes/19.svg";
// import pattern20 from "../../../../../../Assets/CustomQr/Design/patterns/eyes/20.svg";
// import pattern21 from "../../../../../../Assets/CustomQr/Design/patterns/eyes/21.svg";
// import pattern22 from "../../../../../../Assets/CustomQr/Design/patterns/eyes/22.svg";
// import pattern23 from "../../../../../../Assets/CustomQr/Design/patterns/eyes/23.svg";
// import pattern24 from "../../../../../../Assets/CustomQr/Design/patterns/eyes/24.svg";

const patternsArray = [
	{pattern: pattern1},
	{pattern: pattern2},
	// {pattern: pattern3},
	{pattern: pattern4},
	{pattern: pattern5},
	// {pattern: pattern6},
	// {pattern: pattern7},
	// {pattern: pattern8},
	// {pattern: pattern9},
	// {pattern: pattern10},
	// {pattern: pattern11},
	// {pattern: pattern12},
	// {pattern: pattern13},
	// {pattern: pattern14},
	// {pattern: pattern15},
	// {pattern: pattern16},
	// {pattern: pattern17},
	// {pattern: pattern18},
	// {pattern: pattern19},
	// {pattern: pattern20},
	// {pattern: pattern21},
	// {pattern: pattern22},
	// {pattern: pattern23},
	// {pattern: pattern24},
];

function Eyes(props) {
	const {handleData, apiCall, data} = props;

	const [state, setState] = React.useState({
		finderColour: "#000000",
		finderBallColour: "#000000",
		pattern: 1,
	});

	React.useEffect(() => {
		setState((prev) => ({
			...prev,
			finderColour:
				data.filter((ele) => ele.index === 2)?.length > 0
					? data.filter((ele) => ele.index === 2)?.[0]?.finderColour
					: "#000000",
			finderBallColour:
				data.filter((ele) => ele.index === 2)?.length > 0
					? data.filter((ele) => ele.index === 2)?.[0]?.finderBallColour
					: "#000000",
			pattern:
				data.filter((ele) => ele.index === 2)?.length > 0
					? data.filter((ele) => ele.index === 2)?.[0]?.pattern
					: 1,
		}));
	}, [data]);

	return (
		<Box
			style={{
				marginTop: "10px",
				border: "1px solid #E0E0E0",
				padding: "20px",
				borderRadius: "8px",
			}}
		>
			<Grid container spacing={2}>
				<Grid item={12}>
					<Typography variant="h5" style={{color: "#666"}}>
						Choose Outer Eyes Color
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Grid container spacing={3}>
						<Grid item md={4} lg={4} xl={3}>
							<Box
								style={{
									height: "40px",
									background: "#E0E0E0",
									border: "1px solid #999",
									borderRadius: "2px",
									display: "flex",
								}}
							>
								<Box
									style={{
										height: "40px",
										width: "40px",
										borderRight: "1px solid #999",
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									#
								</Box>
								<Typography
									style={{
										height: "40px",
										width: "100%",
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									{state &&
										state.finderColour &&
										state.finderColour.replace("#", "").toUpperCase()}
								</Typography>
							</Box>
						</Grid>
						<Grid item md={4} lg={4} xl={3}>
							<input
								style={{
									width: "100%",
									height: "40px",
								}}
								type="color"
								onChange={(e) => {
									setState((prev) => ({
										...prev,
										finderColour: e.target.value,
									}));
								}}
								value={state.finderColour}
							/>
						</Grid>
						<Grid item md={4} lg={4} xl={3}>
							<Button
								variant="outlined"
								onClick={() => {
									handleData(
										{
											...state,
											finderColour: state.finderColour,
										},
										2
									);
									apiCall({
										finderColour: state.finderColour,
									});
								}}
								style={{marginTop: "2px"}}
							>
								Apply Color
							</Button>
						</Grid>
					</Grid>
				</Grid>
				<Grid item={12}>
					<Typography variant="h5" style={{color: "#666"}}>
						Choose Inner Eyes Color
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Grid container spacing={3}>
						<Grid item md={4} lg={4} xl={3}>
							<Box
								style={{
									height: "40px",
									width: "auto",
									background: "#E0E0E0",
									border: "1px solid #999",
									borderRadius: "2px",
									display: "flex",
								}}
							>
								<Box
									style={{
										height: "40px",
										width: "40px",
										borderRight: "1px solid #999",
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									#
								</Box>
								<Typography
									style={{
										height: "40px",
										width: "100%",
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									{state &&
										state.finderBallColour &&
										state.finderBallColour.replace("#", "").toUpperCase()}
								</Typography>
							</Box>
						</Grid>
						<Grid item md={4} lg={4} xl={3}>
							<input
								style={{
									width: "100%",
									height: "40px",
								}}
								type="color"
								onChange={(e) => {
									setState((prev) => ({
										...prev,
										finderBallColour: e.target.value,
									}));
								}}
								value={state.finderBallColour}
							/>
						</Grid>
						<Grid item md={4} lg={4} xl={3}>
							<Button
								variant="outlined"
								onClick={() => {
									handleData(
										{
											...state,
											finderBallColour: state.finderBallColour,
										},
										2
									);
									apiCall({
										finderBallColour: state.finderBallColour,
									});
								}}
								style={{marginTop: "2px"}}
							>
								Apply Color
							</Button>
						</Grid>
					</Grid>
				</Grid>
				<Grid item={12} style={{marginTop: "10px"}}>
					<Typography variant="h5" style={{color: "#666"}}>
						Choose Pattern
					</Typography>
				</Grid>
				<Grid item={12}>
					<Grid container spacing={2}>
						{patternsArray.map((ele, index) => (
							<Grid item xs={3} key={index}>
								<Button
									style={{
										height: "auto",
										width: "100%",
										padding: "3px",
										boxShadow:
											state.pattern === index
												? "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px"
												: "",
									}}
									onClick={() => {
										setState((prev) => ({
											...prev,
											topLeftFinderShape:
												index === 0 ? 0 : index === 1 ? 1 : index - 1,
											topRightFinderShape:
												index === 0 ? 0 : index === 1 ? 1 : index - 1,
											bottomLeftFinderShape:
												index === 0 ? 0 : index === 1 ? 1 : index - 1,
											finderBallShape: index < 2 ? 0 : 1,
											pattern: index,
										}));
										handleData(
											{
												...state,
												topLeftFinderShape:
													index === 0 ? 0 : index === 1 ? 1 : index - 1,
												topRightFinderShape:
													index === 0 ? 0 : index === 1 ? 1 : index - 1,
												bottomLeftFinderShape:
													index === 0 ? 0 : index === 1 ? 1 : index - 1,
												finderBallShape: index < 2 ? 0 : 1,
												pattern: index,
											},
											2
										);
										apiCall({
											topLeftFinderShape:
												index === 0 ? 0 : index === 1 ? 1 : index - 1,
											topRightFinderShape:
												index === 0 ? 0 : index === 1 ? 1 : index - 1,
											bottomLeftFinderShape:
												index === 0 ? 0 : index === 1 ? 1 : index - 1,
											finderBallShape: index < 2 ? 0 : 1,
										});
									}}
									disableRipple
								>
									<img
										alt=""
										src={ele.pattern}
										style={{
											height: "100%",
											width: "100%",
											objectFit: "contain",
											fillColor: "red",
										}}
									/>
								</Button>
							</Grid>
						))}
					</Grid>
				</Grid>
			</Grid>
		</Box>
	);
}

export default Eyes;
