import React from "react";

import {Container} from "@material-ui/core";

// ------------------- import Accordian And Fields Components ------------------- //
import QrContent from "../../CreateQR/Components/QrContent";
import AdditionalFieldsArray from "../../CreateQR/Components/ContentComponents/AdditionalFieldsArray";

import AuthContext from "../../../../../Context/AuthContext";
import QrContextWrapper from "../../CreateQR/QrContext/QrContextWrapper";
import PageTitle from "../../../../UI/PageTitle";
import {useLocation, useParams} from "react-router";

function EditQrContent(props) {
	const params = useParams();
	const {setAdditionalContent, setCategory} = React.useContext(AuthContext);

	const location = useLocation();

	const uiData = location
		? location.state
			? location.state.uiData
				? location.state.uiData
				: ""
			: ""
		: "";
	const qrType = location
		? location.state
			? location.state.qrType
				? location.state.qrType
				: ""
			: ""
		: "";
	const title = location
		? location.state
			? location.state.title
				? location.state.title
				: ""
			: ""
		: "";

	const qrId = params ? (params.qrId ? params.qrId : "") : "";

	React.useEffect(() => {
		if (uiData && qrType) {
			setCategory(qrType);
			setAdditionalContent(AdditionalFieldsArray(qrType.title));
		}
	}, [setCategory, qrType, setAdditionalContent, uiData]);

	// ---------------------- Scroll Tostick Mobile ----------------------- //

	window.onscroll = function () {
		scrollFunction();
	};

	function scrollFunction() {
		if (
			document.body.scrollTop > 220 ||
			document.documentElement.scrollTop > 220
		) {
			try {
				document.getElementById("navbar").style.position = "fixed";
				document.getElementById("navbar").style.top = "0";
			} catch {
				return null;
			}
		} else {
			try {
				document.getElementById("navbar").style.position = "relative";
			} catch {
				return null;
			}
		}
	}

	return (
		<QrContextWrapper>
			<Container maxWidth="xl" style={{marginTop: "30px"}}>
				<PageTitle
					title={`My QR Code > ${title || "Qr Code"}`}
					subTitle="View, edit, design, and download QR Code"
				/>
				<QrContent uiData={uiData} editQrContent={true} updateQrId={qrId} />
			</Container>
		</QrContextWrapper>
	);
}

export default EditQrContent;
