import React from "react";
import {Box, Typography, TextField, InputAdornment} from "@material-ui/core";
import {makeStyles} from "@material-ui/core-v4";

import {IoLogoGooglePlaystore, SiAppstore} from "react-icons/all";
import QrContext from "../../../QrContext/QrContext";

const useStyles = makeStyles((theme) => ({
	box: {
		"& .MuiPaper-root": {
			boxShadow: "none !important",
		},
		"& .MuiPaper-root > .MuiButtonBase-root": {
			border: "1px solid #000",
			borderRadius: "2px",
			background: "#F0F0F0",
			height: "50px !important",
		},
		"& .MuiPaper-root > .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded":
			{
				minHeight: "50px !important",
			},
		"& .MuiPaper-root > .MuiCollapse-root": {
			border: "1px solid #D8D8D8",
			margin: "10px 0",
			borderRadius: "2px",
		},
	},
	center: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	card: {
		width: "80%",
		"& div > div": {
			height: "40px",
			borderRadius: "0 2px 2px 0",
		},
		"& .MuiFormControl-root > .MuiOutlinedInput-root > input ": {
			marginTop: "-3px",
			borderLeft: "1px solid #999",
			padding: "5px 0 5px 10px",
		},
		"& .MuiFormControl-root > .MuiOutlinedInput-root > .MuiInputAdornment-root > .MuiButtonBase-root ":
			{
				marginTop: "-7px",
			},
	},
}));

function AppStoreComp(props) {
	const classes = useStyles();

	const {setContent} = React.useContext(QrContext);
	const {data, dataIndex} = props;

	const {label, playStoreUrl, appStoreUrl} = data;

	const handleChange = (key, value) => {
		setContent((prev) =>
			prev.map((ele, index) => {
				if (index === dataIndex) {
					return {
						...ele,
						[key]: value,
					};
				} else return ele;
			})
		);
	};
	return (
		<Box className={classes.box}>
			<Typography variant="h4" style={{color: "#666"}}>
				{label}
			</Typography>
			<Box className={classes.card} style={{marginTop: "20px"}}>
				<TextField
					type="text"
					color="primary"
					name="playStoreUrl"
					onChange={(e) => {
						handleChange(e.target.name, e.target.value);
					}}
					fullWidth
					value={playStoreUrl}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<IoLogoGooglePlaystore style={{fontSize: "28px"}} />
							</InputAdornment>
						),
					}}
				/>
			</Box>
			<Box className={classes.card} style={{margin: "20px 0"}}>
				<TextField
					type="text"
					color="primary"
					name="appStoreUrl"
					onChange={(e) => {
						handleChange(e.target.name, e.target.value);
					}}
					fullWidth
					value={appStoreUrl}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<SiAppstore style={{fontSize: "28px"}} />
							</InputAdornment>
						),
					}}
				/>
			</Box>
			<Typography
				variant="h6"
				style={{color: "#666", display: "flex", flexDirection: "column"}}
			>
				<span>
					User will automatically be redirected to the correct app download
					links based on the operating system (OS) of the scanning device. If
					device OS is not detected, user will be shown all available download
					links.
				</span>
			</Typography>
		</Box>
	);
}

export default AppStoreComp;
