import {urls} from "../../../../../Environment/ApiUrl";
import {executeGet} from "../../../../../Services/iwant-rest-generic";
import {executePost} from "../../../../../Services/iwant-rest-http-post";

export const getLicense = () => {
	let url = urls.LICENSE.GETLICENSE;
	let actionCode = "";
	let payload = {};
	let method = "GET";
	return executeGet(url, actionCode, payload, method);
};

export const subscribePlan = (requestLoad) => {
	let url = urls.LICENSE.SUBSCRIBE;
	let actionCode = "";
	let payload = requestLoad;
	let method = "POST";
	return executePost(url, actionCode, payload, method);
};

export const getAllPlans = () => {
	let url = urls.PLANS.GET_ALL;
	let actionCode = "";
	let payload = {};
	let method = "GET";
	return executeGet(url, actionCode, payload, method);
};

export const getPlanById = (requestId) => {
	let url = urls.PLANS.GET_BY_ID + requestId;
	let actionCode = "";
	let payload = {};
	let method = "GET";
	return executeGet(url, actionCode, payload, method);
};

export const getComparisonData = () => {
	let url = urls.PLANS.GET_COM_DATA;
	let actionCode = "";
	let payload = {};
	let method = "GET";
	return executeGet(url, actionCode, payload, method);
};
