import { urls } from '../../../../../Environment/ApiUrl';
// import { executePost } from '../../../../../Services/iwant-rest-http-post';
import { executeGet } from "../../../../../Services/iwant-rest-generic";

export const findAllQrType = () => {
    let url = urls.QRTYPE.FINDALL;
    let actionCode = "";
    let payload = {};
    let method = 'GET';
    return executeGet(url, actionCode, payload, method);
};

export const findByIdQrType = (requestLoad) => {
    let url = urls.QRTYPE.FINDBYID + requestLoad;
    let actionCode = "";
    let payload = {};
    let method = 'GET';
    return executeGet(url, actionCode, payload, method);
};