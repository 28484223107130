import {
	MdDashboard,
	ImQrcode,
	AiFillSetting,
	IoMdAnalytics,
	BsPeopleFill,
	BsTrashFill,
	BiUser,
	MdSubscriptions,
	FiLogOut,
} from "react-icons/all";

export const topItems = [
	{
		id: 1,
		title: "My QR Codes",
		icon: MdDashboard,
		link: "/qr",
		tag: ["view", "edit", "customize", undefined],
		active: "My QR Codes",
	},
	{
		id: 2,
		title: "Create QR Code",
		icon: ImQrcode,
		link: "/qr/create",
		tag: ["create"],
		active: "Create QR Code",
	},
	{
		id: 3,
		title: "Analytics",
		icon: IoMdAnalytics,
		link: "/qr/analytics",
		tag: ["analytics"],
		active: "Analytics",
	},
	{
		id: 3,
		title: "Leads",
		icon: BsPeopleFill,
		link: "/qr/leads",
		tag: ["leads"],
		active: "Leads",
	},
	{
		id: 4,
		title: "Settings",
		icon: AiFillSetting,
		link: "/qr/settings",
		tag: ["settings"],
		active: "Settings",
	},
	{
		id: 5,
		title: "Trash",
		icon: BsTrashFill,
		link: "/qr/trash",
		tag: ["trash"],
		active: "Trash",
	},
];

export const bottomItems = [
	{
		id: 6,
		title: "Subscription",
		icon: MdSubscriptions,
		link: "/qr/subscription",
		tag: ["subscription"],
		active: "Subscription",
	},
	{
		id: 7,
		title: "Account",
		icon: BiUser,
		link: "/qr/account",
		tag: ["account"],
		active: "Account",
	},
	{
		id: 8,
		title: "Logout",
		icon: FiLogOut,
	},
];
