import React from "react";
import {Box, Typography} from "@material-ui/core";

import LocationComp from "./Components/LocationComp";
import QrContext from "../../../QrContext/QrContext";

function GoogleMap(props) {
	const {setContent} = React.useContext(QrContext);

	const {dataIndex, data} = props;
	const {label} = data;

	const handleChange = (key, value) => {
		setContent((prev) =>
			prev.map((ele, index) => {
				if (index === dataIndex) {
					return {
						...ele,
						[key]: value,
					};
				} else return ele;
			})
		);
	};
	return (
		<Box>
			<Typography variant="h4" style={{marginBottom: "10px", color: "#666"}}>
				{label}
			</Typography>
			<Box
				style={{
					position: "relative",
					height: "450px",
					border: "1px solid #999",
				}}
			>
				<LocationComp
					height="350px"
					handleChange={(key, value) => handleChange(key, value)}
				/>
			</Box>
		</Box>
	);
}

export default GoogleMap;
