import * as React from "react";
import {Box, Grid} from "@material-ui/core";

import classes from "./Page1.module.css";

import HOTQr from "../../../../../Assets/HOTQr.png";

import {useNavigate} from "react-router";

function Page1(props) {
	const navigate = useNavigate();
	return (
		<Box className={classes.box}>
			<Box onClick={() => navigate("/")} className={classes.boxLogo}>
				<img className={classes.HOTQr} alt="logo" src={HOTQr} />
			</Box>
			<Grid container>
				<Grid item xs={12} className="d-flex justify-content-center">
					<Box className={classes.boxHead}>
						<label className={classes.boxHeadTitle}>
							{" "}
							HOT QR Code API for Static QR Codes{" "}
						</label>
						<label className={classes.boxHeadText}>
							{" "}
							Generate custom-designed Static QR Codes programmatically in your
							information system or mobile application{" "}
						</label>
						<Box className={classes.boxHeadButton}>
							<span
								className={classes.boxHeadButtonText}
								onClick={() => navigate("/qr")}
							>
								{" "}
								Get started with HOT QR API{" "}
							</span>
						</Box>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
}

export default Page1;
