import React from "react";
import {Modal, Fade, Typography, Box, Button} from "@material-ui/core";
import {makeStyles} from "@material-ui/core-v4";
import {DateRangePicker} from "react-date-range";

const useStyles = makeStyles((theme) => ({
	modal: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	paper: {
		backgroundColor: "#FFF",
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2),
	},
}));

export default function DateRangePickerModal(props) {
	const classes = useStyles();
	const {setDateRange, dateRange, modalOpen, setModalOpen} = props;

	console.log(dateRange);

	return (
		<Modal
			className={classes.modal}
			open={modalOpen}
			onClose={() => setModalOpen(false)}
		>
			<Fade in={modalOpen}>
				<div className={classes.paper}>
					<Typography variant="h4">Select Time Period</Typography>
					<Box
						sx={{
							marginTop: "20px",
							"& .rdrDateRangePickerWrapper": {minHeight: "418px"},
						}}
					>
						<DateRangePicker
							onChange={(item) =>
								setDateRange([item.selection.startDate, item.selection.endDate])
							}
							showSelectionPreview={true}
							moveRangeOnFirstSelection={false}
							months={2}
							ranges={[
								{
									startDate: dateRange[0],
									key: "selection",
									endDate: dateRange[1],
								},
							]}
							direction="horizontal"
							preventSnapRefocus={true}
							calendarFocus="backwards"
							rangeColors="#FC800E"
							color="#FC800E"
							weekStartsOn={0}
						/>
					</Box>
					<Box sx={{textAlign: "right", mt: 1}}>
						<Button
							variant="contained"
							color="primary"
							onClick={() => setModalOpen(false)}
						>
							Submit
						</Button>
					</Box>
				</div>
			</Fade>
		</Modal>
	);
}
