import React from "react";

// ----------------- import Components ------------------ //

import {
	Box,
	Grid,
	CircularProgress,
	Card,
	Typography,
	Button,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core-v4";

// ----------------- import iPhoneX Image ------------------ //

import iphoneX from "../../../../../Assets/iphoneX.png";

//-------------------import CSS --------------------- //

import classess from "./content.module.css";

// ------------------- import Accordian And Fields Components ------------------- //

import AccordionComp from "./ContentComponents/Accordion";
import FieldsComp from "./ContentComponents/Fields";
import AuthContext from "../../../../../Context/AuthContext";
import QrContext from "../QrContext/QrContext";
import {updateQrCode} from "../../Services/DashboardServices/ApiUrl";

import ValidationFunction from "./ContentComponents/ValidationFunction";
import {useNavigate} from "react-router";

// -------------------- Custome makeStyle --------------------- //

const useStyles = makeStyles((theme) => ({}));

function QrContent(props) {
	const classes = useStyles();
	const navigate = useNavigate();

	const {
		uiData,
		finishForm,
		btnTitle,
		handleFinish,
		editQrContent,
		updateQrId,
	} = props;

	const {category, additionalContent, setStepModel, setQrData} =
		React.useContext(AuthContext);

	const {content, setContent} = React.useContext(QrContext);

	const [emptyFields, setEmptyFields] = React.useState(false);
	const [btnDisable, setBtnDisable] = React.useState(false);
	// Edit Qr Content Fucntions

	const handleUpdate = () => {
		setBtnDisable(true);
		updateQrCode(
			{
				payload: {
					qrData: {
						uiData: content,
					},
				},
			},
			updateQrId
		)
			.then((res) => {
				setBtnDisable(true);
				navigate(-1);
			})
			.catch((err) => {
				console.error(err);
			});
	};

	React.useEffect(() => {
		if (uiData && uiData.length > 0) {
			setContent(uiData);
		}
	}, [setContent, uiData, editQrContent]);

	// --------------------- Function for handle Next Step -----------------------//

	const nextStep = () => {
		if (finishForm) {
			handleFinish(content);
		} else {
			const validate = ValidationFunction(category.title, content);
			if (validate) {
				setQrData(content);
				setStepModel((prev) => prev.next());
			} else {
				setEmptyFields(true);
				setContent((prev) =>
					[...prev].map((ele) => {
						if (ele.hasOwnProperty("required")) {
							return {
								...ele,
								required: "indicate",
							};
						} else return ele;
					})
				);
			}
		}
	};

	const setNewFields = (obj) => {
		setContent((prev) => [...prev, obj]);
	};

	// ---------------------- Scroll Tostick Mobile ----------------------- //

	window.onscroll = function () {
		scrollFunction();
	};

	// ----------------------- scroll to top function  ----------------------//

	function scrollFunction() {
		if (
			document.body.scrollTop > 220 ||
			document.documentElement.scrollTop > 220
		) {
			try {
				document.getElementById("navbar").style.position = "fixed";
				document.getElementById("navbar").style.top = "0";
			} catch {
				return null;
			}
		} else {
			try {
				document.getElementById("navbar").style.position = "relative";
			} catch {
				return null;
			}
		}
	}
	//------------------------ Main Switch for return different modules ---------------------- //

	if (category && content) {
		return (
			<Box>
				<Grid container>
					<Grid
						item
						xs={
							category.id === "66922395-e975-40fd-aafe-b379278b2d89" ||
							category.id === "ed4603d6-47b1-4284-989a-736d6ed724bf" ||
							category.id === "63e34d1a-ebaf-4a87-bc1f-7e526dd69538"
								? 12
								: 8
						}
					>
						<Card
							elevation={2}
							style={{
								padding: "30px",
								marginBottom: "5px",
								minHeight: "500px",
							}}
						>
							<Typography color="primary" variant="h3">
								<b>{category.title}</b>
							</Typography>

							{additionalContent && additionalContent.length > 0 && (
								<Box
									style={{
										marginTop: "30px",
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
										width: "550px",
									}}
								>
									<Typography color="primary" variant="h4">
										<b>Add : </b>
									</Typography>
									{additionalContent.map((ele, index) => {
										switch (ele) {
											case "Button":
												return (
													<Button
														key={index}
														variant="outlined"
														onClick={() =>
															setNewFields({
																type: "Button",
																label: "Click Here",
																linkType: "url",
																link: "",
																phone: "",
																email: "",
																bgColor: "#FFFFFF",
																textColor: "#000000",
																borderColor: "#999999",
															})
														}
													>
														Button
													</Button>
												);
											case "DescriptionBox":
												return (
													<Button
														key={index}
														variant="outlined"
														onClick={() => {
															setNewFields({
																type: "DescriptionBox",
																title: "Click Here",
																bgColor: "#FFFFFF",
																textColor: "#000000",
																deletable: false,
															});
														}}
													>
														Description Box
													</Button>
												);
											case "MapLocation":
												return (
													<Button
														key={index}
														variant="outlined"
														onClick={() => {
															setNewFields({
																type: "MapLocationButton",
																label: "Location",
																location: "",
																bgColor: "#FFFFFF",
																textColor: "#000000",
																borderColor: "#999999",
															});
														}}
													>
														Map Location
													</Button>
												);
											case "File":
												return (
													<Button
														key={index}
														variant="outlined"
														onClick={() => {
															setNewFields({
																type: "Files",
																max: 20,
																files: [],
																btnText: "Files Button",
																btnBgColor: "#FFFFFF",
																btnTextColor: "#000000",
																btnBorderColor: "#999999",
															});
														}}
													>
														File
													</Button>
												);
											default:
												return null;
										}
									})}
								</Box>
							)}

							<Box style={{marginTop: "30px"}}>
								<AccordionComp />
							</Box>

							<Button
								color="primary"
								variant="contained"
								onClick={editQrContent ? handleUpdate : nextStep}
								style={{marginTop: "20px"}}
								disabled={btnDisable}
								// disabled={
								// 	content && content.length > 0
								// 		? content.filter((ele) =>
								// 				ele.hasOwnProperty("filled") ? !ele.filled : false
								// 		  ).length > 0
								// 		: true
								// }
							>
								{editQrContent ? "Updtae" : finishForm ? btnTitle : "Next"}
							</Button>
							{emptyFields && (
								<Typography
									style={{color: "red", marginTop: "25px"}}
									className="d-flex flex-column"
								>
									<span>Please Fill Require Fields</span>
									<span>* Indicate Required Fileds</span>
								</Typography>
							)}
						</Card>
					</Grid>
					{category.id === "66922395-e975-40fd-aafe-b379278b2d89" ||
					category.id === "ed4603d6-47b1-4284-989a-736d6ed724bf" ||
					category.id === "63e34d1a-ebaf-4a87-bc1f-7e526dd69538" ? null : (
						<Grid
							item
							xs={4}
							style={{
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
							}}
						>
							<Box
								style={{
									display: "flex",
									justifyContent: "center",
									padding: "30px",
								}}
								id="navbar"
								className={classes.mockup}
							>
								<Box
									style={
										content && content.length > 0
											? {
													backgroundColor:
														content.filter(
															(ele) => ele.type === "BackgroundColor"
														).length > 0
															? content.filter(
																	(ele) => ele.type === "BackgroundColor"
															  )[0].bgColor
															: "#FFF",
													borderRadius: "65px",
													border: "-5px solid #FFF",
											  }
											: {}
									}
								>
									<Box
										style={{
											height: "600px",
											width: "300px",
											backgroundSize: "contain",
											backgroundRepeat: "no-repeat",
											backgroundImage: `url('${iphoneX}')`,
											padding: "41px 22px 28px",
										}}
									>
										<Box
											style={{
												display: "flex",
												flexDirection: "column",
												padding: "10px",
												overflowY: "scroll",
												height: "100%",
											}}
											className={classess.boxMobile}
										>
											<FieldsComp />
										</Box>
									</Box>
								</Box>
							</Box>
						</Grid>
					)}
				</Grid>
			</Box>
		);
	} else {
		return (
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					height: "80vh",
					width: "100%",
				}}
			>
				<CircularProgress />
			</div>
		);
	}
}

export default QrContent;

// const CustomScrollUploadContent = styled(Box)(({theme})=>({
//   '&::-webkit-scrollbar':{
//       width: 6,
//   },
//   '&::-webkit-scrollbar-track': {
//       boxShadow: `inset 0 0 5px ${theme.palette.primary.light}`,
//       borderRadius: 16,
//   },
//   '&::-webkit-scrollbar-thumb':{
//       backgroundColor:  theme.palette.primary.main,
//       borderRadius: 16,
//       '&:hover':{
//           backgroundColor:  theme.palette.primary.dark
//       }
//   }
// }))
