import React from "react";
import {Typography, Box} from "@material-ui/core";

import LogoComp from "./Components/LogoComp";

function Logo(props) {
	const {
		data,
		handleData,
		handleDeleteData,
		setLoadQr,
		loadQr,
		appUrl,
		setQrCode,
		apiCall,
	} = props;

	const Comp = () => {
		// switch (logoSelect) {
		// 	case 0:
		return (
			<LogoComp
				setLoadQr={setLoadQr}
				loadQr={loadQr}
				data={data}
				handleData={handleData}
				setQrCode={(data) => setQrCode(data)}
				handleDeleteData={handleDeleteData}
				appUrl={appUrl}
				apiCall={(data) => apiCall(data)}
			/>
		);
		// case 1:
		// 	return (
		// 		<TextComp
		// 			setLoadQr={setLoadQr}
		// 			loadQr={loadQr}
		// 			data={data}
		// 			setQrCode={setQrCode}
		// 			handleData={handleData}
		// 			handleDeleteData={handleDeleteData}
		// 		/>
		// 	);
		// default:
		// 	return null;
		// }
	};

	return (
		<Box style={{marginTop: "20px"}}>
			<Typography variant="h4" style={{color: "#666"}}>
				Logo Settings
			</Typography>
			<Box
				style={{
					marginTop: "20px",
					border: "1px solid #E0E0E0",
					padding: "20px",
					borderRadius: "8px",
				}}
			>
				{/* <div className={classes.selectSwitch}>
					<SwitchSelector
						onChange={(e) => {
							handleDeleteData(1);
							setLogoSelect(e);
						}}
						options={[
							{label: "Add Logo", value: 0},
							{label: "Add Text", value: 1},
						]}       
						initialSelectedIndex={logoSelect}
						forcedSelectedIndex={logoSelect}
						fontColor="#444"
						fontSize="18"
						optionBorderRadius="4"
						selectedBackgroundColor="#FF800C"
					/>
				</div> */}
				<Box style={{marginTop: "10px"}}>{Comp()}</Box>
			</Box>
		</Box>
	);
}

export default Logo;
