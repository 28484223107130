import React from "react";
import { Button } from '@material-ui/core';
import { LocationOn } from '@material-ui/icons';

function ButtonComp(props){

    const { data } = props 
    const { textColor, bgColor, borderColor, label } = data

    return(
        <Button
            style={{
                marginTop: '10px',
                color: textColor,
                background: bgColor,
                border: '1px solid ' + borderColor
            }}
            startIcon={<LocationOn />}
            variant='contained'
            fullWidth
        >
            {label}
        </Button>
    )
}
export default ButtonComp;