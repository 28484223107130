import React from "react";

import {Grid} from "@mui/material";

import LineComponentWrapper from "./ChartComponent/LineComponent/LineComponentWrapper";

export default function AnalyticsArea({analyticsValues, showData}) {
	const initialState = {
		source: {
			graph: {
				option: {},
				series: [],
			},
			table: {
				content: [],
				columns: {},
			},
		},
		leads: {
			graph: {
				option: {},
				series: [],
			},
			table: {
				content: [],
				columns: {},
			},
		},
		analyticsData: [],
	};
	const [analyticsData, setAnalyticsData] = React.useState(initialState);

	React.useEffect(() => {
		setAnalyticsData((prev) => ({
			source: analyticsValues.source ? analyticsValues.source : prev.source,
			leads: analyticsValues.leads ? analyticsValues.leads : prev.leads,
		}));
	}, [analyticsValues]);

	return (
		<Grid container spacing={3} id="canvas">
			<Grid item xs={12}>
				<LineComponentWrapper
					title={"Leads Conversion"}
					showData={showData}
					data={analyticsData.leads}
				/>
			</Grid>
			<Grid item xs={12}>
				<LineComponentWrapper
					title={"Lead by Source"}
					showData={showData}
					data={analyticsData.source}
				/>
			</Grid>
		</Grid>
	);
}
