import React from "react";
import {
	Container,
	Typography,
	Box,
	TextField,
	Button,
	Grid,
	Tooltip,
	Card,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core-v4";

import {BsInfoCircleFill} from "react-icons/all";

const useStyles = makeStyles((theme) => ({
	box: {
		"& .MuiPaper-root": {
			boxShadow: "none !important",
		},
		"& .MuiPaper-root > .MuiButtonBase-root": {
			border: "1px solid #000",
			borderRadius: "2px",
			background: "#F0F0F0",
			height: "50px !important",
		},
		"& .MuiPaper-root > .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded":
			{
				minHeight: "50px !important",
			},
		"& .MuiPaper-root > .MuiCollapse-root": {
			border: "1px solid #D8D8D8",
			margin: "10px 0",
			borderRadius: "2px",
		},
	},
	center: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	card: {
		width: "100%",
		"& div > div": {
			height: "40px",
			borderRadius: "3px",
		},
		"& .MuiFormControl-root > .MuiOutlinedInput-root > input ": {
			marginTop: "-3px",
		},
		"& .MuiFormControl-root > .MuiOutlinedInput-root > .MuiInputAdornment-root > .MuiButtonBase-root ":
			{
				marginTop: "-7px",
			},
	},
	iconInfo: {
		color: "#999",
		"&:hover": {
			color: "#C86409",
		},
	},
	aic: {
		display: "flex",
		alignItems: "center",
	},
}));

function UserInformation({userState, handleSubmit}) {
	const classes = useStyles();

	const [state, setState] = React.useState({
		firstName: userState.firstName,
		lastName: userState.lastName,
	});

	const fieldArray = [
		{
			name: "firstName",
			label: "First Name",
			info: "The user’s ‘name’ will only be used to personalize the communication between the support team and the user.",
		},
		{
			name: "lastName",
			label: "Last Name",
			info: "You can optionally add your ‘title/designation’ here. We will internally use this information to make recommendations on how to better use HotQr and its features.",
		},
	];

	const handleChange = (key, value) => {
		setState((prev) => ({
			...prev,
			[key]: value,
		}));
	};

	return (
		<Container maxWidth="xl" style={{padding: "0"}}>
			<Card
				style={{
					marginBottom: "5px",
					marginTop: "30px",
					width: "50%",
					borderRadius: "8px",
				}}
			>
				<Typography
					style={{
						height: "50px",
						background: "#E0E0E0",
						width: "100%",
						padding: "0 0 0 20px",
						fontSize: "20px",
						color: "#666",
						borderBottom: "1px solid #555",
					}}
					className={classes.aic}
				>
					<b>User Information</b>
				</Typography>
				<Box
					style={{
						padding: "20px 10px 20px 40px",
						background: "#FFF",
						display: "flex",
						alignItems: "center",
					}}
				>
					<Grid container spacing={2} style={{flexDirection: "column"}}>
						{fieldArray.map((ele, index) => (
							<Grid key={index} item xs={12}>
								<Grid container spacing={1}>
									<Grid item xs={12}>
										<Typography style={{color: "#555"}}>
											{ele.label}:
										</Typography>
									</Grid>
									<Grid item xs={11}>
										<Box className={classes.card}>
											<TextField
												type="text"
												color="primary"
												name={ele.name}
												onChange={(e) => {
													handleChange(e.target.name, e.target.value);
												}}
												fullWidth
												value={state[ele.name]}
											/>
										</Box>
									</Grid>
									<Grid item xs={1}>
										<Box
											style={{
												width: "100%",
												display: "flex",
												alignItems: "center",
												height: "100%",
											}}
										>
											<Tooltip
												title={
													<span style={{fontSize: "15px"}}>{ele.info}</span>
												}
												arrow
												placement="right"
											>
												<Box style={{marginTop: "-10px"}}>
													<BsInfoCircleFill
														size={20}
														className={classes.iconInfo}
													/>
												</Box>
											</Tooltip>
										</Box>
									</Grid>
								</Grid>
							</Grid>
						))}
						<Grid item xs={1}>
							<Button
								variant="outlined"
								color="primary"
								style={{
									marginTop: "10px",
								}}
								fullWidth
								onClick={() => handleSubmit(state)}
							>
								Save
							</Button>
						</Grid>
					</Grid>
				</Box>
			</Card>
		</Container>
	);
}

export default UserInformation;
