const questionTypeArray = [
	{label: "Short answer", value: "shortAnswer"},
	{label: "Paragraph", value: "paragraph"},
	{label: "Multiple choice", value: "multipleChoice"},
	{label: "Checkboxes", value: "checkboxes"},
	{label: "Dropdown list", value: "dropdownList"},
	{label: "Linear scale", value: "linearScale"},
	{label: "Date", value: "date"},
	{label: "Time", value: "time"},
	{label: "Email Address", value: "email"},
	{label: "Phone number", value: "phone"},
	{label: "Star rating", value: "starRating"},
	{label: "Emoji", value: "emoji"},
	{label: "Like/Dislike", value: "likeDislike"},
];

export default questionTypeArray;
