function ValidationFunction(props, content) {
	const validation = (name, type) => {
		const newArray = content.filter((ele) => ele.hasOwnProperty("required"));
		const oneArray = newArray.filter((ele) => ele.hasOwnProperty(name));
		const result =
			type === "string"
				? oneArray.some((ele) => ele[name] !== "")
				: type === "array"
				? oneArray.some((ele) => ele[name].length > 0)
				: false;
		return result;
	};

	switch (props) {
		case "Wedding QR code":
			const weddingPageTitle = validation("title", "string");
			const firstName = validation("firstName", "string");
			const lastName = validation("secondName", "string");
			const address = validation("address", "string");
			if (weddingPageTitle && firstName && lastName && address) return true;
			else return false;
		case "Document QR code":
			const documents = validation("documents", "array");
			if (documents) return true;
			else return false;
		case "Image QR code":
			const images = validation("images", "array");
			if (images) return true;
			else return false;
		case "Event QR code":
			const eventQrPageTitle = validation("title", "string");
			const eventQrAddress = validation("address", "string");
			if (eventQrPageTitle && eventQrAddress) return true;
			else return false;
		case "Social media QR code":
			const socialTitle = validation("title", "string");
			const socialLinks = validation("socialLinks", "array");
			if (socialTitle && socialLinks) return true;
			else return false;
		case "Coupon QR code":
			const couponImage = validation("image", "array");
			const couponLabel = validation("label", "string");
			const couponOfferTitle = validation("offerTitle", "string");
			const couponCode = validation("couponCode", "string");
			const couponOfferDetails = validation("offerDetails", "string");
			if (
				couponImage &&
				couponLabel &&
				couponOfferTitle &&
				couponCode &&
				couponOfferDetails
			)
				return true;
			else return false;
		case "Custom page QR code":
			return true;
		case "Google maps QR code":
			const googleMapLocation = validation("location", "string");
			if (googleMapLocation) return true;
			else return false;
		case "PayPal QR code ....":
			const paypalEmail = validation("email", "string");
			const paypalPrice = validation("price", "string");
			if (paypalEmail && paypalPrice) return true;
			else return false;
		case "App store QR code":
			const playStoreUrl = validation("playStoreUrl", "string");
			const appStoreUrl = validation("appStoreUrl", "string");
			if (playStoreUrl && appStoreUrl) return true;
			else return false;
		case "App Deep Link QR code":
			const playAppStoreUrl = validation("playStoreUrl", "string");
			const appStoreAppUrl = validation("appStoreUrl", "string");
			const androidAppUrl = validation("androidAppUrl", "string");
			const iosAppUrl = validation("iosAppUrl", "string");
			const websiteReUrl = validation("websiteUrl", "string");
			if (
				playAppStoreUrl &&
				appStoreAppUrl &&
				androidAppUrl &&
				iosAppUrl &&
				websiteReUrl
			)
				return true;
			else return false;
		case "Product QR code":
			return true;
		case "Audio QR code":
			const audio = validation("audio", "array");
			if (audio) return true;
			else return false;
		case "VCard profile QR code":
			const vCardName = validation("name", "string");
			const vCardTitle = validation("title", "string");
			const vCardPrimaryPhone = validation("primaryPhone", "string");
			const vCardPrimaryEmail = validation("primaryEmail", "string");
			if (vCardName && vCardTitle && vCardPrimaryPhone && vCardPrimaryEmail)
				return true;
			else return false;
		case "Website URL QR code":
			const websiteUrl = validation("url", "string");
			if (websiteUrl) return true;
			else return false;
		default:
			return false;
	}
}

export default ValidationFunction;
