import React from "react";
import Chart from "react-apexcharts";
export default function LineComponent({ data }) {
	return (
		<div style={{ margin: 10 }}>
			<Chart
				options={data.options}
				series={data.series}
				type={data.options.chart.type}
				width="1440"
				height="300px"
			/>
		</div>
	);
}
