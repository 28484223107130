import React from "react";

// ------------------------ import Components --------------------------- //

import {Container, Box, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core-v4";
import SwitchSelector from "react-switch-selector";

import Subscription from "./Components/Subscription";
import Plans from "./Components/Plans";
import OrderHistory from "./Components/OrderHistory";

// ------------------------ Custome makeStyle --------------------------- //

const useStyles = makeStyles((theme) => ({
	iconInfo: {
		color: "#999",
		"&:hover": {
			color: "#C86409",
		},
	},
	center: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	selectSwitch: {
		width: 600,
		height: 38,
		marginTop: "30px",
		"& div": {
			borderRadius: "6px",
		},
	},
}));

function MainSubscription(props) {
	const classes = useStyles();

	const [selected, setSelected] = React.useState(0);

	const RendorComp = () => {
		switch (selected) {
			case 0:
				return (
					<Subscription
						updatePlan={() => {
							setSelected(1);
						}}
					/>
				);
			case 1:
				return (
					<Plans
						goToSubscription={() => {
							setSelected(0);
						}}
					/>
				);
			case 2:
				return (
					<OrderHistory
						goToPlans={() => {
							setSelected(1);
						}}
					/>
				);
			default:
				return null;
		}
	};

	return (
		<>
			<Container maxWidth="xl" style={{margin: "30px auto"}}>
				<Box>
					<Typography variant="h2" style={{color: "#666"}}>
						<b>Subscription</b>
						<br />
					</Typography>
					<Typography variant="h5" style={{color: "#666", marginTop: "5px"}}>
						<>
							View and update subscription plan and payment preferences. View
							order history and download billing documents
						</>
					</Typography>
					<div className={classes.selectSwitch}>
						<SwitchSelector
							onChange={(e) => {
								setSelected(e);
							}}
							options={[
								{label: "SUBSCRIPTION", value: 0},
								{label: "PLANS", value: 1},
								{label: "ORDER HISTORY", value: 2},
							]}
							initialSelectedIndex={selected}
							forcedSelectedIndex={selected}
							// backgroundColor="#E0E0E0"
							fontColor="#000"
							optionBorderRadius="6"
							selectedBackgroundColor="#FF800C"
						/>
					</div>
				</Box>
				<RendorComp />
			</Container>
		</>
	);
}

export default MainSubscription;
