import {services} from "./Environment.dev";

let token = "Basic aXRwbDppd2FudHVubGltaXRlZA==";
// const QRTYPE = 'https://v4fca80l87.execute-api.ap-southeast-1.amazonaws.com/dev/';
// const QRCONTENT = 'https://cq4q0foj1g.execute-api.ap-southeast-1.amazonaws.com/dev/qr-content';
// const QRCODE = 'https://o53vtaiowf.execute-api.ap-southeast-1.amazonaws.com/dev/';
// const QRLICENSE = 'https://ie3hezgzz8.execute-api.ap-southeast-1.amazonaws.com/dev/'

const PATH = "https://api.hotqrcode.com/";
const LOCALPATH = "http://localhost:4100/dev/";
// const AWS_LEAD_PATH =
// 	"https://ccav0ckq9b.execute-api.ap-southeast-1.amazonaws.com/dev/";

export const urls = {
	headers: {
		"Content-Type": "application/json",
		Authorization: localStorage.getItem("token")
			? "Bearer " + localStorage.getItem("token")
			: token,
		"itpl-app-name": "Merchant-Registration-App",
		"itpl-app-version": "1.0-101",
		"itpl-app-type": "web",
		"itpl-client-id": "SS7052021-001",
		"itpl-app-os": "web",
		"Access-Control-Allow-Origin": "*",
	},

	USER: {
		EXITS_USER: services.USER_SYSTEM_API + "/users/exits",
		OTP_SEND_REGISTER: services.USER_SYSTEM_API + "/otp",
		VALIDATE_OTP: services.USER_SYSTEM_API + "/otp/validate",
		REGISTRATION: services.USER__USER_API + "/profile",
		FORGOTPASSWORD: services.USER_SYSTEM_API + "/users/forgotPassword",
		GET_USER: services.USER__USER_API + "/profile/findById",
		ADD_ADDRESS: services.USER__USER_API + "/address",
		ALL_ADDRESS: services.USER__USER_API + "/address/findAll",
		DELETE_ADDRESS: services.USER__USER_API + "/address/delete/",
		LIST_CITY: services.USER_SYSTEM_API + "/system/city/filter",
		LIST_STATE: services.USER_SYSTEM_API + "/system/states/filter",
		LIST_COUNTRY: services.USER_SYSTEM_API + "/system/country/findAll",
	},
	PROFILE: {
		PROFILE_DISPLAY: services.USER__USER_API + "/user/get/",
		PROFILE_UPDATE: services.USER__USER_API + "/profile",
	},

	SVOD_FIND: services.SVOD + "subscriptionpackage/find",

	LOGIN: services.AUTH,

	QRTYPE: {
		CREATE: PATH + "qr-types",
		FINDALL: PATH + "qr-types",
		FINDBYID: PATH + "qr-types/",
		UPDATE: PATH + "qr-types/",
		DELETE: PATH + "qr-types/",
		ACTIVE: PATH + "qr-types/active/",
		DEACTIVE: PATH + "qr-types/deactive/",
	},
	QRCODE: {
		CREATE: PATH + "qr",
		FINDALL: PATH + "qr",
		FINDBYID: PATH + "qr/",
		FILTER: PATH + "qr/filter",
		ANALYTICS: PATH + "qr/analytics",
		UPDATE: PATH + "qr/",
		DELETE: PATH + "qr/",
		ACTIVE: PATH + "qr/active/",
		DEACTIVE: PATH + "qr/deactive/",
		COUNT: PATH + "qr/count",
	},
	LEAD: {
		CREATE: PATH + "lead",
		FINDALL: PATH + "lead",
		FINDBYID: PATH + "lead/",
		FILTER: PATH + "lead/filter",
		UPDATE: PATH + "lead/",
		DELETE: PATH + "lead/",
		ANALYTICS: PATH + "lead/analytics",
	},
	LICENSE: {
		CREATE: PATH + "qr-license",
		GETLICENSE: PATH + "qr-license/get-license",
		SUBSCRIBE: PATH + "qr-license/subscribe",
	},
	PLANS: {
		GET_ALL: PATH + "qr-plans",
		GET_BY_ID: PATH + "qr-plan/",
		GET_COM_DATA: PATH + "qr-plan/comparison",
	},
};
