import React from "react";
import { Box, Button, Typography } from '@material-ui/core';
import renderHTML from 'react-render-html';

function CouponDetailsComp(props){

    const { data } = props 
    const { bgColor, textColor, offerTitle, couponCode, offerDetails } = data

    // if(){
        return(
            <Box
                style={{
                    padding: '15px 15px 15px 15px',
                    background: bgColor,
                    borderRadius: '5px',
                    color: textColor,
                    marginTop: '10px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'justify'
                }}
            >
                <Typography
                    style={{
                        fontSize: '22px',
                        width: '100%',
                        textAlign: 'center',
                        overflowWrap: 'anywhere'
                        
                    }}
                >
                    <span>{renderHTML(offerTitle)}</span>
                </Typography>

                <Typography
                    style={{
                        border: '1px dashed #999',
                        borderRadius: '8px',
                        padding: '10px',
                        width: '100%',
                        textAlign: 'center'
                    }}
                >
                    {couponCode}
                </Typography>

                <Button
                    variant='contained'
                    color='primary'
                    fullWidth
                    style={{ marginTop: '10px' }}
                    onClick={() => navigator.clipboard.writeText(couponCode)}
                >
                    Copy Coupon Code
                </Button>

                <Typography
                    style={{
                        padding: '10px',
                        width: '100%',
                        marginTop: '8px',
                        textAlign: 'center',
                        overflowWrap: 'anywhere'
                    }}
                >
                    {offerDetails}
                </Typography>
                
            </Box>
        )
    // } else {
    //     return null
    // }
}
export default CouponDetailsComp;