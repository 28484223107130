import * as React from "react";
import {Box, Grid, TextField} from "@material-ui/core";
import {experimentalStyled as styled} from "@material-ui/core/styles";

import classes from "./Footer.module.css";

import callIcon from "../../../../Assets/Home/Page8Svg/call.svg";
import atIcon from "../../../../Assets/Home/Page8Svg/at.svg";
import locationIcon from "../../../../Assets/Home/Page8Svg/location.svg";
import linkedInIcon from "../../../../Assets/Home/Page8Svg/link.svg";
import fbIcon from "../../../../Assets/Home/Page8Svg/fb.svg";
import twitterIcon from "../../../../Assets/Home/Page8Svg/twitter.svg";
import mailIcon from "../../../../Assets/Home/Page8Svg/mail.svg";
import {useNavigate} from "react-router";

function Page8(props) {
	const navigate = useNavigate();
	const CssTextField = styled(TextField)({
		"& label.MuiFormLabel-root": {
			color: "#FF800C",
			width: "100%",
			fontFamily: "Raleway",
			fontStyle: "normal",
			fontWeight: "normal",
			fontSize: "20px",
			textAlign: "center",
			lineHeight: "23px",
		},
		"& label.Mui-focused": {
			color: "#FF800C",
			width: "auto",
			backgroundColor: "#FFF",
			textAlign: "left !important",
			left: "15px",
		},

		"& label.MuiFormLabel-filled": {
			textAlign: "left !important",
			left: "15px",
		},
		"& .MuiOutlinedInput-notchedOutline": {
			padding: "0 20px",
		},
		"& .MuiInput-underline:after": {
			borderBottomColor: "#FF800C",
			borderWidth: 6,
		},
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				borderColor: "#FF800C",
				borderWidth: "4px",
				borderRadius: "21px",
			},
			"&:hover fieldset": {
				borderColor: "#FF800C",
				borderWidth: "4px",
				borderRadius: "21px",
			},
			"&.Mui-focused fieldset": {
				borderColor: "#FF800C",
				borderWidth: "4px",
				borderRadius: "21px",
			},
		},
	});
	return (
		<Box className={classes.box} id={"footer"}>
			<Grid container>
				<Grid item xs={6}>
					<Box className={classes.boxInputMain}>
						<label className={classes.boxInputTitle}> Get In Touch </label>
						<Box className="py-3 w-100 text-center">
							<CssTextField
								className={classes.inputName}
								label="Your Name"
								required
							/>
						</Box>
						<Box className="py-3 w-100 text-center">
							<CssTextField
								className={classes.inputEmail}
								label="Email Address"
								required
							/>
						</Box>
						<Box className="py-3 w-100 text-center">
							<CssTextField
								className={classes.inputSubject}
								label="Your Subject"
								required
							/>
						</Box>
						<Box className="py-3 w-100 text-center">
							<CssTextField
								className={classes.inputMessage}
								label="Write Message"
								multiline
								rows={4}
								required
							/>
						</Box>
						<Box className={classes.btnSendMessage}>
							<span className={classes.btnSendMessageText}>Send Message</span>
						</Box>
					</Box>
				</Grid>
				<Grid item xs={6}>
					<Box className={classes.boxContact}>
						<span className={classes.boxContactTextTitle}>
							Connect With Us!
						</span>
						<span className={classes.boxContactText}>
							QR Codes technology is growing every day across the globe in most
							industries, particularly marketingHouse of technology has
							developed a QR generator/engine (HotQR) to assist technology
							innovators have access to modern APIs/SDK for development of
							solutions to different industry needs.
						</span>
						<Box className="d-flex flex-column mt-4">
							<Box className={classes.boxIcon}>
								<img alt="CallIcon" src={callIcon} />
								<span className={classes.iconText}>+254723218571</span>
							</Box>
							<Box className={classes.boxIcon}>
								<img alt="CallIcon" src={atIcon} />
								<span className={classes.iconText}>info@hotgroup.co.ke</span>
							</Box>
							<Box
								className={[classes.boxIcon, "d-flex align-items-center"].join(
									" "
								)}
							>
								<img alt="CallIcon" src={locationIcon} />
								<span className={classes.iconText}>
									Golf park, Racecourse, next to KK security, last block, Ngong
									Road.
								</span>
							</Box>
						</Box>
					</Box>
				</Grid>
				<Grid item container spacing={2} xs={12} style={{marginTop: "100px"}}>
					<Grid xs={4} className="d-flex justify-content-center">
						<Box className="d-flex flex-column">
							<label className={classes.labelFooterTitle}>COMPANY</label>
							<label className={classes.labelFooterText}>Products</label>
							<label className={classes.labelFooterText}>Blog</label>
						</Box>
					</Grid>
					<Grid xs={4} className="d-flex justify-content-center">
						<Box className="d-flex flex-column">
							<label className={classes.labelFooterTitle}>HOT QR</label>
							<Box className={classes.boxLogoContainer}>
								<img
									className={classes.footerSocialIconLinkedIn}
									alt="LinkedInIcon"
									src={linkedInIcon}
								/>
								<img
									className={classes.footerSocialIcon}
									alt="fbIcon"
									src={fbIcon}
								/>
								<img
									className={classes.footerSocialIcon}
									alt="TwitterIcon"
									src={twitterIcon}
								/>
								<img
									className={classes.footerSocialIconMail}
									alt="MailIcon"
									src={mailIcon}
								/>
							</Box>
						</Box>
					</Grid>
					<Grid xs={4} className="d-flex justify-content-center">
						<Box className="d-flex flex-column">
							<label
								className={classes.labelFooterTitle}
								style={{left: "746px"}}
							>
								PRODUCT
							</label>
							<label
								onClick={() => navigate("/hotqrcodegenerator")}
								className={classes.labelFooterText}
								style={{left: "762px"}}
							>
								HOT QR
							</label>
							<label
								onClick={() => navigate("/hotqrbatchgenerator")}
								className={classes.labelFooterText}
								style={{left: "762px", top: "70px"}}
							>
								HOT QR Batch
							</label>
							<label
								onClick={() => navigate("/hotqrapiforstaticqrcodes")}
								className={classes.labelFooterText}
								style={{left: "762px", top: "105px"}}
							>
								HOT QR API
							</label>
							<label
								onClick={() => navigate("/hotqrcodegeneration&designsdk")}
								className={classes.labelFooterText}
								style={{left: "762px", top: "140px"}}
							>
								HOT QR SDK
							</label>
						</Box>
					</Grid>
				</Grid>
				<Grid xs={12} className="d-flex justify-content-center my-5">
					<label className={classes.footerCopyrights}>
						Copyright @ 2020 HOT QR. All rights reserved.
					</label>
				</Grid>
			</Grid>
		</Box>
	);
}

export default Page8;
