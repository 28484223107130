import React from "react";

// ------------------------ import Components --------------------------- //

import {
	Box,
	CircularProgress,
	Typography,
	Card,
	Button,
	TextField,
	IconButton,
	Modal,
	Backdrop,
	Fade,
	InputAdornment,
} from "@material-ui/core";
// ----------------- import icons ------------------ //
import {Close} from "@material-ui/icons";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {urls} from "../../../../../Environment/ApiUrl";
import {validateUser} from "../../../Session/Login/SignupLoginService/Service";
import axios from "axios";
import AuthContext from "../../../../../Context/AuthContext";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	bgcolor: "background.paper",
	boxShadow: 24,
	p: 4,
	width: "500px",
	paddingBottom: "20px",
};

function LoginMethodUpdateModal(props) {
	const {
		updateModalState: {
			open,
			type,
			data: {mobile, email},
		},
		handleSubmit,
		handleClose,
	} = props;

	const domain = "444-555-666";

	const [password, setPassword] = React.useState("");
	const [passVisible, setPassVisible] = React.useState(false);
	const [fieldView, setFieldView] = React.useState("password");
	const [message, setMessage] = React.useState({display: false});
	const [state, setState] = React.useState("");

	const {
		geoInfo: {country_calling_code: mcc},
	} = React.useContext(AuthContext);

	const handleSubmitPassword = () => {
		let formData = new FormData();
		formData.append("domain", "hotqr");
		formData.append("username", type === "Email" ? mobile : email);
		formData.append("password", password);
		formData.append("accountType", 0);
		formData.append("passwordType", "password");
		formData.append("grant_type", "password");
		formData.append("tid", Date.now() + 30000);
		axios
			.post(urls.LOGIN, formData, {
				headers: {
					...urls.headers,
					Authorization: "Basic aXRwbDppd2FudHVubGltaXRlZA==",
				},
			})
			.then((res) => {
				// console.log(res);
				const {access_token} = res && res.data ? res.data : null;
				if (access_token) {
					setFieldView("final");
				} else {
					setFieldView("password");
					setMessage({
						display: true,
						mess: "Invalid Password. Please try again.",
					});
				}
			})
			.catch((err) => {
				setFieldView("password");
				setMessage({
					display: true,
					mess: "Invalid Password. Please try again.",
				});
				console.error(err);
			});
	};

	const handleClickFinal = () => {
		let payload;
		if (type === "Email") {
			payload = {
				email: state,
				domain: domain,
			};
		} else {
			payload = {
				mobile: state,
				domain: domain,
				mcc: mcc,
			};
		}
		validateUser(payload)
			.then((res) => {
				if (!res) {
					if (type === "Email") {
						handleSubmit({email: state});
					} else {
						handleSubmit({mobile: state, mcc: mcc});
					}
				} else {
					setFieldView("final");
					setMessage({
						display: true,
						mess: `${type} already registered with us. Please enter another ${type.toLowerCase()}`,
					});
				}
			})
			.catch((err) => {
				console.error(err);
				setFieldView("final");
				setMessage({
					display: true,
					mess: `${type} already registered with us. Please enter another ${type.toLowerCase()}`,
				});
			});
	};

	return (
		<Modal
			open={open}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={open}>
				<Card style={style}>
					<Box
						style={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							padding: "0 0 0 20px",
							background: "#e8e8e8",
						}}
					>
						<Typography variant="h4" color="primary" style={{}}>
							Update {type}
						</Typography>
						<IconButton onClick={handleClose}>
							<Close
								style={{color: "#444", fontSize: "30px", fontWeight: 800}}
							/>
						</IconButton>
					</Box>
					{fieldView === "load" ? (
						<Box
							sx={{
								height: "150px",
								width: "100%",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<CircularProgress />
						</Box>
					) : (
						<React.Fragment>
							<Box
								sx={{
									margin: "20px",
								}}
							>
								{fieldView === "password" ? (
									<Box>
										<Typography
											sx={{
												color: "#444",
												fontSize: "18px",
												marginBottom: "20px",
											}}
										>
											To continue verify it's you
										</Typography>
										<Typography sx={{mb: 1, color: "#333"}}>
											Enter Your Current Password
										</Typography>
										<TextField
											autoComplete={false}
											id="password"
											fullWidth
											size="small"
											type={passVisible ? "text" : "password"}
											name="password"
											onChange={({target: {value}}) => setPassword(value)}
											value={password}
											sx={{color: "#333"}}
											InputProps={{
												endAdornment: (
													<InputAdornment position="end">
														<IconButton
															style={{padding: "0"}}
															onClick={() => setPassVisible(!passVisible)}
														>
															{passVisible ? <VisibilityOff /> : <Visibility />}
														</IconButton>
													</InputAdornment>
												),
											}}
										/>
										{message.display && (
											<Typography color="error" variant="body2">
												{message.mess}
											</Typography>
										)}
									</Box>
								) : (
									<Box>
										<Typography sx={{mb: 1, color: "#333"}}>
											Enter New {type}
										</Typography>
										<TextField
											id="state"
											fullWidth
											size="small"
											type={type === "Email" ? "email" : "number"}
											name="state"
											onChange={({target: {value}}) => setState(value)}
											value={state}
											sx={{color: "#333"}}
											InputProps={
												type === "Mobile"
													? {
															startAdornment: (
																<InputAdornment position="start">
																	{mcc || "+91"}
																</InputAdornment>
															),
													  }
													: {}
											}
										/>
										{message.display && (
											<Typography color="error" variant="body2">
												{message.mess}
											</Typography>
										)}
									</Box>
								)}
							</Box>
						</React.Fragment>
					)}
					<Box sx={{padding: "0 20px"}}>
						<Button
							variant="outlined"
							onClick={handleClose}
							sx={{marginRight: "20px"}}
						>
							Cancel
						</Button>
						<Button
							variant="contained"
							onClick={() => {
								if (fieldView === "password") {
									setFieldView("load");
									setMessage({display: false});
									handleSubmitPassword();
								} else {
									setFieldView("load");
									handleClickFinal();
								}
							}}
						>
							{fieldView === "password" ? "Next" : "Save"}
						</Button>
					</Box>
				</Card>
			</Fade>
		</Modal>
	);
}

export default LoginMethodUpdateModal;
