import * as React from "react";
import {Container} from "@material-ui/core";

import Page1 from "./Page1/Page1";
import Page2 from "./Page2/Page2";
import Page3 from "./Page3/Page3";
import Page4 from "./Page4/Page4";
// import Page5 from "./Page5/Page5";
import Footer from "../Footer/Footer";

function main() {
	window.scrollTo({
		top: 0,
		left: 0,
	});
	return (
		<Container
			style={{maxWidth: "100%", padding: "0px", backgroundColor: "#FFF"}}
		>
			{/* ---------------------------------------------------------Page 1------------------------------------------------------------------ */}

			<Page1 />

			{/* ---------------------------------------------------------Page 2------------------------------------------------------------------ */}

			<Page2 />

			{/* ---------------------------------------------------------Page 3------------------------------------------------------------------ */}

			<Page3 />

			{/* ---------------------------------------------------------Page 4------------------------------------------------------------------ */}

			<Page4 />

			{/* ---------------------------------------------------------Page 3------------------------------------------------------------------ */}

			{/* <Page5 /> */}

			{/* ---------------------------------------------------------Footer------------------------------------------------------------------ */}

			<Footer />

			{/* ---------------------------------------------------------Pages End------------------------------------------------------------------ */}
		</Container>
	);
}

export default main;
