import React from "react";
import {Box, Typography, Card, Grid} from "@material-ui/core";

function CompanyBrandingComp(props) {
	const {data} = props;

	const {image, name} = data;

	if (image.length > 0 || name.length > 0) {
		return (
			<Card
				style={{
					padding: "15px 5px 15px 10px",
					marginTop: "10px",
					minHeight: "80px",
				}}
			>
				<Grid container>
					<Grid item xs={4} style={{display: "flex", alignItems: "center"}}>
						<img
							src={image.length > 0 && image[0].url}
							alt=""
							style={{width: "100%", height: "auto", objectFit: "contain"}}
						/>
					</Grid>
					<Grid item xs={8}>
						<Box style={{padding: "0 10px"}}>
							<Typography
								variant="h5"
								style={{
									display: "flex",
									alignItems: "center",
									height: "100%",
									flexDirection: "column",
									justifyContent: "center",
								}}
							>
								{name.length > 0 && (
									<b style={{color: "#444", fontSize: "18px"}}>{name}</b>
								)}
							</Typography>
						</Box>
					</Grid>
				</Grid>
			</Card>
		);
	} else {
		return null;
	}
}
export default CompanyBrandingComp;
