import React from "react";

// ------------------------ import Components --------------------------- //

import {Container, Box, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core-v4";
import SwitchSelector from "react-switch-selector";

import Users from "./Components/Users";
import CustomDomain from "./Components/CustomDomain";
import EventTracking from "./Components/EventTracking";

// ------------------------ Custome makeStyle --------------------------- //

const useStyles = makeStyles((theme) => ({
	iconInfo: {
		color: "#999",
		"&:hover": {
			color: "#C86409",
		},
	},
	center: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	selectSwitch: {
		width: 600,
		height: 38,
		marginTop: "30px",
		"& div": {
			borderRadius: "6px",
		},
	},
}));

function MainSubscription(props) {
	const classes = useStyles();

	const [selected, setSelected] = React.useState(0);

	const RendorComp = () => {
		switch (selected) {
			case 0:
				return <Users />;
			case 1:
				return <CustomDomain />;
			case 2:
				return <EventTracking />;
			default:
				return null;
		}
	};

	return (
		<>
			<Container maxWidth="xl" style={{margin: "30px auto"}}>
				<Box>
					<Typography variant="h2" style={{color: "#666"}}>
						<b>Settings</b>
						<br />
					</Typography>
					<Typography variant="h5" style={{color: "#666", marginTop: "5px"}}>
						<>Use this section to configure advanced settings</>
					</Typography>
					<div className={classes.selectSwitch}>
						<SwitchSelector
							onChange={(e) => {
								setSelected(e);
							}}
							options={[
								{label: "USERS", value: 0},
								{label: "CUSTOM DOMAIN", value: 1},
								{label: "EVENT TRACKING", value: 2},
							]}
							initialSelectedIndex={selected}
							forcedSelectedIndex={selected}
							// backgroundColor="#E0E0E0"
							fontColor="#000"
							optionBorderRadius="6"
							selectedBackgroundColor="#FF800C"
						/>
					</div>
				</Box>
				<RendorComp />
			</Container>
		</>
	);
}

export default MainSubscription;
