import React from "react";
import {
	Container,
	Typography,
	Box,
	Grid,
	Button,
	Card,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core-v4";

const useStyles = makeStyles((theme) => ({
	box: {
		"& .MuiPaper-root": {
			boxShadow: "none !important",
		},
		"& .MuiPaper-root > .MuiButtonBase-root": {
			border: "1px solid #000",
			borderRadius: "2px",
			background: "#F0F0F0",
			height: "50px !important",
		},
		"& .MuiPaper-root > .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded":
			{
				minHeight: "50px !important",
			},
		"& .MuiPaper-root > .MuiCollapse-root": {
			border: "1px solid #D8D8D8",
			margin: "10px 0",
			borderRadius: "2px",
		},
	},
	center: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	card: {
		width: "100%",
		"& div > div": {
			height: "40px",
			borderRadius: "3px",
		},
		"& .MuiFormControl-root > .MuiOutlinedInput-root > input ": {
			marginTop: "-3px",
		},
		"& .MuiFormControl-root > .MuiOutlinedInput-root > .MuiInputAdornment-root > .MuiButtonBase-root ":
			{
				marginTop: "-7px",
			},
	},
	iconInfo: {
		color: "#999",
		"&:hover": {
			color: "#C86409",
		},
	},
	aic: {
		display: "flex",
		alignItems: "center",
	},
}));

// const Android12Switch = styled(Switch)(({ theme }) => ({
// 	padding: 8,
// 	"& .MuiSwitch-track": {
// 		borderRadius: 22 / 2,
// 		"&:before, &:after": {
// 			content: '""',
// 			position: "absolute",
// 			top: "50%",
// 			transform: "translateY(-50%)",
// 			width: 16,
// 			height: 16,
// 		},
// 		"&:before": {
// 			backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
// 				"#000"
// 			)}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
// 			left: 12,
// 		},
// 		"&:after": {
// 			backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
// 				"#FFF"
// 			)}" d="M19,13H5V11H19V13Z" /></svg>')`,
// 			right: 12,
// 		},
// 	},
// 	"& .MuiSwitch-thumb": {
// 		boxShadow: "none",
// 		width: 16,
// 		height: 16,
// 		margin: 2,
// 	},
// }));

function Account(props) {
	const classes = useStyles();

	// const [state, setState] = React.useState({});

	// const handleChange = (key, value) => {
	// 	setState((prev) => ({
	// 		...prev,
	// 		[key]: value,
	// 	}));
	// };

	return (
		<Container maxWidth="xl" style={{padding: "0"}}>
			<Card
				style={{
					marginBottom: "5px",
					marginTop: "30px",
					width: "100%",
					borderRadius: "8px",
				}}
			>
				<Typography
					style={{
						height: "50px",
						background: "#E0E0E0",
						width: "100%",
						padding: "0 0 0 20px",
						fontSize: "20px",
						color: "#666",
						borderBottom: "1px solid #555",
					}}
					className={classes.aic}
				>
					<b>Account</b>
				</Typography>
				<Box
					style={{
						padding: "20px 40px",
						background: "#FFF",
						display: "flex",
						alignItems: "center",
						marginTop: "10px",
					}}
				>
					<Grid container spacing={2} style={{flexDirection: "column"}}>
						<Grid item xs={12}>
							<Grid container spacing={3}>
								<Grid item xs={12}>
									<Typography style={{color: "#555", fontSize: "20px"}}>
										<b>
											This will erase your account. QR Codes, and all other data
											associated with this account.
										</b>
									</Typography>
								</Grid>
								<Grid item xs={2}>
									<Button fullWidth variant="outlined">
										Delete Account
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Box>
			</Card>
		</Container>
	);
}

export default Account;
