import React from "react";
import { Box, Typography, Card, Grid } from '@material-ui/core';

function ProfileInfoComp(props){

    const { data } = props

    const { images, name, title, company } = data

    if(images.length > 0 || name.length > 0 || title.length > 0 || company.length > 0){
        return(
            <Card style={{ padding: '15px 5px 15px 10px', marginTop: '10px' }}>
                <Grid container>
                    <Grid item xs={4} style={{ display: 'flex', alignItems :'center' }}>
                        <img src={images.length > 0 && images[0].url} alt='' style={{ width: '100%', height: 'auto', objectFit: 'contain' }} />
                    </Grid>
                    <Grid item xs={8}>
                        <Box style={{ padding: '0 10px' }}>
                            <Typography variant='h5' style={{ marginBottom: '8px', display: 'flex', flexDirection: 'column' }}>
                                {
                                    name.length > 0 &&
                                    <span style={{ color:'#444', fontSize: '15px', borderBottom: '1px solid #999', paddingBottom: '3px' }}>{name}</span>
                                }
                                {
                                    title.length > 0 &&
                                    <span style={{ color:'#555', fontSize: '15px', borderBottom: '1px solid #999', padding: '3px 0' }}>{title}</span>
                                }
                                {
                                    company.length > 0 &&
                                    <span style={{ color:'#666', fontSize: '15px', paddingTop: '3px' }}>{company}</span>
                                }
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Card>
        )
    } else {
        return null
    }
}
export default ProfileInfoComp;