const ContentArray = (props) => {
	switch (props) {
		case "Wedding QR code":
			return [
				{
					type: "BackgroundColor",
					bgColor: "#E0E0E0",
					color: "#000000",
				},
				{
					type: "PageTitle",
					title: "",
					bgColor: "#FFFFFF",
					textColor: "#000000",
					required: true,
				},
				{
					type: "BannerImage",
					max: 3,
					images: [
						{
							name: "couple1.jpeg",
							url: "https://hotqrs3bucket.s3.ap-southeast-1.amazonaws.com/content/image/jpeg/couple1.jpeg_e20a695c-05db-4753-9b6f-63da83f7f197.jpg",
						},
						{
							name: "couple2.jpeg",
							url: "https://hotqrs3bucket.s3.ap-southeast-1.amazonaws.com/content/image/jpeg/couple2.jpeg_ccea1673-2065-41e2-8163-23124186285e.jpg",
						},
						{
							name: "couple3.jpg",
							url: "https://hotqrs3bucket.s3.ap-southeast-1.amazonaws.com/content/image/jpeg/couple3.jpg_685a2482-8ce2-42eb-96aa-e1a270b937e4.jpg",
						},
					],
					bgColor: "transparent",
				},
				{
					type: "CoupleName",
					firstName: "",
					secondName: "",
					fontFamily: "",
					bgColor: "#FFFFFF",
					textColor: "#000000",
					required: true,
				},
				{
					type: "EventSchedule",
					schedule: [
						{
							date: new Date(),
							time: new Date(),
						},
					],
					bgColor: "#FFFFFF",
					textColor: "#000000",
				},
				{
					type: "EventVanue",
					address: "",
					location: false,
					bgColor: "#FFFFFF",
					textColor: "#000000",
					btnBgColor: "#E0E0E0",
					btnTextColor: "#000000",
					btnBorderColor: "#999999",
					required: true,
				},
				{
					type: "DescriptionBox",
					text: "description",
					bgColor: "#FFFFFF",
					textColor: "#000000",
				},
				{
					type: "Button",
					label: "Click Here",
					linkType: "url",
					link: "",
					phone: "",
					email: "",
					bgColor: "#FFFFFF",
					textColor: "#000000",
					borderColor: "#999999",
				},
			];
		case "Document QR code":
			return [
				{
					type: "Document",
					max: 20,
					documents: [],
					label: "Upload single or multiple documents",
					required: true,
				},
			];
		case "Image QR code":
			return [
				{
					type: "Image",
					max: 20,
					images: [],
					label: "Upload single or multiple Images",
					required: true,
				},
			];
		case "Event QR code":
			return [
				{type: "BackgroundColor", bgColor: "#E0E0E0", color: "#000000"},
				{
					type: "PageTitle",
					title: "",
					bgColor: "#FFFFFF",
					textColor: "#000000",
					required: true,
				},
				{type: "BannerImage", max: 3, images: []},
				{
					type: "EventSchedule",
					schedule: [
						{
							date: new Date(),
							fromTime: new Date(),
							toTime: new Date(),
						},
					],
					bgColor: "#FFFFFF",
					textColor: "#000000",
				},
				{
					type: "EventVanue",
					address: "",
					location: false,
					bgColor: "#FFFFFF",
					textColor: "#000000",
					btnBgColor: "#E0E0E0",
					btnTextColor: "#000000",
					btnBorderColor: "#999999",
					required: true,
				},
				{
					type: "DescriptionBox",
					text: "description",
					bgColor: "#FFFFFF",
					textColor: "#000000",
				},
				{
					type: "Button",
					label: "Click Here",
					linkType: "url",
					link: "",
					phone: "",
					email: "",
					bgColor: "#FFFFFF",
					textColor: "#000000",
					borderColor: "#999999",
				},
			];
		case "Social media QR code":
			return [
				{type: "BackgroundColor", bgColor: "#E0E0E0", color: "#000000"},
				{
					type: "BrandLogo",
					image: [],
					max: 1,
					bgColor: "#FFFFFF",
					textColor: "#000000",
				},
				{
					type: "PageTitle",
					title: "",
					bgColor: "#FFFFFF",
					textColor: "#000000",
					required: true,
				},
				{
					type: "SocialMedia",
					socialLinks: [
						{
							name: "facebook",
							link: "",
							logo: "https://cdn1.iconfinder.com/data/icons/logotypes/32/square-facebook-128.png",
							text: "",
						},
					],
					required: true,
				},
			];
		case "Coupon QR code":
			return [
				{type: "BackgroundColor", bgColor: "#E0E0E0", color: "#000000"},
				{type: "BannerImage", max: 3, images: []},
				{
					type: "CompanyBranding",
					label: "Company Branding",
					image: [],
					max: 1,
					name: "Company Name",
					bgColor: "#FFFFFF",
					textColor: "#000000",
					required: true,
				},
				{
					type: "DescriptionBox",
					text: "description",
					bgColor: "#FFFFFF",
					textColor: "#000000",
				},
				{
					type: "Button",
					label: "Click Here",
					linkType: "url",
					link: "",
					phone: "",
					email: "",
					bgColor: "#FFFFFF",
					textColor: "#000000",
					borderColor: "#999999",
				},
				{
					type: "CouponDetails",
					label: "Coupon Details",
					offerTitle: "",
					couponCode: "",
					offerDetails: "",
					bgColor: "#FFFFFF",
					textColor: "#000000",
					required: true,
				},
			];
		case "Custom page QR code":
			return [
				{type: "BackgroundColor", bgColor: "#E0E0E0", color: "#000000"},
				{type: "BannerImage", max: 3, images: [], required: true},
				{
					type: "Button",
					label: "Click Here",
					linkType: "url",
					link: "",
					phone: "",
					email: "",
					bgColor: "#FFFFFF",
					textColor: "#000000",
					borderColor: "#999999",
				},
				{
					type: "DescriptionBox",
					text: "description",
					bgColor: "#FFFFFF",
					textColor: "#000000",
				},
			];
		case "Google maps QR code":
			return [
				{
					type: "GoogleMap",
					label: "Search location or drop location marker",
					location: "",
					required: true,
				},
			];
		case "PayPal QR code ....":
			return [
				{
					type: "Paypal",
					email: "",
					description: "",
					productCode: "",
					price: "",
					currency: "USD",
					required: true,
				},
			];
		case "App store QR code":
			return [
				{
					type: "AppStore",
					label: "Enter Google Play and App Store URLs of your application",
					playStoreUrl: "",
					appStoreUrl: "",
					required: true,
				},
			];
		case "App Deep Link QR code":
			return [
				{
					type: "AppDeepLink",
					playStoreUrl: "",
					androidAppUrl: "",
					appStoreUrl: "",
					iosAppUrl: "",
					websiteUrl: "",
					required: true,
				},
			];
		case "Product QR code":
			return [
				{type: "BackgroundColor", bgColor: "#E0E0E0", color: "#000000"},
				{type: "BannerImage", max: 3, images: [], required: true},
				{
					type: "Button",
					label: "Click Here",
					linkType: "url",
					link: "",
					phone: "",
					email: "",
					bgColor: "#FFFFFF",
					textColor: "#000000",
					borderColor: "#999999",
				},
				{
					type: "DescriptionBox",
					text: "description",
					bgColor: "#FFFFFF",
					textColor: "#000000",
					required: true,
				},
			];
		case "Audio QR code":
			return [
				{
					type: "Audio",
					max: 20,
					audio: [],
					label: "Upload single or multiple audio files",
					required: true,
				},
			];
		case "VCard profile QR code":
			return [
				{type: "BackgroundColor", bgColor: "#E0E0E0"},
				{
					type: "ProfileInfo",
					max: 1,
					images: [],
					name: "",
					title: "",
					company: "",
					required: true,
				},
				{
					type: "ContactDetails",
					primaryPhone: "",
					primaryEmail: "",
					website: "",
					fax: "",
					address: [
						{
							street: "",
							city: "",
							state: "",
							country: "",
							postalCode: "",
							type: "",
						},
					],
					location: false,
					required: true,
				},
				{
					type: "DescriptionBox",
					text: "description",
					bgColor: "#FFFFFF",
					textColor: "#000000",
				},
				{type: "VCard"},
			];
		case "Website URL QR code":
			return [
				{
					type: "WebsiteUrl",
					label: "Paste URL of webpage, video, etc. here",
					url: "",
					validate: false,
					required: true,
				},
			];
		case "Lead":
			return [
				{
					type: "PageTitle",
					title: "",
					bgColor: "#FFFFFF",
					textColor: "#000000",
					required: true,
				},
				{type: "BackgroundColor", bgColor: "#E0E0E0", color: "#000000"},
				{
					type: "DescriptionBox",
					text: "description",
					bgColor: "#FFFFFF",
					textColor: "#000000",
				},
				{type: "BannerImage", max: 3, images: []},
				{
					type: "LeadForm",
					text: "description",
					bgColor: "#FFFFFF",
					textColor: "#000000",
					questions: [
						{
							required: false,
							question: "",
							answerType: "",
						},
					],
				},
				{
					type: "Button",
					label: "Submit",
					linkType: false,
					bgColor: "#FFFFFF",
					textColor: "#000000",
					borderColor: "#999999",
				},
				{
					type: "Button",
					label: "Skip",
					linkType: false,
					bgColor: "#FFFFFF",
					textColor: "#000000",
					borderColor: "#999999",
				},
			];
		default:
			return [];
	}
};

export default ContentArray;
