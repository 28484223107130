import React from "react";
import {Grid, Typography, Box, IconButton, Button} from "@material-ui/core";
import {FaDownload} from "react-icons/all";

function DocumentComp(props) {
	const {data} = props;
	const {documents} = data;

	const LongText = ({content, limit}) => {
		if (content.length <= limit) {
			return (
				<span style={{padding: "0", margin: "0", fontSize: "15px"}}>
					{content}
				</span>
			);
		}
		const toShow = content.substring(0, limit) + "...";
		return (
			<span style={{padding: "0", margin: "0", fontSize: "15px"}}>
				{toShow}
			</span>
		);
	};

	return (
		<React.Fragment>
			<Grid container spacing={1} style={{marginTop: "5px"}}>
				<Grid item xs={12}>
					<Box
						style={{
							padding: "15px",
							borderRadius: "8px",
							border: "1px solid #999",
						}}
					>
						<Typography variant="h5">
							{documents && documents.length}{" "}
							{documents.length > 1 ? "Documents" : "Document"}
						</Typography>
					</Box>
				</Grid>
				{documents &&
					documents.length > 0 &&
					documents.map((file, index) => (
						<Grid item xs={12} key={index}>
							<Button
								style={{
									padding: "5px 15px",
									borderRadius: "8px",
									display: "flex",
									alignItems: "center",
									justifyContent: "space-between",
									background: "#FFF",
								}}
								variant="outlined"
								fullWidth
								onClick={() => {
									var a = document.createElement("a");
									a.href = file.url;
									a.download = file.name ? file.name : "dowload";
									a.click();
								}}
							>
								<LongText content={file.name} limit={25} />
								<IconButton>
									<FaDownload />
								</IconButton>
							</Button>
						</Grid>
					))}
			</Grid>
		</React.Fragment>
	);
}
export default DocumentComp;
