import * as React from "react";
import { Box, Grid, TextField } from "@material-ui/core";
import { experimentalStyled as styled } from "@material-ui/core/styles";

import classes from "./Page4.module.css";

function Page4() {
  const CssTextField = styled(TextField)({
    "& label.MuiFormLabel-root": {
      color: "#FF800C",
      width: "100%",
      fontFamily: "Raleway",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "20px",
      textAlign: "center",
      lineHeight: "23px",
    },
    "& label.Mui-focused": {
      color: "#FF800C",
      width: "auto",
      backgroundColor: "#FFF",
      textAlign: "left !important",
      left: "15px",
    },

    "& label.MuiFormLabel-filled": {
      textAlign: "left !important",
      left: "15px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      padding: "0 20px",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#FF800C",
      borderWidth: 6,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#FF800C",
        borderWidth: "4px",
        borderRadius: "21px",
      },
      "&:hover fieldset": {
        borderColor: "#FF800C",
        borderWidth: "4px",
        borderRadius: "21px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#FF800C",
        borderWidth: "4px",
        borderRadius: "21px",
      },
    },
  });
  return (
    <Box className={classes.box}>
      <Grid container>
        <Grid item xs={12} className="d-flex justify-content-center mb-5">
          <label className={classes.title}> Get In Touch With Us </label>
        </Grid>
        <Grid item container xs={12} spacing={5}>
          <Grid item xs={6} className="d-flex justify-content-end">
            <Box className={classes.boxInputLeft}>
              <CssTextField
                className={classes.inputName}
                label="Your Name"
                required
              />
              <CssTextField
                className={classes.inputEmail}
                label="Your Email Address"
                required
              />
              <CssTextField
                className={classes.inputPhone}
                label="Your Phone Number"
                required
              />
            </Box>
          </Grid>
          <Grid item xs={6} className="d-flex justify-content-start">
            <Box className={classes.boxInputRight}>
              <CssTextField
                className={classes.inputCompanyName}
                label="Company Name"
                required
              />
              <CssTextField
                className={classes.inputCompanySite}
                label="Company Website"
                required
              />
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12} className="d-flex justify-content-center mt-4">
          <Box className={classes.boxInputBottom}>
            <CssTextField
              className={classes.inputMessage}
              label="Please explain your use case"
              multiline
              rows={4}
              required
            />
          </Box>
        </Grid>
        <Grid item xs={12} className="d-flex justify-content-center mt-4">
          <Box className={classes.btnSendEmail}>
            <span className={classes.btnSendEmailText}> Send Email </span>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Page4;
