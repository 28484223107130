import React from "react";
import {Box, Typography, Grid, IconButton} from "@material-ui/core";

function SocialMedia(props) {
	const {data} = props;

	const {socialLinks} = data;

	return (
		<Box style={{marginTop: "10px"}}>
			<Grid container spacing={1}>
				{socialLinks &&
					socialLinks.length > 0 &&
					socialLinks.map((ele, index) => (
						<Grid item xs={6} key={index}>
							<IconButton
								style={{
									padding: "15px 10px 10px 10px",
									background: "#FFF",
									borderRadius: "8px",
									width: "100%",
								}}
							>
								<Box
									style={{
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
										justifyContent: "center",
									}}
								>
									<img
										src={ele.logo ? ele.logo : ""}
										alt=""
										style={{
											height: "65px",
											width: "65px",
											objectFit: "contain",
										}}
									/>
									<Typography style={{marginTop: "10px", color: "#444"}}>
										<b>{ele.text ? ele.text : "follow on"}</b>
									</Typography>
								</Box>
							</IconButton>
						</Grid>
					))}
			</Grid>
		</Box>
	);
}

export default SocialMedia;
