import React from "react";

import QrContext from "../../QrContext/QrContext";

// ----------------- Import Components ------------------ //

import BackgroundColor from "./Accordion/BackgroundColor";
import PageTitle from "./Accordion/PageTitle";
import Button from "./Accordion/Button";
import DescriptionBox from "./Accordion/DescriptionBox";
import MapLocationButton from "./Accordion/MapLocationButton";
import BannerImage from "./Accordion/BannerImage";
import EventVanue from "./Accordion/EventVanue";
import EventSchedule from "./Accordion/EventSchedule";
import CoupleName from "./Accordion/CoupleName";
import Files from "./Accordion/Files";
import WebsiteUrl from "./Accordion/WebsiteUrl";
import GoogleMap from "./Accordion/GoogleMap";
import Image from "./Accordion/Image";
import Document from "./Accordion/Document";
import Audio from "./Accordion/Audio";
import AppStore from "./Accordion/AppStore";
import ProfileInfo from "./Accordion/ProfileInfo";
import ContactDetails from "./Accordion/ContactDetails";
import CompanyBranding from "./Accordion/CompanyBranding";
import CouponDetails from "./Accordion/CouponDetails";
import BrandLogo from "./Accordion/BrandLogo";
import SocialMedia from "./Accordion/SocialMedia";
import Paypal from "./Accordion/Paypal";
import AppDeepLink from "./Accordion/AppDeepLink";
import LeadForm from "./Accordion/LeadForm";

function Accordion() {
	const {content} = React.useContext(QrContext);
	return (
		<React.Fragment>
			{content.length > 0 &&
				content.map((ele, index) => {
					switch (ele.type) {
						case "BackgroundColor":
							return (
								<BackgroundColor key={index} dataIndex={index} data={ele} />
							);
						case "PageTitle":
							return <PageTitle key={index} dataIndex={index} data={ele} />;
						case "BannerImage":
							return <BannerImage key={index} dataIndex={index} data={ele} />;
						case "Image":
							return <Image key={index} dataIndex={index} data={ele} />;
						case "Document":
							return <Document key={index} dataIndex={index} data={ele} />;
						case "Audio":
							return <Audio key={index} dataIndex={index} data={ele} />;
						case "EventVanue":
							return <EventVanue key={index} dataIndex={index} data={ele} />;
						case "EventSchedule":
							return <EventSchedule key={index} dataIndex={index} data={ele} />;
						case "CoupleName":
							return <CoupleName key={index} dataIndex={index} data={ele} />;
						case "Button":
							return <Button key={index} dataIndex={index} data={ele} />;
						case "DescriptionBox":
							return (
								<DescriptionBox key={index} dataIndex={index} data={ele} />
							);
						case "MapLocationButton":
							return (
								<MapLocationButton key={index} dataIndex={index} data={ele} />
							);
						case "WebsiteUrl":
							return <WebsiteUrl key={index} dataIndex={index} data={ele} />;
						case "Files":
							return <Files key={index} dataIndex={index} data={ele} />;
						case "GoogleMap":
							return <GoogleMap key={index} dataIndex={index} data={ele} />;
						case "AppStore":
							return <AppStore key={index} dataIndex={index} data={ele} />;
						case "ProfileInfo":
							return <ProfileInfo key={index} dataIndex={index} data={ele} />;
						case "ContactDetails":
							return (
								<ContactDetails key={index} dataIndex={index} data={ele} />
							);
						case "CompanyBranding":
							return (
								<CompanyBranding key={index} dataIndex={index} data={ele} />
							);
						case "CouponDetails":
							return <CouponDetails key={index} dataIndex={index} data={ele} />;
						case "BrandLogo":
							return <BrandLogo key={index} dataIndex={index} data={ele} />;
						case "SocialMedia":
							return <SocialMedia key={index} dataIndex={index} data={ele} />;
						case "Paypal":
							return <Paypal key={index} dataIndex={index} data={ele} />;
						case "AppDeepLink":
							return <AppDeepLink key={index} dataIndex={index} data={ele} />;
						case "LeadForm":
							return <LeadForm key={index} dataIndex={index} data={ele} />;
						default:
							return null;
					}
				})}
		</React.Fragment>
	);
}

export default Accordion;
