import React from "react";
import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Typography,
	Grid,
	Box,
	IconButton,
} from "@material-ui/core";
import {Delete, ExpandMore} from "@material-ui/icons";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {makeStyles} from "@material-ui/core-v4";

import BgColorComp from "./ColorComponents/BgColor";
import TextColorComp from "./ColorComponents/TextColor";
import QrContext from "../../../QrContext/QrContext";

const useStyles = makeStyles((theme) => ({
	box: {
		"& .MuiPaper-root": {
			boxShadow: "none !important",
		},
		"& .MuiPaper-root > .MuiButtonBase-root": {
			border: (props) => (props ? "2px solid #ff3333" : "1px solid #000"),
			borderRadius: "2px",
			background: "#F0F0F0",
			height: "50px !important",
		},
		"& .MuiPaper-root > .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded":
			{
				minHeight: "50px !important",
			},
		"& .MuiPaper-root > .MuiCollapse-root": {
			border: "1px solid #D8D8D8",
			margin: "10px 0",
			borderRadius: "2px",
		},
	},
	center: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	card: {
		width: "100%",
		"& div > div": {
			height: "40px",
			borderRadius: "0 2px 2px 0",
		},
		"& .MuiFormControl-root > .MuiOutlinedInput-root > input ": {
			marginTop: "-3px",
		},
		"& .MuiFormControl-root > .MuiOutlinedInput-root > .MuiInputAdornment-root > .MuiButtonBase-root ":
			{
				marginTop: "-7px",
			},
	},
}));

function DescriptionBoxComp(props) {
	const {data, dataIndex} = props;

	const {bgColor, textColor, text, required, deletable} = data;

	const classes = useStyles(required === "indicate" ? true : false);

	const [expanded, setExpanded] = React.useState(false);

	const {setContent} = React.useContext(QrContext);

	// const fontFamilyConfig = {
	//     options: [
	//         'default',
	//         'Arial, Helvetica, sans-serif',
	//         'Courier New, Courier, monospace',
	//         'Georgia, serif',
	//         'Lucida Sans Unicode, Lucida Grande, sans-serif',
	//         'Tahoma, Geneva, sans-serif',
	//         'Times New Roman, Times, serif',
	//         'Trebuchet MS, Helvetica, sans-serif',
	//         'Verdana, Geneva, sans-serif'
	//     ]
	// };

	// const ckeditorApi = React.useCallback(() => {
	//     ClassicEditor.create({
	// 	    fontFamily: {
	//             options: [
	//                 'default',
	//                 'Arial, Helvetica, sans-serif',
	//                 'Courier New, Courier, monospace',
	//                 'Georgia, serif',
	//                 'Lucida Sans Unicode, Lucida Grande, sans-serif',
	//                 'Tahoma, Geneva, sans-serif',
	//                 'Times New Roman, Times, serif',
	//                 'Trebuchet MS, Helvetica, sans-serif',
	//                 'Verdana, Geneva, sans-serif'
	//             ]
	//         } // Font family feature configuration.
	//     }).then(res => {
	//     })
	// },[fontFamilyConfig])

	// React.useEffect(() => {
	//     ckeditorApi()
	// }, [ckeditorApi])

	// const customStyles = {
	//     control: base => ({
	//       ...base,
	//       marginTop: '-5px',
	//       height: 45,
	//       width: 120,
	//       minHeight: 40,
	//       borderRadius: '2px 0 0 2px',
	//       borderRight: '0'
	//     })
	//   };

	const handleDelete = () => {
		setContent((prev) => [...prev].filter((ele, index) => dataIndex !== index));
	};

	if (props.data) {
		const handleChange = (key, value) => {
			setContent((prev) =>
				prev.map((ele, index) => {
					if (index === dataIndex) {
						return {
							...ele,
							[key]: value,
						};
					} else return ele;
				})
			);
		};
		return (
			<div className={classes.box}>
				<Accordion onChange={(e, data) => setExpanded(data)}>
					<AccordionSummary expandIcon={<ExpandMore />}>
						<Box
							style={{
								display: "flex",
								justifyContent: "space-between",
								width: "100%",
								alignItems: "center",
							}}
						>
							<Typography
								style={{color: "#615757", fontSize: "20px", fontWeight: "600"}}
							>
								Description Box
							</Typography>
							{expanded && !deletable && (
								<IconButton
									style={{marginRight: "15px"}}
									onClick={() => handleDelete()}
								>
									<Delete />
								</IconButton>
							)}
						</Box>
					</AccordionSummary>
					<AccordionDetails style={{display: "flex", alignItems: "center"}}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Grid container spacing={1}>
									<Grid item xs={12}>
										<Typography variant="h5">Text</Typography>
									</Grid>
									<Grid item xs={12}>
										<CKEditor
											id="inputText"
											editor={ClassicEditor}
											fontFamily
											onChange={(e, data) =>
												handleChange("text", data.getData())
											}
											data={text}
										/>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={12}>
								<BgColorComp
									bgColor={bgColor}
									handleChange={(key, value) => handleChange(key, value)}
									name="Background Color"
								/>
							</Grid>
							<Grid item xs={12}>
								<TextColorComp
									textColor={textColor}
									handleChange={(key, value) => handleChange(key, value)}
									name="Text Color"
								/>
							</Grid>
						</Grid>
					</AccordionDetails>
				</Accordion>
			</div>
		);
	} else {
		return null;
	}
}
export default DescriptionBoxComp;
