import React from "react";
import { Box, Typography } from '@material-ui/core';
import moment from "moment";

function EventVanueComp(props){

    const { data } = props 
    const { textColor, bgColor, schedule } = data

    if(schedule && schedule.length > 0){
        return(
            <Box
                style={{
                    padding: '15px',
                    background: bgColor,
                    borderRadius: '5px',
                    color: textColor,
                    marginTop: '10px',
                    display: 'flex',
                    flexDirection: 'column',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <Typography variant='h5'>
                    Timings
                </Typography>
                {
                    schedule.map((ele, index) => {
                        return (
                            <Box key={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '10px' }}>
                                <Typography variant='h5' style={{ marginTop: '5px', color: textColor }}>
                                    {moment(ele.date).format('LL')}
                                </Typography>
                                <Typography variant='h6' style={{ marginTop: '0px', color: textColor }}>
                                    {moment(ele.fromTime).format('LT')}
                                </Typography>
                            </Box>
                        )
                    })
                }
            </Box>
        )
    } else {
        return null
    }
}
export default EventVanueComp;