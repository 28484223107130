import React from "react";
import { Box, Typography } from '@material-ui/core';
import WeddingFram from '../../../../../../../Assets/WeddingFram.jpg'

function CoupleNameComp(props){

    const { data } = props 
    const { textColor, firstName, secondName } = data

        return(
            <Box style={{ position: 'relative', marginTop: '10px' }}>
                <Box style={{ position: 'absolute', top: '32%' }}>
                    <Box
                        style={{
                            borderRadius: '5px',
                            color: textColor,
                            display: 'flex',
                            width: '225px',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Typography className="apply-font" variant='h3'>
                            {firstName ? firstName : 'Romeo'}
                        </Typography>

                        <Typography className="apply-font" style={{ marginTop: '10px', fontSize: '15px' }}>
                            Weds
                        </Typography>

                        <Typography className="apply-font" variant='h3' style={{ marginTop: '10px' }}>
                            {secondName ? secondName : 'Juliet'}
                        </Typography>
                    </Box>
                </Box>
                <Box>
                    <img alt='' src={WeddingFram} style={{ height: '333px', objectFit: 'contain', borderRadius: '5px' }} />
                </Box>
            </Box>
        )
}
export default CoupleNameComp;