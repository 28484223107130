import * as React from "react";
import {Box, Grid} from "@material-ui/core";

import classes from "./Page3.module.css";

import interRemoveBgPreview from "../../../../../Assets/inter-removebg-preview1.svg";
import dynamicRemovebgPreview1 from "../../../../../Assets/dynamic-removebg-preview1.svg";
import staticRemovebgPreview1 from "../../../../../Assets/static-removebg-preview1.svg";
import RightArrowImg from "../.././../../../Assets/Vector28.svg";
import boxToolsQrImg from "../../../../../Assets/qr5.svg";
import boxToolsHotBatchImg from "../../../../../Assets/HOTBATCH1.svg";
import boxToolsQrApiImg from "../../../../../Assets/QRAPI1.svg";
import boxToolsSdkQrImg from "../../../../../Assets/SDKQR1.svg";
import {useNavigate} from "react-router";

function Page3(props) {
	const navigate = useNavigate();
	const handleClickTool1 = () => {
		navigate("/hotqrcodegenerator");
	};
	const handleClickTool2 = () => {
		navigate("/hotqrbatchgenerator");
	};
	const handleClickTool3 = () => {
		navigate("/hotqrapiforstaticqrcodes");
	};
	const handleClickTool4 = () => {
		navigate("/hotqrcodegeneration&designsdk");
	};
	return (
		<Box className={classes.box} ref={props.ref} id={"page3"}>
			<Box className="d-flex flex-column align-items-center">
				<label className={classes.labelTitle} style={{marginBottom: "40px"}}>
					{" "}
					HOT QR ENGINES{" "}
				</label>
				<Box className="d-flex align-items-center py-2">
					<Box className={classes.boxContainImg}>
						<img
							className={classes.boxDetailsImg}
							alt="img1"
							src={interRemoveBgPreview}
						/>
					</Box>
					<label className={classes.boxDetailsLabel}> Inter-operable QR </label>
					<img
						className={classes.boxArrowImg}
						alt="ArrowImg"
						src={RightArrowImg}
					/>
					<label className={classes.boxDetailsText}>
						Interoperability is a characteristic of a product or system, whose
						interfaces are completely understood, to work with other products or
						systems, at present or in the future, in either implementation or
						access, without any restrictions ,interoperable quick response (QR)
						code-based application would enable merchants and customers to
						transact without being restricted to specific wallets or banks
					</label>
				</Box>
				<Box className="d-flex align-items-center py-2">
					<Box className={classes.boxContainImg}>
						<img
							className={classes.boxDetailsImg1}
							alt="img1"
							src={dynamicRemovebgPreview1}
						/>
					</Box>
					<label className={classes.boxDetailsLabel}> Dynamic QR </label>
					<img
						className={classes.boxArrowImg}
						alt="ArrowImg"
						src={RightArrowImg}
					/>
					<label className={classes.boxDetailsText}>
						Dynamic QR Codes allow you to edit the encoded content (such as
						website link, text, and images) anytime you want without reprinting
						the QR Code.
					</label>
				</Box>
				<Box className="d-flex align-items-center py-2">
					<Box className={classes.boxContainImg}>
						<img
							className={classes.boxDetailsImg2}
							alt="img1"
							src={staticRemovebgPreview1}
						/>
					</Box>
					<label className={classes.boxDetailsLabel}> Static QR </label>
					<img
						className={classes.boxArrowImg}
						alt="ArrowImg"
						src={RightArrowImg}
					/>
					<label className={classes.boxDetailsText}>
						Such QR Codes, which cannot be edited after being created, are
						called Static QR Codes.If the QR Code itself stores the target
						content, then it is a Static QR Code. The content could be a serial
						code, text message, URL, Wi-Fi password, or contact details.
					</label>
				</Box>
			</Box>
			<Grid container>
				<Grid item xs={12} style={{textAlign: "center"}}>
					<label className={classes.labelTextTitle}>
						{" "}
						QR Code Generation and Management Tools{" "}
					</label>
				</Grid>
				<Grid item xs={3} className="d-flex justify-content-center">
					<Box className={classes.boxTools}>
						<img
							className={classes.boxToolsImg1}
							alt="ImgQr"
							src={boxToolsQrImg}
						/>
						<label className={classes.boxToolsTitle1}> HOT QR </label>
						<label className={classes.boxToolsText}>
							{" "}
							Create, design, manage, and track QR Codes for print media
							marketing campaigns.{" "}
						</label>
						<label
							className={classes.boxToolsSubTitle}
							onClick={handleClickTool1}
						>
							{" "}
							Tool{" "}
						</label>
					</Box>
				</Grid>
				<Grid item xs={3} className="d-flex justify-content-center">
					<Box className={classes.boxTools}>
						<img
							className={classes.boxToolsTitleImg}
							alt="ImgQr"
							src={boxToolsHotBatchImg}
						/>
						<label className={classes.boxToolsText}>
							{" "}
							Generate custom-designed QR Codes in large number.{" "}
						</label>
						<label
							className={classes.boxToolsSubTitle}
							onClick={handleClickTool2}
						>
							{" "}
							Tool{" "}
						</label>
					</Box>
				</Grid>
				<Grid item xs={3} className="d-flex justify-content-center">
					<Box className={classes.boxTools}>
						<img
							className={classes.boxToolsTitleImg}
							alt="ImgQr"
							src={boxToolsQrApiImg}
						/>
						<label className={classes.boxToolsText}>
							{" "}
							Generate static QR Codes over web in your own system (via REST
							API).{" "}
						</label>
						<label
							className={classes.boxToolsSubTitle}
							onClick={handleClickTool3}
						>
							{" "}
							Tool{" "}
						</label>
					</Box>
				</Grid>
				<Grid item xs={3} className="d-flex justify-content-center">
					<Box className={classes.boxTools}>
						<img
							className={classes.boxToolsTitleImg}
							alt="ImgQr"
							src={boxToolsSdkQrImg}
						/>
						<label className={classes.boxToolsText}>
							{" "}
							Generate high volume static QR Codes over web your own system
							locally.{" "}
						</label>
						<label
							className={classes.boxToolsSubTitle}
							onClick={handleClickTool4}
						>
							{" "}
							Tool{" "}
						</label>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
}

export default Page3;
