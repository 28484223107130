import React from "react";
import {
	Typography,
	IconButton,
	Snackbar,
	Alert,
	Button,
	LinearProgress,
	Box,
	Switch,
	Grid,
} from "@material-ui/core";
import {Slider} from "@mui/material";
import {styled as muiStyled} from "@mui/material/styles";
import {Cancel} from "@material-ui/icons";

import {useDropzone} from "react-dropzone";
import styled from "styled-components";

import {
	uploader,
	deleteUploadedFile,
} from "../../../../Services/QrGenerateServices/ApiUrl";

const Android12Switch = muiStyled(Switch)(({theme}) => ({
	padding: 8,
	"& .MuiSwitch-track": {
		borderRadius: 22 / 2,
		"&:before, &:after": {
			content: '""',
			position: "absolute",
			top: "50%",
			transform: "translateY(-50%)",
			width: 16,
			height: 16,
		},
		"&:before": {
			backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
				"#000"
			)}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
			left: 12,
		},
		"&:after": {
			backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
				"#FFF"
			)}" d="M19,13H5V11H19V13Z" /></svg>')`,
			right: 12,
		},
	},
	"& .MuiSwitch-thumb": {
		boxShadow: "none",
		width: 16,
		height: 16,
		margin: 2,
	},
}));

const PrettoSlider = muiStyled(Slider)({
	color: "#FC800D",
	height: 8,
	"& .MuiSlider-track": {
		border: "none",
	},
	"& .MuiSlider-thumb": {
		height: 24,
		width: 24,
		backgroundColor: "#fff",
		border: "2px solid currentColor",
		"&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
			boxShadow: "inherit",
		},
		"&:before": {
			display: "block",
		},
	},
	"& .MuiSlider-valueLabel": {
		lineHeight: 1.2,
		fontSize: 12,
		background: "unset",
		padding: 0,
		width: 32,
		height: 32,
		borderRadius: "50% 50% 50% 0",
		backgroundColor: "#FC800D",
		transformOrigin: "bottom left",
		transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
		"&:before": {
			display: "none",
		},
		"&.MuiSlider-valueLabelOpen": {
			transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
		},
		"& > *": {
			transform: "rotate(45deg)",
		},
	},
});

const thumbsContainer = {
	display: "flex",
	flexDirection: "row",
	flexWrap: "wrap",
	marginTop: 16,
};

const thumb = {
	display: "inline-flex",
	borderRadius: 2,
	border: "1px solid #eaeaea",
	marginBottom: 8,
	marginRight: 8,
	width: 110,
	height: 110,
	padding: 4,
	boxSizing: "border-box",
};

const thumbInner = {
	display: "flex",
	minWidth: 0,
	overflow: "hidden",
	objectFit: "contain",
	position: "relative",
};

const img = {
	display: "block",
	width: 100,
	height: 100,
	objectFit: "contain",
};

const getColor = (props) => {
	if (props.isDragAccept) {
		return "#00e676";
	}
	if (props.isDragReject) {
		return "#ff1744";
	}
	if (props.isDragActive) {
		return "#2196f3";
	}
	return "#E0E0E0";
};

const Container = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px;
	border-width: 2px;
	border-radius: 2px;
	border-color: ${(props) => getColor(props)};
	border-style: dashed;
	background-color: #fafafa;
	color: #bdbdbd;
	outline: none;
	transition: border 0.24s ease-in-out;
`;

function Logo(props) {
	const {data, handleData, apiCall} = props;

	const [loader, setLoader] = React.useState(false);
	const [imgData, setImgData] = React.useState(null);
	const [size, setSize] = React.useState(60);
	const [blocks, setBlocks] = React.useState(true);
	const [logoBgColor, setLogoBgColor] = React.useState("#000000");

	const [snackbar, setSnackbar] = React.useState({
		open: false,
		severity: "success",
		message: "here",
	});

	const maxSize = 5242880;

	const handleFile = React.useCallback(
		(data) => {
			handleData({logo: data, blocks: blocks, size: size}, 1);
		},
		[handleData, blocks, size]
	);

	function getThe64StringSize(base64String) {
		if (base64String) {
			const string = base64String.split(",")[1];
			let x =
				string.length * (3 / 4) -
				(string.slice(string.length - 2, string.length) !== "=="
					? string.slice(string.length - 1, string.length) === "="
						? 1
						: 0
					: 2);
			return x;
		}
	}

	const uploadFile = React.useCallback(
		(files) => {
			Object.values(files).forEach((file) => {
				var reader = new FileReader();
				if (file) {
					reader.readAsDataURL(file);
					reader.onload = () => {
						uploader({
							payload: [
								{
									name: file.name,
									size: file.size,
									type: file.type,
									base64String: reader.result.split(",")[1],
									base64StringSize: getThe64StringSize(reader.result),
								},
							],
						})
							.then((res) => {
								setLoader(false);
								setImgData(res[0]);
								apiCall({logo: res[0].url});
								handleFile(res[0]);
								setSnackbar({
									open: true,
									message: "Image Upload Successfully",
									severity: "success",
								});
							})
							.catch((err) => {
								setLoader(false);
								console.error(err);
								setSnackbar({
									open: true,
									message: "Error while upload files",
									severity: "error",
								});
							});
					};
					reader.onerror = () => {
						setLoader(false);
						setSnackbar({
							open: true,
							message: "Error while uplaod files",
							severity: "error",
						});
					};
				}
			});
		},
		[handleFile, apiCall]
	);

	const {
		getRootProps,
		getInputProps,
		isDragActive,
		isDragAccept,
		isDragReject,
	} = useDropzone({
		accept: "image/*",
		minSize: 0,
		maxSize,
		onDrop: (acceptedFiles) => {
			if (
				(data && data.filter((ele) => ele.index === 1).length === 0) ||
				(data &&
					data.length > 0 &&
					data.filter((ele) => ele.index === 1)[0].logo === null) ||
				data.filter((ele) => ele.index === 1)[0].logo === ""
			) {
				if (
					acceptedFiles &&
					acceptedFiles.length > 0 &&
					acceptedFiles[0].size < 5242880
				) {
					let imageDataPayload = acceptedFiles.map((file) =>
						Object.assign(file, {
							preview: URL.createObjectURL(file),
						})
					);
					setLoader(true);
					uploadFile(imageDataPayload);
				} else {
					setSnackbar({
						open: true,
						message: "File Size more then 5 MB",
						severity: "warning",
					});
				}
			} else {
				setSnackbar({
					open: true,
					message: "You have upload max 1 Image",
					severity: "warning",
				});
			}
		},
	});

	const handleDeleteImage = React.useCallback(() => {
		let imgUrlArray =
			imgData !== null &&
			imgData.url.split(
				"https://hotqrs3bucket.s3.ap-southeast-1.amazonaws.com/"
			);
		let deletePayload = imgUrlArray[1];
		deleteUploadedFile(deletePayload)
			.then((res) => {
				apiCall({logo: ""});
				handleData({logo: null, blocks: blocks, size: size}, 1);
				setImgData(null);
				setSnackbar({
					open: true,
					message: "Image Remove Successfully",
					severity: "success",
				});
			})
			.catch((err) => {
				setSnackbar({
					open: true,
					message: "Error in remove image",
					severity: "error",
				});
			});
	}, [handleData, imgData, blocks, size, apiCall]);

	const handleChangeSize = (data) => {
		handleData({logo: imgData, blocks: blocks, size: data}, 1);
	};

	return (
		<Box>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<section className="container">
						<Container
							{...getRootProps({isDragActive, isDragAccept, isDragReject})}
						>
							<input {...getInputProps()} />
							<p>Drag 'n' drop some files here, or click to select files</p>
						</Container>
						<aside style={thumbsContainer}>
							{data &&
								data.length > 0 &&
								data.filter((ele) => ele.index === 1).length > 0 &&
								data.filter((ele) => ele.index === 1)[0].logo !== null &&
								data.filter((ele) => ele.index === 1)[0].logo !== "" && (
									<div style={thumb}>
										<div style={thumbInner}>
											<IconButton
												style={{
													position: "absolute",
													padding: "0",
													margin: "0",
													right: "0",
													border: "0",
													background: "transparent",
													height: "1px !important",
												}}
												onClick={handleDeleteImage}
											>
												<Cancel />
											</IconButton>
											<img
												src={
													data &&
													data.length > 0 &&
													data.filter((ele) => ele.index === 1).length > 0 &&
													data.filter((ele) => ele.index === 1)[0].logo !==
														null &&
													data.filter((ele) => ele.index === 1)[0].logo.url
												}
												style={img}
												alt=""
											/>
										</div>
									</div>
								)}
							{loader && (
								<div style={thumb}>
									<div style={thumbInner}>
										<Box
											variant="rectangular"
											width={110}
											height={110}
											style={{marginTop: "90px"}}
										>
											<LinearProgress />
										</Box>
									</div>
								</div>
							)}
						</aside>
					</section>
				</Grid>
				<Grid item xs={12}>
					<Typography
						variant="h5"
						style={{color: "#666", marginBottom: "10px"}}
					>
						Logo Background Color
					</Typography>
					<Grid container spacing={3}>
						<Grid item md={4} lg={4} xl={3}>
							<Box
								style={{
									height: "40px",
									width: "auto",
									background: "#E0E0E0",
									border: "1px solid #999",
									borderRadius: "2px",
									display: "flex",
								}}
							>
								<Box
									style={{
										height: "40px",
										width: "40px",
										borderRight: "1px solid #999",
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									#
								</Box>
								<Typography
									style={{
										height: "40px",
										width: "100%",
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									{logoBgColor && logoBgColor.replace("#", "").toUpperCase()}
								</Typography>
							</Box>
						</Grid>
						<Grid item md={4} lg={4} xl={3}>
							<input
								style={{
									width: "100%",
									height: "40px",
								}}
								type="color"
								onChange={(e) => {
									setLogoBgColor(e.target.value);
								}}
								value={logoBgColor}
							/>
						</Grid>
						<Grid item md={4} lg={3} xl={2}>
							<Button
								variant="outlined"
								onClick={() => {
									handleData(
										{
											logoBackgroundColor: logoBgColor,
										},
										1
									);
									apiCall({
										logoBackgroundColor: logoBgColor,
									});
								}}
								style={{marginTop: "2px"}}
							>
								Apply Color
							</Button>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Typography variant="h5" style={{color: "#555", marginBottom: "5px"}}>
						Logo Size
					</Typography>
					<Box style={{padding: "0 40px 0 20px"}}>
						<PrettoSlider
							value={size}
							onChange={(event, newValue) => {
								setSize(newValue);
								handleChangeSize(newValue);
							}}
							valueLabelDisplay="on"
						/>
					</Box>
				</Grid>
				<Grid item xs={12}>
					<Grid container style={{alignItems: "center"}}>
						<Grid item xs={7}>
							<Typography variant="h5" style={{color: "#555"}}>
								Remove Background Around Logo:
							</Typography>
						</Grid>
						<Grid item xs={5}>
							<Android12Switch
								value={!blocks}
								onChange={(e) => {
									setBlocks(!blocks);
									handleData({logo: imgData, blocks: !blocks}, 1);
									apiCall({bDrawLogoBackground: !blocks});
								}}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Snackbar
				open={snackbar.open}
				anchorOrigin={{vertical: "top", horizontal: "right"}}
				autoHideDuration={5000}
				onClose={() => setSnackbar({open: false})}
			>
				<Alert
					onClose={() => setSnackbar({open: false})}
					severity={snackbar.severity}
					sx={{width: "100%"}}
				>
					{snackbar.message}
				</Alert>
			</Snackbar>
		</Box>
	);
}

export default Logo;
