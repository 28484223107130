import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InfoIcon from "@mui/icons-material/Info";
import {Grid, Button, Typography} from "@mui/material";

import AuthContext from "../../../../../Context/AuthContext";

const DialogModal = (props) => {
	const {goBackModal, setGoBackModal, setStepModel} =
		React.useContext(AuthContext);

	return (
		<Dialog
			open={goBackModal}
			onClose={() => setGoBackModal(false)}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">
				<Grid container spacing={2}>
					<Grid item xs={1}>
						<InfoIcon color="warning" />
					</Grid>
					<Grid item xs={11}>
						<Typography variant="h3" component={"div"} color="warning">
							{"Alert"}
						</Typography>
					</Grid>
				</Grid>
			</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description" component={"div"}>
					<Typography variant="h5" color="GrayText" component={"div"}>
						You're attempting to go back. This will discard all current
						progress. Please confirm.
					</Typography>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					color="error"
					className="mainButton"
					onClick={() => {
						setStepModel((prev) => prev.previous());
						setGoBackModal(false);
					}}
					autoFocus
				>
					Confirm
				</Button>
				<Button onClick={() => setGoBackModal(false)}>Cancle</Button>
			</DialogActions>
		</Dialog>
	);
};

export default DialogModal;
