import * as React from "react";
import {
	Box,
	Grid,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@material-ui/core";

import classes from "./Page4.module.css";

function createData(features, starter, advanced, pro) {
	return {features, starter, advanced, pro};
}

const rows = [
	createData(
		"API Requests",
		"Upto 2500 per month",
		"Upto 25000 per month",
		"Upto 25000 per month"
	),
	createData(
		"Request Rate",
		"Upto 10 per second",
		"Upto 100 per second",
		"Upto 25000 per month"
	),
	createData(
		"Account Validity",
		"Active Subscription",
		"Active Subscription",
		"Upto 25000 per month"
	),
	createData("QR Code Categories", "All", "All", "All"),
	createData("QR Code Designing", "Available", "Available", "Available"),
	createData(
		"QR Code in Raster Formats - PNG, JPEG",
		"Available",
		"Available",
		"Available"
	),
	createData(
		"QR Code in Vector Formats - SVG",
		"Available",
		"Available",
		"Available"
	),
	createData("API Logs", "Available", "Available", "Available"),
	createData("Email Support", "Available", "Available", "Available"),
	createData("Phone Support", "Not Available", "Available", "Available"),
];

function Page4() {
	return (
		<Box className={classes.box}>
			<Grid container>
				<Grid xs={12} className="d-flex justify-content-center">
					<label className={classes.title}> HOT QR API Pricing </label>
				</Grid>
				<Grid item xs={12} className="mt-3">
					<TableContainer>
						<Table sx={{minWidth: 650}} aria-label="simple table">
							<TableHead style={{backgroundColor: "#FF800C"}}>
								<TableRow>
									<TableCell style={{width: "332.5px"}} align="center">
										<spna className={classes.tableHeaderText}> Features </spna>
									</TableCell>

									<TableCell style={{width: "332.5px"}} align="center">
										<Box className={classes.boxTabelCell}>
											<span className={classes.tableHeaderText}> STARTER </span>
											<span className={classes.tableHeaderText}>
												$24 MONTHLY
											</span>
											<Box className={classes.boxHeaderPlanBtn}>
												<span className={classes.boxHeaderPlanBtnText}>
													Choose plan
												</span>
											</Box>
										</Box>
									</TableCell>

									<TableCell style={{width: "332.5px"}} align="center">
										<Box className={classes.boxTabelCell}>
											<span className={classes.tableHeaderText}>ADVANCED</span>
											<span className={classes.tableHeaderText}>
												$75 MONTHLY
											</span>
											<Box className={classes.boxHeaderPlanBtn}>
												<span className={classes.boxHeaderPlanBtnText}>
													Choose plan
												</span>
											</Box>
										</Box>
									</TableCell>
									<TableCell align="center">
										<Box className={classes.boxTabelCell}>
											<span className={classes.tableHeaderText}> PRO </span>
											<span className={classes.tableHeaderText}>
												$300 MONTHLY
											</span>
											<Box className={classes.boxHeaderPlanBtn}>
												<span className={classes.boxHeaderPlanBtnText}>
													Choose plan
												</span>
											</Box>
										</Box>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{rows.map((row, index) => (
									<TableRow key={index}>
										<TableCell
											align="center"
											className={classes.tableBodyRow}
											style={{borderBottom: "4px solid #FF800C"}}
										>
											<span className={classes.tableBodyText}>
												{row.features}
											</span>
										</TableCell>
										<TableCell
											align="center"
											className={classes.tableBodyRow}
											style={{borderBottom: "4px solid #FF800C"}}
										>
											<span className={classes.tableBodyText}>
												{row.starter}
											</span>
										</TableCell>
										<TableCell
											align="center"
											className={classes.tableBodyRow}
											style={{borderBottom: "4px solid #FF800C"}}
										>
											<span className={classes.tableBodyText}>
												{row.advanced}
											</span>
										</TableCell>
										<TableCell
											align="center"
											style={{borderBottom: "4px solid #FF800C"}}
										>
											<span className={classes.tableBodyText}> {row.pro} </span>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</Grid>
			</Grid>
		</Box>
	);
}

export default Page4;
