import React from "react";
import {Box, Card, Typography} from "@material-ui/core";

import template1 from "../../../../../../Assets/CustomQr/Design/templates/1.png";
import template2 from "../../../../../../Assets/CustomQr/Design/templates/2.png";
import template3 from "../../../../../../Assets/CustomQr/Design/templates/3.png";
import template4 from "../../../../../../Assets/CustomQr/Design/templates/4.png";

const templatesArray = [
	{template: template1},
	{template: template2},
	{template: template3},
	{template: template4},
];

function Template(props) {
	const {handleData, setLoadQr, loadQr} = props;

	const [activeTemplate, setActiveTemplate] = React.useState();

	return (
		<Box style={{marginTop: "20px"}}>
			<Typography variant="h4" style={{color: "#666"}}>
				Choose a ready made template to quickly add design or skip to create new
				design.
			</Typography>
			<Box
				style={{
					marginTop: "30px",
					border: "1px solid #E0E0E0",
					padding: "20px",
					borderRadius: "8px",
				}}
			>
				<Typography variant="h5" style={{color: "#666"}}>
					Choose from HotQr's templates.
				</Typography>
				<Box style={{marginTop: "20px", display: "flex", flexWrap: "wrap"}}>
					{templatesArray.map((file, index) => (
						<Card
							key={index}
							style={{marginRight: "20px", cursor: "pointer"}}
							elevation={activeTemplate === index ? 10 : 2}
							onClick={() => {
								setActiveTemplate(index);
								setLoadQr(!loadQr);
								handleData({template: index + 1}, 0);
							}}
						>
							<img
								alt=""
								src={file.template}
								style={{height: "120px", width: "120px", objectFit: "contain"}}
							/>
						</Card>
					))}
				</Box>
			</Box>
		</Box>
	);
}

export default Template;
