import * as React from "react";
import {Box, Grid} from "@material-ui/core";

import classes from "./Page2.module.css";

const gridArray = [
	{
		title: "Customize QR Code Design",
		text: "Add brand colors, logo and patterns to QR Code or combine an image and a semi-transparent QR Code",
	},
	{
		title: "Highly Scannable Static QR Codes",
		text: "Encode static QR Codes with URL, text, Vcard, WiFi, Phone, Email, and SMS content",
	},
	{
		title: "Real-time Generation",
		text: "Generate QR Codes programmatically in your own system via REST API calls",
	},
	{
		title: "Million QR Codes/month",
		text: "Generate upto a million QR Codes per month via API requests",
	},
	{
		title: "Generate for Web or Print",
		text: "Generate high-resolution QR Codes in PNG, JPG, and SVG, suitable for both web and print",
	},
	{
		title: "Flexible Pricing",
		text: "Suitable plans for low, medium, and high number of API requests",
	},
];

function Page2() {
	return (
		<Box className={classes.box}>
			<Grid container>
				<Grid item xs={12} className="d-flex flex-column align-items-center">
					<span className={classes.boxTitle}>
						HOT QR Code API — Design and Other Features
					</span>
					<span className={classes.boxsubTitle}>
						Add design and branding to standard B&W QR Codes and do much more
						with QR API
					</span>
				</Grid>
				<Grid item container xs={12} spacing={3} className="mt-2">
					{gridArray.map((ele, index) => (
						<Grid item xs={4} key={index}>
							<Box className={classes.labelText}>
								<span style={{color: "#000000"}}>{ele.title}</span>
								<span className="mt-3">{ele.text}</span>
							</Box>
						</Grid>
					))}
				</Grid>
			</Grid>
		</Box>
	);
}

export default Page2;
