// import React from "react";
// import {isMobile} from "react-device-detect";

function AppStoreComp(props) {
	// const {data} = props;

	// const {playStoreUrl, appStoreUrl} = data;

	// const goToUrl = React.useCallback(
	// 	(device) => {
	// 		switch (device) {
	// 			case "Android":
	// 				window.location.href = playStoreUrl;
	// 				break;
	// 			case "iOS":
	// 				window.location.href = appStoreUrl;
	// 				break;
	// 			case "pc":
	// 				window.location.href = playStoreUrl;
	// 				break;
	// 			default:
	// 				window.location.href = playStoreUrl;
	// 				break;
	// 		}
	// 	},
	// 	[playStoreUrl, appStoreUrl]
	// );

	// const Detect = React.useCallback(() => {
	// 	if (isMobile) {
	// 		var userAgent = navigator.userAgent || navigator.vendor || window.opera;
	// 		if (/android/i.test(userAgent)) {
	// 			goToUrl("Android");
	// 		}
	// 		if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
	// 			goToUrl("iOS");
	// 		}
	// 	} else {
	// 		goToUrl("pc");
	// 	}
	// }, [goToUrl]);

	// React.useEffect(() => {
	//     Detect()
	// },[Detect])

	return null;
}

export default AppStoreComp;
