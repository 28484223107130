import React from "react";

// ----------------- import Components ------------------ //

import {
	Box,
	CircularProgress,
	Container,
	Typography,
	Button,
	TextField,
	Paper,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TablePagination,
	useTheme,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Snackbar,
	Alert,
	InputAdornment,
} from "@material-ui/core";
// import { Fade }
import {makeStyles} from "@material-ui/core-v4";
import {useNavigate} from "react-router";
import Lottie from "lottie-react";
import Select from "react-select";
import SwitchSelector from "react-switch-selector";
import Moment from "moment";

// ----------------- import Files ------------------ //
import error from "../../../../Assets/Animation/error.json";
import notFound from "../../../../Assets/Animation/notFound.json";
import empty from "../../../../Assets/Animation/empty.json";

// ----------------- import icons ------------------ //
import {BsTrashFill, MdRecycling, IoClose} from "react-icons/all";
import {
	FirstPage,
	LastPage,
	KeyboardArrowLeft,
	KeyboardArrowRight,
} from "@material-ui/icons";

// ----------------- import API's ------------------ //
import {
	filterQrCode,
	updateQrCode,
	deleteQrCode,
} from "../Services/DashboardServices/ApiUrl";

import AuthContext from "../../../../Context/AuthContext";

const useStyles = makeStyles((theme) => ({
	boxTextField: {
		"& div > div": {
			height: "35px",
		},
		"& div > div > input": {
			marginTop: "-4px",
		},
		"& div > label": {
			marginTop: "-12px",
		},
		"& div > .MuiInputLabel-shrink": {
			marginTop: "-15px",
		},
	},
	root: {
		"& > *": {
			borderBottom: "unset",
		},
	},
	gridItem: {
		height: "70px",
	},
	gridItemTitle: {
		height: "70px",
		padding: "7px 0",
	},
	gridItemTypo: {
		height: "100%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		color: "#858585",
		fontWeight: "bold",
	},
	gray: {
		color: theme.palette.primary.main,
	},
	graylight: {
		color: "#787878",
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 150,
	},
	tableCell: {
		// padding: '10px 16px !important'
		width: "305px",
	},

	// Pagination CSS

	rootPage: {
		flexShrink: 0,
	},

	modalStyles: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		bgcolor: "background.paper",
		boxShadow: 24,
		p: 4,
	},
	tableRowPagination: {
		"& td": {
			border: "0",
		},
		"& td > div > .MuiTablePagination-selectLabel": {
			marginTop: "10px",
		},
		"& td > div > div": {
			marginTop: "-4px",
			marginRight: "12px",
		},
		"& td > div > .MuiTablePagination-displayedRows": {
			marginTop: "12px",
			marginRight: "8px",
		},
	},
}));

function Dashboard(props) {
	const navigate = useNavigate();
	const classes = useStyles();

	const context = React.useContext(AuthContext);

	// ---------------------------------------- Manage All React State Hooks ------------------------------------ //

	const [loadPage, setLoadPage] = React.useState();
	const [state, setState] = React.useState({
		qrTypeId: "",
		qrCodeType: "",
		keyword: "",
	});
	const [qrData, setQrData] = React.useState();
	const [count, setCount] = React.useState();
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const [openLoadingModal, setOpenLoadingModal] = React.useState(false);
	const [openTrashDialog, setOpenTrashDialog] = React.useState(false);
	const [snackbar, setSnackbar] = React.useState({
		open: false,
		message: "",
		severity: "success",
	});
	const [itemId, setItemId] = React.useState(false);
	const [openRestoreDialog, setOpenRestoreDialog] = React.useState(false);

	// ---------------------------------------- Get All Qr Type Api Call ------------------------------------ //

	// ---------------------------------------- Check Login ------------------------------------ //

	const checkLogin = React.useCallback(() => {
		if (!localStorage.getItem("token")) {
			navigate("/login");
		}
	}, [navigate]);

	// ---------------------------------------- Get All Qr Code by Filter Api Call ------------------------------------ //

	const filterQrCodeApiCall = React.useCallback(
		(page, pageSize, qrTypeId, type, keyword) => {
			filterQrCode({
				payload: {
					page: page,
					pageSize: pageSize,
					qrTypeId: qrTypeId,
					type: type,
					tags: keyword,
					trash: true,
				},
			})
				.then((res) => {
					setQrData(res);
					setCount(res.Count);
					if (res.Items && res.Items.length > 0) {
						setLoadPage("Load");
					} else {
						setLoadPage("NoData");
					}
					setOpenLoadingModal(false);
					setOpenTrashDialog(false);
					// setSnackbar({
					//     open: true,
					//     message: res.ScanCount > 0 ? 'Qr Codes Load Successfully' : 'Create New Qr Code',
					//     severity: res.ScanCount > 0 ? 'success' : 'info'
					// })
				})
				.catch((err) => {
					setQrData();
					setOpenLoadingModal(false);
					setLoadPage("NotFound");
					setSnackbar({
						open: true,
						message: "Error when Load Qr Codes",
						severity: "error",
					});
				});
		},
		[]
	);

	React.useEffect(() => {
		checkLogin();
		if (context.qrTypes && context.qrTypes.length > 0 && context.license) {
			filterQrCodeApiCall(0, 10);
		}
	}, [checkLogin, filterQrCodeApiCall, context]);

	// ---------------------------------------- Permanent Delete Api Call ------------------------------------ //

	const deleteQr = React.useCallback(() => {
		setOpenLoadingModal(true);
		deleteQrCode(itemId)
			.then((res) => {
				filterQrCodeApiCall(
					page,
					rowsPerPage,
					state.qrTypeId,
					state.qrCodeType
				);
				setSnackbar({
					open: true,
					message: "Qr Code Permanently Deleted Successfully",
					severity: "success",
				});
			})
			.catch((err) => {
				console.error(err);
				setOpenLoadingModal(false);
				setSnackbar({
					open: true,
					message: "Error while Qr Code Permanently Delete",
					severity: "error",
				});
			});
	}, [
		itemId,
		filterQrCodeApiCall,
		page,
		rowsPerPage,
		state.qrTypeId,
		state.qrCodeType,
	]);

	// ---------------------------------------- Restore Api Call ------------------------------------ //

	const restoreQr = React.useCallback(() => {
		setOpenLoadingModal(true);
		updateQrCode({payload: {trash: false}}, itemId)
			.then((res) => {
				filterQrCodeApiCall(
					page,
					rowsPerPage,
					state.qrTypeId,
					state.qrCodeType
				);
				setSnackbar({
					open: true,
					message: "Qr Code Restored Successfully",
					severity: "success",
				});
			})
			.catch((err) => {
				console.error(err);
				setOpenLoadingModal(false);
				setSnackbar({
					open: true,
					message: "Error while Qr Code Restore",
					severity: "error",
				});
			});
	}, [
		itemId,
		filterQrCodeApiCall,
		page,
		rowsPerPage,
		state.qrTypeId,
		state.qrCodeType,
	]);

	// ---------------------------------------- Handle Chnage Type Id ------------------------------------ //

	// ---------------------------------------- Handle Chnage Type Id ------------------------------------ //

	const handleChangeType = (event) => {
		setQrData();
		setLoadPage();
		setState((prevState) => ({
			...prevState,
			qrTypeId: event ? (event.value ? event.value : "") : "",
		}));
		filterQrCodeApiCall(
			page,
			rowsPerPage,
			event ? (event.value ? event.value : "") : "",
			state.qrCodeType,
			state.keyword
		);
	};

	// ---------------------------------------- Handle Qr Type Type ------------------------------------ //

	const handleChangeQrType = (event) => {
		setLoadPage();
		setQrData();
		setState((prevState) => ({...prevState, qrCodeType: event ? event : ""}));
		filterQrCodeApiCall(
			page,
			rowsPerPage,
			state.qrTypeId,
			event,
			state.keyword
		);
	};

	// ---------------------------------------- Handle Chnage Keyword ------------------------------------ //

	const handleChangeKeyword = (event) => {
		setState((prevState) => ({
			...prevState,
			keyword: event.target.value ? event.target.value : "",
		}));
	};

	const handleKeyword = () => {
		setQrData();
		setLoadPage();
		filterQrCodeApiCall(
			page,
			rowsPerPage,
			state.qrTypeId,
			state.qrCodeType,
			state.keyword
		);
	};

	const handleRemoveKeyword = () => {
		setQrData();
		setLoadPage();
		setState((prevState) => ({...prevState, keyword: ""}));
		filterQrCodeApiCall(
			page,
			rowsPerPage,
			state.qrTypeId,
			state.qrCodeType,
			""
		);
	};

	// ---------------------------------------- Change Page Function ------------------------------------ //

	const handleChangePage = (event, newPage) => {
		setQrData();
		setPage(newPage);
	};

	// ---------------------------------------- Change Row per Page Function Call API ------------------------------------ //

	const handleChangeRowsPerPage = (event) => {
		setQrData();
		setRowsPerPage(event.target.value);
		filterQrCodeApiCall(
			page,
			event.target.value,
			state.qrTypeId,
			state.qrCodeType,
			state.keyword
		);
	};

	// ----------------------------------------------------------- Pagination Component Start -------------------------------------------------- //

	const TablePaginationActions = (props) => {
		const theme = useTheme();

		const {count, page, rowsPerPage, onPageChange} = props;

		const handleFirstPageButtonClick = (event) => {
			onPageChange(event, 0);
			filterQrCodeApiCall(
				0,
				rowsPerPage,
				state.qrTypeId,
				state.qrCodeType,
				state.keyword
			);
		};

		const handleBackButtonClick = (event) => {
			onPageChange(event, page - 1);
			filterQrCodeApiCall(
				page - 1,
				rowsPerPage,
				state.qrTypeId,
				state.qrCodeType,
				state.keyword
			);
		};

		const handleNextButtonClick = (event) => {
			onPageChange(event, page + 1);
			filterQrCodeApiCall(
				page + 1,
				rowsPerPage,
				state.qrTypeId,
				state.qrCodeType,
				state.keyword
			);
		};

		const handleLastPageButtonClick = (event) => {
			onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
			filterQrCodeApiCall(
				Math.max(0, Math.ceil(count / rowsPerPage) - 1),
				rowsPerPage,
				state.qrTypeId,
				state.qrCodeType,
				state.keyword
			);
		};

		return (
			<div className={classes.rootPage}>
				<IconButton
					onClick={handleFirstPageButtonClick}
					disabled={page === 0}
					aria-label="first page"
				>
					{theme.direction === "rtl" ? <LastPage /> : <FirstPage />}
				</IconButton>
				<IconButton
					onClick={handleBackButtonClick}
					disabled={page === 0}
					aria-label="previous page"
				>
					{theme.direction === "rtl" ? (
						<KeyboardArrowRight />
					) : (
						<KeyboardArrowLeft />
					)}
				</IconButton>
				<IconButton
					onClick={handleNextButtonClick}
					disabled={page >= Math.ceil(count / rowsPerPage) - 1}
					aria-label="next page"
				>
					{theme.direction === "rtl" ? (
						<KeyboardArrowLeft />
					) : (
						<KeyboardArrowRight />
					)}
				</IconButton>
				<IconButton
					onClick={handleLastPageButtonClick}
					disabled={page >= Math.ceil(count / rowsPerPage) - 1}
					aria-label="last page"
				>
					{theme.direction === "rtl" ? <FirstPage /> : <LastPage />}
				</IconButton>
			</div>
		);
	};

	// ----------------------------------------------------------- Pagination Component End -------------------------------------------------- //

	// ----------------------------------------------------------- Header Component Start -------------------------------------------------- //

	const HeaderComponent = () => {
		return (
			<Container maxWidth="xl" style={{minHeight: "auto", marginTop: "20px"}}>
				<Box
					style={{
						display: "flex",
						width: "100%",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<Typography color="primary" variant="h2">
						Trash
					</Typography>
				</Box>
				<Box
					style={{
						display: "flex",
						width: "100%",
						justifyContent: "space-between",
						marginTop: "30px",
					}}
				>
					<Box
						style={{width: "400px", display: "flex", alignItems: "center"}}
						className={classes.boxTextField}
					>
						<TextField
							type="text"
							onChange={handleChangeKeyword}
							value={state.keyword}
							color="primary"
							label="Serch By QR Name"
							placeholder="Serch By QR Name"
							fullWidth
							className={""}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											onClick={handleRemoveKeyword}
											style={{
												padding: "0",
												marginTop: "-5px",
												marginRight: "-6px",
											}}
										>
											<IoClose />
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
						<Button
							color="primary"
							variant="outlined"
							onClick={handleKeyword}
							style={{marginLeft: "10px", height: "38px", marginTop: "-5px"}}
						>
							Search
						</Button>
					</Box>
					<Box style={{display: "flex"}}>
						<Box style={{width: "300px"}}>
							<Select
								onChange={handleChangeType}
								width="300px"
								maxMenuHeight="240px"
								placeholder="View By Category"
								isClearable={true}
								menuShouldScrollIntoView={true}
								closeMenuOnScroll={true}
								value={state.qrType}
								theme={(theme) => ({
									...theme,
									colors: {
										...theme.colors,
										text: "#FFF",
										primary25: "#FF800C",
										primary: "#FF800C",
									},
								})}
								options={
									context &&
									context.qrTypes.length > 0 &&
									context.qrTypes.map((ele) => {
										return {value: ele.id, label: ele.title};
									})
								}
							/>
						</Box>
						<Box style={{width: "300px", marginLeft: "20px"}}>
							<div
								className="your-required-wrapper"
								style={{width: 300, height: 38}}
							>
								<SwitchSelector
									onChange={handleChangeQrType}
									options={[
										{label: "ALL", value: ""},
										{label: "STATIC", value: "STATIC"},
										{label: "DYNAMIC", value: "DYNAMIC"},
									]}
									initialSelectedIndex={0}
									backgroundColor="#353b48"
									fontColor="#f5f6fa"
									selectedBackgroundColor="#FF800C"
								/>
							</div>
						</Box>
					</Box>
				</Box>
			</Container>
		);
	};

	// ----------------------------------------------------------- Header Component End -------------------------------------------------- //

	// ----------------------------------------------------------- Switch Case Main Return Start -------------------------------------------------- //

	switch (loadPage) {
		case "Load":
			return (
				<>
					{HeaderComponent()}
					<Container
						maxWidth="xl"
						style={{minHeight: "80vh", marginTop: "40px"}}
					>
						<Paper style={{padding: "10px 0"}}>
							{qrData ? (
								qrData.Items.length === 0 ? (
									<div
										style={{
											height: "500px",
											width: "100%",
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											flexDirection: "column",
										}}
									>
										<Typography style={{fontSize: "18px", color: "#666"}}>
											Looks like you haven’t created any QR Codes yet. Create
											your first QR Code now.
										</Typography>
									</div>
								) : (
									<TableContainer style={{width: "100%"}}>
										<Table aria-label="collapsible table">
											<TableHead>
												<TableRow>
													<TableCell width="400">
														<Typography variant="h5" color="primary">
															<b>Name of QR Code</b>
														</Typography>
													</TableCell>
													<TableCell width="250" style={{textAlign: "center"}}>
														<Typography variant="h5" color="primary">
															<b>Category</b>
														</Typography>
													</TableCell>
													<TableCell width="200" style={{textAlign: "center"}}>
														<Typography variant="h5" color="primary">
															<b>QR Type</b>
														</Typography>
													</TableCell>
													<TableCell width="300" style={{textAlign: "center"}}>
														<Typography variant="h5" color="primary">
															<b>Created On</b>
														</Typography>
													</TableCell>
													<TableCell width="200" style={{textAlign: "center"}}>
														<Typography variant="h5" color="primary">
															<b>Total Scan</b>
														</Typography>
													</TableCell>
													<TableCell width="250" style={{textAlign: "center"}}>
														<Typography variant="h5" color="primary">
															<b>Actions</b>
														</Typography>
													</TableCell>
													<TableCell />
												</TableRow>
											</TableHead>
											<TableBody>
												{qrData.Items.map((item, index) => {
													// if(!item.trash){
													return (
														<React.Fragment key={index}>
															<TableRow className={classes.root}>
																{/* <TableCell component="th" scope="row" className={classes.tableCell}><Typography variant='body1' color='primary'><b>{item.orderNumber}</b></Typography></TableCell> */}
																<TableCell width="400" style={{padding: "8px"}}>
																	<Box
																		style={{
																			display: "flex",
																			alignItems: "center",
																			height: "50px",
																			marginLeft: "8px",
																		}}
																	>
																		<img
																			alt=""
																			src={item.qr && item.qr}
																			style={{
																				height: "50px",
																				objectFit: "contain",
																				marginRight: "10px",
																			}}
																		/>
																		<Typography
																			variant="body1"
																			className={classes.graylight}
																		>
																			<b>{item.title ? item.title : "Name"}</b>
																		</Typography>
																	</Box>
																</TableCell>
																<TableCell
																	width="250"
																	style={{padding: "8px", textAlign: "center"}}
																>
																	<Typography
																		variant="body1"
																		className={classes.graylight}
																	>
																		<b>
																			{item.qrType && item.qrType.title
																				? item.qrType.title
																				: ""}
																		</b>
																	</Typography>
																</TableCell>
																<TableCell
																	width="200"
																	style={{padding: "8px", textAlign: "center"}}
																>
																	<Typography
																		variant="body1"
																		className={classes.graylight}
																	>
																		<b>{item && item.type ? item.type : ""}</b>
																	</Typography>
																</TableCell>
																<TableCell
																	width="300"
																	style={{padding: "8px", textAlign: "center"}}
																>
																	<Typography
																		variant="body1"
																		className={classes.graylight}
																	>
																		<b>
																			{Moment(item.createdOn).format("l LT")}
																		</b>
																	</Typography>
																</TableCell>
																<TableCell
																	width="200"
																	style={{padding: "8px", textAlign: "center"}}
																>
																	<Typography
																		variant="body1"
																		className={classes.graylight}
																	>
																		<b>{item.scanCount}</b>
																	</Typography>
																</TableCell>
																<TableCell
																	width="250"
																	style={{padding: "8px", textAlign: "center"}}
																>
																	<Box
																		style={{
																			display: "flex",
																			justifyContent: "center",
																		}}
																	>
																		<IconButton
																			style={{
																				border: "1px solid #888",
																				borderRadius: "8px",
																			}}
																			onClick={() => {
																				setOpenRestoreDialog(true);
																				setItemId(item.id);
																			}}
																		>
																			<MdRecycling />
																		</IconButton>
																		<IconButton
																			style={{
																				border: "1px solid #888",
																				borderRadius: "8px",
																				marginLeft: "15px",
																			}}
																			onClick={() => {
																				setOpenTrashDialog(true);
																				setItemId(item.id);
																			}}
																		>
																			<BsTrashFill />
																		</IconButton>
																	</Box>
																</TableCell>
															</TableRow>
														</React.Fragment>
													);
													// }
												})}
											</TableBody>
										</Table>
										<TableRow
											className={classes.tableRowPagination}
											style={{
												display: "flex",
												justifyContent: "flex-end",
												width: "98%",
												marginTop: "8px",
												alignItems: "center",
											}}
										>
											<TablePagination
												rowsPerPageOptions={[5, 10, 25]}
												colSpan={3}
												count={count}
												rowsPerPage={rowsPerPage}
												page={page}
												SelectProps={{
													inputProps: {"aria-label": "rows per page"},
													native: true,
												}}
												onPageChange={handleChangePage}
												onRowsPerPageChange={handleChangeRowsPerPage}
												ActionsComponent={TablePaginationActions}
											/>
										</TableRow>
									</TableContainer>
								)
							) : (
								<div
									style={{
										height: "500px",
										width: "100%",
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									<CircularProgress />
								</div>
							)}
						</Paper>

						<Dialog open={openTrashDialog}>
							{openLoadingModal ? (
								<DialogContent>
									<Box
										style={{
											height: "100px",
											width: "100px",
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
										}}
									>
										<CircularProgress />
									</Box>
								</DialogContent>
							) : (
								<>
									<DialogTitle>{"Permanent Delete QR Code"}</DialogTitle>
									<DialogContent>
										<DialogContentText>
											Can you delete this qr permanently ?
										</DialogContentText>
									</DialogContent>
									<DialogActions>
										<Button
											onClick={() => {
												setOpenTrashDialog(false);
											}}
											variant="contained"
											style={{color: "#FFF", background: "#c72a2a"}}
										>
											Cancle
										</Button>
										<Button
											onClick={deleteQr}
											variant="contained"
											style={{color: "#FFF", background: "#1d8f48"}}
										>
											Yes, I'm Sure
										</Button>
									</DialogActions>
								</>
							)}
						</Dialog>

						<Dialog open={openRestoreDialog}>
							{openLoadingModal ? (
								<DialogContent>
									<Box
										style={{
											height: "100px",
											width: "100px",
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
										}}
									>
										<CircularProgress />
									</Box>
								</DialogContent>
							) : (
								<>
									<DialogTitle>{"Restore QR Code"}</DialogTitle>
									<DialogContent>
										<DialogContentText>
											Can you Restore this QR Code ?
										</DialogContentText>
									</DialogContent>
									<DialogActions>
										<Button
											onClick={() => {
												setOpenRestoreDialog(false);
											}}
											variant="contained"
											style={{color: "#FFF", background: "#c72a2a"}}
										>
											Cancle
										</Button>
										<Button
											onClick={restoreQr}
											variant="contained"
											style={{color: "#FFF", background: "#1d8f48"}}
										>
											Yes, Restore it
										</Button>
									</DialogActions>
								</>
							)}
						</Dialog>

						<Snackbar
							open={snackbar.open}
							anchorOrigin={{vertical: "top", horizontal: "right"}}
							autoHideDuration={5000}
							onClose={() => {
								setSnackbar({open: false});
							}}
						>
							<Alert
								onClose={() => {
									setSnackbar({open: false});
								}}
								severity={snackbar.severity}
								sx={{width: "100%"}}
							>
								{snackbar.message}
							</Alert>
						</Snackbar>

						{/* ---------------------------------------------------------- Table End ----------------------------------------------------------------- */}
					</Container>
				</>
			);
		case "NoData":
			return (
				<>
					{HeaderComponent()}
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							height: "80vh",
							width: "100%",
							flexDirection: "column",
						}}
					>
						<Lottie animationData={empty} style={{height: 300, color: "red"}} />
						<Typography style={{fontSize: "25px"}} color="primary">
							<b>No QR Codes in Trash</b>
						</Typography>
					</div>
				</>
			);
		case "NotFound":
			return (
				<>
					{HeaderComponent()}
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							height: "80vh",
							width: "100%",
						}}
					>
						<Lottie animationData={notFound} style={{height: 300}} />
					</div>
				</>
			);
		case "Error":
			return (
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "80vh",
						width: "100%",
					}}
				>
					<Lottie animationData={error} style={{height: 300}} />
				</div>
			);
		default:
			return (
				<>
					{HeaderComponent()}
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							height: "80vh",
							width: "100%",
						}}
					>
						<CircularProgress />
					</div>
				</>
			);
	}

	// ----------------------------------------------------------- Switch Case Main Return Start -------------------------------------------------- //
}

export default Dashboard;
