import React from "react";

import QrContext from "../../QrContext/QrContext";

// ------------------------- Import Fields Components ------------------- //

import PageTitle from "./Fields/PageTitle";
import Button from "./Fields/Button";
import DescriptionBox from "./Fields/DescriptionBox";
import MapLocationButton from "./Fields/MapLocationButton";
import BannerImage from "./Fields/BannerImage";
import EventVanue from "./Fields/EventVanue";
import EventSchedule from "./Fields/EventSchedule";
import CoupleName from "./Fields/CoupleName";
import WebsiteUrl from "./Fields/WebsiteUrl";
import GoogleMap from "./Fields/GoogleMap";
import Image from "./Fields/Image";
import Ducument from "./Fields/Ducument";
import Audio from "./Fields/Audio";
import ContactDetails from "./Fields/ContactDetails";
import ProfileInfo from "./Fields/ProfileInfo";
import FileButton from "./Fields/FileButton";
import CompanyBranding from "./Fields/CompanyBranding";
import CouponDetails from "./Fields/CouponDetails";
import BrandLogo from "./Fields/BrandLogo";
import SocialMedia from "./Fields/SocialMedia";
import Paypal from "./Fields/Paypal";
import AppStore from "./Fields/AppStore";
import AppDeepLink from "./Fields/AppDeepLink";
import LeadForm from "./Fields/LeadForm";

function Fields() {
	const {content} = React.useContext(QrContext);

	return (
		<React.Fragment>
			{content.length > 0 &&
				content.map((ele, index) => {
					switch (ele.type) {
						case "PageTitle":
							return <PageTitle key={index} data={ele} />;
						case "BannerImage":
							return <BannerImage key={index} data={ele} />;
						case "Image":
							return <Image key={index} data={ele} />;
						case "Document":
							return <Ducument key={index} data={ele} />;
						case "Audio":
							return <Audio key={index} data={ele} />;
						case "Button":
							return <Button key={index} data={ele} />;
						case "EventVanue":
							return <EventVanue key={index} data={ele} />;
						case "EventSchedule":
							return <EventSchedule key={index} data={ele} />;
						case "CoupleName":
							return <CoupleName key={index} data={ele} />;
						case "DescriptionBox":
							return <DescriptionBox key={index} data={ele} />;
						case "MapLocationButton":
							return <MapLocationButton key={index} data={ele} />;
						case "WebsiteUrl":
							return <WebsiteUrl key={index} data={ele} />;
						case "GoogleMap":
							return <GoogleMap key={index} data={ele} />;
						case "ContactDetails":
							return <ContactDetails key={index} data={ele} />;
						case "ProfileInfo":
							return <ProfileInfo key={index} data={ele} />;
						case "Files":
							return <FileButton key={index} data={ele} />;
						case "AppStore":
							return <AppStore key={index} data={ele} />;
						case "CompanyBranding":
							return <CompanyBranding key={index} data={ele} />;
						case "CouponDetails":
							return <CouponDetails key={index} data={ele} />;
						case "BrandLogo":
							return <BrandLogo key={index} data={ele} />;
						case "SocialMedia":
							return <SocialMedia key={index} data={ele} />;
						case "Paypal":
							return <Paypal key={index} data={ele} />;
						case "AppDeepLink":
							return <AppDeepLink key={index} data={ele} />;
						case "LeadForm":
							return <LeadForm key={index} data={ele} />;
						default:
							return null;
					}
				})}
		</React.Fragment>
	);
}

export default Fields;
