import React from "react";
// ------------------------ import Components --------------------------- //
import {
	Box,
	Grid,
	CircularProgress,
	Container,
	Typography,
	Card,
	Button,
	Tooltip,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core-v4";
import {useNavigate} from "react-router";
import SwitchSelector from "react-switch-selector";
// ----------------- import icons ------------------ //
import {BsInfoCircleFill, MdVerified} from "react-icons/all";
// ------------------------ import Context --------------------------- //
import AuthContext from "../../../../../Context/AuthContext";
// import subscriptionPlan from '../subscriptionPlan';
import SubscribeModal from "./SubscribeModal";
import {
	getAllPlans,
	getComparisonData,
} from "../../Services/LicenseServices/ApiUrl";

// ------------------------ Custom makeStyle --------------------------- //

const useStyles = makeStyles((theme) => ({
	iconInfo: {
		color: "#999",
		"&:hover": {
			color: "#C86409",
		},
	},
	center: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	selectSwitch: {
		width: 600,
		height: 38,
		marginTop: "30px",
		"& div": {
			borderRadius: "6px",
		},
	},
}));

// ------------------------ Main Function --------------------------- //

function PlansPricing(props) {
	const navigate = useNavigate();
	const classes = useStyles();

	const {goToSubscription} = props;

	const [plans, setPlans] = React.useState([]);
	const [comparisonData, setComparisonData] = React.useState([]);

	const [plan, setPlan] = React.useState(true);
	const [subscribeModalState, setSubscribeModalState] = React.useState({
		open: false,
		data: "",
	});

	const getAllPlansApiCall = React.useCallback(() => {
		getAllPlans()
			.then((res) => {
				setPlans(res.Items);
			})
			.catch((err) => {
				console.error(err);
			});
	}, []);

	const getComparisonDataApiCall = React.useCallback(() => {
		getComparisonData()
			.then((res) => {
				setComparisonData(res.Item);
			})
			.catch((err) => {
				console.error(err);
			});
	}, []);

	React.useEffect(() => {
		getAllPlansApiCall();
		getComparisonDataApiCall();
	}, [getAllPlansApiCall, getComparisonDataApiCall]);

	const handleClickSubscribe = (data) => {
		if (localStorage.getItem("token")) {
			setSubscribeModalState({
				open: true,
				data: data,
			});
		} else {
			navigate("/login");
		}
	};

	function dynamicSort(property) {
		var sortOrder = 1;
		if (property[0] === "-") {
			sortOrder = -1;
			property = property.substr(1);
		}
		return function (a, b) {
			var result =
				a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
			return result * sortOrder;
		};
	}

	if (
		plans &&
		plans.length > 0 &&
		comparisonData &&
		comparisonData.features &&
		comparisonData.features.length > 0 &&
		comparisonData.plans &&
		comparisonData.plans.length > 0
	) {
		return (
			<Container maxWidth="xl" style={{margin: "30px auto"}}>
				{/* <Box style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography variant='h1' color='primary'>
                        <b>HotQR Plans & Pricing</b>
                    </Typography>
                    <Typography variant='h4' style={{ color: '#666' }}>
                        HotQR is accessible to all types of users at flexible pricing and payment options
                    </Typography>
                </Box> */}
				<Box
					style={{
						width: "100%",
						margin: "30px 0 0 0",
						display: "flex",
						flexDirection: "column",
						color: "#666",
					}}
				>
					<Typography variant="h5">Choose Billing Period</Typography>
					<div
						className="your-required-wrapper"
						style={{width: 300, height: 38, marginTop: "10px"}}
					>
						<SwitchSelector
							onChange={(e) => {
								setPlan(e);
							}}
							options={[
								{label: "Monthly", value: true},
								{label: "Annually", value: false},
							]}
							initialSelectedIndex={0}
							backgroundColor="#666"
							fontColor="#f5f6fa"
							selectedBackgroundColor="#FF800C"
						/>
					</div>
				</Box>
				<Box style={{marginTop: "30px"}}>
					<Grid container spacing={3}>
						{plans &&
							plans.length > 0 &&
							plans.sort(dynamicSort("sequence")).map((ele, index) => {
								if (
									ele.title.toLowerCase() !== "free" &&
									ele.title.toLowerCase() !== "ultra lite"
								) {
									return (
										<Grid item lg={3} md={3} sm={6} xs={12} key={index}>
											<Card>
												<Typography
													color="primary"
													variant="h4"
													style={{
														height: "60px",
														borderBottom: "1px solid",
														display: "flex",
														justifyContent: "center",
														alignItems: "center",
													}}
												>
													{ele.title}
												</Typography>
												<Box
													style={{
														height: "220px",
														display: "flex",
														alignItems: "center",
														flexDirection: "column",
														justifyContent: "center",
													}}
												>
													<Typography
														style={{
															color: "#444",
															display: "flex",
															alignItems: "center",
															flexDirection: "column",
														}}
													>
														{ele.title.toLowerCase() === "enterprise" ? (
															<b
																style={{
																	color: "#444",
																	fontSize: "19px",
																	marginBottom: "20px",
																}}
															>
																Request for Quote
															</b>
														) : (
															<React.Fragment>
																<b style={{color: "#444", fontSize: "25px"}}>
																	<span style={{fontSize: "18px"}}>KSh</span>{" "}
																	{plan ? ele.monthly_cost : ele.annual_cost}
																</b>
																<p style={{fontSize: "18px"}}>per Month</p>
															</React.Fragment>
														)}
														{!plan && ele.title.toLowerCase() !== "enterprise" && (
															<p
																style={{
																	fontSize: "15px",
																	color: "#666",
																	marginTop: "-15px",
																}}
															>
																KSh {ele.total_year_cost} billed every year
															</p>
														)}
													</Typography>
													<Button
														color="primary"
														variant="contained"
														onClick={() => {
															if (ele.title.toLowerCase() !== "enterprise") {
																handleClickSubscribe({
																	...ele,
																	plan,
																});
															}
														}}
													>
														{ele.title.toLowerCase() === "enterprise"
															? "Contact Us"
															: "Subscribe"}
													</Button>
													<Typography variant="h5" style={{marginTop: "15px"}}>
														Create{" "}
														<b style={{color: "#C86409"}}>
															{ele.qrcode_limit === "max"
																? "Unlimited"
																: ele.qrcode_limit}
														</b>{" "}
														QR Codes
													</Typography>
												</Box>
												<Typography
													variant="h5"
													style={{
														height: "40px",
														background: "#E0E0E0",
														color: "#585858",
														display: "flex",
														alignItems: "center",
														paddingLeft: "15px",
													}}
												>
													Features
												</Typography>
												<Typography
													style={{
														color: "#585858",
														padding: "15px 25px 0 15px",
														height: "420px",
													}}
												>
													{ele.features &&
														ele.features.length > 0 &&
														ele.features.map((item, index) => (
															<Box
																key={index}
																style={{
																	display: "flex",
																	justifyContent: "space-between",
																}}
															>
																<p>{item.feature_name}</p>
																<Tooltip
																	title={
																		<span style={{fontSize: "15px"}}>
																			{item.support_text}
																		</span>
																	}
																	arrow
																	placement="right"
																>
																	<Box>
																		<BsInfoCircleFill
																			className={classes.iconInfo}
																		/>
																	</Box>
																</Tooltip>
															</Box>
														))}
												</Typography>
											</Card>
										</Grid>
									);
								} else {
									return null;
								}
							})}
						{plans &&
							plans.length > 0 &&
							plans?.map((ele, index) => {
								if (ele.lifetime_cost) {
									return (
										<Grid item xs={12} key={index}>
											<Card>
												<Grid container spacing={1}>
													<Grid item xs={1}>
														<Typography
															variant="h3"
															color="primary"
															style={{
																height: "100px",
																borderRadius: "8px",
																textAlign: "center",
																marginLeft: "10px",
															}}
															className={classes.center}
														>
															{ele.title}
														</Typography>
													</Grid>
													<Grid item xs={2}>
														<Typography
															style={{
																height: "100px",
																flexDirection: "column",
																textAlign: "center",
																color: "#555",
															}}
															className={classes.center}
														>
															<b style={{fontSize: "25px"}}>
																<span style={{fontSize: "16px"}}>KSh</span>
																{ele.lifetime_cost}
															</b>
															<span style={{fontSize: "15px"}}>
																for lifetime validity
															</span>
														</Typography>
													</Grid>
													<Grid item xs={7}>
														<Typography
															style={{
																height: "100px",
																flexDirection: "column",
																alignItems: "flex-start",
																color: "#555",
															}}
															className={classes.center}
														>
															<b>Need a single Dynamic QR Code permanently?</b>
															<span>
																Pay a one-time fee and create a single Dynamic
																QR Code with lifetime validity. This plan
																includes only 1 Dynamic QR Code and all LITE
																plan features.
															</span>
														</Typography>
													</Grid>
													<Grid item xs={2} className={classes.center}>
														<Button
															variant="contained"
															color="primary"
															onClick={() => handleClickSubscribe("ultraLite")}
														>
															Purchase deal
														</Button>
													</Grid>
												</Grid>
											</Card>
										</Grid>
									);
								} else return null;
							})}
					</Grid>
				</Box>
				<Box style={{marginTop: "50px", width: "100%"}}>
					<Typography
						color="primary"
						variant="h4"
						style={{
							borderTop: "2px solid ",
							borderBottom: "2px solid",
							height: "80px",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<span style={{color: "#444"}}>
							Not sure which plan is right for you?
						</span>{" "}
						<span style={{marginLeft: "20px"}}>
							SEE COMPLETE PLAN COMPARISON
						</span>
					</Typography>
					<Box style={{marginTop: "30px", background: "#FFF"}}>
						<Grid container>
							<Grid item xs={12}>
								<Grid container>
									<Grid
										item
										xs={6}
										style={{
											borderBottom: "1px solid #888",
											height: "60px",
											fontSize: "25px",
										}}
										className={classes.center}
									>
										<Typography style={{textAlign: "center", color: "#555"}}>
											<b>FEATURE LIST</b>
										</Typography>
									</Grid>
									{comparisonData.plans.map((ele, index) => (
										<Grid
											key={index}
											item
											xs={1.5}
											style={{
												borderBottom: "1px solid #888",
												height: "60px",
												fontSize: "25px",
											}}
											className={classes.center}
										>
											<Typography color="primary" style={{textAlign: "center"}}>
												<b>{ele}</b>
											</Typography>
										</Grid>
									))}
								</Grid>
							</Grid>
							{comparisonData.features.map((ele, index) => (
								<Grid item xs={12} key={index}>
									<Grid container>
										<Grid
											item
											xs={12}
											style={{borderBottom: "1px solid #E0E0E0"}}
										>
											<Typography
												color="primary"
												style={{
													fontSize: "18px",
													width: "50%",
													height: "50px",
													display: "flex",
													alignItems: "center",
													justifyContent: "center",
													marginTop: "8px",
												}}
											>
												<b>{ele.title}</b>
											</Typography>
											<Grid container style={{marginBottom: "10px"}}>
												{ele.features &&
													ele.features.length > 0 &&
													ele.features.map((item, index) => (
														<Grid item xs={12} key={index}>
															<Grid container>
																<Grid item xs={6}>
																	<Typography
																		style={{
																			marginLeft: "35px",
																			marginRight: "55px",
																			height: "50px",
																			display: "flex",
																			alignItems: "center",
																			color: "#828282",
																			justifyContent: "space-between",
																		}}
																	>
																		<b>{item.title}</b>
																		<Tooltip
																			title={
																				<span style={{fontSize: "15px"}}>
																					{item.support_text}
																				</span>
																			}
																			arrow
																			placement="right"
																		>
																			<Box>
																				<BsInfoCircleFill
																					className={classes.iconInfo}
																				/>
																			</Box>
																		</Tooltip>
																	</Typography>
																</Grid>
																{comparisonData.plans.map((iItem, iIIndex) => (
																	<Grid item xs={1.5} key={iIIndex}>
																		<Typography
																			style={{
																				height: "50px",
																				color: "#828282",
																			}}
																			className={classes.center}
																		>
																			{item[iItem.toLowerCase()] === true ? (
																				<MdVerified
																					style={{
																						fontSize: "27px",
																						color: "#C86409",
																					}}
																				/>
																			) : item[iItem.toLowerCase()] ===
																			  false ? (
																				<MdVerified
																					style={{
																						color: "#999",
																						fontSize: "27px",
																					}}
																				/>
																			) : (
																				<b>{item[iItem.toLowerCase()]}</b>
																			)}
																		</Typography>
																	</Grid>
																))}
															</Grid>
														</Grid>
													))}
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							))}
						</Grid>
					</Box>
				</Box>
				{subscribeModalState.open && (
					<SubscribeModal
						subscribeModalState={subscribeModalState}
						handleClose={() => setSubscribeModalState({open: false})}
						goToSubscription={goToSubscription}
					/>
				)}
			</Container>
		);
	} else {
		return (
			<Box style={{marginTop: "10%", width: "100%", textAlign: "center"}}>
				<CircularProgress />
			</Box>
		);
	}
}

export default PlansPricing;
