import * as React from "react";
import {Box, Grid} from "@material-ui/core";

import classes from "./Page1.module.css";

import HOTQr from "../../../../../Assets/HOTQr.png";
import HotQr3 from "../../../../../Assets/qr3.svg";
import LinesGroup4 from "../../../../../Assets/Group4.svg";
import LinesGroup5 from "../../../../../Assets/Group5.svg";
import {useNavigate} from "react-router";

function Page1(props) {
	const navigate = useNavigate();
	return (
		<Box className={classes.box}>
			<Box onClick={() => navigate("/")} className={classes.boxLogo}>
				<img className={classes.HOTQr} alt="logo" src={HOTQr} />
			</Box>
			<Grid container spacing={2}>
				<Grid item xs={12} className="d-flex justify-content-center">
					<Box className={classes.boxHead}>
						<label className={classes.boxHeadTitle}>
							HOT QR Code Generator
						</label>
						<label className={classes.boxHeadText}>
							Create custom designed QR codes with HOT QR code generator to
							attract more scans
						</label>
						<Box
							onClick={() => navigate("/login")}
							className={classes.boxHeadButton}
						>
							<span className={classes.boxHeadButtonText}>Create QR code</span>
						</Box>
					</Box>
				</Grid>
				<Grid item xs={12} className="d-flex flex-column align-items-center">
					<img className={classes.QrLogo} alt="QrLogo" src={HotQr3} />
					<Box className="d-flex">
						<img alt="Lines" src={LinesGroup4} />
						<img alt="Lines" src={LinesGroup5} style={{marginLeft: "-5px"}} />
					</Box>
					<Box className="d-flex justify-content-center w-100 px-5">
						<span className={classes.qrText} style={{marginLeft: "0px"}}>
							Facebook
						</span>
						<span className={classes.qrText} style={{marginLeft: "30px"}}>
							Website
						</span>
						<span className={classes.qrText} style={{marginLeft: "55px"}}>
							Audio
						</span>
						<span className={classes.qrText} style={{marginLeft: "30px"}}>
							Map Location
						</span>
						<span className={classes.qrText} style={{marginLeft: "30px"}}>
							Form
						</span>
						<span className={classes.qrText} style={{marginLeft: "30px"}}>
							Contact Info
						</span>
						<span className={classes.qrText} style={{marginLeft: "35px"}}>
							Video
						</span>
						<span className={classes.qrText} style={{marginLeft: "40px"}}>
							Document
						</span>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
}

export default Page1;
