import * as React from "react";
import {Box, Grid} from "@material-ui/core";

import classes from "./Page2.module.css";

function Page2() {
	return (
		<Box className={classes.box}>
			<Grid container spacing={2}>
				<Grid
					item
					xs={12}
					className="d-flex flex-column align-items-center justify-content-center"
				>
					<span className={classes.boxTitle}>
						Engage customers via print media using HotQr's QR Codes
					</span>
					<span className={classes.boxsubTitle}>
						Add QR Codes to make your print promotions more engaging and useful
					</span>
				</Grid>
				<Grid item container xs={12} spacing={2} className="mt-3">
					<Grid item xs={4} className="d-flex justify-content-center">
						<Box className={classes.boxContentboxes}>
							<label className={classes.boxContentboxesText}>
								<span style={{color: "#000"}}>
									Drive traffic to your website
								</span>
								<br />
								<br />A QR Code is the best way to get instant web traffic from
								print media promotions by simply linking your website to the QR
								Code
							</label>
						</Box>
					</Grid>
					<Grid item xs={4} className="d-flex justify-content-center">
						<Box className={classes.boxContentboxes}>
							<label className={classes.boxContentboxesText}>
								<span style={{color: "#000"}}>Share multimedia content</span>
								<br />
								<br />
								With HotQr's QR Codes, you can share multimedia content such as
								formatted text, images, documents, audio, videos, social media
								profiles, map location, coupons, mobile apps, and event details
							</label>
						</Box>
					</Grid>
					<Grid item xs={4} className="d-flex justify-content-center">
						<Box className={classes.boxContentboxes}>
							<label className={classes.boxContentboxesText}>
								<span style={{color: "#000"}}>
									Acquire social media followers
								</span>
								<br />
								<br />
								Make it easier for your print media audience to follow you on
								Social Media such as Facebook, Instagram, LinkedIn, Twitter,
								TikTok, and Snapchat with a single scan and click
							</label>
						</Box>
					</Grid>
					<Grid item xs={4} className="d-flex justify-content-center mt-4">
						<Box className={classes.boxContentboxes}>
							<label className={classes.boxContentboxesText}>
								<span style={{color: "#000"}}>Share personalized content</span>
								<br />
								<br />
								HotQr's QR Code Generator helps you create special QR Codes such
								as Product and Wedding QR Codes that allow you to build and
								share customized content for your audience
							</label>
						</Box>
					</Grid>
					<Grid item xs={4} className="d-flex justify-content-center mt-4">
						<Box className={classes.boxContentboxes}>
							<label className={classes.boxContentboxesText}>
								<span style={{color: "#000"}}>
									Share detailed contact information
								</span>
								<br />
								<br />
								With HotQr's Business Card QR Codes, you can make it easier for
								people to view and save your detailed contact information
							</label>
						</Box>
					</Grid>
					<Grid item xs={4} className="d-flex justify-content-center mt-4">
						<Box className={classes.boxContentboxes}>
							<label className={classes.boxContentboxesText}>
								<span style={{color: "#000"}}>Generate lead lists</span>
								<br />
								<br />
								With features such as Lead Generation, capture contact
								information of the scanning audience via print media
							</label>
						</Box>
					</Grid>
				</Grid>
			</Grid>
		</Box>
	);
}

export default Page2;
