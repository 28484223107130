import React from "react";
import {
	Typography,
	IconButton,
	Snackbar,
	Alert,
	LinearProgress,
	Box,
	AccordionDetails,
	Accordion,
	AccordionSummary,
	Grid,
	TextField,
} from "@material-ui/core";
import {ExpandMore, Delete, Cancel} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core-v4";
import {useDropzone} from "react-dropzone";
import styled from "styled-components";
import {FileIcon, defaultStyles} from "react-file-icon";

import {uploader} from "../../../../Services/QrGenerateServices/ApiUrl";

import BgColorComp from "./ColorComponents/BgColor";
import TextColorComp from "./ColorComponents/TextColor";
import BorderColorComp from "./ColorComponents/BorderColor";
import QrContext from "../../../QrContext/QrContext";

const useStyles = makeStyles((theme) => ({
	box: {
		"& .MuiPaper-root": {
			boxShadow: "none !important",
		},
		"& .MuiPaper-root > .MuiButtonBase-root": {
			border: "1px solid #000",
			borderRadius: "2px",
			background: "#F0F0F0",
			height: "50px !important",
		},
		"& .MuiPaper-root > .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded":
			{
				minHeight: "50px !important",
			},
		"& .MuiPaper-root > .MuiCollapse-root": {
			border: "1px solid #D8D8D8",
			margin: "10px 0",
			borderRadius: "2px",
		},
	},
	center: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	card: {
		width: "100%",
		"& div > div": {
			height: "40px",
			borderRadius: "3px",
		},
		"& .MuiFormControl-root > .MuiOutlinedInput-root > input ": {
			marginTop: "-3px",
		},
		"& .MuiFormControl-root > .MuiOutlinedInput-root > .MuiInputAdornment-root > .MuiButtonBase-root ":
			{
				marginTop: "-7px",
			},
	},
	divExtensionLogo: {
		marginTop: "5px",
		"& svg": {
			height: "75px",
			width: "100px",
		},
	},
}));

const thumbsContainer = {
	display: "flex",
	flexDirection: "row",
	flexWrap: "wrap",
	marginTop: 16,
};

const thumb = {
	display: "inline-flex",
	borderRadius: 2,
	border: "1px solid #eaeaea",
	marginBottom: 8,
	marginRight: 8,
	width: 110,
	height: 110,
	padding: 4,
	boxSizing: "border-box",
};

const thumbInner = {
	display: "flex",
	minWidth: 0,
	overflow: "hidden",
	objectFit: "contain",
	position: "relative",
};

const getColor = (props) => {
	if (props.isDragAccept) {
		return "#00e676";
	}
	if (props.isDragReject) {
		return "#ff1744";
	}
	if (props.isDragActive) {
		return "#2196f3";
	}
	return "#E0E0E0";
};

const Container = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px;
	border-width: 2px;
	border-radius: 2px;
	border-color: ${(props) => getColor(props)};
	border-style: dashed;
	background-color: #fafafa;
	color: #bdbdbd;
	outline: none;
	transition: border 0.24s ease-in-out;
`;

function FilesComp(props) {
	const classes = useStyles();
	const {setContent} = React.useContext(QrContext);

	const {data, dataIndex} = props;

	const {max, files, btnBgColor, btnTextColor, btnBorderColor, btnText} = data;

	const [expanded, setExpanded] = React.useState(false);

	const [snackbar, setSnackbar] = React.useState({
		open: false,
		severity: "success",
		message: "here",
	});

	const [loader, setLoader] = React.useState(false);

	const maxSize = 20971520;

	const handleChange = (key, value) => {
		setContent((prev) =>
			prev.map((ele, index) => {
				if (index === dataIndex) {
					return {
						...ele,
						[key]: value,
					};
				} else return ele;
			})
		);
	};

	const handleDelete = () => {
		setContent((prev) => [...prev].filter((ele, index) => dataIndex !== index));
	};
	function getThe64StringSize(base64String) {
		if (base64String) {
			const string = base64String.split(",")[1];
			let x =
				string.length * (3 / 4) -
				(string.slice(string.length - 2, string.length) !== "=="
					? string.slice(string.length - 1, string.length) === "="
						? 1
						: 0
					: 2);
			return x;
		}
	}

	const handleFile = (imageData) => {
		handleChange("files", imageData);
	};

	const uploadFile = (filesData) => {
		Object.values(filesData).forEach((file) => {
			var reader = new FileReader();
			if (file) {
				reader.readAsDataURL(file);
				reader.onload = () => {
					uploader({
						payload: [
							{
								name: file.name,
								size: file.size,
								type: file.type,
								base64String: reader.result.split(",")[1],
								base64StringSize: getThe64StringSize(reader.result),
							},
						],
					})
						.then((res) => {
							setLoader(false);
							files.push(...res);
							handleFile(files);
							setSnackbar({
								open: true,
								message: "File Upload Successfully",
								severity: "success",
							});
						})
						.catch((err) => {
							setLoader(false);
							console.error(err);
							setSnackbar({
								open: true,
								message: "Error while uplaod files",
								severity: "error",
							});
						});
				};
				reader.onerror = () => {
					setLoader(false);
					setSnackbar({
						open: true,
						message: "Error while uplaod files",
						severity: "error",
					});
				};
			}
		});
	};
	const {
		getRootProps,
		getInputProps,
		isDragActive,
		isDragAccept,
		isDragReject,
	} = useDropzone({
		maxFiles: 20,
		accept: "application/*",
		minSize: 0,
		maxSize,
		onDrop: (acceptedFiles) => {
			if (files.length < max) {
				if (
					acceptedFiles &&
					acceptedFiles.length > 0 &&
					acceptedFiles[0].size < 20971520
				) {
					setLoader(acceptedFiles.length);
					let imageDataPayload = acceptedFiles.map((file) =>
						Object.assign(file, {
							preview: URL.createObjectURL(file),
						})
					);
					uploadFile(imageDataPayload);
				} else {
					setSnackbar({
						open: true,
						message: "File Size more then 20 MB",
						severity: "warning",
					});
				}
			} else {
				setSnackbar({
					open: true,
					message: "You have upload max 20 Ducuments",
					severity: "warning",
				});
			}
		},
	});

	const handleDeleteImage = (index) => {
		handleChange(
			"files",
			files.filter((ele, eleIndex) => eleIndex !== index)
		);
	};

	if (props.data) {
		const thumbs =
			files &&
			files.length > 0 &&
			files.map((file, index) => {
				const fileType = file.name.split(".")[1];
				const fileName = file.name.split(".")[0];
				return (
					<div style={thumb} key={index}>
						<div style={thumbInner}>
							<IconButton
								style={{
									position: "absolute",
									padding: "0",
									margin: "0",
									right: "0",
									border: "0",
									background: "transparent",
									height: "1px !important",
								}}
								onClick={() => handleDeleteImage(index)}
							>
								<Cancel />
							</IconButton>
							<div className={classes.divExtensionLogo}>
								<FileIcon extension={fileType} {...defaultStyles[fileType]} />
								<Typography
									variant="caption"
									style={{
										width: "100%",
										display: "flex",
										justifyContent: "center",
										marginTop: "5px",
									}}
								>
									{fileName}
								</Typography>
							</div>
						</div>
					</div>
				);
			});

		return (
			<div className={classes.box}>
				<Accordion onChange={(e, data) => setExpanded(data)}>
					<AccordionSummary expandIcon={<ExpandMore />}>
						<Box
							style={{
								display: "flex",
								justifyContent: "space-between",
								width: "100%",
								alignItems: "center",
							}}
						>
							<Typography
								style={{color: "#615757", fontSize: "20px", fontWeight: "600"}}
							>
								Files
							</Typography>
							{expanded && (
								<IconButton
									style={{marginRight: "15px"}}
									onClick={() => handleDelete()}
								>
									<Delete />
								</IconButton>
							)}
						</Box>
					</AccordionSummary>
					<AccordionDetails
						style={{display: "flex", alignItems: "center", marginTop: "20px"}}
					>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Typography
									variant="h6"
									style={{
										color: "#666",
										margin: "-10px 0 20px 15px",
										display: "flex",
										flexDirection: "column",
									}}
								>
									<span style={{fontSize: "20px", marginBottom: "8px"}}>
										Upload File
									</span>
									<span>1. Formats-</span>
									<span style={{marginLeft: "18px"}}>
										<b>Documents-</b> pdf, doc, docx, xls, xlsx, ppt, pptx, odt,
										ods, odp, txt, csv
									</span>
									<span style={{marginLeft: "18px"}}>
										<b>Images-</b> png, jpg, jpeg, gif
									</span>
									<span style={{marginLeft: "18px"}}>
										<b>Audio-</b> mp3, wav, aac, m4a
									</span>
									<span>2. Maximum File size is 20 MB</span>
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<section className="container">
									<Container
										{...getRootProps({
											isDragActive,
											isDragAccept,
											isDragReject,
										})}
									>
										<input {...getInputProps()} />
										<p>
											Drag 'n' drop some files here, or click to select files
										</p>
									</Container>
									<aside style={thumbsContainer}>
										{thumbs}
										{loader !== false &&
											[...Array(loader)].map((item, index) => (
												<div style={thumb} key={index}>
													<div style={thumbInner}>
														<Box
															key={index}
															variant="rectangular"
															width={110}
															height={110}
															style={{marginTop: "90px"}}
														>
															<LinearProgress />
														</Box>
													</div>
												</div>
											))}
									</aside>
								</section>
							</Grid>
							<Grid item xs={12}>
								<Grid container spacing={1}>
									<Grid item xs={12}>
										<Typography variant="h5">Button Text</Typography>
									</Grid>
									<Grid item xs={12}>
										<Box className={classes.card}>
											<TextField
												type="text"
												color="primary"
												name="btnText"
												onChange={(e) => {
													handleChange(e.target.name, e.target.value);
												}}
												fullWidth
												value={btnText}
											/>
										</Box>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={12}>
								<BgColorComp
									bgColor={btnBgColor}
									handleChange={(key, value) =>
										handleChange("btnBgColor", value)
									}
									name="Button Background Color"
								/>
							</Grid>
							<Grid item xs={12}>
								<TextColorComp
									textColor={btnTextColor}
									handleChange={(key, value) =>
										handleChange("btnTextColor", value)
									}
									name="Button Text Color"
								/>
							</Grid>
							<Grid item xs={12}>
								<BorderColorComp
									borderColor={btnBorderColor}
									handleChange={(key, value) =>
										handleChange("btnBorderColor", value)
									}
									name="Button Border Color"
								/>
							</Grid>
						</Grid>
					</AccordionDetails>
				</Accordion>
				<Snackbar
					open={snackbar.open}
					anchorOrigin={{vertical: "top", horizontal: "right"}}
					autoHideDuration={5000}
					onClose={() => setSnackbar({open: false})}
				>
					<Alert
						onClose={() => setSnackbar({open: false})}
						severity={snackbar.severity}
						sx={{width: "100%"}}
					>
						{snackbar.message}
					</Alert>
				</Snackbar>
			</div>
		);
	} else {
		return null;
	}
}
export default FilesComp;
