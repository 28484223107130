import * as React from "react";
import {Box, Grid} from "@material-ui/core";

import classes from "./Page5.module.css";

const gridArray = [
	{
		title: "Add QR Code design",
		text: "Add design and branding to QR Codes to increase visual appeal as they blend well with print creatives",
		text1:
			"Adding logo and colors give a branded touch to QR Codes which a generic B&W QR Code does not",
		text2:
			"QR Codes personalized to the brand attract 50-200% more scans than black-and-white QR Codes",
	},
	{
		title: "Generate High-Resolution Images",
		text: "The minimum size of a printed QR Code should at least be 1.2 inch or 3 cm or 360 pixels (300 DPI) in width and height",
		text1:
			"Ensure that the QR Code is printed on a flat surface. Curved surfaces reduce scannability of QR Codes",
		text2:
			"If exporting a QR Code without background, ensure that the background is white or light-colored for adequate contrast ratio",
	},
	{
		title: "Create Printable QR Code Labels",
		text: "Choose from a list of 10 standard labels layouts—1, 4, 6, 9, 12, 20, 48, and 80 QR Code labels per sheet—compatible with Avery sheets",
		text1:
			'Choose the QR Code label size you need—0.75", 1", 1.5", 2", 2.125", 2.5", 2.75", 3", 4", and 8"',
		text2: "Download print-ready labels document in PDF format",
	},
	{
		title: "Ensure data spreadsheet is error-free",
		text: "Setup data in Microsoft Excel or Google Spreadsheets as both support CSV and XLS file formats",
		text1:
			"Each row represents data for a single QR Code with Column A reserved for QR Code image filename. Column B onwards specify data for the QR Code content—URL, Text, or Vcard Fields",
		text2:
			"For best results, ensure there are no headers, empty rows, missing cells, or extra columns",
	},
];

function Page5() {
	return (
		<Box className={classes.box}>
			<Grid container>
				<Grid item xs={12} className="d-flex justify-content-center">
					<label className={classes.title}>
						{" "}
						HOT QR Code Batch Generation Best Practices{" "}
					</label>
				</Grid>
				<Grid item container xs={12} spacing={2} className="mt-3 mb-5">
					{gridArray.map((ele, index) => (
						<Grid item xs={3} key={index}>
							<Box className={classes.labelText}>
								<span style={{color: "#000"}}>{ele.title}</span>
								<span className="mt-3">{ele.text}</span>
								<span className="mt-3">{ele.text1}</span>
								<span className="mt-3">{ele.text2}</span>
							</Box>
						</Grid>
					))}
				</Grid>
			</Grid>
		</Box>
	);
}

export default Page5;
