import React from "react";
import {
	Card,
	CardContent,
	Box,
	Grid,
	Typography,
	Button,
	Menu,
	MenuItem,
} from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import DateRangePickerModal from "./DateRangePickerModal";
import xlsx from "json-as-xlsx";
import {CSVLink} from "react-csv";
import getNormalCars from "../../../../UI/getNormalCars";

export default function TriggeredArea({
	leadData,
	analyticsData,
	handleSubmit,
	dateRange,
	setDateRange,
}) {
	const {leadCount, totalQrCount, Items} = analyticsData;
	const csvLinkRef = React.useRef();
	const [modalOpen, setModalOpen] = React.useState(false);
	const [anchorEl, setAnchorEl] = React.useState();

	const returnDate = (dateString, date) => {
		let month = {
			Jan: "01",
			Feb: "02",
			Mar: "03",
			Apr: "04",
			May: "05",
			Jun: "06",
			Jul: "07",
			Aug: "08",
			Sep: "09",
			Oct: "10",
			Nov: "11",
			Dec: "12",
		};
		let dateArr = dateString.toDateString().split(" ").slice(1, 4);
		if (date) {
			return `${dateArr[1]}-${month[dateArr[0]]}-${dateArr[2]}`;
		} else {
			return `${dateArr[2]}-${month[dateArr[0]]}-${dateArr[1]}`;
		}
	};

	const leadFormCSVQuestions =
		leadData?.uiData
			?.filter((ele) => ele.type === "LeadForm")?.[0]
			?.questionBlock?.map((item) => {
				return {
					label: item.question,
					key: getNormalCars(item.question),
				};
			}) || [];

	const csvHeaders = [
		{label: "QR Code", key: "qrTitle"},
		{label: "Submitted On", key: "submittedOn"},
		{label: "Skipped", key: "skipped"},
		...leadFormCSVQuestions,
	];

	const csvData =
		Items?.map((ele) => {
			let keysArray = {};
			Object.entries(ele?.data)?.map(([keys, values]) => {
				keysArray = {
					...keysArray,
					[getNormalCars(keys)]: values,
				};
				return null;
			});
			return {
				qrTitle: ele?.qr?.title || "Qr code name not available",
				submittedOn: ele?.submittedOn || "Lead submit date not available",
				skipped: ele?.skipped ? "true" : "false",
				...keysArray,
			};
		}) || [];

	const csvReport = {
		data: csvData || [],
		headers: csvHeaders || [],
		filename: leadData?.title + ".csv" || "leads.csv",
	};

	const leadFormXLSXQuestions =
		leadData?.uiData
			?.filter((ele) => ele.type === "LeadForm")?.[0]
			?.questionBlock?.map((item) => {
				return {
					label: item.question,
					value: getNormalCars(item.question),
				};
			}) || [];

	const xlsxReport = [
		{
			sheet: "Leads",
			columns: [
				{label: "Qr Code", value: "qrTitle"}, // Top level data
				{label: "Submitted On", value: "submittedOn"}, // Custom format
				{label: "Skipped", value: "skipped"}, // Run functions
				...leadFormXLSXQuestions,
			],
			content:
				Items?.map((ele) => {
					let keysArray = {};
					Object.entries(ele.data).map(([keys, values]) => {
						keysArray = {
							...keysArray,
							[getNormalCars(keys)]: values,
						};
						return null;
					});
					return {
						qrTitle: ele?.qr?.title || "Qr code name not available",
						submittedOn: ele?.submittedOn || "Lead submit date not available",
						skipped: ele.skipped ? "true" : "false",
						...keysArray,
					};
				}) || [],
		},
	];

	const handleDownloadXlsx = () => {
		let settings = {
			fileName: leadData?.title || "leads", // Name of the resulting spreadsheet
			extraLength: 3, // A bigger number means that columns will be wider
			writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
		};
		xlsx(xlsxReport, settings);
	};

	const handleDownalodCSV = () => {
		csvLinkRef.current.link.click();
	};

	const renderDownloadAnalyticsMenu = () => (
		<Menu
			anchorEl={anchorEl}
			open={anchorEl}
			onClose={() => setAnchorEl(null)}
			onClick={() => setAnchorEl(null)}
			PaperProps={{
				elevation: 0,
				sx: {
					overflow: "visible",
					filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
					mt: 1.5,
					"& .MuiAvatar-root": {
						width: 32,
						height: 32,
						ml: -0.5,
						mr: 1,
					},
					"&:before": {
						content: '""',
						display: "block",
						position: "absolute",
						top: 0,
						right: 14,
						width: 10,
						height: 10,
						bgcolor: "background.paper",
						transform: "translateY(-50%) rotate(45deg)",
						zIndex: 0,
					},
				},
			}}
			transformOrigin={{horizontal: "right", vertical: "top"}}
			anchorOrigin={{horizontal: "right", vertical: "bottom"}}
		>
			<MenuItem onClick={handleDownalodCSV}>Export to CSV</MenuItem>
			<MenuItem onClick={handleDownloadXlsx}>Export to XLSX</MenuItem>
		</Menu>
	);

	return (
		<Card
			sx={{
				backgroundColor: "#f8f8f8",
				border: "1px solid lightgrey",
				borderRadius: "4px",
				boxShadow: "0px 20px 27px 0px rgba(0, 0, 0, 0.05)",
			}}
			elevation={6}
		>
			<CardContent>
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<Typography sx={{color: "#444", ml: "3px"}}>
							Select time range
						</Typography>
						<Button
							fullWidth
							variant="outlined"
							sx={{
								border: "1px solid #D0D0D0",
								padding: "10px",
								borderRadius: "7px",
								display: "flex",
								justifyContent: "flex-start",
							}}
							onClick={() => setModalOpen(true)}
						>
							<Typography sx={{color: "#555"}}>
								<span>{returnDate(dateRange[0], true)}</span>
								<b style={{margin: "0 5px"}}>to</b>
								<span>{returnDate(dateRange[1], true)}</span>
							</Typography>
						</Button>
					</Grid>
					<Grid item xs={6} sx={{display: "flex", alignItems: "flex-end"}}>
						<Button
							variant="outlined"
							onClick={handleSubmit}
							sx={{
								borderRadius: "7px",
								height: "45px",
							}}
						>
							Get Analytics
						</Button>
					</Grid>
					<Grid item xs={12}>
						<Grid container spacing={2} sx={{mt: 1}}>
							{leadCount && (
								<Grid item xs={2}>
									<Box
										sx={{
											padding: "5px 15px",
											border: "1px solid #D0D0D0",
											borderRadius: "8px",
										}}
									>
										<Typography sx={{color: "#444", fontSize: "18px"}}>
											Total Leads{" "}
											<b style={{color: "#333", fontSize: "26px"}}>
												{leadCount}
											</b>
										</Typography>
									</Box>
								</Grid>
							)}
							{totalQrCount && (
								<Grid item xs={2.5}>
									<Box
										sx={{
											padding: "5px 15px",
											border: "1px solid #D0D0D0",
											borderRadius: "8px",
										}}
									>
										<Typography sx={{color: "#444", fontSize: "18px"}}>
											Leads from{" "}
											<b style={{color: "#333", fontSize: "26px"}}>
												{totalQrCount}
											</b>{" "}
											qr codes
										</Typography>
									</Box>
								</Grid>
							)}
							{leadCount > 0 && (
								<Grid item xs={2}>
									<Button
										fullWidth
										variant="outlined"
										color="primary"
										sx={{
											borderRadius: "8px",
											height: "100%",
										}}
										endIcon={
											<CloudDownloadIcon
												style={{fontSize: "24px", marginLeft: "10px"}}
											/>
										}
										onClick={(e) => setAnchorEl(e.currentTarget)}
									>
										<Typography sx={{fontSize: "18px"}}>
											Export Leads
										</Typography>
									</Button>
								</Grid>
							)}
						</Grid>
					</Grid>
				</Grid>
			</CardContent>
			{renderDownloadAnalyticsMenu()}
			<CSVLink
				{...csvReport}
				ref={csvLinkRef}
				className="hidden"
				target="_blank"
			/>
			<DateRangePickerModal
				setDateRange={setDateRange}
				dateRange={dateRange}
				modalOpen={modalOpen}
				setModalOpen={setModalOpen}
			/>
		</Card>
	);
}
