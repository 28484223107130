import React from "react";

import QrContext from "./QrContext";

function QrContextWrapper(props) {
	const {content} = React.useContext(QrContext);

	const [contentContext, setCotentContext] = React.useState(content);
	const contextContentHandler = React.useCallback((data) => {
		setCotentContext(data);
	}, []);
	return (
		<QrContext.Provider
			value={{
				content: contentContext,
				setContent: contextContentHandler,
			}}
		>
			{props.children}
		</QrContext.Provider>
	);
}
export default QrContextWrapper;
