import React from "react";
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Slide,
} from "@material-ui/core";
import {useNavigate} from "react-router-dom";
import AuthContext from "../../../../Context/AuthContext";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

function Logout(props) {
	const navigate = useNavigate();
	const {setLogin, login} = React.useContext(AuthContext);

	const handleLogout = () => {
		localStorage.removeItem("token");
		localStorage.removeItem("currentItem");

		setLogin(false);
		if (!localStorage.getItem("token")) {
			navigate("/");
		}
	};

	return (
		<Dialog open={login} TransitionComponent={Transition} keepMounted>
			<DialogTitle>{"Logout"}</DialogTitle>
			<DialogContent style={{width: "300px"}}>
				<DialogContentText>You really want to Logout</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => setLogin(false)}
					variant="contained"
					style={{color: "#FFF", background: "#1d8f48"}}
				>
					No
				</Button>
				<Button
					onClick={handleLogout}
					variant="contained"
					style={{color: "#FFF", background: "#c72a2a"}}
				>
					Yes
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default Logout;
