import React from "react";

// ------------------------ import Components --------------------------- //

import {
	Container,
	Box,
	Typography,
	Button,
	Paper,
	CircularProgress,
	Card,
	Grid,
} from "@material-ui/core";
// import { makeStyles } from '@material-ui/core-v4';

// ------------------------ import Context --------------------------- //
import AuthContext from "../../../../../Context/AuthContext";
import moment from "moment";

// import subscriptionPlan from '../subscriptionPlan';

// ------------------------ Custome makeStyle --------------------------- //

// const useStyles = makeStyles((theme) => ({
//     iconInfo: {
//         color: '#999',
//         '&:hover': {
//             color: '#C86409'
//         }
//     },
//     center: {
//         display: 'flex',
//         justifyContent: 'center',
//         alignItems: 'center'
//     },
//     selectSwitch: {
//         width: 600,
//         height: 38,
//         marginTop: '30px',
//         '& div': {
//             borderRadius: '6px'
//         }
//     }
// }))

const dataArray = [
	{
		title: "Purchase Count",
		key: "purchaseCount",
	},
	{
		title: "Purchased On",
		key: "purchasedOn",
		type: "date",
	},
	{
		title: "Subscription Plan",
		type: "object",
		objName: "subscriptionPlanDetails",
		key: "title",
	},
	{
		title: "Subscription Plan Type",
		key: "subscriptionPlanType",
	},
	{
		title: "Expired On",
		key: "expiredOn",
		type: "date",
	},
];

const keyTypoStyle = {
	fontSize: "16px",
	letterSpacing: "0.7px",
	width: "195px",
	fontWeight: 600,
};

const valueTypoStyle = {
	fontSize: "16px",
	letterSpacing: "0.8px",
	fontWeight: 600,
	color: "#666",
};

function OrderHistory(props) {
	const context = React.useContext(AuthContext);

	const {license} = context;

	if (license) {
		if (license.purchaseHistory && license.purchaseHistory.length > 0) {
			return (
				<Container
					maxWidth="xl"
					style={{
						margin: "30px 0px",
						padding: "0px",
					}}
				>
					<Grid container spacing={2}>
						{license.purchaseHistory.map((ele, index) => (
							<Grid item key={index} lg={6} xl={4}>
								<Card sx={{p: 2}}>
									{dataArray.map((item, iIndex) => (
										<React.Fragment key={iIndex}>
											<Box
												sx={{
													display: "flex",
													mb: 0.1,
													borderBottom: "1px solid #D0D0D0",
													px: 1,
													py: 0.5,
												}}
											>
												<Typography
													color="primary"
													style={keyTypoStyle}
												>{`${item.title}: `}</Typography>
												<Typography style={valueTypoStyle}>
													{item.type === "date"
														? moment(ele?.[item.key]).format("llll")
														: item.type === "object"
														? ele?.[item.objName]?.[item.key]
														: ele?.[item.key] || ""}
												</Typography>
											</Box>
										</React.Fragment>
									))}
								</Card>
							</Grid>
						))}
					</Grid>
					<Button
						variant="outlined"
						color="primary"
						style={{marginTop: "20px"}}
						onClick={props.goToPlans}
					>
						Go to Plans
					</Button>
				</Container>
			);
		} else {
			return (
				<Container
					component={Paper}
					maxWidth="xl"
					style={{margin: "30px auto", padding: "30px"}}
				>
					<Typography variant="h5" style={{color: "#666"}}>
						You have not made any transactions yet. Select a Plan to get
						started.
					</Typography>
					<Button
						variant="outlined"
						color="primary"
						style={{marginTop: "20px"}}
						onClick={props.goToPlans}
					>
						Go to Plans
					</Button>
				</Container>
			);
		}
	} else {
		return (
			<Box style={{marginTop: "10%", width: "100%", textAlign: "center"}}>
				<CircularProgress />
			</Box>
		);
	}
}

export default OrderHistory;
