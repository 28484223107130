import React from "react";

import {
	Container,
	Box,
	Typography,
	Button,
	Grid,
	CircularProgress,
	Card,
	Switch,
	Snackbar,
	Alert,
	Tooltip,
	Slide,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Chip,
} from "@material-ui/core";
import {useNavigate, useParams} from "react-router";
import {styled} from "@mui/material/styles";
import EasyEdit from "react-easy-edit";
import {makeStyles} from "@material-ui/core-v4";

import {CloudDownload, Edit, Check, Clear} from "@material-ui/icons";

import {
	findByIdQrCode,
	qrActive,
	qrDeactive,
	updateQrCode,
} from "../../Services/DashboardServices/ApiUrl";
import PageTitle from "../../../../UI/PageTitle";

// import { SampleQrJason } from '../SampleQrJson';

const useStyles = makeStyles((theme) => ({
	aic: {
		display: "flex",
		alignItems: "center",
	},
	center: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	easyEditSave: {
		background: "transparent",
		border: "0px",
		color: "green",
		marginLeft: "5px",
	},
	easyEditCancel: {
		background: "transparent",
		border: "0px",
		color: "red",
		marginLeft: "5px",
	},
}));

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const Android12Switch = styled(Switch)(({theme}) => ({
	padding: 8,
	"& .MuiSwitch-track": {
		borderRadius: 22 / 2,
		"&:before, &:after": {
			content: '""',
			position: "absolute",
			top: "50%",
			transform: "translateY(-50%)",
			width: 16,
			height: 16,
		},
		"&:before": {
			backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
				"#000"
			)}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
			left: 12,
		},
		"&:after": {
			backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
				"#FFF"
			)}" d="M19,13H5V11H19V13Z" /></svg>')`,
			right: 12,
		},
	},
	"& .MuiSwitch-thumb": {
		boxShadow: "none",
		width: 16,
		height: 16,
		margin: 2,
	},
}));

function ViewQr(props) {
	const navigate = useNavigate();
	// const data = SampleQrJason

	const classes = useStyles();
	const params = useParams();
	const qrId = params.qrId;

	const [data, setData] = React.useState();
	const [inactiveQr, setInactiveQr] = React.useState(false);
	const [imageBase64, setImageBase64] = React.useState(false);
	const [load, setLoad] = React.useState({
		updateField: false,
		activeSwitch: false,
	});
	const [updateData, setUpdateData] = React.useState({
		dialogOpen: false,
		content: true,
		field: "",
		value: "",
	});
	const [snackbar, setSnackbar] = React.useState({
		open: false,
		message: "",
		severity: "success",
	});

	const qrActiveApiCall = React.useCallback(() => {
		qrActive(qrId)
			.then((res) => {
				setInactiveQr(res.inactive);
				setLoad((prev) => ({...prev, activeSwitch: false}));
				setSnackbar({
					open: true,
					message: "QR Code Activated Successfully",
					severity: "success",
				});
			})
			.catch((err) => {
				console.error(err);
				setLoad((prev) => ({...prev, activeSwitch: false}));
				setSnackbar({
					open: true,
					message: "Error in Activate QR Code",
					severity: "error",
				});
			});
	}, [qrId]);

	const qrDeactiveApiCall = React.useCallback(() => {
		qrDeactive(qrId)
			.then((res) => {
				setInactiveQr(res.inactive);
				setLoad((prev) => ({...prev, activeSwitch: false}));
				setSnackbar({
					open: true,
					message: "QR Code Deactivated Successfully",
					severity: "success",
				});
			})
			.catch((err) => {
				console.error(err);
				setLoad((prev) => ({...prev, activeSwitch: false}));
				setSnackbar({
					open: true,
					message: "Error in Deactivet QR Code",
					severity: "error",
				});
			});
	}, [qrId]);

	const handleInactivaeQr = () => {
		setLoad((prev) => ({...prev, activeSwitch: true}));
		if (!inactiveQr) {
			qrDeactiveApiCall();
		} else {
			qrActiveApiCall();
		}
	};

	// const blobToBase64 = (blob) => {
	// 	const reader = new FileReader();
	// 	reader.readAsDataURL(blob);
	// 	return new Promise((rs, rj) => {
	// 		reader.onloadend = () => {
	// 			rs(reader.result);
	// 		};
	// 		reader.onerror = rj;
	// 	});
	// };

	// const handlebase64 = React.useCallback(async (qr) => {
	// 	const data = await fetch(qr);
	// 	const blob = await data.blob();
	// 	const base64 = await blobToBase64(blob);
	// 	setImageBase64(base64);
	// }, []);

	const findByIdQrCodeApiCall = React.useCallback(() => {
		setImageBase64(false);
		findByIdQrCode(qrId)
			.then((res) => {
				setData(res);
				setInactiveQr(res.inactive);
				setLoad((prev) => ({...prev, updateField: false}));

				// handlebase64(res.qr);
				setImageBase64(`${res.qr}?${new Date().getTime()}`);

				setSnackbar({
					open: true,
					message: "QR Code Data Found Successfully",
					severity: "success",
				});
			})
			.catch((err) => {
				console.error(err);
				setLoad((prev) => ({...prev, updateField: false}));
				setSnackbar({
					open: true,
					message: "Error in Found QR Code Data",
					severity: "error",
				});
			});
	}, [qrId]);

	React.useEffect(() => {
		findByIdQrCodeApiCall();
	}, [findByIdQrCodeApiCall]);

	const handleCancelUpdate = () => {
		findByIdQrCodeApiCall();
		setLoad((prev) => ({...prev, updateField: updateData.field}));
		setUpdateData((prev) => ({...prev, dialogOpen: false}));
	};

	const handleUpdateApiCall = React.useCallback(() => {
		setUpdateData((prev) => ({...prev, dialogOpen: false}));
		setLoad((prev) => ({...prev, updateField: field}));
		const {field, value} = updateData;
		updateQrCode(
			{
				payload: {
					qrData: {
						[field]: value,
					},
				},
			},
			qrId
		)
			.then((res) => {
				setData(res);
				setLoad((prev) => ({...prev, updateField: false}));
				setSnackbar({
					open: true,
					message: field + " Updated Successfully",
					severity: "success",
				});
			})
			.catch((err) => {
				console.error(err);
				setLoad((prev) => ({...prev, updateField: false}));
				setSnackbar({
					open: true,
					message: "Error in Update " + field,
					severity: "error",
				});
			});
	}, [qrId, updateData]);

	const renderComp = () => {
		if (data) {
			return (
				<React.Fragment>
					<Grid container spacing={4}>
						<Grid item xs={8}>
							<Card style={{marginBottom: "5px"}}>
								<Typography
									style={{
										height: "50px",
										background: "#E0E0E0",
										width: "100%",
										padding: "0 0 0 20px",
										fontSize: "20px",
										color: "#666",
										borderBottom: "1px solid #555",
									}}
									className={classes.aic}
								>
									<b>QR Code Details</b>
								</Typography>
								<Box style={{padding: "20px", background: "#FFF"}}>
									<Grid container spacing={1}>
										<Grid item xs={4}>
											<Typography style={{color: "#666", fontSize: "18px"}}>
												<b>Name :</b>
											</Typography>
										</Grid>
										<Grid item xs={8}>
											<Typography
												style={{
													color: "#555",
													fontSize: "18px",
													display: "flex",
													alignItems: "center",
												}}
											>
												{load.updateField === "title" ? (
													<CircularProgress
														style={{height: "20px", width: "20px"}}
													/>
												) : (
													<React.Fragment>
														<Tooltip
															title="Click on text to edit text"
															placement="left-start"
														>
															<Edit
																style={{fontSize: "15px", marginRight: "10px"}}
															/>
														</Tooltip>
														<EasyEdit
															type="text"
															onSave={(event) => {
																setUpdateData({
																	dialogOpen: true,
																	content: false,
																	field: "title",
																	value: event,
																});
															}}
															saveButtonLabel={<Check />}
															saveButtonStyle={classes.easyEditSave}
															cancelButtonLabel={<Clear />}
															cancelButtonStyle={classes.easyEditCancel}
															// cancelOnBlur={true}
															value={
																data.title && data.title !== ""
																	? data.title
																	: "QR Code"
															}
														/>
													</React.Fragment>
												)}
											</Typography>
										</Grid>

										<Grid item xs={4}>
											<Typography style={{color: "#666", fontSize: "18px"}}>
												<b>Type :</b>
											</Typography>
										</Grid>
										<Grid item xs={8}>
											<Typography style={{color: "#555", fontSize: "18px"}}>
												{data.type}
											</Typography>
										</Grid>

										<Grid item xs={4}>
											<Typography style={{color: "#666", fontSize: "18px"}}>
												<b>Category :</b>
											</Typography>
										</Grid>
										<Grid item xs={8}>
											<Typography style={{color: "#555", fontSize: "18px"}}>
												{data.qrType.title}
											</Typography>
										</Grid>

										<Grid item xs={4}>
											<Typography style={{color: "#666", fontSize: "18px"}}>
												<b>Tags :</b>
											</Typography>
										</Grid>
										<Grid item xs={8}>
											<Typography style={{color: "#555", fontSize: "18px"}}>
												{data.tags &&
													data.tags.length > 0 &&
													data.tags.map((ele, index) => (
														<Chip
															key={index}
															size="small"
															label={ele}
															style={{margin: "0 2px"}}
														/>
													))}
											</Typography>
										</Grid>

										<Grid item xs={4}>
											<Typography style={{color: "#666", fontSize: "18px"}}>
												<b>Capture Leads :</b>
											</Typography>
										</Grid>
										<Grid item xs={8}>
											<Typography style={{color: "#555", fontSize: "18px"}}>
												{data.captureLeads ? "Yes" : "No"}
											</Typography>
										</Grid>

										<Grid item xs={4}>
											<Typography style={{color: "#666", fontSize: "18px"}}>
												<b>Password Protected :</b>
											</Typography>
										</Grid>
										<Grid item xs={8}>
											<Typography style={{color: "#555", fontSize: "18px"}}>
												{data.passwordProtected ? "Yes" : "No"}
											</Typography>
										</Grid>

										<Grid item xs={4}>
											<Typography style={{color: "#666", fontSize: "18px"}}>
												<b>App Url :</b>
											</Typography>
										</Grid>
										<Grid item xs={8}>
											<Typography style={{color: "#555", fontSize: "18px"}}>
												{data.appUrl}
											</Typography>
										</Grid>
									</Grid>
								</Box>
							</Card>

							<Card style={{marginBottom: "5px", marginTop: "30px"}}>
								<Typography
									style={{
										height: "50px",
										background: "#E0E0E0",
										width: "100%",
										padding: "0 0 0 20px",
										fontSize: "20px",
										color: "#666",
										borderBottom: "1px solid #555",
									}}
									className={classes.aic}
								>
									<b>Content</b>
								</Typography>
								<Box
									style={{
										padding: "20px",
										background: "#FFF",
										display: "flex",
										alignItems: "center",
									}}
								>
									<Typography style={{color: "#444"}}>
										Use this option to view and edit the content linked with the
										QR Code. As this is a Dynamic QR Code, linked content can be
										updated anytime.
									</Typography>
									<Button
										variant="outlined"
										color="primary"
										onClick={() => {
											navigate("/qr/edit/content/" + qrId, {
												state: {
													uiData: data.uiData,
													title: data.title,
													qrType: data.qrType,
												},
											});
										}}
									>
										View/Edit
									</Button>
								</Box>
							</Card>

							<Card style={{marginBottom: "5px", marginTop: "30px"}}>
								<Typography
									style={{
										height: "50px",
										background: "#E0E0E0",
										width: "100%",
										padding: "0 0 0 20px",
										fontSize: "20px",
										color: "#666",
										borderBottom: "1px solid #555",
									}}
									className={classes.aic}
								>
									<b>Advanced Settings</b>
								</Typography>
								<Box
									style={{
										padding: "20px",
										background: "#FFF",
										display: "flex",
										alignItems: "center",
									}}
								>
									<Typography style={{color: "#444"}}>
										Use this option to view, edit, enable/disable settings of
										advanced features—Password-protection, Lead Generation, and
										GPS tracking.
									</Typography>
									<Button
										variant="outlined"
										color="primary"
										onClick={() => {
											navigate("/qr/edit/settings/" + qrId, {
												state: {
													uiData: data.uiData,
													title: data.title,
													qrType: data.qrType,
													qrData: {
														passwordProtected: data.passwordProtected,
														type: data.type,
														captureLeads: data.captureLeads,
														leadId: data?.leadId || "",
													},
												},
											});
										}}
									>
										View/Edit
									</Button>
								</Box>
							</Card>

							<Card style={{marginBottom: "5px", marginTop: "30px"}}>
								<Typography
									style={{
										height: "50px",
										background: "#E0E0E0",
										width: "100%",
										padding: "0 0 0 20px",
										fontSize: "20px",
										color: "#666",
										borderBottom: "1px solid #555",
									}}
									className={classes.aic}
								>
									<b>Analytics</b>
								</Typography>
								<Box
									style={{
										padding: "20px",
										background: "#FFF",
										display: "flex",
										alignItems: "center",
										justifyContent: "space-between",
									}}
								>
									<Typography style={{color: "#444"}}>
										Use this option to view scan analytics of this QR Code.
									</Typography>
									<Button
										variant="outlined"
										color="primary"
										onClick={() =>
											navigate("/qr/analytics", {
												state: {
													qrId: data.id,
													qrTitle: data.title,
												},
											})
										}
									>
										View
									</Button>
								</Box>
								<Typography
									style={{padding: "0 20px 20px", color: "#333"}}
									variant="h5"
								>
									Total Scan : {data.scanCount}
								</Typography>
							</Card>
						</Grid>

						<Grid item xs={4}>
							<Typography
								style={{
									height: "50px",
									background: "#E0E0E0",
									width: "100%",
									padding: "0 0 0 20px",
									fontSize: "20px",
									color: "#666",
									borderBottom: "1px solid #555",
								}}
								className={classes.center}
							>
								<b>QR Code Activate/ Deactivate </b>
								<div style={{marginLeft: "10px"}}>
									{load.activeSwitch ? (
										<CircularProgress style={{height: "20px", width: "20px"}} />
									) : (
										<Android12Switch
											checked={!inactiveQr}
											onChange={handleInactivaeQr}
										/>
									)}
								</div>
							</Typography>
							<Box
								style={{
									height: "350px",
									border: "1px solid #999",
									marginTop: "15px",
									padding: "15px",
								}}
								className={classes.center}
							>
								{imageBase64 && (
									<img
										alt=""
										src={imageBase64}
										style={{
											height: "100%",
											objectFit: "contain",
											marginRight: "10px",
											filter: inactiveQr ? "opacity(50%)" : "opacity(100%)",
										}}
									/>
								)}
							</Box>
							<Button
								variant="outlined"
								style={{marginTop: "15px"}}
								fullWidth
								startIcon={<Edit />}
								onClick={() =>
									navigate("/qr/customize/design/" + data.id, {
										state: {
											url: data.appUrl,
											qr: imageBase64,
											qrDesignData: data.qrDesignData,
										},
									})
								}
							>
								Edit Design
							</Button>
							<Button
								variant="contained"
								color="primary"
								style={{marginTop: "15px"}}
								fullWidth
								startIcon={<CloudDownload />}
								onClick={() => {
									var link = document.createElement("a");
									link.href = imageBase64;
									link.download = data.title
										? data.title + "-HotQR.png"
										: "My QR - HotQR.png";
									document.body.appendChild(link);
									link.click();
									document.body.removeChild(link);
									setSnackbar({
										open: true,
										message: "QR Code Downloaded Successfully",
										severity: "success",
									});
								}}
							>
								Download
							</Button>
						</Grid>
					</Grid>

					<Dialog
						open={updateData.dialogOpen}
						TransitionComponent={Transition}
						keepMounted
						onClose={() => {
							setUpdateData((prev) => ({...prev, dialogOpen: false}));
						}}
						aria-describedby="alert-dialog-slide-description"
					>
						<DialogTitle>{"Update Qr Content"}</DialogTitle>
						<DialogContent>
							<DialogContentText id="alert-dialog-slide-description">
								Are you Sure want to Update, <br />
								{updateData.field} : {updateData.value}
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button
								onClick={handleCancelUpdate}
								variant="contained"
								style={{color: "#FFF", background: "#1d8f48"}}
							>
								Cancel
							</Button>
							<Button
								onClick={handleUpdateApiCall}
								variant="contained"
								style={{color: "#FFF", background: "#c72a2a"}}
							>
								Yes, I'm Sure
							</Button>
						</DialogActions>
					</Dialog>

					{/* <CustomizeQrModal
                    open={openModal}
                    handleClose={() => setOpenModal(false)}
                    id={data.id}
                    url={data.appUrl}
                /> */}

					<Snackbar
						open={snackbar.open}
						anchorOrigin={{vertical: "top", horizontal: "right"}}
						autoHideDuration={5000}
						onClose={() => {
							setSnackbar({open: false});
						}}
					>
						<Alert
							onClose={() => {
								setSnackbar({open: false});
							}}
							severity={snackbar.severity}
							sx={{width: "100%"}}
						>
							{snackbar.message}
						</Alert>
					</Snackbar>
				</React.Fragment>
			);
		} else {
			return (
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "80vh",
						width: "100%",
					}}
				>
					<CircularProgress />
				</div>
			);
		}
	};

	return (
		<React.Fragment>
			<Container maxWidth="xl">
				<PageTitle
					title={`My QR Code > ${data?.title || "Qr Code"}`}
					subTitle="View, edit, design, and download QR Code"
				/>
				{renderComp()}
			</Container>
		</React.Fragment>
	);
}

export default ViewQr;
