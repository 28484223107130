import React from "react";
import { useNavigate } from "react-router";

// ----------------- import Components ------------------ //
import {
	Box,
	Card,
	Typography,
	Button,
	TextField,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	InputAdornment,
	FormGroup,
	FormControlLabel,
	Checkbox,
	Slide,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core-v4";
import SwitchSelector from "react-switch-selector";
import Select from "react-select";
import { filterAllLead } from "../../Services/LeadServices/ApiUrl";

//----------------- import input Tag Components ------------ //

import TagsInput from "./TagsInput";

// --------------- import icons ---------------------- //

import { AiOutlineQrcode, FcLock } from "react-icons/all";

// ----------------- import CSS ------------------ //
import "html5-device-mockups/dist/device-mockups.min.css";

// ----------------- import Api's ------------------ //
import { createQrCode } from "../../Services/QrGenerateServices/ApiUrl";
import AuthContext from "../../../../../Context/AuthContext";
import { CircularProgress } from "@mui/material";

// --------------- Custome makeStyles ----------- //

const useStyles = makeStyles((theme) => ({
	card: {
		"& div > div": {
			height: "40px",
			padding: "0",
		},
		"& div > div > div": {
			width: "40px",
			height: "100%",
			padding: "0",
			borderRadius: "5px",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			margin: "-5px 5px 0 5px",
		},
	},
	boxTags: {
		"& div > div > div": {
			height: "40px",
		},
		"& div > div > div > div": {
			height: "35px",
		},
	},
	selectSwitch: {
		width: 300,
		height: 38,
		border: "1px solid #e0e0E0",
		borderRadius: "6px",
		"& div": {
			borderRadius: "6px",
		},
	},
}));

const Transition = React.forwardRef(function Transition(props, ref) {
	return (
		<Slide
			direction="up"
			ref={ref}
			{...props}
		/>
	);
});

function QrCodeGenerate(props) {
	const navigate = useNavigate();
	const classes = useStyles();

	const context = React.useContext(AuthContext);

	// -------------------- Manage States --------------------- //

	const [data, setData] = React.useState({
		title: "",
		tags: [],
		type: "STATIC",
		passwordProtected: false,
		password: "",
		confPassword: "",
		captureLeads: false,
		leadId: "",
	});
	const [btn, setBtn] = React.useState(false);
	const [leadList, setLeadList] = React.useState([]);
	const [emptyTitle, setEmptyTitle] = React.useState(false);
	const [passDialog, setPassDialog] = React.useState(data.password);
	const [passMatch, setPassMatch] = React.useState(false);
	const [exceedLimit, setExceedLimit] = React.useState(false);

	//------------------- Qr Code Create ApiCall ------------------ //

	const createQrCodeApiCall = React.useCallback(
		(payload) => {
			createQrCode({ payload: payload })
				.then((res) => {
					setBtn(false);
					navigate("/qr/view/" + res.id);
					context.setQrCount(context.qrCount + 1);
					context.setStepModel((prev) => prev.setCurrent(0));
				})
				.catch((err) => {
					console.error(err);
				});
		},
		[navigate, context]
	);

	// ----------------- Submit Data Function------------------- //

	console.log(context.license.qrcode_limit, context.qrCount);

	const handleSubmit = () => {
		setBtn(true);
		let newData = data;
		if (context.license.qrcode_limit > context.qrCount) {
			if (newData.captureLeads) {
				if (
					newData.leadId === "" ||
					newData.leadId === " " ||
					newData.leadId === undefined ||
					newData.leadId === null
				) {
					delete newData.leadId;
					newData.captureLeads = false;
				}
			}
			if (newData.passwordProtected) {
				delete newData.confPassword;
			} else {
				delete newData.confPassword;
				delete newData.password;
				delete newData.passwordProtected;
			}
			let payload = {
				qrData: {
					uiData: context.qrData,
					...{ ...newData, tags: [...newData.tags, newData.title] },
				},
				qrTypeId: context.category.id,
			};
			createQrCodeApiCall(payload);
		} else {
			setExceedLimit(true);
			setBtn(false);
		}
	};

	// ----------------- Tags Input Chnage Function --------------------- //

	const handleSelecetedTags = React.useCallback((data) => {
		setData((prev) => ({ ...prev, tags: data }));
	}, []);

	const findAllLeadApiCall = React.useCallback(() => {
		filterAllLead({ payload: {} })
			.then((res) => {
				setLeadList(res.Items);
			})
			.catch((err) => {
				console.error(err);
			});
	}, []);

	React.useEffect(() => {
		findAllLeadApiCall();
	}, [findAllLeadApiCall]);

	// ----------------------- main return --------------------- //

	return (
		<Box style={{ marginTop: "30px", padding: "0", position: "relative" }}>
			<Card
				elevation={2}
				style={{ padding: "20px", marginBottom: "5px" }}
			>
				<Box>
					<Typography style={{ color: "#666" }}>
						<b>QR Code Name</b>
					</Typography>
					<Box
						className={classes.card}
						style={{ marginTop: "10px" }}
					>
						<TextField
							type="text"
							color="primary"
							onChange={(e) => setData((prev) => ({ ...prev, title: e.target.value }))}
							fullWidth
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<AiOutlineQrcode style={{ fontSize: "28px" }} />
									</InputAdornment>
								),
							}}
						/>
					</Box>
					{emptyTitle && (
						<Typography style={{ color: "red", fontSize: "15px" }}>
							Please Enter Title of QrCode
						</Typography>
					)}
				</Box>
				<Box style={{ marginTop: "20px" }}>
					<Typography style={{ color: "#666" }}>
						<b>Tags</b>
					</Typography>
					<Box
						className={classes.boxTags}
						style={{ marginTop: "10px" }}
					>
						<TagsInput
							selectedTags={handleSelecetedTags}
							fullWidth
							variant="outlined"
							name="tags"
							placeholder="Tags"
						/>
					</Box>
					<Typography style={{ color: "#666", fontSize: "15px" }}>
						Hit Enter after typing each tag
					</Typography>
				</Box>
				<Box style={{ marginTop: "40px" }}>
					<Typography style={{ color: "#666" }}>
						<b>QR Code Type</b>
					</Typography>
					<Box style={{ width: "300px", marginTop: "10px" }}>
						<div
							className={classes.selectSwitch}
							style={{ width: 300, height: 38 }}
						>
							<SwitchSelector
								onChange={(e) => setData((prev) => ({ ...prev, type: e }))}
								options={[
									{ label: "STATIC", value: "STATIC" },
									{ label: "DYNAMIC", value: "DYNAMIC" },
								]}
								initialSelectedIndex={0}
								fontColor="#000"
								optionBorderRadius="6"
								selectedBackgroundColor="#FF800C"
							/>
						</div>
					</Box>
					<Typography
						style={{ color: "#666", fontSize: "15px", paddingLeft: "0px" }}
					>
						[Recommended] Dynamic QR Codes have useful capabilities-content editing,
						scan tracking, and landing pages-but need an ongoing subscription with
						HotQR to work
					</Typography>
				</Box>
				<Box style={{ marginTop: "40px" }}>
					<Typography style={{ color: "#666", fontSize: "20px" }}>
						<b>Advance Settings</b>
					</Typography>
					<Box>
						<FormGroup>
							<FormControlLabel
								control={
									<Checkbox
										checked={data.passwordProtected}
										onChange={(e) => {
											setData((prev) => ({
												...prev,
												passwordProtected: e.target.checked,
											}));
											!data.passwordProtected && setPassDialog(true);
											data.passwordProtected &&
												setData((prev) => ({
													...prev,
													confPassword: "",
													password: "",
												}));
										}}
									/>
								}
								label={
									<React.Fragment>
										<b style={{ color: "#666", fontSize: "18px" }}>
											Add Password Protection
										</b>
										<FcLock
											style={{
												marginLeft: "5px",
												fontSize: "22px",
												marginTop: "-5px",
											}}
										/>
									</React.Fragment>
								}
							/>
						</FormGroup>
						<Typography
							style={{ color: "#666", fontSize: "15px", paddingLeft: "30px" }}
						>
							Use this option to add password protection to Dynamic QR Codes. Password
							will be required to access QR Code content after scanning
						</Typography>
					</Box>
					<Box>
						<Box>
							<FormGroup>
								<FormControlLabel
									control={
										<Checkbox
											checked={data.captureLeads}
											onChange={(e) => {
												setData((prev) => ({
													...prev,
													captureLeads: e.target.checked,
												}));
												if (!e.target.checked) {
													setData((prev) => ({
														...prev,
														leadId: "",
													}));
												}
											}}
										/>
									}
									label={
										<React.Fragment>
											<b style={{ color: "#666", fontSize: "18px" }}>Capture Leads</b>
										</React.Fragment>
									}
								/>
							</FormGroup>
							<Typography
								style={{ color: "#666", fontSize: "15px", paddingLeft: "30px" }}
							>
								Use this option to get contact information of people who scan the QR
								Code. They will get a quick form to fill before seeing the QR Code
								content
							</Typography>
						</Box>
						{data.captureLeads && (
							<Box sx={{ pl: "30px", width: "50%", mt: 1.5 }}>
								<Typography
									style={{ color: "#444", fontSize: "15px", marginBottom: "7px" }}
								>
									Choose Lead List :
								</Typography>
								<Select
									onChange={(e) => setData((prev) => ({ ...prev, leadId: e.value }))}
									width="300px"
									maxMenuHeight="240px"
									placeholder="Choose list from dropdown"
									isClearable={false}
									menuShouldScrollIntoView={true}
									closeMenuOnScroll={true}
									value={
										leadList
											?.map((ele) => {
												if (ele.id === data.leadId) {
													return {
														label: ele.title,
														value: ele.id,
													};
												} else return null;
											})
											?.filter((ele) => ele !== null) || []
									}
									theme={(theme) => ({
										...theme,
										colors: {
											...theme.colors,
											text: "#FFF",
											primary25: "#FF800C",
											primary: "#FF800C",
										},
									})}
									options={leadList.map((ele) => {
										return {
											label: ele.title,
											value: ele.id,
										};
									})}
								/>
							</Box>
						)}
					</Box>
				</Box>
				<Button
					variant="contained"
					color="primary"
					style={{ marginTop: "30px" }}
					onClick={() => {
						if (data.title === "") {
							setEmptyTitle(true);
						} else {
							setEmptyTitle(false);
							handleSubmit();
						}
					}}
					disabled={btn}
				>
					Create QR Code
				</Button>
			</Card>

			<Dialog
				open={exceedLimit}
				TransitionComponent={Transition}
				keepMounted
				onClose={() => {
					setExceedLimit(false);
				}}
				aria-describedby="alert-dialog-slide-description"
			>
				<DialogTitle>Qr Code Generate</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-slide-description">
						Your QR Code Generate Limit Exceed. Please go to subscription page and buy
						a plan.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => {
							setExceedLimit(false);
						}}
						variant="contained"
						style={{ color: "#FFF", background: "#c72a2a" }}
					>
						No
					</Button>
					<Button
						onClick={() => {
							navigate("/qr/subscription");
						}}
						variant="contained"
						style={{ color: "#FFF", background: "#1d8f48" }}
					>
						Go to Subscription Page
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				open={passDialog}
				TransitionComponent={Transition}
				keepMounted
				aria-describedby="alert-dialog-slide-description"
			>
				<DialogTitle>{"Add Password Protection"}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-slide-description">
						<Typography style={{ color: "#666" }}>
							<b>Enter Password</b>
						</Typography>
						<Box
							className={classes.card}
							style={{ marginTop: "5px" }}
						>
							<TextField
								type="text"
								color="primary"
								onChange={(e) =>
									setData((prev) => ({ ...prev, password: e.target.value }))
								}
								fullWidth
								value={data.password}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<FcLock style={{ fontSize: "28px" }} />
										</InputAdornment>
									),
								}}
							/>
						</Box>
						<Typography style={{ color: "#666", marginTop: "10px" }}>
							<b>Confirm Password</b>
						</Typography>
						<Box
							className={classes.card}
							style={{ marginTop: "5px" }}
						>
							<TextField
								type="text"
								color="primary"
								onChange={(e) =>
									setData((prev) => ({ ...prev, confPassword: e.target.value }))
								}
								fullWidth
								value={data.confPassword}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<FcLock style={{ fontSize: "28px" }} />
										</InputAdornment>
									),
								}}
							/>
						</Box>
						{passMatch && (
							<Typography style={{ color: "red", fontSize: "12px" }}>
								Password Doesn't Match
							</Typography>
						)}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => {
							setPassDialog(false);
							setData((prev) => ({ ...prev, passwordProtected: false }));
						}}
						variant="contained"
						style={{ color: "#FFF", background: "#1d8f48" }}
					>
						Cancel
					</Button>
					<Button
						onClick={() => {
							if (data.password === data.confPassword) {
								setPassMatch(false);
								setPassDialog(false);
							} else {
								setPassMatch(true);
								setData((prev) => ({ ...prev, password: "", confPassword: "" }));
							}
						}}
						variant="contained"
						style={{
							color: "#FFF",
							background:
								data.password === "" && data.confPassword === "" ? "#999" : "#c72a2a",
						}}
						disabled={data.password === "" && data.confPassword === "" ? true : false}
					>
						Submit
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				open={btn}
				TransitionComponent={Transition}
				keepMounted
			>
				<DialogContent>
					<Box
						style={{
							height: "100px",
							width: "180px",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							flexDirection: "column",
						}}
					>
						<Typography sx={{ marginBottom: "20px" }}>
							Generating qr code...
						</Typography>
						<CircularProgress />
					</Box>
				</DialogContent>
			</Dialog>
		</Box>
	);
}

export default QrCodeGenerate;
