import React from "react";
import {Grid, Box, useScrollTrigger, Fab, Fade} from "@mui/material";
import {KeyboardArrowUp} from "@material-ui/icons";
import TriggeredArea from "./AnalyticsComp/TriggeredArea";
import AnalyticsArea from "./AnalyticsComp/AnalyticsArea";
import {leadAnalytics} from "../Services/LeadServices/ApiUrl";
import {CircularProgress} from "@material-ui/core";
import {useParams} from "react-router";

// import { makeStyles } from "@mui/styles";

// const useStyle = makeStyles(() => ({}));

function ScrollTop(props) {
	const {children} = props;
	const trigger = useScrollTrigger({
		// target: window ? window() : undefined,
		disableHysteresis: true,
		threshold: 100,
	});

	const handleClick = (event) => {
		window.scrollTo(0, 0);
	};

	return (
		<Fade in={trigger}>
			<Box
				onClick={handleClick}
				role="presentation"
				sx={{position: "fixed", bottom: 16, right: 16}}
			>
				{children}
			</Box>
		</Fade>
	);
}

function Analytics(props) {
	const params = useParams();
	const {leadId} = params;
	const {leadData} = props;
	const [analyticsData, setAnalyticsData] = React.useState([]);
	const [isLoad, setLoad] = React.useState(true);
	const [dateRange, setDateRange] = React.useState([new Date(), new Date()]);

	const handleSetLoad = React.useCallback((data) => {
		setLoad(() => data);
	}, []);

	document.addEventListener("contextmenu", (event) => event.preventDefault());

	// document.onkeydown = function (e) {
	// 	// disable F12 key
	// 	if (e.keyCode == 123) {
	// 		return false;
	// 	}

	// 	// disable I key
	// 	if (e.ctrlKey && e.shiftKey && e.keyCode === 73) {
	// 		return false;
	// 	}

	// 	// disable J key
	// 	if (e.ctrlKey && e.shiftKey && e.keyCode === 74) {
	// 		return false;
	// 	}

	// 	// disable U key
	// 	if (e.ctrlKey && e.keyCode == 85) {
	// 		return false;
	// 	}
	// };

	console.log("dateRange", dateRange);

	const returnDate = (dateString, date) => {
		let month = {
			Jan: "01",
			Feb: "02",
			Mar: "03",
			Apr: "04",
			May: "05",
			Jun: "06",
			Jul: "07",
			Aug: "08",
			Sep: "09",
			Oct: "10",
			Nov: "11",
			Dec: "12",
		};
		let dateArr = dateString.toDateString().split(" ").slice(1, 4);
		if (date) {
			return `${dateArr[1]}-${month[dateArr[0]]}-${dateArr[2]}`;
		} else {
			return `${dateArr[2]}-${month[dateArr[0]]}-${dateArr[1]}`;
		}
	};

	const getLeadAnalyticsApiCall = React.useCallback(
		(date, fDate, tDate) => {
			handleSetLoad(false);
			let payload;
			if (date === true) {
				payload = {
					leadId: leadId,
				};
			} else {
				payload = {
					leadId: leadId,
					fromDate: returnDate(fDate, false),
					toDate: returnDate(tDate, false),
				};
			}
			leadAnalytics({payload: payload})
				.then((res) => {
					handleSetLoad(true);
					setAnalyticsData(res);
				})
				.catch((err) => {
					console.error(err);
				});
		},
		[leadId, handleSetLoad]
	);

	React.useEffect(() => {
		getLeadAnalyticsApiCall(true);
	}, [getLeadAnalyticsApiCall]);

	if (analyticsData) {
		return (
			<Box sx={{padding: "25px"}}>
				<Grid container>
					<Grid item xs={12}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<TriggeredArea
									leadData={leadData}
									analyticsData={analyticsData}
									handleSubmit={() =>
										getLeadAnalyticsApiCall(false, dateRange[0], dateRange[1])
									}
									dateRange={dateRange}
									setDateRange={setDateRange}
								/>
							</Grid>
							<Grid item xs={12}>
								<AnalyticsArea
									analyticsValues={analyticsData}
									showData={isLoad}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<ScrollTop {...props}>
					<Fab size="small" color="primary" aria-label="scroll back to top">
						<KeyboardArrowUp />
					</Fab>
				</ScrollTop>
			</Box>
		);
	} else {
		return (
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					height: "70vh",
					width: "100%",
				}}
			>
				<CircularProgress />
			</div>
		);
	}
}
export default Analytics;
