import React from "react";
import {Grid, Typography, Box, IconButton, Button} from "@material-ui/core";
import {FaDownload} from "react-icons/all";

import AudioPlayer from "./Components/AudioComp/AudioPlayer";

function AudioComp(props) {
	const {data} = props;
	const {audio} = data;

	const [audioUrl, setAudioUrl] = React.useState(audio);

	const LongText = ({content, limit}) => {
		if (content.length <= limit) {
			return (
				<span style={{padding: "0", margin: "0", fontSize: "15px"}}>
					{content}
				</span>
			);
		}
		const toShow = content.substring(0, limit) + "...";
		return (
			<span style={{padding: "0", margin: "0", fontSize: "15px"}}>
				{toShow}
			</span>
		);
	};

	return (
		<React.Fragment>
			<Grid container spacing={1} style={{marginTop: "5px"}}>
				<Grid item xs={12}>
					<Box
						style={{
							padding: "15px",
							borderRadius: "8px",
							border: "1px solid #999",
						}}
					>
						<Typography variant="h5">{audio && audio.length} Audio</Typography>
					</Box>
				</Grid>
				<Grid item xs={12}>
					<Box style={{margin: "10px 0"}}>
						{audioUrl && audioUrl.length > 0 && (
							<AudioPlayer tracks={audioUrl ? audioUrl : []} />
						)}
					</Box>
				</Grid>
				{audio &&
					audio.length > 0 &&
					audio.map((file, index) => (
						<Grid item xs={12} key={index}>
							<Button
								style={{
									padding: "5px 15px",
									borderRadius: "8px",
									display: "flex",
									alignItems: "center",
									justifyContent: "space-between",
									background: "#FFF",
								}}
								variant="outlined"
								fullWidth
								onClick={() => {
									setAudioUrl([file]);
								}}
							>
								<LongText content={file.name} limit={15} />
								<IconButton
									onClick={() => {
										var a = document.createElement("a");
										a.href = file.url;
										a.download = file.name ? file.name : "dowload";
										a.click();
									}}
								>
									<FaDownload />
								</IconButton>
							</Button>
						</Grid>
					))}
			</Grid>
		</React.Fragment>
	);
}
export default AudioComp;
