import React from "react";
import {useLocation} from "react-router";
import {Grid, Box, Container, useScrollTrigger, Fab, Fade} from "@mui/material";
import {KeyboardArrowUp} from "@material-ui/icons";
import TriggeredArea from "./TriggeredArea";
import AnalyticsArea from "./AnalyticsArea";
import PageTitle from "../../../UI/PageTitle";

// import { makeStyles } from "@mui/styles";

// const useStyle = makeStyles(() => ({}));

function ScrollTop(props) {
	const {children} = props;
	const trigger = useScrollTrigger({
		// target: window ? window() : undefined,
		disableHysteresis: true,
		threshold: 100,
	});

	const handleClick = (event) => {
		window.scrollTo(0, 0);
	};

	return (
		<Fade in={trigger}>
			<Box
				onClick={handleClick}
				role="presentation"
				sx={{position: "fixed", bottom: 16, right: 16}}
			>
				{children}
			</Box>
		</Fade>
	);
}

function Analytics(props) {
	const location = useLocation();
	const qrId = location
		? location?.state
			? location?.state?.qrId
				? location?.state?.qrId
				: ""
			: ""
		: "" || false;
	const qrTitle = location
		? location?.state
			? location?.state?.qrTitle
				? location?.state?.qrTitle
				: ""
			: ""
		: "" || false;
	const [analyticsData, setAnalyticsData] = React.useState([]);
	const [isLoad, setLoad] = React.useState(true);

	const handleAnalyticsData = React.useCallback((data) => {
		setAnalyticsData(() => data);
	}, []);

	const handleSetLoad = React.useCallback((data) => {
		setLoad(() => data);
	}, []);

	document.addEventListener("contextmenu", (event) => event.preventDefault());

	// document.onkeydown = function (e) {
	// 	// disable F12 key
	// 	if (e.keyCode == 123) {
	// 		return false;
	// 	}

	// 	// disable I key
	// 	if (e.ctrlKey && e.shiftKey && e.keyCode === 73) {
	// 		return false;
	// 	}

	// 	// disable J key
	// 	if (e.ctrlKey && e.shiftKey && e.keyCode === 74) {
	// 		return false;
	// 	}

	// 	// disable U key
	// 	if (e.ctrlKey && e.keyCode == 85) {
	// 		return false;
	// 	}
	// };

	return (
		<Container maxWidth="xl" sx={{padding: 0, margin: "auto"}}>
			<Grid container>
				<Grid item xs={12}>
					<PageTitle
						title="Analytics"
						subTitle="
								Track QR Code scanning activity based on date, geography, and
								device"
						backBtn={qrId ? false : true}
					/>
				</Grid>
				<Grid item xs={12} sx={{padding: "1.5rem", backgroundColor: "#ffffff"}}>
					{/* <Box sx={{padding: "1.5rem "}}>
						<Tabs
							value={value}
							onChange={handleChange}
							sx={{
								maxWidth: "660px",
								"& > div > div": {
									display: "flex",
									justifyContent: "space-evenly",
									"& > button": {
										width: "215px",
										height: "37px",
										float: "left",
										backgroundColor: "#f8f8f8",
										color: "grey",
									},
									"& > button.Mui-selected": {
										backgroundColor: "primary.main",
										color: "#ffff",
									},
								},
								"& .MuiTabs-indicator": {
									display: "none",
								},
							}}
							aria-label="basic tabs example"
						>
							<Tab label="ANALYTICS" {...a11yProps(0)} />
							<Tab label="INTEGRATIONS" {...a11yProps(1)} />
							<Tab label="NOTIFICATIONS" {...a11yProps(2)} />
						</Tabs>
					</Box> */}
					{/* <TabPanel value={value} index={0}> */}
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<TriggeredArea
								id={qrId}
								qrTitle={qrTitle}
								handleSetLoad={handleSetLoad}
								handleAnalyticsData={handleAnalyticsData}
							/>
						</Grid>
						<Grid item xs={12}>
							<AnalyticsArea
								analyticsValues={analyticsData}
								showData={isLoad}
							/>
						</Grid>
					</Grid>
					{/* </TabPanel> */}
					{/* <TabPanel value={value} index={1}>
						Item Two
					</TabPanel>
					<TabPanel value={value} index={2}>
						Item Three
					</TabPanel> */}
				</Grid>
			</Grid>
			<ScrollTop {...props}>
				<Fab size="small" color="primary" aria-label="scroll back to top">
					<KeyboardArrowUp />
				</Fab>
			</ScrollTop>
		</Container>
	);
}
export default Analytics;
