import React from "react";
import {
	Box,
	IconButton,
	Typography,
	TextField,
	FormControlLabel,
	RadioGroup,
	FormControl,
	Radio,
	Checkbox,
	FormGroup,
	InputAdornment,
} from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import {makeStyles} from "@material-ui/core-v4";
import renderHTML from "react-render-html";
import Select from "react-select";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {TimePicker} from "@mui/x-date-pickers/TimePicker";
import {MobileDatePicker} from "@mui/x-date-pickers/MobileDatePicker";
import {
	BsEmojiNeutral,
	BsEmojiFrown,
	BsEmojiSmile,
	BiLike,
	BsCalendar3,
} from "react-icons/all";

const useStyles = makeStyles((theme) => ({
	card: {
		width: "100%",
		"& div > div": {
			height: "35px",
			borderRadius: "5px",
			color: (props) => {
				return props.color ? props.colorCode : "blue";
			},
		},
		"& .MuiFormControl-root > .MuiOutlinedInput-root > input ": {
			marginTop: "-3px",
		},
		"& .MuiFormControl-root > .MuiOutlinedInput-root > .MuiInputAdornment-root > .MuiButtonBase-root ":
			{
				marginTop: "-7px",
			},
	},
	textFieldParagraph: {
		width: "100%",
		"& div > div": {
			borderRadius: "5px",
			padding: "5px 10px 10px 10px !important",
		},
		"& .MuiFormControl-root > .MuiOutlinedInput-root > input ": {
			marginTop: "-3px",
		},
		"& .MuiFormControl-root > .MuiOutlinedInput-root > .MuiInputAdornment-root > .MuiButtonBase-root ":
			{
				marginTop: "-17px",
			},
	},
}));

const customStyles = {
	control: (base) => ({
		...base,
		height: 40,
		width: "100%",
		minHeight: 40,
		borderRadius: "2px",
	}),
};

function LeadFormComp(props) {
	const {data} = props;
	const {textColor, bgColor, description, questionBlock} = data;
	const classes = useStyles({
		color: true,
		colorCode: props?.data?.textColor || false,
	});

	const [state, setState] = React.useState();

	const handleChange = (type, e) => {
		switch (type) {
			case "shortAnswer":
			case "phone":
			case "email":
			case "paragraph":
			case "multipleChoice":
				setState(e.target.value);
				break;
			case "checkboxes":
				setState((prev) => ({...prev, [e.target.name]: e.target.checked}));
				break;
			case "dropdownList":
			case "starRating":
			case "emoji":
			case "likeDislike":
			case "date":
			case "time":
				setState(e);
				break;
			default:
				break;
		}
	};

	const renderQuestionType = (ele) => {
		if (questionBlock?.length > 0 && ele) {
			switch (ele?.type) {
				case "shortAnswer":
					return (
						<Box className={classes.card} sx={{marginTop: "17px"}}>
							<TextField
								type="text"
								fullWidth
								name="shortAnswer"
								placeholder="Write here..."
								value={state}
								onChange={(e) => {
									handleChange(ele.type, e);
								}}
							/>
						</Box>
					);
				case "phone":
					return (
						<Box className={classes.card} sx={{marginTop: "17px"}}>
							<TextField
								type="text"
								fullWidth
								name="phone"
								placeholder="Enter phone number"
								value={state}
								onChange={(e) => {
									handleChange(ele.type, e);
								}}
							/>
						</Box>
					);
				case "email":
					return (
						<Box className={classes.card} sx={{marginTop: "17px"}}>
							<TextField
								type="text"
								fullWidth
								name="email"
								placeholder="Enter email address"
								value={state}
								onChange={(e) => {
									handleChange(ele.type, e);
								}}
							/>
						</Box>
					);
				case "starRating":
					return (
						<Box
							component="fieldset"
							borderColor="transparent"
							sx={{marginTop: "17px"}}
						>
							<Rating
								name="option"
								value={state}
								style={{
									width: "100%",
									fontSize: "40px",
									display: "flex",
									justifyContent: "space-evenly",
								}}
								onChange={(event, newValue) => {
									handleChange(ele.type, newValue);
								}}
							/>
						</Box>
					);
				case "emoji":
					return (
						<Box
							sx={{
								marginTop: "17px",
								display: "flex",
								justifyContent: "space-evenly",
							}}
						>
							<IconButton
								color={state === 1 ? "primary" : "default"}
								onClick={() => handleChange(ele.type, 1)}
							>
								<BsEmojiFrown style={{fontSize: "30px"}} />
							</IconButton>
							<IconButton
								color={state === 2 ? "primary" : "default"}
								onClick={() => handleChange(ele.type, 2)}
							>
								<BsEmojiNeutral style={{fontSize: "30px"}} />
							</IconButton>
							<IconButton
								color={state === 3 ? "primary" : "default"}
								onClick={() => handleChange(ele.type, 3)}
							>
								<BsEmojiSmile style={{fontSize: "30px"}} />
							</IconButton>
						</Box>
					);
				case "likeDislike":
					return (
						<Box
							sx={{
								marginTop: "17px",
								display: "flex",
								justifyContent: "space-around",
							}}
						>
							<IconButton
								color={state === "like" ? "primary" : "default"}
								onClick={() => handleChange(ele.type, "like")}
								sx={{
									height: "80px",
									width: "80px",
									display: "flex",
									flexDirection: "column",
								}}
							>
								<BiLike style={{fontSize: "40px"}} />
								<b style={{fontSize: "16px"}}>Like</b>
							</IconButton>
							<IconButton
								color={state === "dislike" ? "primary" : "default"}
								onClick={() => handleChange(ele.type, "dislike")}
								sx={{
									height: "80px",
									width: "80px",
									display: "flex",
									flexDirection: "column",
								}}
							>
								<BiLike
									style={{fontSize: "40px", transform: "rotate(180deg)"}}
								/>
								<b style={{fontSize: "16px"}}>Dislike</b>
							</IconButton>
						</Box>
					);
				case "paragraph":
					return (
						<Box
							className={classes.textFieldParagraph}
							sx={{marginTop: "17px"}}
						>
							<TextField
								type="text"
								fullWidth
								name="shortAnswer"
								multiline={true}
								minRows={3}
								placeholder="Write here..."
								value={state}
								onChange={(e) => {
									handleChange(ele.type, e);
								}}
							/>
						</Box>
					);
				case "multipleChoice":
					return (
						<FormControl
							component="fieldset"
							sx={{padding: "0 7px", marginTop: "7px"}}
						>
							{/* <FormLabel component="legend">Select any one option</FormLabel> */}
							<RadioGroup
								name="option"
								value={state}
								onChange={(e) => handleChange(ele.type, e)}
							>
								{ele?.options?.map((item, iIndex) => {
									if (
										item.option &&
										item.option !== "" &&
										item.option !== null
									) {
										return (
											<FormControlLabel
												key={iIndex}
												value={item.option}
												control={
													<Radio
														sx={{
															padding:
																ele?.options?.length === iIndex + 1
																	? "5px 5px 0 5px"
																	: "5px",
														}}
													/>
												}
												label={
													<span style={{wordBreak: "break-word"}}>
														{item.option}
													</span>
												}
											/>
										);
									} else return null;
								})}
							</RadioGroup>
						</FormControl>
					);
				case "checkboxes":
					return (
						<FormControl component="fieldset" sx={{margin: "7px 0 0 7px"}}>
							{/* <FormLabel component="legend">Select multiple option</FormLabel> */}
							<FormGroup>
								{ele?.options?.map((item, iIndex) => {
									if (
										item.option &&
										item.option !== "" &&
										item.option !== null
									) {
										return (
											<FormControlLabel
												control={
													<Checkbox
														checked={state?.[item?.option]}
														onChange={(e) => handleChange(ele.type, e)}
														name={item?.option}
														sx={{
															padding:
																ele?.options?.length === iIndex + 1
																	? "5px 5px 0px 5px"
																	: "5px",
														}}
													/>
												}
												label={item.option}
											/>
										);
									} else return null;
								})}
							</FormGroup>
						</FormControl>
					);
				case "dropdownList":
					return (
						<Box sx={{marginTop: "12px", width: "100%"}}>
							<Select
								onChange={(e) => handleChange(ele.type, e.value)}
								width="100%"
								maxMenuHeight="240px"
								placeholder="Select any one option"
								menuShouldScrollIntoView={true}
								closeMenuOnScroll={true}
								value={{
									label: state,
									value: state,
								}}
								theme={(theme) => ({
									...theme,
									colors: {
										...theme.colors,
										text: "#FFF",
										primary25: "#FF800C",
										primary: "#FF800C",
									},
								})}
								styles={customStyles}
								options={ele.options.map((oEle, oIndex) => {
									return {
										label: oEle?.option,
										value: oEle?.option,
									};
								})}
							/>
						</Box>
					);
				case "date":
					return (
						<Box sx={{marginTop: "12px", width: "100%"}}>
							<LocalizationProvider dateAdapter={AdapterDateFns}>
								<MobileDatePicker
									placeholder="Pick Date"
									inputFormat="dd/MM/yyyy"
									value={state ? state : new Date()}
									onChange={(e) => handleChange(ele.type, e)}
									renderInput={(params) => (
										<Box className={classes.card}>
											<TextField {...params} />
										</Box>
									)}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<Box sx={{marginTop: "7px"}}>
													<BsCalendar3 />
												</Box>
											</InputAdornment>
										),
									}}
								/>
							</LocalizationProvider>
						</Box>
					);
				case "time":
					return (
						<Box sx={{marginTop: "12px", width: "100%"}}>
							<LocalizationProvider dateAdapter={AdapterDateFns}>
								<TimePicker
									placeholder="Pick Time"
									value={state ? state : new Date()}
									onChange={(e) => handleChange(ele.type, e)}
									renderInput={(params) => (
										<Box className={classes.card}>
											<TextField {...params} />
										</Box>
									)}
								/>
							</LocalizationProvider>
						</Box>
					);
				default:
					return null;
			}
		} else return null;
	};

	if (
		description !== "" ||
		questionBlock?.filter((iE, iI) => iE?.question !== "").length > 0 ||
		questionBlock?.filter(
			(iE, iI) => iE?.options?.filter((e, i) => e?.option !== "")?.[0]
		)?.length > 0
	) {
		return (
			<React.Fragment>
				<Box
					style={{
						padding: "15px 15px",
						background: bgColor,
						borderRadius: "5px",
						color: textColor,
						marginTop: "10px",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						textAlign: "justify",
					}}
				>
					{description && (
						<Typography
							variant="h5"
							style={{
								marginBottom: "0px",
								width: "100%",
								wordBreak: "break-word",
							}}
						>
							{renderHTML(description)}
						</Typography>
					)}
					{questionBlock?.map((ele, index) => {
						if (
							ele?.options?.filter((eFil) => eFil?.option !== "").length > 0 ||
							ele?.question !== ""
						) {
							return (
								<Box
									sx={{
										mt: index === 0 ? 0 : 1.5,
										pb: index + 1 === questionBlock?.length ? 1 : 2.5,
										pt: 1,
										width: "100%",
										borderBottom:
											questionBlock?.length === index + 1
												? "0px"
												: "1px solid #e0e0e0",
									}}
								>
									{ele?.question && (
										<Typography
											style={{
												width: "100%",
												wordBreak: "break-word",
												lineHeight: "18px",
											}}
										>
											{ele?.question}
										</Typography>
									)}
									{ele?.options?.length > 0 &&
										ele?.options?.filter((eFil) => eFil?.option !== "").length >
											0 && (
											<Box sx={{width: "100%"}}>{renderQuestionType(ele)}</Box>
										)}
								</Box>
							);
						} else return null;
					})}
				</Box>
			</React.Fragment>
		);
	} else {
		return null;
	}
}
export default LeadFormComp;
