import React from "react";
import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Typography,
	Box,
	Grid,
	Checkbox,
	FormControlLabel,
} from "@material-ui/core";
import {ExpandMore} from "@material-ui/icons";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {makeStyles} from "@material-ui/core-v4";

import LocationComp from "./Components/LocationComp";

import BorderColorComp from "./ColorComponents/BorderColor";
import BgColorComp from "./ColorComponents/BgColor";
import TextColorComp from "./ColorComponents/TextColor";
import QrContext from "../../../QrContext/QrContext";

const useStyles = makeStyles((theme) => ({
	box: {
		"& .MuiPaper-root": {
			boxShadow: "none !important",
		},
		"& .MuiPaper-root > .MuiButtonBase-root": {
			border: (props) => (props ? "2px solid #ff3333" : "1px solid #000"),
			borderRadius: "2px",
			background: "#F0F0F0",
			height: "50px !important",
		},
		"& .MuiPaper-root > .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded":
			{
				minHeight: "50px !important",
			},
		"& .MuiPaper-root > .MuiCollapse-root": {
			border: "1px solid #D8D8D8",
			margin: "10px 0",
			borderRadius: "2px",
		},
	},
	center: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	card: {
		width: "100%",
		"& div > div": {
			height: "40px",
			borderRadius: "0 2px 2px 0",
		},
		"& .MuiFormControl-root > .MuiOutlinedInput-root > input ": {
			marginTop: "-3px",
		},
		"& .MuiFormControl-root > .MuiOutlinedInput-root > .MuiInputAdornment-root > .MuiButtonBase-root ":
			{
				marginTop: "-7px",
			},
	},
}));

function DescriptionBoxComp(props) {
	const {data, dataIndex} = props;
	const {
		bgColor,
		textColor,
		location,
		btnBorderColor,
		btnBgColor,
		btnTextColor,
		address,
		required,
	} = data;

	const classes = useStyles(required === "indicate" ? true : false);

	const {setContent} = React.useContext(QrContext);

	if (props.data) {
		const handleChange = (key, value) => {
			setContent((prev) =>
				prev.map((ele, index) => {
					if (index === dataIndex) {
						return {
							...ele,
							[key]: value,
						};
					} else return ele;
				})
			);
		};

		return (
			<div className={classes.box}>
				<Accordion>
					<AccordionSummary expandIcon={<ExpandMore />}>
						<Typography
							style={{color: "#615757", fontSize: "20px", fontWeight: "600"}}
						>
							Event Venue *
						</Typography>
					</AccordionSummary>
					<AccordionDetails style={{display: "flex", alignItems: "center"}}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Grid container spacing={1}>
									<Grid item xs={12}>
										<Typography variant="h5">Venue*</Typography>
									</Grid>
									<Grid item xs={12}>
										<CKEditor
											id="inputText"
											editor={ClassicEditor}
											fontFamily
											onChange={(e, data) =>
												handleChange("address", data.getData())
											}
											data={address}
										/>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={12}>
								<Grid item xs={12}>
									<BgColorComp
										bgColor={bgColor}
										handleChange={(key, value) => handleChange(key, value)}
										name="Background Color"
									/>
								</Grid>
								<Grid item xs={12}>
									<TextColorComp
										textColor={textColor}
										handleChange={(key, value) => handleChange(key, value)}
										name="Text Color"
									/>
								</Grid>
							</Grid>
							<Grid item xs={12}>
								<FormControlLabel
									control={
										<Checkbox
											checked={location}
											onChange={(e) => {
												handleChange("location", e.target.checked);
											}}
										/>
									}
									label={
										<React.Fragment>
											<b style={{color: "#666", fontSize: "18px"}}>
												Show venue location on maps
											</b>
										</React.Fragment>
									}
								/>
							</Grid>
							{location !== false && (
								<React.Fragment>
									<Grid item xs={12}>
										<Box
											style={{
												position: "relative",
												height: "450px",
												border: "1px solid #999",
											}}
										>
											<LocationComp
												height="350px"
												handleChange={(key, value) => handleChange(key, value)}
											/>
										</Box>
									</Grid>
									<Grid item xs={12}>
										<BgColorComp
											bgColor={btnBgColor}
											handleChange={(key, value) => handleChange(key, value)}
											name="Button Background Color"
											btnName={"btnBgColor"}
										/>
									</Grid>
									<Grid item xs={12}>
										<TextColorComp
											textColor={btnTextColor}
											handleChange={(key, value) => handleChange(key, value)}
											name="Button Text Color"
											btnName={"btnTextColor"}
										/>
									</Grid>
									<Grid item xs={12}>
										<BorderColorComp
											borderColor={btnBorderColor}
											handleChange={(key, value) => handleChange(key, value)}
											name="Button Border Color"
											btnName={"btnBorderColor"}
										/>
									</Grid>
								</React.Fragment>
							)}
						</Grid>
					</AccordionDetails>
				</Accordion>
			</div>
		);
	} else {
		return null;
	}
}
export default DescriptionBoxComp;
