import * as React from "react";
import {Box, Grid} from "@material-ui/core";

import classes from "./Page4.module.css";

const gridArray = [
	{
		title: "Multiple QR Code Categories",
		text: "QR Batch allows you to generate the following static QR Codes—Website URL, Text, Vcard, Serial Code, and Random Code",
	},
	{
		title: "Custom QR Code Design",
		text: "Add colors, patterns, and images to create personalized and branded QR Codes that attract more scans",
	},
	{
		title: "High-resolution Images",
		text: "Generate QR Codes in both raster (PNG, JPG) and vector (SVG, EPS, DXF) image formats for high-resolution printing",
	},
	{
		title: "Custom Size",
		text: "Specify exact size of QR Code images in pixels/inches for high-resolution printing",
	},
	{
		title: "QR Code Labels",
		text: "Export QR Codes as printable Labels PDF",
	},
	{
		title: "Sensible Pricing",
		text: "Pay only for the QR Codes you generate. Larger the batch, cheaper the rate per QR Code",
	},
];

function Page4() {
	return (
		<Box className={classes.box}>
			<Grid container spacing={3}>
				<Grid item xs={12} className="d-flex justify-content-center">
					<label className={classes.title}> HOT QR Batch Features </label>
				</Grid>
				<Grid item container>
					{gridArray.map((ele, index) => (
						<Grid
							item
							key={index}
							xs={4}
							className={
								index < 3
									? "d-flex justify-content-center px-5"
									: "d-flex justify-content-center px-5 mt-4"
							}
						>
							<Box className={classes.boxContent}>
								<Box className={classes.labelText}>
									<span style={{color: "#000000"}}>{ele.title}</span>
									<span className="mt-3">{ele.text}</span>
								</Box>
							</Box>
						</Grid>
					))}
				</Grid>
			</Grid>
		</Box>
	);
}

export default Page4;
