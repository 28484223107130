import React from "react";
import {Box, Typography} from "@material-ui/core";

function ErrorCorrection(props) {
	return (
		<Box
			style={{
				marginTop: "10px",
				border: "1px solid #E0E0E0",
				padding: "20px",
				borderRadius: "8px",
			}}
		>
			<Typography
				variant="h5"
				style={{color: "#444", display: "flex", flexDirection: "column"}}
			>
				QR Codes have error correction capability that ensures scannability if
				the code is dirty or damaged.
			</Typography>
			<Typography style={{color: "#555", marginTop: "15px"}}>
				There are 4 error correction levels available. Raising the level
				increases damage tolerance but it makes the QR Code more dense.
			</Typography>
			<Typography
				style={{
					color: "#555",
					marginTop: "15px",
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box
					style={{
						width: "280px",
						borderBottom: "1px solid #DDDDDD",
						textAlign: "center",
						padding: "0 0 5px 0",
					}}
				>
					QR Code Error Correcion Capability
				</Box>
				<Box style={{width: "300px", display: "flex"}}>
					<Box
						style={{
							height: "140px",
							width: "150px",
							borderRight: "1px solid #DDDDDD",
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							justifyContent: "space-around",
						}}
					>
						<span>Level L</span>
						<span>Level M</span>
						<span>Level Q</span>
						<span>Level H</span>
					</Box>
					<Box
						style={{
							height: "140px",
							width: "150px",
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							justifyContent: "space-around",
						}}
					>
						<span>~7%</span>
						<span>~15%</span>
						<span>~25%</span>
						<span>~30%</span>
					</Box>
				</Box>
			</Typography>
			<Typography
				style={{
					color: "#555",
					marginTop: "10px",
					display: "flex",
					flexDirection: "column",
				}}
			>
				<span>Select error correction levels on the following basis:</span>
				<li style={{marginLeft: "10px"}}>
					Expecting wear and tear: Level Q or Level H
				</li>
				<li style={{marginLeft: "10px"}}>
					Not expecting wear and tear: Level L or Level M
				</li>
			</Typography>
			<Typography variant="h5" style={{color: "#555", marginTop: "30px"}}>
				<span style={{color: "#FC800D"}}>Note : </span>
				<span>
					Error correction has been set automatically for the uploaded logo to
					ensure scannability.If you want to manually set error correction,
					remove logo.
				</span>
			</Typography>
		</Box>
	);
}

export default ErrorCorrection;
