import React from "react";

// ----------------- import Components ------------------ //

import {Container, Typography, Grid, Box, Button} from "@material-ui/core";
import {Arrows} from "awesome-react-steps";
import {makeStyles} from "@material-ui/core-v4";
import {ArrowBack} from "@material-ui/icons";

// ----------------- import API's ------------------ //

import QrContextWrapper from "./QrContext/QrContextWrapper";

import QrContent from "./Components/QrContent";
import HotQrTypes from "./Components/QrTypes";
import GenerateQr from "./Components/QrGenerate";

import AuthContext from "../../../../Context/AuthContext";

import GoBackModal from "./Components/GoBackModal";

const useStyles = makeStyles((theme) => ({
	boxStepper: {
		"& div > div": {
			maxWidth: "100% !important",
		},
	},
}));

function CreateQR(props) {
	const style = useStyles();
	const {stepModel, setGoBackModal} = React.useContext(AuthContext);
	const ComponentRendorSwitch = () => {
		switch (stepModel._current) {
			case 0:
				return <HotQrTypes />;
			case 1:
				return <QrContent />;
			case 2:
				return <GenerateQr />;
			default:
				return null;
		}
	};
	return (
		<QrContextWrapper>
			<Container maxWidth="xl" style={{marginTop: "30px"}}>
				<Grid container spacing={1}>
					<Grid item xs={7}>
						<Typography style={{color: "#666", fontSize: "30px"}}>
							<b>Create QR Code</b>
						</Typography>
						<Typography style={{color: "#666", fontSize: "18px"}}>
							<b>
								Create custom designed QR Codes with Hot QR Code Generator in
								minutes
							</b>
						</Typography>
					</Grid>
					<Grid item xs={5}></Grid>
				</Grid>

				<Grid container spacing={2}>
					{stepModel._current !== 0 && (
						<Grid item xs={1}>
							<Box
								sx={{
									margin: "30px 0 0 0",
								}}
							>
								<Button
									variant="outlined"
									sx={{
										borderWidth: "2px",
										borderRadius: "8px",
										fontWeight: 500,
										height: "48px",
										"&:hover": {
											borderWidth: "2px",
										},
									}}
									startIcon={<ArrowBack tyle={{fontWeight: 900}} />}
									onClick={() => setGoBackModal(true)}
								>
									Back
								</Button>
							</Box>
						</Grid>
					)}
					<Grid item xs={stepModel._current !== 0 ? 11 : 12}>
						<Box style={{marginTop: "30px"}} className={style.boxStepper}>
							<Arrows model={stepModel} />
						</Box>
					</Grid>
				</Grid>
				<Box style={{marginTop: "20px"}}>
					<ComponentRendorSwitch />
				</Box>
			</Container>
			<GoBackModal />
		</QrContextWrapper>
	);
}

export default CreateQR;
