import React from "react";
import {
	Typography,
	Box,
	TextField,
	Button,
	IconButton,
	InputAdornment,
} from "@material-ui/core";
import {Delete, Add} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core-v4";
import QrContext from "../../../../QrContext/QrContext";
import optionsFunction from "./optionsFunction";
import questionTypeArray from "./questionTypeArray";

const useStyles = makeStyles((theme) => ({
	card: {
		width: "100%",
		"& div > div": {
			height: "40px",
			borderRadius: "2px",
		},
		"& .MuiFormControl-root > .MuiOutlinedInput-root > input ": {
			marginTop: "-3px",
		},
		"& .MuiFormControl-root > .MuiOutlinedInput-root > .MuiInputAdornment-root > .MuiButtonBase-root ":
			{
				marginTop: "-7px",
			},
	},
}));

function MCQ_Checkbox_Dropdown({ele, bIndex, data, dataIndex}) {
	const {setContent} = React.useContext(QrContext);
	const {required} = data;
	const classes = useStyles(required === "indicate" ? true : false);

	const handleChangeBlockOptions = (key, value, bindex, oIndex) => {
		setContent((prev) =>
			prev.map((ele, index) => {
				if (index === dataIndex) {
					return {
						...ele,
						questionBlock: ele.questionBlock.map((e, i) => {
							if (i === bindex) {
								return {
									...e,
									options: e.options?.map((opEle, opIndex) => {
										if (opIndex === oIndex) {
											return {
												option: value,
											};
										} else return opEle;
									}),
								};
							} else return e;
						}),
					};
				} else return ele;
			})
		);
	};

	const handleAddQuestionOption = (bindex, type) => {
		setContent((prev) =>
			prev.map((ele, index) => {
				if (index === dataIndex) {
					return {
						...ele,
						questionBlock: ele.questionBlock.map((e, i) => {
							if (i === bindex) {
								return {
									...e,
									options: [...e?.options, optionsFunction(type)],
								};
							} else return e;
						}),
					};
				} else return ele;
			})
		);
	};

	const handleRemoveQuestionOption = (bindex, oIndex) => {
		setContent((prev) =>
			prev.map((ele, index) => {
				if (index === dataIndex) {
					return {
						...ele,
						questionBlock: ele.questionBlock.map((e, i) => {
							if (i === bindex) {
								return {
									...e,
									options: e.options?.filter((oE, oI) => oI !== oIndex),
								};
							} else return e;
						}),
					};
				} else return ele;
			})
		);
	};

	return (
		<Box
			sx={{
				border: "1px solid #e0e0e0",
				padding: "12px",
				width: "100%",
			}}
		>
			<Typography sx={{marginBottom: "15px"}}>
				{questionTypeArray
					?.map((oEle, oIndex) => {
						if (oEle?.value === ele.type) {
							return oEle;
						}
						return null;
					})
					?.filter((e, i) => e !== null)?.[0]?.label || ""}{" "}
				options
			</Typography>
			{ele?.options?.map((item, iIndex) => (
				<Box sx={{marginBottom: "10px"}}>
					<Typography sx={{marginBottom: "5px"}}>
						Option {iIndex + 1}
					</Typography>
					<Box sx={{display: "flex", alignItems: "center", width: "100%"}}>
						<Box className={classes.card} key={iIndex} sx={{width: "100%"}}>
							<TextField
								type="text"
								fullWidth
								name="option"
								onChange={(e) => {
									if (e.target.value?.length < item?.option?.length) {
										handleChangeBlockOptions(
											e.target.name,
											e.target.value,
											bIndex,
											iIndex
										);
									} else {
										if (item?.option?.length < 100) {
											handleChangeBlockOptions(
												e.target.name,
												e.target.value,
												bIndex,
												iIndex
											);
										}
									}
								}}
								value={item?.option}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											{item?.option?.length || 0}
											/100
										</InputAdornment>
									),
								}}
							/>
						</Box>

						{ele?.options?.length > 1 && (
							<IconButton
								sx={{
									height: "35px",
									width: "35px",
									marginLeft: "10px",
								}}
								onClick={() => handleRemoveQuestionOption(bIndex, iIndex)}
							>
								<Delete />
							</IconButton>
						)}
					</Box>
				</Box>
			))}
			<Button
				size="small"
				onClick={() => handleAddQuestionOption(bIndex, ele?.type)}
				startIcon={<Add />}
			>
				Add Option
			</Button>
		</Box>
	);
}

export default MCQ_Checkbox_Dropdown;
