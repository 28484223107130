import React from "react";

// ------------------------ import Components --------------------------- //

import {Box, Grid, Container, Typography} from "@material-ui/core";

function EventTracking(props) {
	// if(plans){
	return (
		<Container maxWidth="xl" style={{margin: "30px auto"}}>
			<Box
				style={{
					width: "100%",
					margin: "30px 0 0 0",
					display: "flex",
					flexDirection: "column",
					color: "#666",
				}}
			>
				<Grid container>
					<Grid item container xs={12}>
						<Grid item xs={8}>
							<Typography variant="h5" className="d-flex flex-column">
								<b>
									Using this feature, you can track number of clicks and
									engagement rate on actionable elements (such as buttons) on
									landing pages (custom page, product page, VCard profile page,
									social media links page, etc.) created using HotQr's QR Code
									Generator.
								</b>
							</Typography>
						</Grid>
					</Grid>
					<Grid item container xs={12} style={{marginTop: "30px"}}>
						<Grid item xs={8}>
							<Typography variant="h5" className="d-flex flex-column">
								<b>Enable Event Tracking</b>
								<span style={{marginTop: "5px"}}>
									Enabling event tracking will add additional setup fields on
									landing page design screens. The captured data will be
									available as graphs and for export via the Analytics section
								</span>
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Box>
		</Container>
	);
	// } else {
	//     return (
	//         <Box style={{ marginTop: '10%', width: '100%', textAlign: 'center' }}>
	//             <CircularProgress />
	//         </Box>
	//     )
	// }
}

export default EventTracking;
