import React from "react";
import {Grid, Card, Typography, Button, Box} from "@material-ui/core";
import {FaDownload} from "react-icons/all";

function BannerImageComp(props) {
	const {data} = props;
	const {images} = data;

	return (
		<React.Fragment>
			<Grid container spacing={1} style={{marginTop: "5px"}}>
				<Grid item xs={12}>
					<Box
						style={{
							padding: "15px",
							borderRadius: "8px",
							border: "1px solid #999",
						}}
					>
						<Typography variant="h5">
							{images && images.length} {images.length > 1 ? "Images" : "Image"}
						</Typography>
					</Box>
				</Grid>
				{images &&
					images.length > 0 &&
					images.map((file, index) => (
						<Grid key={index} item md={12} sm={12} xs={12}>
							<Card style={{padding: "10px 15px"}} elevation={3}>
								<img
									alt=""
									src={file.url}
									style={{height: "100px", width: "100%", objectFit: "contain"}}
								/>
								<Button
									onClick={() => {
										var a = document.createElement("a");
										a.href = file.url;
										a.download = file.name ? file.name : "dowload";
										a.click();
									}}
									startIcon={<FaDownload />}
									fullWidth
									style={{marginTop: "10px"}}
								>
									Downlaod
								</Button>
							</Card>
						</Grid>
					))}
			</Grid>
		</React.Fragment>
	);
}
export default BannerImageComp;
