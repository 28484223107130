import React from 'react'

function GoogleMapComp(props) {

    const { data } = props 
    const { location } = data

    const newUrl = location.split('?').join('maps?')

    return (
        <div style={{ position: 'relative', height: '100%', width: '100%' }}>
            <iframe 
                title='showcase' 
                src={newUrl + '&output=svembed'}
                style={{
                    width: '100%',
                    height: '100%',
                    margin: 0,
                    borderRadius: '2px 2px 20px 20px',
                    visibility: 'visible'
                }}
            />
            <div style={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
                cursor: 'none',
                zIndex: '100',
             }}></div>
        </div>
    )   
}

export default GoogleMapComp