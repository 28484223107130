import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import {useNavigate, useLocation} from "react-router-dom";
import {Button, ListItem} from "@material-ui/core";
import AuthContext from "../../Context/AuthContext";

const NavItem = (props) => {
	const location = useLocation();
	const navigate = useNavigate();
	const {setLogin, setStepModel} = React.useContext(AuthContext);

	const goTo = (link) => {
		if (link) {
			navigate(link);
		}
		localStorage.setItem("currentItem", props.title);
	};
	const {
		title,
		icon: Icon,
		root,
		link,
		tag,
		active,
		staticContext,
		...rest
	} = props;

	const url = location.pathname;
	const url1 = url?.split("/")?.[2];
	const activeItem = tag
		? tag.length > 0
			? tag.includes(url1)
			: false
		: false;

	return (
		<ListItem
			disableGutters
			sx={{
				display: "flex",
				py: 0,
			}}
			{...rest}
		>
			<Button
				sx={{
					color: "#FFF",
					fontWeight: "medium",
					justifyContent: "flex-start",
					letterSpacing: 0,
					py: 1.2,
					paddingLeft: "15px",
					textTransform: "none",
					width: "100%",
					...(activeItem && {
						backdropFilter: "brightness(70%)",
					}),
					"& svg": {
						mr: 1,
					},
				}}
				onClick={() => {
					if (title === "Logout") {
						setLogin(true);
					} else {
						goTo(link);
					}
					setStepModel((prev) => prev.setCurrent(0));
				}}
			>
				{Icon && <Icon size="20" />}
				<Typography style={{fontSize: "20px"}}>{title}</Typography>
			</Button>
		</ListItem>
	);
};

NavItem.propTypes = {
	link: PropTypes.string,
	icon: PropTypes.elementType.isRequired,
	title: PropTypes.string.isRequired,
};

export default NavItem;
