import {executePost} from "../../../../../Services/PUT_OR_POST";

export const qrCustomize = (requestLoad) => {
	let url = "http://136.232.113.214:10004/api/generate";
	let actionCode = "";
	let payload = requestLoad;
	let method = "POST";
	return executePost(url, actionCode, payload, method);
};

export const updateCustomizedQr = (id, requestLoad) => {
	let url = "https://api.hotqrcode.com/qr/update-qr/" + id;
	let actionCode = "";
	let payload = requestLoad;
	let method = "PUT";
	return executePost(url, actionCode, payload, method);
};
