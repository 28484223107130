import React, {useState} from "react";
import {useNavigate} from "react-router";
import {Box, Container, Button} from "@material-ui/core";
import axios from "axios";
import OtpInput from "react-otp-input";
import SwitchSelector from "react-switch-selector";

// ------------------------------ Import UI Components ------------------------------- //

// import GoogleLoginComponent from "../../../UI/GoogleLogin/GoogleLogin.jsx";
import SnackBarComponent from "../../../UI/SnackBar.jsx";

// ------------------------------ Import Modules ------------------------------------- //

import classes from "./styles/Login.module.css";
import CustomeTextField from "./styles/CustomeTextField";
import classess from "./styles/SignUp.module.css";

// ------------------------------ Import Icons -------------------------------------- //

import qrBackground from "../../../../Assets/qr1.svg";
import Ellipse14 from "../../../../Assets/Ellipse14.svg";
import Hotqr2 from "../../../../Assets/Hotqr2.svg";
import GoogleIcon from "../../../../Assets/googleIcon.svg";

// ------------------------------ Import API's -------------------------------------- //

// import { loginApi } from './LoginServices/LoginApiUrl';
import {urls} from "../../../../Environment/ApiUrl";
import {
	validateOtp,
	signupapi,
	signupWithOtp,
	licenseApi,
	validateUser,
} from "./SignupLoginService/Service";
import AuthContext from "../../../../Context/AuthContext.js";

// ------------------------------End Imports-------------------------------------- //

function Login(props) {
	const navigate = useNavigate();
	const {setLicense, setQrTypes, setQrCount} = React.useContext(AuthContext);
	const [snackbar, setSnackbar] = useState({
		open: false,
		message: "",
		severity: "",
	});

	const [login, setLogin] = React.useState({});
	const [mcc, setMcc] = React.useState();
	const [signup, setSignup] = React.useState({
		otp: "",
	});
	const [account, setAccount] = React.useState({
		gender: "Male",
		email: "",
		type: "",
		mobile: "",
	});

	const [state, setState] = React.useState();

	const [page, setPage] = React.useState("login");
	const [otpBtn, setOtpBtn] = React.useState("otp");

	const domain = "444-555-666";

	const getGeoInfo = React.useCallback(() => {
		axios
			.get("https://ipapi.co/json/")
			.then((response) => {
				setMcc(response.data.country_calling_code);
			})
			.catch((error) => {
				console.error(error);
			});
	}, []);
	// ------------------------------- Check Login ------------------------------------- //

	React.useEffect(() => {
		if (localStorage.getItem("token")) {
			navigate("/qr");
		}
		getGeoInfo();
	}, [getGeoInfo, navigate]);

	// ----------------------- Validate Email or Not Function ------------------------- //

	const validateEmail = (emailAdress) => {
		let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
		if (emailAdress.match(regexEmail)) {
			return true;
		} else {
			return false;
		}
	};

	// ------------------------------- Login Api Call ------------------------------------- //

	const handleLogin = (event, val) => {
		let formData = new FormData();
		formData.append("domain", "hotqr");
		formData.append("username", val ? account.mobile : login.mobile);
		formData.append("password", val ? account.password : login.password);
		formData.append("accountType", 0);
		formData.append("passwordType", "password");
		formData.append("grant_type", "password");
		formData.append("tid", Date.now() + 30000);
		// formData.append("sid",setting.setSecurity(urls.headers['itpl-client-id'],Date.now() +30000));

		axios
			.post(urls.LOGIN, formData, {
				headers: {
					...urls.headers,
					Authorization: "Basic aXRwbDppd2FudHVubGltaXRlZA==",
				},
			})
			.then((response) => {
				const {access_token} = response && response.data ? response.data : null;
				localStorage.setItem("token", access_token);
				setSnackbar({
					open: true,
					message: "Login Success!!",
					severity: "success",
				});

				setQrTypes();
				setQrCount();

				if (val) {
					licenseApi()
						.then((res) => {
							setLicense();
							if (localStorage.getItem("token")) {
								navigate("/qr");
							}
						})
						.catch((err) => {
							console.error(err);
						});
				} else {
					setLicense();
					if (localStorage.getItem("token")) {
						navigate("/qr");
					}
				}
			})
			.catch((error) => {
				console.error(error);
				if (error.response.status === 400) {
					setSnackbar({
						open: true,
						message: "Please Enter Correct UserName or Password",
						severity: "warning",
					});
				} else if (error.response.status === 404) {
					setSnackbar({
						open: true,
						message: "The Requested URL/badpage was not found on this server.",
						severity: "warning",
					});
				} else if (error.response.status === 500) {
					setSnackbar({
						open: true,
						message: "Something Went Wrong!",
						severity: "warning",
					});
				}
			});
		event.preventDefault();
	};

	// ------------------------------- Login With Google Api Call ------------------------------------- //

	// const responseSuccessGoogle = (response) => {
	// 	console.log("Google Auth Success");
	// 	// setSnackbar({open: true, message: "Login Success!!", severity: "success"});
	// 	// navigate("/qr");
	// };

	// const responseFailGoogle = (response) => {
	// 	console.log("Google Auth Fail");
	// 	// setSnackbar({open: true, message: "Login Success!!", severity: "success"});
	// };

	// ---------------------- OTP Varify Api Call ------------------------------- //

	const handleSubmitOtp = (e) => {
		e.preventDefault();
		let payload;
		if (validateEmail(signup.username)) {
			payload = {
				domain: domain,
				email: signup.username,
				otp: signup.otp,
				transactionCode: state.sendOtpRes.transactionCode,
				transactionId: state.sendOtpRes.transactionId,
			};
			setAccount((prev) => ({...prev, email: signup.username, type: "email"}));
		} else {
			payload = {
				domain: domain,
				mcc: mcc,
				mobile: signup.username,
				otp: signup.otp,
				transactionCode: state.sendOtpRes.transactionCode,
				transactionId: state.sendOtpRes.transactionId,
			};
			setAccount((prev) => ({
				...prev,
				mobile: signup.username,
				type: "mobile",
			}));
		}
		validateOtp(payload)
			.then((res) => {
				if (res.valid) {
					setSnackbar({
						open: true,
						message: "Otp Verify Successfully",
						severity: "success",
					});
					setPage("registration");
				} else {
					setSnackbar({
						open: true,
						message: "Please enter correct otp",
						severity: "warning",
					});
				}
			})
			.catch((err) => {
				setSnackbar({
					open: true,
					message: "Please enter correct otp",
					severity: "error",
				});
				console.error(err);
			});
	};

	// ---------------------- OTP Send Api Call ------------------------------- //

	const handleSentOtp = (e) => {
		e.preventDefault();
		let payload;
		if (validateEmail(signup.username)) {
			payload = {
				email: signup.username,
				domain: domain,
			};
		} else {
			payload = {
				mobile: signup.username,
				domain: domain,
				mcc: mcc,
			};
		}
		validateUser(payload)
			.then((res) => {
				if (!res) {
					signupWithOtp(payload)
						.then((res) => {
							setState((prev) => ({...prev, sendOtpRes: res}));
							setOtpBtn("otpsend");
							setSnackbar({
								open: true,
								message: "Otp Sent Successfully",
								severity: "success",
							});
						})
						.catch((err) => {
							setSnackbar({
								open: true,
								message: "Something went wrong in Sent OTP",
								severity: "error",
							});
							console.error(err);
						});
				} else {
					setSnackbar({
						open: true,
						message:
							"User already exist. Please try again with different mobile number.",
						severity: "error",
					});
				}
			})
			.catch((err) => console.error(err));
	};

	const registration = (e) => {
		setAccount((prev) => ({...prev, [e.target.name]: e.target.value}));
	};

	// ---------------------- Registration Api Call ------------------------------- //

	const {
		firstName,
		lastName,
		password,
		gender,
		mobile,
		email,
		confirmpassword,
	} = account;

	const handleRegistration = (e) => {
		e.preventDefault();
		if (password === confirmpassword) {
			signupapi({
				domain: domain,
				firstName: firstName,
				lastName: lastName,
				mobile: mobile,
				password: password,
				gender: gender,
				email: email,
				userType: 0,
				mcc: mcc,
				dateOfBirth: "",
			})
				.then((res) => {
					setSnackbar({
						open: true,
						message: "Account Created Successfully",
						severity: "success",
					});
					handleLogin(e, true);
				})
				.catch((err) => {
					setSnackbar({
						open: true,
						message: "Something went wrong while create account",
						severity: "error",
					});
					console.error(err);
				});
		} else {
			setSnackbar({
				open: true,
				message: "Password Dosent Match",
				severity: "warning",
			});
		}
	};
	// ------------------------------- Snackbar Close Function ------------------------------------- //

	const snackbarClose = () => {
		setSnackbar((prevSnack) => ({...prevSnack, open: false}));
	};

	const goToLogin = () => {
		setPage("login");
		setAccount({});
		setSignup({});
		setLogin({});
		setOtpBtn("otp");
	};

	// ------------------------------- Login SignUp Fileds Component Start ------------------------------------- //

	const PageComponent = () => {
		switch (page) {
			case "login":
				return (
					<Box className={classes.boxRight}>
						<form onSubmit={handleLogin} className={classes.boxLogin}>
							{/* <Box 
                className={classes.boxLogin}
                component="form"
                autoComplete="off"
              > */}
							<span className={classes.loginLebelText}> Login with Email </span>
							<CustomeTextField
								name="mobile"
								type="text"
								autoFocus={true}
								autoComplete="off"
								onChange={(e) =>
									setLogin((prev) => ({...prev, mobile: e.target.value}))
								}
								className={classes.inputEmail}
								label="Your Mobile or Email"
								required
							/>
							<CustomeTextField
								name="pass"
								type="password"
								onChange={(e) =>
									setLogin((prev) => ({...prev, password: e.target.value}))
								}
								className={classes.inputPass}
								label="Your Password"
								required
							/>
							<Box type="submit" className={classes.btnLogin}>
								<Button
									type="submit"
									variant="contained"
									className={classes.inputBtnSubmit}
									sx={{
										border: "0px solid #FFF",
										borderRadius: "21px",
										color: "#FF800C",
										backgroundColor: "#FFF",
										"&:hover": {
											backgroundColor: "#fff",
											color: "#FF800C",
										},
									}}
								>
									Login
								</Button>
							</Box>
							{/* </Box> */}
						</form>
						<label className={classes.btnSignUp}>
							<span> Don’t have an account? </span>
							<span
								onClick={() => setPage("signup")}
								style={{textDecoration: "underline", cursor: "pointer"}}
							>
								{" "}
								Sign Up{" "}
							</span>
						</label>
					</Box>
				);
			case "signup":
				return (
					<Box className={classess.boxRight}>
						<form
							onSubmit={(e) => {
								e.preventDefault();
								if (otpBtn === "otp") {
									handleSentOtp(e);
								} else if (otpBtn === "otpsend") {
									handleSubmitOtp(e);
								}
							}}
							className={classes.boxLogin}
						>
							<span className={classess.signupLebelText}>
								Sign Up with Mobile or Email
							</span>
							<Box
								style={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
									width: "100%",
								}}
							>
								<CustomeTextField
									name="username"
									className={classess.inputMobile}
									type="text"
									onChange={(e) =>
										setSignup((prev) => ({...prev, username: e.target.value}))
									}
									fullWidth
									label="Your Mobile or Email"
									required
									disabled={otpBtn === "otpsend" ? true : false}
								/>
								{otpBtn === "otp" && (
									<Button
										type="submit"
										variant="contained"
										className={classess.inputBtnSignUp}
										sx={{
											border: "0px solid #FFF",
											marginTop: "20px",
											borderRadius: "21px",
											color: "#FF800C",
											backgroundColor: "#FFF",
											"&:hover": {
												backgroundColor: "#fff",
												color: "#FF800C",
											},
										}}
									>
										Send OTP
									</Button>
								)}
								{otpBtn === "otpsend" && (
									<Box className={classess.inputOtp}>
										<OtpInput
											inputStyle={{
												width: "100%",
												height: "3rem",
												margin: "60px 5px 0 5px",
												fontSize: "1rem",
												borderRadius: "14px",
												border: "2px solid rgba(0,0,0,0.3)",
											}}
											name="mobileotp"
											value={signup.otp}
											onChange={(actualOTP) =>
												setSignup((prev) => ({...prev, otp: actualOTP}))
											}
											otpType="number"
											numInputs={4}
											separator={<span style={{marginTop: "60px"}}>-</span>}
										/>
										<Button
											onClick={handleSentOtp}
											style={{
												color: "#FFF",
												margin: "15px 0 0 0",
												padding: "0px",
											}}
										>
											Resend OTP
										</Button>
										<Box style={{display: "flex", justifyContent: "center"}}>
											<Button
												type="submit"
												variant="contained"
												className={classess.inputBtnSignUp}
												sx={{
													border: "0px solid #FFF",
													borderRadius: "21px",
													color: "#FF800C",
													backgroundColor: "#FFF",
													"&:hover": {
														backgroundColor: "#fff",
														color: "#FF800C",
													},
												}}
												disabled={
													signup.otp && signup.otp.length > 3 ? false : true
												}
											>
												Submit OTP
											</Button>
										</Box>
									</Box>
								)}
							</Box>
						</form>
						<label className={classess.loginLink} style={{marginTop: "20px"}}>
							<span> You have already account? </span>
							<span
								onClick={goToLogin}
								style={{textDecoration: "underline", cursor: "pointer"}}
							>
								{" "}
								Login Now{" "}
							</span>
						</label>
					</Box>
				);
			case "registration":
				return (
					<Box className={classes.boxRight}>
						<form
							onSubmit={handleRegistration}
							className={classes.boxReg}
							autoComplete="off"
						>
							{/* <Box 
                component="form"
                autoComplete="off"
                className={classes.boxReg}
              > */}
							<span className={classess.signupLebelText}>
								{" "}
								Sign Up with Mobile{" "}
							</span>
							<CustomeTextField
								name="firstName"
								type="text"
								onChange={registration}
								style={{marginTop: "15px"}}
								label="Enter Firstname"
								required
								fullWidth
								className={classess.inputRegForm}
							/>
							<CustomeTextField
								name="lastName"
								type="text"
								onChange={registration}
								label="Enter Lastname"
								required
								fullWidth
								className={classess.inputRegForm}
							/>
							<CustomeTextField
								name="mobile"
								type="text"
								onChange={registration}
								value={account.mobile}
								disabled={account.type === "mobile" ? true : false}
								label="Mobile"
								required
								fullWidth
								className={classess.inputRegForm}
							/>
							<CustomeTextField
								name="email"
								type="email"
								autoComplete="off"
								onChange={registration}
								value={account.email}
								disabled={account.type === "email" ? true : false}
								label="Enter Email ID"
								required
								fullWidth
								className={classess.inputRegForm}
							/>
							<div
								className={[
									`your-required-wrapper ${classess.inputGender}`,
								].join(" ")}
							>
								<SwitchSelector
									onChange={(value) =>
										setAccount((prev) => ({...prev, gender: value}))
									}
									options={[
										{label: "MALE", value: "Male"},
										{label: "FEMALE", value: "Female"},
										{label: "OTHER", value: "Other"},
									]}
									initialSelectedIndex={0}
									backgroundColor="#353b48"
									fontColor="#f5f6fa"
									selectedBackgroundColor="#FF800C"
								/>
							</div>
							<CustomeTextField
								name="password"
								type="password"
								onChange={registration}
								label="Enter Password"
								required
								fullWidth
								className={classess.inputRegForm}
							/>
							<CustomeTextField
								name="confirmpassword"
								type="password"
								onChange={registration}
								label="Confirm Enter Password"
								required
								fullWidth
								className={classess.inputRegForm}
							/>
							<Box type="submit" className={classess.btnReg}>
								<Button
									type="submit"
									variant="contained"
									// className={classes.inputBtnSubmit}
									style={{height: "100%", width: "100%", marginTop: "-120px"}}
									sx={{
										border: "0px solid #FFF",
										borderRadius: "21px",
										color: "#FF800C",
										backgroundColor: "#FFF",
										"&:hover": {
											backgroundColor: "#fff",
											color: "#FF800C",
										},
									}}
								>
									Create Account
								</Button>
							</Box>
							{/* </Box> */}
						</form>
						<label className={classess.loginLink} style={{marginTop: "220px"}}>
							<span> You have already account? </span>
							<span
								onClick={goToLogin}
								style={{textDecoration: "underline", cursor: "pointer"}}
							>
								{" "}
								Login Now{" "}
							</span>
						</label>
					</Box>
				);
			default:
				return <Box className={classes.boxRight} />;
		}
	};

	// ------------------------------- Login SignUp Fileds Component End ------------------------------------- //

	// ------------------------------------- Main Return ---------------------------------------------- //

	return (
		<React.Fragment>
			<img
				style={{
					position: "absolute",
					objectFit: "cover",
					height: "1024px",
					minWidth: "100%",
					maxwidth: "100%",
					opacity: "70%",
				}}
				alt="BackgroungImage"
				src={qrBackground}
			/>
			<Container style={{maxWidth: "1440px", padding: "0px"}}>
				<SnackBarComponent
					severity={snackbar.severity}
					open={snackbar.open}
					message={snackbar.message}
					handleClose={snackbarClose}
				/>
				<Box className={classes.Box}>
					{page === "login" ? (
						<label className={classes.labelWelcome}>
							<span style={{color: "#FF800C"}}>WELC</span>
							<span style={{color: "#FFFFFF"}}>OME!!</span>
						</label>
					) : (
						<label className={classess.labelTitle}>
							<span style={{color: "#FF800C"}}>Get started </span>
							<span style={{color: "#FFFFFF"}}>with HOT QR</span>
						</label>
					)}
					<Box className={classes.boxOr}>
						<span className={classes.boxOrText}> OR </span>
					</Box>
					<Box className={classes.boxLeft}>
						<Box onClick={() => navigate("/")} className={classes.boxLogo}>
							<img className={classes.Ellipse14} alt="logo" src={Ellipse14} />
							<img className={classes.Hotqr2} alt="logo" src={Hotqr2} />
						</Box>
						<Box className={classes.boxGoogle}>
							<span className={classes.labelGoogleText}> Login with </span>
							{/* <GoogleLoginComponent
								component={(renderProps) => (
									<img
										onClick={renderProps.onClick}
										disabled={renderProps.disabled}
										className={classes.googleIcon}
										alt="Google Img"
										src={GoogleIcon}
									/>
								)}
								onSuccess={responseSuccessGoogle}
								onFailure={responseFailGoogle}
							/> */}
							<img
								className={classes.googleIcon}
								alt="Google Img"
								src={GoogleIcon}
							/>
						</Box>
					</Box>
					{PageComponent()}
				</Box>
			</Container>
		</React.Fragment>
	);
}

export default Login;
