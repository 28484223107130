import React from "react";
import {Typography, Button, Grid, Box} from "@material-ui/core";

function TextColorComp(props) {
	const {textColor, handleChange, name, btnName} = props;

	const [state, setState] = React.useState({
		key: btnName ? btnName : "textColor",
		value: textColor,
	});

	if (props && props.handleChange) {
		return (
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<Typography variant="h5">{name}</Typography>
				</Grid>
				<Grid item md={4} lg={4} xl={3}>
					<Box
						style={{
							height: "40px",
							width: "100%",
							background: "#E0E0E0",
							border: "1px solid #999",
							borderRadius: "2px",
							display: "flex",
						}}
					>
						<Box
							style={{
								height: "40px",
								width: "40px",
								borderRight: "1px solid #999",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							#
						</Box>
						<Typography
							style={{
								height: "40px",
								width: "100%",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							{state &&
								state.value &&
								state.value.replace("#", "").toUpperCase()}
						</Typography>
					</Box>
				</Grid>
				<Grid item md={4} lg={4} xl={3}>
					<input
						style={{
							width: "100%",
							height: "40px",
						}}
						type="color"
						name={state.key}
						onChange={(e) => {
							setState({key: e.target.name, value: e.target.value});
						}}
						value={state.value}
					/>
				</Grid>
				<Grid item md={4} lg={3} xl={2}>
					<Button
						variant="outlined"
						onClick={() => handleChange(state.key, state.value)}
						style={{marginTop: "2px"}}
					>
						Apply Color
					</Button>
				</Grid>
			</Grid>
		);
	}
}

export default TextColorComp;
