import React from "react";
import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Typography,
	Grid,
	Box,
	Switch,
	TextField,
	Button,
	IconButton,
	InputAdornment,
} from "@material-ui/core";
import {styled} from "@mui/material/styles";
import {ExpandMore, Delete} from "@material-ui/icons";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {makeStyles} from "@material-ui/core-v4";
import Select from "react-select";

import BgColorComp from "./ColorComponents/BgColor";
import TextColorComp from "./ColorComponents/TextColor";
import QrContext from "../../../QrContext/QrContext";

import optionsFunction from "./LeadFormComponents/optionsFunction";
import questionTypeArray from "./LeadFormComponents/questionTypeArray";
import MCQCheckboxDropdown from "./LeadFormComponents/MCQ_Checkbox_Dropdown";

const useStyles = makeStyles((theme) => ({
	box: {
		"& .MuiPaper-root": {
			boxShadow: "none !important",
		},
		"& .MuiPaper-root > .MuiButtonBase-root": {
			border: (props) => (props ? "2px solid #ff3333" : "1px solid #000"),
			borderRadius: "2px",
			background: "#F0F0F0",
			height: "50px !important",
		},
		"& .MuiPaper-root > .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded":
			{
				minHeight: "50px !important",
			},
		"& .MuiPaper-root > .MuiCollapse-root": {
			border: "1px solid #D8D8D8",
			margin: "10px 0",
			borderRadius: "2px",
		},
	},
	center: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	card: {
		width: "100%",
		"& div > div": {
			height: "40px",
			borderRadius: "2px",
		},
		"& .MuiFormControl-root > .MuiOutlinedInput-root > input ": {
			marginTop: "-3px",
		},
		"& .MuiFormControl-root > .MuiOutlinedInput-root > .MuiInputAdornment-root > .MuiButtonBase-root ":
			{
				marginTop: "-7px",
			},
	},
}));

const Android12Switch = styled(Switch)(({theme}) => ({
	padding: 8,
	"& .MuiSwitch-track": {
		borderRadius: 22 / 2,
		"&:before, &:after": {
			content: '""',
			position: "absolute",
			top: "50%",
			transform: "translateY(-50%)",
			width: 16,
			height: 16,
		},
		"&:before": {
			backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
				"#000"
			)}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
			left: 12,
		},
		"&:after": {
			backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
				"#FFF"
			)}" d="M19,13H5V11H19V13Z" /></svg>')`,
			right: 12,
		},
	},
	"& .MuiSwitch-thumb": {
		boxShadow: "none",
		width: 16,
		height: 16,
		margin: 2,
	},
}));

const customStyles = {
	control: (base) => ({
		...base,
		height: 45,
		width: "100%",
		minHeight: 40,
		borderRadius: "2px",
	}),
};

function LeadFormComp(props) {
	const {data, dataIndex} = props;

	const {bgColor, textColor, description, questionBlock, required} = data;
	const classes = useStyles(required === "indicate" ? true : false);
	const {setContent} = React.useContext(QrContext);

	// console.log(data);
	if (props.data) {
		const handleChange = (key, value) => {
			setContent((prev) =>
				prev.map((ele, index) => {
					if (index === dataIndex) {
						return {
							...ele,
							[key]: value,
						};
					} else return ele;
				})
			);
		};
		const handleChangeBlock = (key, value, fIndex) => {
			setContent((prev) =>
				prev.map((ele, index) => {
					if (index === dataIndex) {
						return {
							...ele,
							questionBlock: ele.questionBlock.map((e, i) => {
								if (i === fIndex) {
									if (key === "type") {
										return {
											question: e?.question,
											required: e.required,
											type: value,
											options: [optionsFunction(value)],
										};
									} else {
										return {
											...e,
											[key]: value,
										};
									}
								} else return e;
							}),
						};
					} else return ele;
				})
			);
		};

		const handleAddQuestionBlock = () => {
			setContent((prev) =>
				prev.map((ele, index) => {
					if (index === dataIndex) {
						return {
							...ele,
							questionBlock: [
								...ele.questionBlock,
								{
									question: "",
									type: false,
									required: true,
								},
							],
						};
					} else return ele;
				})
			);
		};

		const handleRemoveQuestionBlock = (dI) => {
			setContent((prev) =>
				prev.map((ele, index) => {
					if (index === dataIndex) {
						return {
							...ele,
							questionBlock: ele?.questionBlock?.filter((e, i) => i !== dI),
						};
					} else return ele;
				})
			);
		};

		const renderQuestionType = (ele, bIndex) => {
			switch (ele?.type) {
				case "multipleChoice":
				case "checkboxes":
				case "dropdownList":
					return (
						<MCQCheckboxDropdown
							ele={ele}
							bIndex={bIndex}
							data={data}
							dataIndex={dataIndex}
						/>
					);
				case "date":
					return <Box />;
				default:
					return null;
			}
		};

		return (
			<div className={classes.box}>
				<Accordion>
					<AccordionSummary expandIcon={<ExpandMore />}>
						<Typography
							style={{color: "#615757", fontSize: "20px", fontWeight: "600"}}
						>
							Lead Form
						</Typography>
					</AccordionSummary>
					<AccordionDetails style={{display: "flex", alignItems: "center"}}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Grid container spacing={1}>
									<Grid item xs={12}>
										<Typography variant="h5">Form Description</Typography>
									</Grid>
									<Grid item xs={12}>
										<CKEditor
											id="inputText"
											editor={ClassicEditor}
											fontFamily
											onChange={(e, data) =>
												handleChange("description", data.getData())
											}
											data={description}
										/>
									</Grid>
								</Grid>
							</Grid>
							{questionBlock?.map((ele, index) => (
								<Grid item xs={12} sx={{display: "flex"}}>
									<Box
										sx={{
											border: "1px solid #e0e0e0",
											padding: "7px 12px 12px 12px",
											width: "100%",
										}}
										key={index}
									>
										<Grid container spacing={2}>
											<Grid
												item
												xs={12}
												sx={{
													display: "flex",
													justifyContent: "space-between",
													alignItems: "center",
												}}
											>
												<Box sx={{display: "flex", alignItems: "center"}}>
													<Typography variant="h5">
														Question Block {index + 1}
													</Typography>
													{index > 0 && (
														<IconButton
															sx={{
																height: "50px",
																width: "50px",
																marginLeft: "10px",
															}}
															onClick={() => handleRemoveQuestionBlock(index)}
														>
															<Delete />
														</IconButton>
													)}
												</Box>
												<Box sx={{display: "flex", alignItems: "center"}}>
													<Typography sx={{color: "#555"}}>Required</Typography>
													<Android12Switch
														checked={ele?.required}
														// value={ele?.required}
														onChange={(e, val) => {
															if (index > 0) {
																handleChangeBlock("required", val, index);
															}
														}}
														disabled={index === 0}
													/>
												</Box>
											</Grid>
											<Grid item xs={12}>
												<Typography variant="h5" sx={{marginBottom: "8px"}}>
													Question
												</Typography>
												<Box className={classes.card}>
													<TextField
														type="text"
														fullWidth
														name="question"
														onChange={(e) => {
															if (
																e.target.value?.length < ele.question?.length
															) {
																handleChangeBlock(
																	e.target.name,
																	e.target.value,
																	index
																);
															} else {
																if (ele.question?.length < 200) {
																	handleChangeBlock(
																		e.target.name,
																		e.target.value,
																		index
																	);
																}
															}
														}}
														value={ele.question}
														InputProps={{
															endAdornment: (
																<InputAdornment position="end">
																	{ele.question?.length || 0}
																	/200
																</InputAdornment>
															),
														}}
													/>
												</Box>
											</Grid>
											<Grid item xs={12}>
												<Typography variant="h5">Answer Type</Typography>
												<Select
													onChange={(e) =>
														handleChangeBlock("type", e.value, index)
													}
													width="100%"
													maxMenuHeight="240px"
													placeholder="Select Answer Type"
													menuShouldScrollIntoView={true}
													closeMenuOnScroll={true}
													value={{
														label: questionTypeArray
															?.map((oEle, oIndex) => {
																if (oEle?.value === ele.type) {
																	return oEle;
																}
																return null;
															})
															?.filter((e, i) => e !== null)?.[0]?.label,
														value: ele.type,
													}}
													theme={(theme) => ({
														...theme,
														colors: {
															...theme.colors,
															text: "#FFF",
															primary25: "#FF800C",
															primary: "#FF800C",
														},
													})}
													styles={customStyles}
													options={questionTypeArray}
												/>
											</Grid>
											<Grid item xs={12}>
												{renderQuestionType(ele, index)}
											</Grid>
										</Grid>
									</Box>
								</Grid>
							))}
							<Grid item xs={12} sx={{textAlign: "end"}}>
								<Button
									variant="outlined"
									size="small"
									onClick={handleAddQuestionBlock}
								>
									Add Question Block
								</Button>
							</Grid>
							<Grid item xs={12}>
								<BgColorComp
									bgColor={bgColor}
									handleChange={(key, value) => handleChange(key, value)}
									name="Background Color"
								/>
							</Grid>
							<Grid item xs={12}>
								<TextColorComp
									textColor={textColor}
									handleChange={(key, value) => handleChange(key, value)}
									name="Text Color"
								/>
							</Grid>
						</Grid>
					</AccordionDetails>
				</Accordion>
			</div>
		);
	} else {
		return null;
	}
}
export default LeadFormComp;
