import React from "react";
import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Typography,
	Box,
	Grid,
	TextField,
	IconButton,
	Button,
} from "@material-ui/core";
import {ExpandMore, Delete} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core-v4";
import QrContext from "../../../QrContext/QrContext";

const useStyles = makeStyles((theme) => ({
	box: {
		"& .MuiPaper-root": {
			boxShadow: "none !important",
		},
		"& .MuiPaper-root > .MuiButtonBase-root": {
			border: (props) => (props ? "2px solid #ff3333" : "1px solid #000"),
			borderRadius: "2px",
			background: "#F0F0F0",
			height: "50px !important",
		},
		"& .MuiPaper-root > .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded":
			{
				minHeight: "50px !important",
			},
		"& .MuiPaper-root > .MuiCollapse-root": {
			border: "1px solid #D8D8D8",
			margin: "10px 0",
			borderRadius: "2px",
		},
	},
	center: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	card: {
		width: "100%",
		"& div > div": {
			height: "40px",
			borderRadius: "3px",
		},
		"& .MuiFormControl-root > .MuiOutlinedInput-root > input ": {
			marginTop: "-3px",
		},
		"& .MuiFormControl-root > .MuiOutlinedInput-root > .MuiInputAdornment-root > .MuiButtonBase-root ":
			{
				marginTop: "-7px",
			},
	},
}));

function ContactDetailsComp(props) {
	const {setContent} = React.useContext(QrContext);

	const {data, dataIndex} = props;

	const {primaryPhone, primaryEmail, website, fax, address, required} = data;

	const classes = useStyles(required === "indicate" ? true : false);

	const [expanded, setExpanded] = React.useState(false);

	const handleChange = (key, value) => {
		setContent((prev) =>
			prev.map((ele, index) => {
				if (index === dataIndex) {
					return {
						...ele,
						[key]: value,
					};
				} else return ele;
			})
		);
	};

	const handleDelete = () => {
		setContent((prev) => [...prev].filter((ele, index) => dataIndex !== index));
	};
	const handleAddAddressObj = () => {
		handleChange("address", [
			...address,
			{street: "", city: "", state: "", country: "", postalCode: "", type: ""},
		]);
	};

	const handleRemoveAddressObj = (addIndex) => {
		handleChange(
			"address",
			address.filter((ele, index) => index !== addIndex)
		);
	};

	const handleChangeAddress = (key, value, addIndex) => {
		handleChange(
			"address",
			[...address].map((ele, index) => {
				if (index === addIndex) {
					return {
						...ele,
						[key]: value,
					};
				} else return ele;
			})
		);
	};

	if (props.data) {
		return (
			<div className={classes.box}>
				<Accordion onChange={(e, data) => setExpanded(data)}>
					<AccordionSummary expandIcon={<ExpandMore />}>
						<Box
							style={{
								display: "flex",
								justifyContent: "space-between",
								width: "100%",
								alignItems: "center",
							}}
						>
							<Typography
								style={{color: "#615757", fontSize: "20px", fontWeight: "600"}}
							>
								Contact Details *
							</Typography>
							{expanded && (
								<IconButton
									style={{marginRight: "15px"}}
									onClick={() => handleDelete()}
								>
									<Delete />
								</IconButton>
							)}
						</Box>
					</AccordionSummary>
					<AccordionDetails
						style={{display: "flex", alignItems: "center", padding: "20px"}}
					>
						<Grid container spacing={1}>
							<Grid item xs={12}>
								<Grid container spacing={1}>
									<Grid item xs={12}>
										<Typography variant="h5">Primary Phone Number*</Typography>
									</Grid>
									<Grid item xs={12}>
										<Box className={classes.card}>
											<TextField
												type="text"
												color="primary"
												name="primaryPhone"
												onChange={(e) => {
													handleChange(e.target.name, e.target.value);
												}}
												fullWidth
												value={primaryPhone}
											/>
										</Box>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={12}>
								<Grid container spacing={1}>
									<Grid item xs={12}>
										<Typography variant="h5">Primary Email Address*</Typography>
									</Grid>
									<Grid item xs={12}>
										<Box className={classes.card}>
											<TextField
												type="text"
												color="primary"
												name="primaryEmail"
												onChange={(e) => {
													handleChange(e.target.name, e.target.value);
												}}
												fullWidth
												value={primaryEmail}
											/>
										</Box>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={12}>
								<Grid container spacing={1}>
									<Grid item xs={12}>
										<Typography variant="h5">Website</Typography>
									</Grid>
									<Grid item xs={12}>
										<Box className={classes.card}>
											<TextField
												type="text"
												color="primary"
												name="website"
												onChange={(e) => {
													handleChange(e.target.name, e.target.value);
												}}
												fullWidth
												value={website}
											/>
										</Box>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={12}>
								<Grid container spacing={1}>
									<Grid item xs={12}>
										<Typography variant="h5">Fax</Typography>
									</Grid>
									<Grid item xs={12}>
										<Box className={classes.card}>
											<TextField
												type="text"
												color="primary"
												name="fax"
												onChange={(e) => {
													handleChange(e.target.name, e.target.value);
												}}
												fullWidth
												value={fax}
											/>
										</Box>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={12}>
								<Box
									style={{
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
										margin: "20px 0 10px 0",
									}}
								>
									<Typography variant="h4">Enter Address</Typography>
									<Button
										color="primary"
										variant="outlined"
										onClick={handleAddAddressObj}
									>
										Add
									</Button>
								</Box>
							</Grid>
							{address &&
								address.length > 0 &&
								address.map((ele, index) => (
									<Grid item xs={12}>
										<Box
											style={{
												display: "flex",
												justifyContent: "space-between",
												alignItems: "center",
											}}
										>
											<Typography variant="h5" style={{margin: "0 0 10px 0"}}>
												Address {index + 1}
											</Typography>
											{index !== 0 && (
												<Button
													color="primary"
													variant="outlined"
													onClick={() => handleRemoveAddressObj(index)}
												>
													Remove
												</Button>
											)}
										</Box>
										<Grid container spacing={2} style={{marginBottom: "10px"}}>
											{Object.entries(ele).map(([key, value]) => {
												return (
													<Grid item container spacing={1} xs={6}>
														<Grid item xs={12}>
															<Typography variant="h5">
																{key.slice(0, 1).toUpperCase() +
																	key.slice(1, key.length + 1)}
															</Typography>
														</Grid>
														<Grid item xs={12}>
															<Box className={classes.card}>
																<TextField
																	type="text"
																	color="primary"
																	name={key}
																	onChange={(e) => {
																		handleChangeAddress(
																			e.target.name,
																			e.target.value,
																			index
																		);
																	}}
																	fullWidth
																	value={value}
																/>
															</Box>
														</Grid>
													</Grid>
												);
											})}
										</Grid>
									</Grid>
								))}
						</Grid>
					</AccordionDetails>
				</Accordion>
			</div>
		);
	} else {
		return null;
	}
}
export default ContactDetailsComp;
