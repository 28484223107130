import React from "react";
import { Box, Button, Typography } from '@material-ui/core';
import { LocationOn } from '@material-ui/icons';
import renderHTML from 'react-render-html';

function EventVanueComp(props){

    const { data } = props 
    const { textColor, bgColor, address, location, btnTextColor, btnBgColor, btnBorderColor } = data

    if(address && address !== ''){
        return(
            <Box
                style={{
                    padding: '15px 15px 0px 15px',
                    background: bgColor,
                    borderRadius: '5px',
                    color: textColor,
                    marginTop: '10px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'justify'
                }}
            >
                <Typography variant='h5' style={{ marginBottom: '5px' }}>
                    Venue
                </Typography>
                {renderHTML(address)}
                {
                    location !== false &&
                    <Box style={{ margin: '5px 0', width: '100%' }}>
                        <Button
                            fullWidth
                            variant='contained'
                            startIcon={<LocationOn />}
                            style={{
                                margin: '4px 0',
                                color: btnTextColor,
                                background: btnBgColor,
                                border: '1px solid ' + btnBorderColor
                            }}
                        >
                            View on Map
                        </Button>
                    </Box>
                }
            </Box>
        )
    } else {
        return null
    }
}
export default EventVanueComp;
