import React from "react";
import {
	Typography,
	IconButton,
	Snackbar,
	Alert,
	LinearProgress,
	Box,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Grid,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core-v4";
import {useDropzone} from "react-dropzone";
import {ExpandMore, Delete, Cancel} from "@material-ui/icons";
import styled from "styled-components";

import {uploader} from "../../../../Services/QrGenerateServices/ApiUrl";
import QrContext from "../../../QrContext/QrContext";

const useStyles = makeStyles((theme) => ({
	box: {
		"& .MuiPaper-root": {
			boxShadow: "none !important",
		},
		"& .MuiPaper-root > .MuiButtonBase-root": {
			border: (props) => (props ? "2px solid #ff3333" : "1px solid #000"),
			borderRadius: "2px",
			background: "#F0F0F0",
			height: "50px !important",
		},
		"& .MuiPaper-root > .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded":
			{
				minHeight: "50px !important",
			},
		"& .MuiPaper-root > .MuiCollapse-root": {
			border: "1px solid #D8D8D8",
			margin: "10px 0",
			borderRadius: "2px",
		},
	},
	center: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	card: {
		width: "100%",
		"& div > div": {
			height: "40px",
			borderRadius: "3px",
		},
		"& .MuiFormControl-root > .MuiOutlinedInput-root > input ": {
			marginTop: "-3px",
		},
		"& .MuiFormControl-root > .MuiOutlinedInput-root > .MuiInputAdornment-root > .MuiButtonBase-root ":
			{
				marginTop: "-7px",
			},
	},
}));

const thumbsContainer = {
	display: "flex",
	flexDirection: "row",
	flexWrap: "wrap",
	marginTop: 16,
};

const thumb = {
	display: "inline-flex",
	borderRadius: 2,
	border: "1px solid #eaeaea",
	marginBottom: 8,
	marginRight: 8,
	width: 110,
	height: 110,
	padding: 4,
	boxSizing: "border-box",
};

const thumbInner = {
	display: "flex",
	minWidth: 0,
	overflow: "hidden",
	objectFit: "contain",
	position: "relative",
};

const img = {
	display: "block",
	width: 100,
	height: 100,
	objectFit: "contain",
};

const getColor = (props) => {
	if (props.isDragAccept) {
		return "#00e676";
	}
	if (props.isDragReject) {
		return "#ff1744";
	}
	if (props.isDragActive) {
		return "#2196f3";
	}
	return "#E0E0E0";
};

const Container = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px;
	border-width: 2px;
	border-radius: 2px;
	border-color: ${(props) => getColor(props)};
	border-style: dashed;
	background-color: #fafafa;
	color: #bdbdbd;
	outline: none;
	transition: border 0.24s ease-in-out;
`;

function BrandLogo(props) {
	const {setContent} = React.useContext(QrContext);
	const {data, dataIndex} = props;

	const {max, image, required} = data;
	const classes = useStyles(required === "indicate" ? true : false);

	const [expanded, setExpanded] = React.useState(false);

	const [loader, setLoader] = React.useState(false);

	const [snackbar, setSnackbar] = React.useState({
		open: false,
		severity: "success",
		message: "here",
	});

	const handleChange = (key, value) => {
		setContent((prev) =>
			prev.map((ele, index) => {
				if (index === dataIndex) {
					return {
						...ele,
						[key]: value,
					};
				} else return ele;
			})
		);
	};
	const handleDelete = () => {
		setContent((prev) => [...prev].filter((ele, index) => dataIndex !== index));
	};
	const maxSize = 5242880;

	function getThe64StringSize(base64String) {
		if (base64String) {
			const string = base64String.split(",")[1];
			let x =
				string.length * (3 / 4) -
				(string.slice(string.length - 2, string.length) !== "=="
					? string.slice(string.length - 1, string.length) === "="
						? 1
						: 0
					: 2);
			return x;
		}
	}

	const handleFile = (data) => {
		handleChange("image", data);
	};

	const uploadFile = (files) => {
		Object.values(files).forEach((file) => {
			var reader = new FileReader();
			if (file) {
				reader.readAsDataURL(file);
				reader.onload = () => {
					uploader({
						payload: [
							{
								name: file.name,
								size: file.size,
								type: file.type,
								base64String: reader.result.split(",")[1],
								base64StringSize: getThe64StringSize(reader.result),
							},
						],
					})
						.then((res) => {
							setLoader(false);
							image.push(...res);
							handleFile(image);
							setSnackbar({
								open: true,
								message: "Image Upload Successfully",
								severity: "success",
							});
						})
						.catch((err) => {
							setLoader(false);
							console.error(err);
							setSnackbar({
								open: true,
								message: "Error while uplaod files",
								severity: "error",
							});
						});
				};
				reader.onerror = () => {
					setLoader(false);
					setSnackbar({
						open: true,
						message: "Error while uplaod files",
						severity: "error",
					});
				};
			}
		});
	};

	const {
		getRootProps,
		getInputProps,
		isDragActive,
		isDragAccept,
		isDragReject,
	} = useDropzone({
		maxFiles: 20,
		accept: "image/*",
		minSize: 0,
		maxSize,
		onDrop: (acceptedFiles) => {
			if (image.length < max) {
				if (
					acceptedFiles &&
					acceptedFiles.length > 0 &&
					acceptedFiles[0].size < 5242880
				) {
					let imageDataPayload = acceptedFiles.map((file) =>
						Object.assign(file, {
							preview: URL.createObjectURL(file),
						})
					);
					setLoader(acceptedFiles.length);
					uploadFile(imageDataPayload);
				} else {
					setSnackbar({
						open: true,
						message: "File Size more then 5 MB",
						severity: "warning",
					});
				}
			} else {
				setSnackbar({
					open: true,
					message: "You have upload max 1 Image",
					severity: "warning",
				});
			}
		},
	});

	const handleDeleteImage = (index) => {
		handleChange(
			"image",
			image.filter((ele, eleIndex) => eleIndex !== index)
		);
	};

	if (props.data) {
		const thumbs =
			image &&
			image.length > 0 &&
			image.map((file, index) => (
				<div style={thumb} key={file.name}>
					<div style={thumbInner}>
						<IconButton
							style={{
								position: "absolute",
								padding: "0",
								margin: "0",
								right: "0",
								border: "0",
								background: "transparent",
								height: "1px !important",
							}}
							onClick={() => handleDeleteImage(index)}
						>
							<Cancel />
						</IconButton>
						<img src={file.url} style={img} alt="" />
					</div>
				</div>
			));

		return (
			<div className={classes.box}>
				<Accordion onChange={(e, data) => setExpanded(data)}>
					<AccordionSummary expandIcon={<ExpandMore />}>
						<Box
							style={{
								display: "flex",
								justifyContent: "space-between",
								width: "100%",
								alignItems: "center",
							}}
						>
							<Typography
								style={{color: "#615757", fontSize: "20px", fontWeight: "600"}}
							>
								Brand Logo *
							</Typography>
							{expanded && (
								<IconButton
									style={{marginRight: "15px"}}
									onClick={() => handleDelete()}
								>
									<Delete />
								</IconButton>
							)}
						</Box>
					</AccordionSummary>
					<AccordionDetails style={{display: "flex", alignItems: "center"}}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Grid conatiner>
									<Grid item xs={12}>
										<Typography variant="h5" style={{margin: "10px 0"}}>
											Upload Company Logo
										</Typography>
									</Grid>
									<Grid item xs={12}>
										<section className="container">
											<Container
												{...getRootProps({
													isDragActive,
													isDragAccept,
													isDragReject,
												})}
											>
												<input {...getInputProps()} />
												<p>
													Drag 'n' drop some files here, or click to select
													files
												</p>
											</Container>
											<aside style={thumbsContainer}>
												{thumbs}
												{loader !== false &&
													[...Array(loader)].map((item, index) => (
														<div style={thumb} key={index}>
															<div style={thumbInner}>
																<Box
																	key={index}
																	variant="rectangular"
																	width={110}
																	height={110}
																	style={{marginTop: "90px"}}
																>
																	<LinearProgress />
																</Box>
															</div>
														</div>
													))}
											</aside>
										</section>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</AccordionDetails>
				</Accordion>
				<Snackbar
					open={snackbar.open}
					anchorOrigin={{vertical: "top", horizontal: "right"}}
					autoHideDuration={5000}
					onClose={() => setSnackbar({open: false})}
				>
					<Alert
						onClose={() => setSnackbar({open: false})}
						severity={snackbar.severity}
						sx={{width: "100%"}}
					>
						{snackbar.message}
					</Alert>
				</Snackbar>
			</div>
		);
	} else {
		return null;
	}
}

export default BrandLogo;
