import React from "react";
import {
	Container,
	Box,
	TextField,
	Typography,
	InputAdornment,
	CircularProgress,
	Paper,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core-v4";
import {BiMenu} from "react-icons/all";
import SwitchSelector from "react-switch-selector";

import QrContextWrapper from "../CreateQR/QrContext/QrContextWrapper";
import PageTitle from "../../../UI/PageTitle";
import QrContent from "../CreateQR/Components/QrContent";
import AuthContext from "../../../../Context/AuthContext";

import {leadFindById, updateLead} from "../Services/LeadServices/ApiUrl";
import Analytics from "./Analytics";
import {useParams} from "react-router";

const useStyles = makeStyles((theme) => ({
	card: {
		width: "100%",
		"& div > div": {
			height: "40px",
			borderRadius: "5px",
		},
		"& .MuiFormControl-root > .MuiOutlinedInput-root > input ": {
			marginTop: "-5px",
			// borderLeft: "1px solid #999",
			// padding: "10px 5px 10px 10px",
		},
		"& .MuiFormControl-root > .MuiOutlinedInput-root > .MuiInputAdornment-root > .MuiButtonBase-root ":
			{
				marginTop: "-7px",
			},
	},
	selectSwitch: {
		width: 500,
		height: 38,
		"& div": {
			borderRadius: "6px",
		},
	},
}));

const additionalContentArray = ["DescriptionBox"];

function ManageLead(props) {
	const classes = useStyles();
	const params = useParams();
	const {leadId} = params;
	const {setAdditionalContent, setCategory} = React.useContext(AuthContext);

	const [title, setTitle] = React.useState();
	const [page, setPage] = React.useState();
	const [render, setRender] = React.useState(0);
	const [leadData, setLeadData] = React.useState();

	React.useEffect(() => {
		setCategory("Lead");
		setAdditionalContent(additionalContentArray);
	}, [setCategory, setAdditionalContent]);

	// ---------------------- Scroll Tostick Mobile ----------------------- //

	window.onscroll = function () {
		scrollFunction();
	};

	function scrollFunction() {
		if (
			document.body.scrollTop > 220 ||
			document.documentElement.scrollTop > 220
		) {
			try {
				document.getElementById("navbar").style.position = "fixed";
				document.getElementById("navbar").style.top = "0";
			} catch {
				return null;
			}
		} else {
			try {
				document.getElementById("navbar").style.position = "relative";
			} catch {
				return null;
			}
		}
	}

	const getLeadByIdApiCall = React.useCallback(() => {
		leadFindById(leadId)
			.then((res) => {
				setLeadData(res);
				setTitle(res.title);
				setPage("display");
			})
			.catch((err) => {
				console.error(err);
			});
	}, [leadId]);

	React.useEffect(() => {
		getLeadByIdApiCall();
	}, [getLeadByIdApiCall]);

	const handleFinish = (data) => {
		setPage();
		let payload = {
			payload: {
				qrData: {
					title: title,
					uiData: data,
				},
			},
		};
		updateLead(leadId, payload)
			.then((res) => {
				getLeadByIdApiCall();
			})
			.catch((err) => {
				getLeadByIdApiCall();
				console.error(err);
			});
	};

	const RenderComp = () => {
		switch (render) {
			case 0:
				return (
					<React.Fragment>
						<Box
							sx={{
								padding: "30px 30px 10px 30px",
								background: "#FFF",
							}}
						>
							<Typography sx={{color: "#444", fontSize: "18px", mb: 1}}>
								<b>Lead List Name</b>
							</Typography>
							<Box
								className={classes.card}
								sx={{
									width: "50%",
								}}
							>
								<TextField
									placeholder="Write here..."
									type="text"
									onChange={(e) => setTitle(e.target.value)}
									value={title}
									variant="outlined"
									color="primary"
									fullWidth
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<Box sx={{marginTop: "8px"}}>
													<BiMenu style={{fontSize: "23px"}} />
												</Box>
											</InputAdornment>
										),
									}}
								/>
							</Box>
						</Box>
						<QrContent
							uiData={leadData?.uiData}
							finishForm={true}
							handleFinish={handleFinish}
							btnTitle="Update"
						/>
					</React.Fragment>
				);
			case 1:
				return (
					<React.Fragment>
						<Analytics leadData={leadData} />
					</React.Fragment>
				);
			default:
				break;
		}
	};

	return (
		<QrContextWrapper>
			<Container maxWidth="xl" style={{marginTop: "30px"}}>
				<PageTitle
					title="Leads"
					subTitle={
						<div style={{display: "flex", flexDirection: "column"}}>
							<span>
								Lead Generation feature allows you to get contact and other
								information when people scan your QR Codes.
							</span>
							<span>
								Use this section to setup lead lists and forms, and to export
								captured leads data
							</span>
						</div>
					}
				/>
				{page === "display" ? (
					<Paper elevation={0}>
						<Box
							sx={{
								padding: "20px 0 0 30px",
							}}
						>
							<div className={classes.selectSwitch}>
								<SwitchSelector
									onChange={(e) => {
										setRender(e);
									}}
									options={[
										{label: "FORM", value: 0},
										{label: "LEADS DATA & ANALYTICS", value: 1},
									]}
									initialSelectedIndex={render}
									forcedSelectedIndex={render}
									// backgroundColor="#E0E0E0"
									fontColor="#000"
									optionBorderRadius="6"
									selectedBackgroundColor="#FF800C"
								/>
							</div>
						</Box>
						{RenderComp()}
					</Paper>
				) : (
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							height: "80vh",
							width: "100%",
						}}
					>
						<CircularProgress />
					</div>
				)}
			</Container>
		</QrContextWrapper>
	);
}

export default ManageLead;
