import React from "react";

import {
	Grid,
	Card,
	CardContent,
	Button,
	Typography,
	Menu,
	MenuItem,
} from "@mui/material";
import xlsx from "json-as-xlsx";
import {jsPDF} from "jspdf";
import * as htmlToImage from "html-to-image";

import ChipComponent from "./ChartComponent/ChipComponent";
import MapComponent from "./ChartComponent/MapComponent";
import LineComponentWrapper from "./ChartComponent/LineComponent/LineComponentWrapper";

export default function AnalyticsArea({analyticsValues, showData}) {
	const initialState = {
		scanCount: {
			graph: {
				option: {},
				series: [],
			},
			table: {
				content: [],
				columns: {},
			},
		},
		date: {
			graph: {
				option: {},
				series: [],
			},
			table: {
				content: [],
				columns: {},
			},
		},
		location: {
			graph: {
				option: {},
				series: [],
			},
			table: {
				content: [],
				columns: {},
			},
		},
		device: {
			graph: {
				option: {},
				series: [],
			},
			table: {
				content: [],
				columns: {},
			},
		},
		platform: {
			graph: {
				option: {},
				series: [],
			},
			table: {
				content: [],
				columns: {},
			},
		},
		os: {
			graph: {
				option: {},
				series: [],
			},
			table: {
				content: [],
				columns: {},
			},
		},
		handler: {
			graph: {
				option: {},
				series: [],
			},
			table: {
				content: [],
				columns: {},
			},
		},
		analyticsData: [],
	};
	const [analyticsData, setAnalyticsData] = React.useState(initialState);
	const [anchorEl, setAnchorEl] = React.useState({
		download: null,
		scan: null,
	});

	const handleClick = (event, type) => {
		setAnchorEl((prev) => ({
			[type]: event.currentTarget,
		}));
	};
	const handleClose = (type) => {
		setAnchorEl((prev) => ({
			...prev,
			[type]: null,
		}));
	};

	React.useEffect(() => {
		setAnalyticsData((prev) => ({
			location: analyticsValues.location
				? analyticsValues.location
				: prev.location,
			scanCount: analyticsValues.scanCount
				? analyticsValues.scanCount
				: prev.scanCount,
			device: analyticsValues.device ? analyticsValues.device : prev.device,
			platform: analyticsValues.platform
				? analyticsValues.platform
				: prev.platform,
			os: analyticsValues.os ? analyticsValues.os : prev.os,
			handler: analyticsValues.handler ? analyticsValues.handler : prev.handler,
			date: analyticsValues.date ? analyticsValues.date : prev.date,
			analyticsData: analyticsValues.analyticsData
				? analyticsValues.analyticsData
				: [],
		}));
	}, [analyticsValues]);

	const generateFileName = (length) => {
		var result = "";
		var characters =
			"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
		var charactersLength = characters.length;
		for (var i = 0; i < length; i++) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}
		return result;
	};

	const getPrintableFormatData = () => {
		let d = document.getElementsByClassName("customLabelData");
		let labels = [];
		for (let i = 0; i < d.length; i++) {
			labels.push(d[i].innerHTML);
		}

		let data = Object.entries(analyticsData).map(([key, value], index) => {
			if (key !== "analyticsData") {
				return {
					sheet: labels[index],
					...value.table,
				};
			}
			return {
				sheet: "",
				content: [],
				columns: [],
			};
		});
		return data;
	};

	const renderDownloadFileFunc = (action, fileType) => {
		var doc = new jsPDF({
			putOnlyUsedFonts: true,
			unit: "px",
			orientation: "p",
		});
		switch (fileType) {
			case "PDF":
				if (action === "SCAN") {
					let data = getPrintableFormatData().filter((ele) => ele.sheet !== "");
					var generateData = function (row) {
						return row.map((ele) => {
							let d = {};
							Object.entries(ele).forEach(([key, value]) => {
								d = {...d, [key]: value.toString()};
							});
							return d;
						});
					};

					function createHeaders(keys) {
						var result = [];
						for (var i = 0; i < keys.length; i += 1) {
							result.push({
								id: keys[i],
								name: keys[i],
								prompt: keys[i],
								width: 65,
								align: "center",
								padding: 0,
							});
						}
						return result;
					}
					data.forEach((ele) => {
						var headers = createHeaders(ele.columns.map((ele) => ele.value));
						doc.text(ele.sheet, 1, 20);
						doc.table(1, 30, generateData(ele.content), headers, {
							autoSize: true,
							css: {
								"font-size": 0.5,
							},
						});
						doc.cellAddPage();
					});

					doc.save(`${Math.random()}.pdf`);
				} else {
					htmlToImage
						.toJpeg(document.getElementById("canvas"), {quality: 1})
						.then(function (dataUrl) {
							doc.addImage(dataUrl, "JPEG", 0, 0, 450, 630);
							doc.save(`${Math.random()}.pdf`);
						});
				}
				break;
			case "IMAGE":
				htmlToImage
					.toJpeg(document.getElementById("canvas"), {quality: 1})
					.then(function (dataUrl) {
						var link = document.createElement("a");
						link.download = `${generateFileName(10)}.jpeg`;
						link.href = dataUrl;
						link.click();
					});
				break;
			case "CSV":
				let data = getPrintableFormatData().filter((ele) => ele.sheet !== "");
				let l = data
					.map((ele) => {
						let x = ["id", ...ele.columns.map((c) => c.value)].join(",");
						let y = ele.content
							.map((c) => Object.values(c).map((v) => v))
							.join(",\n");
						return "\n\n" + ele.sheet + "\n\n" + x + "\n" + y;
					})
					.join("\n");
				var link = document.createElement("a");
				link.download = `${generateFileName(10)}.csv`;
				link.href = Buffer.from("data:text/csv;charset=utf-8," + l);
				link.click();
				break;
			case "XLSX":
				let dataTable = getPrintableFormatData().filter(
					(ele) => ele.sheet !== ""
				);
				console.log("dataTable", dataTable);
				let settings = {
					fileName: Math.random(), // Name of the spreadsheet
					extraLength: 3, // A bigger number means that columns will be wider
					writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
				};
				xlsx(dataTable, settings);
				break;
			default:
				break;
		}
	};

	const renderDownloadAnalyticsMenu = () => (
		<Menu
			anchorEl={anchorEl.download}
			open={anchorEl.download}
			onClose={() => handleClose("download")}
			onClick={() => handleClose("download")}
			PaperProps={{
				elevation: 0,
				sx: {
					overflow: "visible",
					filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
					mt: 1.5,
					"& .MuiAvatar-root": {
						width: 32,
						height: 32,
						ml: -0.5,
						mr: 1,
					},
					"&:before": {
						content: '""',
						display: "block",
						position: "absolute",
						top: 0,
						right: 14,
						width: 10,
						height: 10,
						bgcolor: "background.paper",
						transform: "translateY(-50%) rotate(45deg)",
						zIndex: 0,
					},
				},
			}}
			transformOrigin={{horizontal: "right", vertical: "top"}}
			anchorOrigin={{horizontal: "right", vertical: "bottom"}}
		>
			<MenuItem onClick={() => renderDownloadFileFunc("DOWNLOAD", "CSV")}>
				Export to CSV
			</MenuItem>
			<MenuItem onClick={() => renderDownloadFileFunc("DOWNLOAD", "IMAGE")}>
				Export to IMAGE
			</MenuItem>
			<MenuItem onClick={() => renderDownloadFileFunc("DOWNLOAD", "PDF")}>
				Export to PDF
			</MenuItem>
		</Menu>
	);
	const renderDownloadScanMenu = () => (
		<Menu
			anchorEl={anchorEl.scan}
			open={anchorEl.scan}
			onClose={() => handleClose("scan")}
			onClick={() => handleClose("scan")}
			PaperProps={{
				elevation: 0,
				sx: {
					overflow: "visible",
					filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
					mt: 1.5,
					"& .MuiAvatar-root": {
						width: 32,
						height: 32,
						ml: -0.5,
						mr: 1,
					},
					"&:before": {
						content: '""',
						display: "block",
						position: "absolute",
						top: 0,
						right: 14,
						width: 10,
						height: 10,
						bgcolor: "background.paper",
						transform: "translateY(-50%) rotate(45deg)",
						zIndex: 0,
					},
				},
			}}
			transformOrigin={{horizontal: "right", vertical: "top"}}
			anchorOrigin={{horizontal: "right", vertical: "bottom"}}
		>
			<MenuItem onClick={() => renderDownloadFileFunc("SCAN", "CSV")}>
				Export to CSV
			</MenuItem>
			<MenuItem onClick={() => renderDownloadFileFunc("SCAN", "XLSX")}>
				Export to XLSX
			</MenuItem>
			<MenuItem onClick={() => renderDownloadFileFunc("SCAN", "PDF")}>
				Export to PDF
			</MenuItem>
		</Menu>
	);

	return (
		<Grid container spacing={3} id="canvas">
			<ChipComponent
				analyticsData={analyticsData.analyticsData}
				showData={showData}
			/>
			<Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
				<Card
					sx={{
						backgroundColor: "#f8f8f8",
						border: "1px solid lightgrey",
						borderRadius: "4px",
						height: "100%",
						boxShadow: "0px 20px 27px 0px rgba(0, 0, 0, 0.05)",
					}}
					elevation={6}
				>
					<CardContent sx={{paddingBottom: "16px !important"}}>
						<Grid container spacing={3}>
							<Grid item sm={4} xs={4} md={12} lg={12} xl={4}>
								<Typography
									variant="h4"
									color="textSecondary"
									sx={{
										padding: "0.74rem 0",
									}}
								>
									Export Anlytics
								</Typography>
							</Grid>
							<Grid
								item
								sm={4}
								xs={4}
								md={6}
								lg={6}
								xl={4}
								sx={{display: "flex"}}
							>
								<Button
									onClick={(e) => handleClick(e, "download")}
									disabled={analyticsData.analyticsData.length === 0}
								>
									Get Analytics
								</Button>
							</Grid>
							<Grid
								item
								sm={4}
								xs={4}
								md={6}
								lg={6}
								xl={4}
								sx={{display: "flex"}}
							>
								<Button
									onClick={(e) => handleClick(e, "scan")}
									disabled={analyticsData.analyticsData.length === 0}
								>
									Get Scan Data
								</Button>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			</Grid>
			<Grid item xs={12}>
				<LineComponentWrapper
					title={"Scan Count of QR's"}
					showData={showData}
					data={analyticsData.scanCount}
				/>
			</Grid>
			<Grid item xs={12}>
				<LineComponentWrapper
					title={"Scan Date of QR's"}
					data={analyticsData.date}
					dataType="date"
					analyticsData={analyticsData.analyticsData}
					showData={showData}
				/>
			</Grid>
			<Grid item xs={12}>
				<LineComponentWrapper
					title={"Scan Devices of QR's"}
					data={analyticsData.device}
					showData={showData}
				/>
			</Grid>
			<Grid item xs={12}>
				<LineComponentWrapper
					title="Scan Platform of QR's"
					data={analyticsData.platform}
					showData={showData}
				/>
			</Grid>
			<Grid item xs={12}>
				<LineComponentWrapper
					title={"Scan OS of QR's"}
					data={analyticsData.os}
					showData={showData}
				/>
			</Grid>
			<Grid item xs={12}>
				<LineComponentWrapper
					title={"Scan Handsets of QR's"}
					data={analyticsData.handler}
					showData={showData}
				/>
			</Grid>
			<Grid item xs={12}>
				<MapComponent data={analyticsData.analyticsData} showData={showData} />
			</Grid>
			{renderDownloadAnalyticsMenu()}
			{renderDownloadScanMenu()}
		</Grid>
	);
}
