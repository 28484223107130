import * as React from "react";
import {Box, Grid} from "@material-ui/core";

import classes from "./Page3.module.css";

const gridArray = [
	{
		title: "QR Code Payments",
		text: "Generate QR Codes in mobile app or print on bills to allow users to transfer money and accept payments using their smartphones",
	},
	{
		title: "Social Media Interactions",
		text: "Generate and share unique profile QR Codes in your mobile application to add new contacts via scanning",
	},
	{
		title: "Employee IDs and Event Nametags",
		text: "Add a QR Code with a unique serial code on company ID cards or nametags for digital verification",
	},
	{
		title: "Document Verification",
		text: "Add unique QR Codes to documents that link to information in your database for verification",
	},
	{
		title: "Product Tags and Packaging",
		text: "Add QR Code to product packaging with links to receive customer feedback, provide customer support, or promote social media",
	},
	{
		title: "Health Care Systems",
		text: "Add QR Codes to patient cards with information like detailed medical history, current medication, and other records",
	},
];

function Page3() {
	return (
		<Box className={classes.box}>
			<Grid container>
				<Grid item xs={12} className="d-flex justify-content-center">
					<span className={classes.boxTitle}>
						{" "}
						Automated QR Code Generation Use Cases{" "}
					</span>
				</Grid>
				<Grid item container xs={12} spacing={3} className="mt-2">
					{gridArray.map((ele, index) => (
						<Grid item xs={4} key={index}>
							<Box className={classes.labelText}>
								<span style={{color: "#000000"}}>{ele.title}</span>
								<span className="mt-3">{ele.text}</span>
							</Box>
						</Grid>
					))}
				</Grid>
			</Grid>
		</Box>
	);
}

export default Page3;
