import React from "react";

// ------------------------ import Components --------------------------- //

import {
	Container,
	Box,
	Typography,
	Grid,
	Paper,
	CircularProgress,
} from "@material-ui/core";
import moment from "moment";

// ------------------------ import Context --------------------------- //
import AuthContext from "../../../../../Context/AuthContext";

// ------------------------ Custome makeStyle --------------------------- //

// const useStyles = makeStyles((theme) => ({
//     iconInfo: {
//         color: '#999',
//         '&:hover': {
//             color: '#C86409'
//         }
//     },
//     center: {
//         display: 'flex',
//         justifyContent: 'center',
//         alignItems: 'center'
//     },
//     selectSwitch: {
//         width: 600,
//         height: 38,
//         marginTop: '30px',
//         '& div': {
//             borderRadius: '6px'
//         }
//     }
// }))

function Subscription(props) {
	const context = React.useContext(AuthContext);

	const {license} = context;

	if (license) {
		return (
			<Container
				component={Paper}
				maxWidth="xl"
				style={{margin: "30px auto", padding: "30px"}}
			>
				<Grid container spacing={0}>
					<Grid item lg={6} md={6} sm={12} xs={12}>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<Typography variant="h5" style={{color: "#444"}}>
									<b>Account Status :</b>
								</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography style={{color: "green"}}>
									{license.expired ? "Deactive" : "Active"}
								</Typography>
							</Grid>

							<Grid item xs={6}>
								<Typography variant="h5" style={{color: "#444"}}>
									<b>Current Plan :</b>
								</Typography>
							</Grid>
							<Grid item xs={6}>
								<Box style={{display: "flex"}}>
									<Typography style={{color: "#444"}}>
										{license?.planDetail?.title || "<empty>"}
									</Typography>
									<Typography
										color="primary"
										onClick={props.updatePlan}
										style={{marginLeft: "60px", cursor: "pointer"}}
									>
										Upgrade Plan
									</Typography>
								</Box>
							</Grid>

							<Grid item xs={6}>
								<Typography variant="h5" style={{color: "#444"}}>
									<b>Subscription Paid Till :</b>
								</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography style={{color: "#444"}}>
									{moment(license?.expiredOn).format("llll")}
								</Typography>
							</Grid>

							<Grid item xs={6}>
								<Typography variant="h5" style={{color: "#444"}}>
									<b>Subscription Plan Type :</b>
								</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography style={{color: "#444"}}>
									{license?.subscriptionPlanType === "MONTHLY"
										? "Monthly"
										: "Annual"}
								</Typography>
							</Grid>

							{license?.subscriptionPlanType ? (
								license?.subscriptionPlanType === "ANNUALLY" ? (
									<React.Fragment>
										<Grid item xs={6}>
											<Typography variant="h5" style={{color: "#444"}}>
												<b>Monthly Cost :</b>
											</Typography>
										</Grid>
										<Grid item xs={6}>
											<Typography style={{color: "#444"}}>
												{license?.planDetail?.annual_cost + " KSh" || "<empty>"}
											</Typography>
										</Grid>

										<Grid item xs={6}>
											<Typography variant="h5" style={{color: "#444"}}>
												<b>Total Year Cost :</b>
											</Typography>
										</Grid>
										<Grid item xs={6}>
											<Typography style={{color: "#444"}}>
												{license?.planDetail?.total_year_cost + " KSh" ||
													"<empty>"}
											</Typography>
										</Grid>
									</React.Fragment>
								) : (
									<React.Fragment>
										<Grid item xs={6}>
											<Typography variant="h5" style={{color: "#444"}}>
												<b>Monthly Cost :</b>
											</Typography>
										</Grid>
										<Grid item xs={6}>
											<Typography style={{color: "#444"}}>
												{license?.planDetail?.monthly_cost + " KSh" ||
													"<empty>"}
											</Typography>
										</Grid>
									</React.Fragment>
								)
							) : (
								<React.Fragment>
									<Grid item xs={6}>
										<Typography variant="h5" style={{color: "#444"}}>
											<b>Monthly Cost :</b>
										</Typography>
									</Grid>
									<Grid item xs={6}>
										<Typography style={{color: "#444"}}>
											{license?.planDetail?.monthly_cost || "<empty>"}
										</Typography>
									</Grid>
								</React.Fragment>
							)}

							<Grid item xs={6}>
								<Typography variant="h5" style={{color: "#444"}}>
									<b>QR Code Creation Limit :</b>
								</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography style={{color: "#444"}}>
									{license?.planDetail?.qrcode_limit || "<empty>"}
								</Typography>
							</Grid>

							<Grid item xs={6}>
								<Typography variant="h5" style={{color: "#444"}}>
									<b>QR Code Scan Limit :</b>
								</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography style={{color: "#444"}}>
									{license?.planDetail?.qrcode_scan_limit === "max"
										? "Unlimited"
										: license?.planDetail?.qrcode_scan_limit
										? license?.planDetail?.qrcode_scan_limit
										: "<empty>"}
								</Typography>
							</Grid>

							<Grid item xs={12}>
								<Typography variant="h5" style={{color: "#444"}}>
									<b>Plan Features :</b>
								</Typography>
								<Typography
									style={{
										color: "#444",
										display: "flex",
										flexDirection: "column",
										marginTop: "10px",
									}}
								>
									<ul>
										{license.planDetail &&
											license.planDetail.features &&
											license.planDetail.features.length > 0 &&
											license.planDetail.features.map((ele, index) => (
												<li key={index} style={{marginBottom: "5px"}}>
													{ele.feature_name}
												</li>
											))}
									</ul>
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Container>
		);
	} else {
		return (
			<Box style={{marginTop: "10%", width: "100%", textAlign: "center"}}>
				<CircularProgress />
			</Box>
		);
	}
}

export default Subscription;
