import * as React from "react";
import {Box, Grid} from "@material-ui/core";

import classes from "./Page1.module.css";

import HOTQr from "../../../../../Assets/HOTQr.png";
import {useNavigate} from "react-router";

function Page1(props) {
	const navigate = useNavigate();
	return (
		<Box className={classes.box}>
			<Box onClick={() => navigate("/")} className={classes.boxLogo}>
				<img className={classes.HOTQr} alt="logo" src={HOTQr} />
			</Box>
			<Grid container>
				<Grid item xs={12} className="d-flex justify-content-center">
					<Box className={classes.boxHead}>
						<label className={classes.boxHeadTitle}>
							{" "}
							HOT QR Code Generation & Design SDK{" "}
						</label>
						<label className={classes.boxHeadText}>
							{" "}
							Generate designer static QR Codes in your payments, ticketing or
							any other information system or mobile app using our SDK{" "}
						</label>
						<Box
							className={classes.boxHeadButton}
							onClick={() => navigate("/qr")}
						>
							<span className={classes.boxHeadButtonText}>
								{" "}
								Get started with HOT QR SDK{" "}
							</span>
						</Box>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
}

export default Page1;
