import { urls } from '../../../../../Environment/ApiUrl';
import { executePost } from '../../../../../Services/iwant-rest-http-post';
import { executeGet } from "../../../../../Services/iwant-rest-generic";

export const findAllQrCode = (requestLoad) => {
    let url = urls.QRCODE.FINDALL;
    let actionCode = "";
    let payload = {};
    let method = 'GET';
    return executeGet(url, actionCode, payload, method);
};

export const findQrCodeById = (requestLoad) => {
    let url = urls.QRCODE.FINDBYID + requestLoad;
    let actionCode = "";
    let payload = {};
    let method = 'GET';
    return executeGet(url, actionCode, payload, method);
};

export const createQrCode = (requestLoad) => {
    let url = urls.QRCODE.CREATE;
    let actionCode = "";
    let payload = requestLoad;
    let method = 'POST';
    return executePost(url, actionCode, payload, method);
};

export const updateQrCode = (id, requestLoad) => {
    let url = urls.QRCODE.UPDATE + id;
    let actionCode = "";
    let payload = requestLoad;
    let method = 'PUT';
    return executePost(url, actionCode, payload, method);
};

export const deleteQrCode = (requestLoad) => {
    let url = urls.QRCODE.DELETE + requestLoad;
    let actionCode = "";
    let payload = {};
    let method = 'DELETE';
    return executeGet(url, actionCode, payload, method);
};

export const activeQrCode = (requestLoad) => {
    let url = urls.QRCODE.ACTIVE + requestLoad;
    let actionCode = "";
    let payload = {};
    let method = 'GET';
    return executeGet(url, actionCode, payload, method);
};

export const deactiveQrCode = (requestLoad) => {
    let url = urls.QRCODE.DEACTIVE + requestLoad;
    let actionCode = "";
    let payload = {};
    let method = 'GET';
    return executeGet(url, actionCode, payload, method);
};

export const uploader = (requestLoad) => {
    let url = "https://api.hotqrcode.com/qr/upload-content";
    let actionCode = "";
    let payload = requestLoad;
    let method = 'POST';
    return executePost(url, actionCode, payload, method);
};

export const deleteUploadedFile = (requestLoad) => {
    let url = 'https://api.hotqrcode.com/qr/delete-content?fileKey=' + requestLoad;
    let actionCode = "";
    let payload = {};
    let method = 'DELETE';
    return executeGet(url, actionCode, payload, method);
}