import React from "react";
import {
	Button,
	Grid,
	Box,
	Tooltip,
	Typography,
	Card,
	CardContent,
	IconButton,
	CircularProgress,
} from "@mui/material";
import {Chart} from "react-google-charts";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";

const MapComponent = ({data: mapData, showData}) => {
	const [data, setData] = React.useState([]);
	const [options, setOptions] = React.useState({});
	const [wholeData, setWholeData] = React.useState([]);
	const [wrapper, setWrapper] = React.useState([]);

	const setMapData = React.useCallback(
		(type) => {
			setWholeData([]);
			const x = mapData
				.map((ele) => {
					return ele.scanLocations.map((dataele) => dataele);
				})
				.flat()
				.filter((ele) => ele[type]);

			const locData = x
				.map((e, i, a) => {
					if ((a[i + 1] && a[i + 1][type]) !== e[type]) {
						setWholeData((prev) => [...prev, e]);
						if (type === "city") {
							return [
								e.lat,
								e.lon,
								e.city,
								x.filter((data) => data[type] === e[type]).length,
							];
						} else {
							return [
								e[type],
								x.filter((data) => data[type] === e[type]).length,
							];
						}
					} else {
						return null;
					}
				})
				.filter((ele) => ele != null);
			if (type === "city") {
				let dlema = {};
				locData.forEach((ele) => {
					dlema = {...dlema, [ele[2]]: ele};
				});
				setData([
					["Lat", "Lon", "Label", "Count"],
					...Object.entries(dlema).map(([k, v]) => v),
				]);
			} else {
				let dlema = {};
				locData.forEach((ele) => {
					dlema = {...dlema, [ele[0]]: ele};
				});
				setData([
					["Label", "Count"],
					...Object.entries(dlema).map(([k, v]) => v),
				]);
			}
		},
		[mapData]
	);
	React.useEffect(() => {
		setMapData("country");
	}, [setMapData]);

	const mapHandlerFunction = (chartWrapper) => {
		setWrapper(chartWrapper);
		const chart = chartWrapper.getChart();
		const selection = chart.getSelection();
		if (selection.length === 0) return;
		const region = data[selection[0].row + 1];
		if (region[0]) {
			let drilldownValue = wholeData[0].countryCode;
			setMapData("city");
			setOptions({
				region: drilldownValue,
				displayMode: "markers",
				colorAxis: {
					colors: ["red", "yellow", "orange", "green", "blue"],
				},
				resolution: "provinces",
			});

			chartWrapper.draw();
		}
	};

	return (
		<div className="App">
			<Card
				sx={{
					backgroundColor: "#f8f8f8",
					border: "1px solid lightgrey",
					borderRadius: "4px",
					boxShadow: "0px 20px 27px 0px rgba(0, 0, 0, 0.05)",
				}}
				elevation={6}
			>
				<CardContent sx={{paddingBottom: "16px !important"}}>
					<Grid container>
						<Grid item xs={12}>
							<Grid container sx={{alignItems: "center"}}>
								<Grid item xs={10}>
									<Typography
										variant="h4"
										className="customLabelData"
										color="textSecondary"
										sx={{
											padding: "1rem",
										}}
									>
										Scan Location of QR's
									</Typography>
								</Grid>
								<Grid item xs={2} container>
									<Button
										startIcon={<ArrowBackIcon />}
										onClick={() => {
											setOptions({});
											setMapData("country");
										}}
										disabled={
											(mapData && mapData.length === 0) ||
											Object.keys(options).length === 0
										}
									>
										{" "}
										Visit to World Map{" "}
									</Button>
									<Tooltip title={"Download"}>
										<IconButton
											color="primary"
											disabled={mapData && mapData.length === 0}
											onClick={() => {
												var file =
													wrapper &&
													wrapper.getChart &&
													wrapper.getChart().getImageURI();

												var a = document.createElement("a");
												a.href = file;
												a.download = "file.png";
												a.click();
											}}
										>
											<ArrowCircleDownIcon />
										</IconButton>
									</Tooltip>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<hr />
					<Box
						sx={{
							minHeight: "300px",
							height: "100%",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						{showData ? (
							<React.Fragment>
								{mapData && mapData.length > 0 ? (
									<Chart
										enableRegionInteractivity={true}
										chartEvents={[
											{
												eventName: "select",
												callback: ({chartWrapper}) => {
													mapHandlerFunction(chartWrapper);
												},
											},
										]}
										chartType="GeoChart"
										width="1440px"
										mapsApiKey="AIzaSyBqW8iaz-_qlaTMc1ynbj9f7mpfmbVUcW4"
										height="300px"
										data={data}
										options={options}
									/>
								) : (
									<Typography
										variant="h4"
										color="textSecondary"
										sx={{
											padding: "1rem",
										}}
									>
										No data for current selection
									</Typography>
								)}
							</React.Fragment>
						) : (
							<CircularProgress />
						)}
					</Box>
				</CardContent>
			</Card>
		</div>
	);
};

export default MapComponent;
