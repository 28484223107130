import "react-perfect-scrollbar/dist/css/styles.css";
import {BrowserRouter} from "react-router-dom";
import GlobalStyles from "./Components/GlobalStyles";
import {ThemeProvider} from "@material-ui/core";
import theme from "./theme";
import Router from "./routes";
import SubMain from "./Components/Main/SubMain";
import BussinessHandler from "./Components/Main/BussinessHandler";

function App() {
	let inputTypeNumbers = document.querySelectorAll("input[type=number]");

	for (let a = 0; a < inputTypeNumbers.length; a++) {
		inputTypeNumbers[a].onwheel = function (event) {
			event.target.blur();
		};
	}

	return (
		<ThemeProvider theme={theme}>
			<SubMain>
				<BussinessHandler>
					<BrowserRouter>
						<GlobalStyles />
						<Router />
					</BrowserRouter>
				</BussinessHandler>
			</SubMain>
		</ThemeProvider>
	);
}

export default App;
