import React from 'react';

import paypalScreen from '../../../../../../../Assets/paypal.png';

function Paypal (props) {
    return (
        <img alt='' src={paypalScreen} style={{ height: '100%', width: '100%', objectFit: 'fill' }} />
    )
}

export default Paypal;