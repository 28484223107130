import * as React from "react";
import {Box, Grid} from "@material-ui/core";

import classes from "./Page2.module.css";

const gridArray = [
	{
		title: "Choose QR Code Category and Add Data",
		text: "Select the type of QR Code—Website URL, Text, Vcard, Serial Code, or Random Code",
		text1:
			"Enter data manually using dashboard editor or upload spreadsheet (CSV, XLS, XLSX) with data in specified format",
	},
	{
		title: "Choose QR Code Category and Add Data",
		text: "Select the type of QR Code—Website URL, Text, Vcard, Serial Code, or Random Code",
		text1:
			"Enter data manually using dashboard editor or upload spreadsheet (CSV, XLS, XLSX) with data in specified format",
	},
	{
		title: "Choose QR Code Category and Add Data",
		text: "Select the type of QR Code—Website URL, Text, Vcard, Serial Code, or Random Code",
		text1:
			"Enter data manually using dashboard editor or upload spreadsheet (CSV, XLS, XLSX) with data in specified format",
	},
];

function Page2() {
	return (
		<Box className={classes.box}>
			<Grid container spacing={4}>
				<Grid item xs={12} className="d-flex justify-content-center">
					<label className={classes.labelTitle}>
						How Bulk HOT QR Code Generation Works
					</label>
				</Grid>
				{gridArray.map((ele, index) => (
					<Grid item xs={4} key={index}>
						<Box className={classes.boxContent}>
							<Box className={classes.labelBoxNumber}>
								<span className={classes.labelNumber}>{index + 1}</span>
							</Box>
							<Box className={classes.labelText}>
								<span style={{color: "#000"}} className="mt-3">
									{ele.title}
								</span>
								<span className="mt-3">{ele.text}</span>
								<span className="mt-3">{ele.text1}</span>
							</Box>
						</Box>
					</Grid>
				))}
			</Grid>
		</Box>
	);
}

export default Page2;
