const getNormalCars = (data) => {
	if (data) {
		return data
			.split(" ")
			.join("")
			.split(".")
			.join("")
			.split("?")
			.join("")
			.split("(")
			.join("")
			.split(")")
			.join("")
			.split("*")
			.join("")
			.split("#")
			.join("")
			.split("@")
			.join("")
			.split("+")
			.join("")
			.split("-")
			.join("")
			.split("/")
			.join("")
			.split(":")
			.join("")
			.split(";")
			.join("")
			.split("[")
			.join("")
			.split("]")
			.join("")
			.split("{")
			.join("")
			.split("}")
			.join("")
			.split("'")
			.join("")
			.split("`")
			.join("")
			.split("!")
			.join("")
			.split(",")
			.join("")
			.split(">")
			.join("")
			.split("<")
			.join("")
			.toLowerCase();
	} else return "No Data Found";
};

export default getNormalCars;
