import React from "react";
import {Button, Snackbar, Alert} from "@material-ui/core";

function ButtonComp(props) {
	const {data} = props;
	const {textColor, bgColor, borderColor, label, linkType, url, phone, email} =
		data;

	const [snackbar, setSnackbar] = React.useState({
		open: false,
		severity: "success",
		message: "here",
	});

	return (
		<React.Fragment>
			<Button
				style={{
					marginTop: "10px",
					color: textColor,
					background: bgColor,
					border: "1px solid " + borderColor,
				}}
				variant="contained"
				fullWidth
				onClick={() => {
					switch (linkType) {
						case "url":
							window.location.href = url;
							break;
						case "phone":
							navigator.clipboard.writeText(phone);
							setSnackbar({
								open: true,
								severity: "success",
								message: "Phone Number Copy to Clipboard",
							});
							break;
						case "email":
							navigator.clipboard.writeText(email);
							setSnackbar({
								open: true,
								severity: "success",
								message: "Email Id Copy to Clipboard",
							});
							break;
						default:
							break;
					}
				}}
			>
				{label}
			</Button>
			<Snackbar
				open={snackbar.open}
				anchorOrigin={{vertical: "top", horizontal: "right"}}
				autoHideDuration={5000}
				onClose={() => setSnackbar({open: false})}
			>
				<Alert
					onClose={() => setSnackbar({open: false})}
					severity={snackbar.severity}
					sx={{width: "100%"}}
				>
					{snackbar.message}
				</Alert>
			</Snackbar>
		</React.Fragment>
	);
}
export default ButtonComp;
