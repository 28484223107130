import React from "react";
import AuthContext from "../../Context/AuthContext";
import axios from "axios";

function BussinessHandler(props) {
	const {setQrTypes, setLicense, setGeoInfo, setQrCount} =
		React.useContext(AuthContext);

	const getGeoInfo = React.useCallback(() => {
		axios
			.get("https://ipapi.co/json/")
			.then((response) => {
				setGeoInfo(response.data);
			})
			.catch((error) => {
				console.error(error);
			});
	}, [setGeoInfo]);

	React.useEffect(() => {
		getGeoInfo();
		if (localStorage.getItem("token")) {
			setQrTypes();
			setLicense();
			setQrCount();
		}
	}, [setQrTypes, setLicense, getGeoInfo, setQrCount]);

	return <React.Fragment>{props.children}</React.Fragment>;
}

export default BussinessHandler;
