import React from "react";
import {Card, CardContent, Box, Grid, Typography, Button} from "@mui/material";
import {Hidden} from "@material-ui/core-v4";
import {DateRangePicker} from "react-date-range";
import Select from "react-select";

import {
	filterQrCode,
	analyticsQrCode,
} from "../Services/DashboardServices/ApiUrl";

export default function TriggeredArea({
	handleAnalyticsData,
	id,
	qrTitle,
	handleSetLoad,
}) {
	const [type, setType] = React.useState("QR");
	const [qr, setQr] = React.useState([]);
	const [data, setData] = React.useState([]);
	const [searchData, setSearchData] = React.useState({
		qrs: [],
		tags: [],
		dateRange: [new Date(), new Date()],
	});

	const getAllQr = React.useCallback(() => {
		filterQrCode({payload: {}})
			.then((res) => {
				setQr(() => res.Items);
				setData(() => res.Items);
			})
			.catch((err) => {
				console.error(err);
			});
	}, []);

	React.useEffect(() => {
		if (id && qr && qr.length > 0) {
			setSearchData((prev) => ({
				...prev,
				qrs: qr.filter((ele) => ele.id === id),
			}));
		}
	}, [id, qr]);

	React.useEffect(() => {
		getAllQr({});
	}, [getAllQr]);

	const handleSearchData = (data) => {
		if (type === "TAGS") {
			setSearchData((prevData) => ({
				...prevData,
				tags: data,
				qrs: [],
			}));
		} else {
			setSearchData((prevData) => ({
				...prevData,
				qrs: data,
				tags: [],
			}));
		}
	};

	const getAnalyticsData = React.useCallback(
		(data) => {
			handleSetLoad(false);
			analyticsQrCode({payload: data})
				.then((res) => {
					handleAnalyticsData(res);
					handleSetLoad(true);
				})
				.catch((err) => {
					console.error(err);
					setTimeout(() => {
						handleSetLoad(true);
					}, 500);
				});
		},
		[handleSetLoad, handleAnalyticsData]
	);

	React.useEffect(() => {
		if (id) {
			getAnalyticsData({
				// dateRange: ["2022-02-02", "2022-01-02"],
				qrs: [id],
			});
			setTimeout(() => {
				setSearchData((prev) => ({
					...prev,
					qrs: [
						{
							value: id,
							label: qrTitle,
						},
					],
				}));
			}, 2000);
		}
	}, [getAnalyticsData, id, qrTitle]);

	const returnDate = (dateString) => {
		let month = {
			Jan: "01",
			Feb: "02",
			Mar: "03",
			Apr: "04",
			May: "05",
			Jun: "06",
			Jul: "07",
			Aug: "08",
			Sep: "09",
			Oct: "10",
			Nov: "11",
			Dec: "12",
		};
		let dateArr = dateString.toDateString().split(" ").slice(1, 4);
		return `${dateArr[2]}-${month[dateArr[0]]}-${dateArr[1]}`;
	};

	const selectTagsOptions =
		data && data.length > 0
			? data
					.map((ele) =>
						ele.tags
							? ele.tags.length > 0
								? ele.tags.map((eleTag) => {
										return {value: eleTag, label: eleTag};
								  })
								: []
							: []
					)
					.flat()
			: [];

	const selectQrOptions =
		data && data.length > 0
			? data.map((ele) => {
					return {value: ele.id, label: ele.title};
			  })
			: [];

	return (
		<Card
			sx={{
				backgroundColor: "#f8f8f8",
				border: "1px solid lightgrey",
				borderRadius: "4px",
				boxShadow: "0px 20px 27px 0px rgba(0, 0, 0, 0.05)",
			}}
			elevation={6}
		>
			<CardContent>
				<Grid container spacing={4}>
					<Grid
						item
						xs={12}
						sm={12}
						md={12}
						lg={12}
						xl={4}
						className="d-flex flex-column justify-content-between"
					>
						<Box className="d-flex flex-column">
							<Typography variant="h4">Select QR Codes or Tags</Typography>
							<Typography variant="h5" sx={{margin: "30px 0 0 3px"}}>
								Select Type
							</Typography>
							<Box sx={{marginTop: "5px"}}>
								<Select
									width="300px"
									maxMenuHeight="240px"
									placeholder="View By Category"
									isClearable={false}
									menuShouldScrollIntoView={true}
									value={
										type === "QR"
											? {value: "QR", label: "QR"}
											: {value: "TAGS", label: "TAGS"}
									}
									closeMenuOnScroll={true}
									theme={(theme) => ({
										...theme,
										colors: {
											...theme.colors,
											text: "#FFF",
											primary25: "#FF800C",
											primary: "#FF800C",
										},
									})}
									options={[
										{value: "QR", label: "QR"},
										{value: "TAGS", label: "TAGS"},
									]}
									onChange={({value}) => {
										setSearchData((prev) => ({
											...prev,
											qrs: [],
											tags: [],
										}));
										setType(value);
									}}
								/>
							</Box>
							<Typography variant="h5" sx={{margin: "20px 0 0 3px"}}>
								Select One or Multiple QR Code By{" "}
								{type === "TAGS" ? "Tag" : "Name"}
							</Typography>
							<Box sx={{marginTop: "5px"}}>
								<Select
									value={
										type === "TAGS"
											? searchData?.tags?.length > 0
												? searchData?.tags
												: null
											: searchData?.qrs?.length > 0
											? searchData?.qrs
											: null
									}
									isMulti
									className="basic-multi-select"
									classNamePrefix="select"
									width="300px"
									maxMenuHeight="190px"
									placeholder={
										type === "TAGS" ? "QR Codes Tags" : "QR Code Name"
									}
									isClearable={true}
									menuShouldScrollIntoView={true}
									closeMenuOnScroll={true}
									theme={(theme) => ({
										...theme,
										colors: {
											...theme.colors,
											text: "#FFF",
											primary25: "#FF800C",
											primary: "#FF800C",
										},
									})}
									options={
										type === "TAGS" ? selectTagsOptions : selectQrOptions
									}
									onChange={(data) => {
										handleSearchData(data);
									}}
								/>
							</Box>
						</Box>
						<Hidden lgDown>
							<Button
								variant="outlined"
								onClick={() => {
									if (type === "QR") {
										getAnalyticsData({
											qrs:
												searchData?.qrs?.length > 0
													? searchData?.qrs?.map((ele) => {
															return ele.value;
													  })
													: [],
											dateRange: searchData.dateRange.map((ele) =>
												returnDate(ele)
											),
										});
									} else {
										getAnalyticsData({
											tags:
												searchData?.tags?.length > 0
													? searchData?.tags?.map((ele) => {
															return ele.value;
													  })
													: [],
											dateRange: searchData.dateRange.map((ele) =>
												returnDate(ele)
											),
										});
									}
								}}
								disabled={
									searchData.qrs.length === 0 && searchData.tags.length === 0
								}
								sx={{
									background: "#FFFFFF",
									boxShadow: "0px 20px 27px rgba(0, 0, 0, 0.05)",
									borderRadius: "8px",
									padding: "10px 45px",
									width: "200px",
								}}
							>
								Get Analytics
							</Button>
						</Hidden>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={8}>
						<Typography variant="h4">Select Time Period</Typography>
						<Box
							sx={{
								marginTop: "20px",
								"& .rdrDateRangePickerWrapper": {minHeight: "418px"},
							}}
						>
							<DateRangePicker
								onChange={(item) => {
									setSearchData((prevData) => ({
										...prevData,
										dateRange: [
											item.selection.startDate,
											item.selection.endDate,
										],
									}));
								}}
								showSelectionPreview={true}
								moveRangeOnFirstSelection={false}
								months={2}
								ranges={[
									{
										startDate: searchData.dateRange[0],
										key: "selection",
										endDate: searchData.dateRange[1],
									},
								]}
								direction="horizontal"
								preventSnapRefocus={true}
								calendarFocus="backwards"
								rangeColors="#FC800E"
								color="#FC800E"
								weekStartsOn={0}
							/>
						</Box>
					</Grid>

					<Hidden xlUp>
						<Button
							variant="outlined"
							onClick={() => {
								if (type === "QR") {
									getAnalyticsData({
										qrs:
											searchData?.qrs?.length > 0
												? searchData?.qrs?.map((ele) => {
														return ele.value;
												  })
												: [],
										dateRange: searchData.dateRange.map((ele) =>
											returnDate(ele)
										),
									});
								} else {
									getAnalyticsData({
										tags:
											searchData?.tags?.length > 0
												? searchData?.tags?.map((ele) => {
														return ele.value;
												  })
												: [],
										dateRange: searchData.dateRange.map((ele) =>
											returnDate(ele)
										),
									});
								}
							}}
							disabled={
								searchData.qrs.length === 0 && searchData.tags.length === 0
							}
							sx={{
								background: "#FFFFFF",
								boxShadow: "0px 20px 27px rgba(0, 0, 0, 0.05)",
								borderRadius: "8px",
								padding: "10px 45px",
								marginLeft: "35px",
								marginTop: "20px",
							}}
						>
							Get Analytics
						</Button>
					</Hidden>
				</Grid>
			</CardContent>
		</Card>
	);
}

// {
/* <Grid item xs={12} sm={12} md={12} lg={8} xl={6}>
						<Box sx={{margin: "0px 0 10px 0"}}>
							<Typography sx={{margin: "0 0 20px 0"}} variant="h4">
								Current Collection
							</Typography>
							{searchData.qrs.length > 0 && (
								<Grid
									item
									xs={12}
									container
									sx={{alignItems: "center", margin: "10px 20px"}}
								>
									<Grid item xs={3} sm={3} md={3} lg={2} xl={1}>
										<Typography variant="h5">Qr`s :</Typography>
									</Grid>
									<Grid
										item
										xs={9}
										sm={9}
										md={9}
										lg={10}
										xl={11}
										container
										sx={{alignItems: "center"}}
									>
										{searchData.qrs.map((ele, index) => {
											if (index < 3) {
												return (
													<Box
														sx={{
															display: "flex",
															alignItems: "center",
															marginRight: "3px",
															padding: "1px 5px",
															marginTop: "5px",
															background: "#FFFFFF",
															boxShadow: "0px 20px 27px rgba(0, 0, 0, 0.05)",
															borderRadius: "4px",
															border: "1px solid grey",
														}}
													>
														<Typography>{ele.title}</Typography>
														<IconButton
															onClick={() => {
																setSearchData((prevData) => ({
																	...prevData,
																	qrs: prevData.qrs.filter(
																		(ele, qrIndex) => qrIndex !== index
																	),
																}));
															}}
														>
															<ClearIcon />
														</IconButton>
													</Box>
												);
											} else {
												return null;
											}
										})}
										{searchData.qrs.length > 3
											? `+${searchData.qrs.length - 3}`
											: ""}
									</Grid>
								</Grid>
							)}
							{searchData.tags.length > 0 && (
								<Grid
									item
									xs={12}
									container
									sx={{alignItems: "center", margin: "10px 20px"}}
								>
									<Grid item xs={3} sm={3} md={3} lg={2} xl={1}>
										<Typography variant="h5">Tag`s :</Typography>
									</Grid>
									<Grid item xs={9} sm={9} md={9} lg={10} xl={11} container>
										{searchData.tags.map((ele, index) => {
											if (index < 3) {
												return (
													<Box
														sx={{
															display: "flex",
															alignItems: "center",
															marginRight: "3px",
															padding: "1px 5px",
															marginTop: "5px",
															border: "1px solid grey",
															background: "#FFFFFF",
															boxShadow: "0px 20px 27px rgba(0, 0, 0, 0.05)",
															borderRadius: "5px",
														}}
													>
														<Typography>{ele}</Typography>
														<IconButton
															onClick={() => {
																setSearchData((prevData) => ({
																	...prevData,
																	tags: prevData.tags.filter(
																		(ele, tagIndex) => tagIndex !== index
																	),
																}));
															}}
														>
															<ClearIcon />
														</IconButton>
													</Box>
												);
											} else {
												return null;
											}
										})}
										{searchData.tags.length > 3
											? `+${searchData.tags.length - 3}`
											: ""}
									</Grid>
								</Grid>
							)}
							<Grid
								item
								xs={12}
								container
								sx={{alignItems: "center", margin: "15px 20px"}}
							>
								<Grid item xs={3} sm={3} md={3} lg={2} xl={1}>
									<Typography variant="h5">Period :</Typography>
								</Grid>
								<Grid item xs={9} sm={9} md={9} lg={10} xl={11}>
									{(searchData &&
										searchData.dateRange[0] &&
										returnDate(searchData.dateRange[0])
											.split("-")
											.reverse()
											.join("/")) +
										" - " +
										(searchData &&
											searchData.dateRange[1] &&
											returnDate(searchData.dateRange[1])
												.split("-")
												.reverse()
												.join("/"))}
								</Grid>
							</Grid>
						</Box>
					</Grid> */
// }
