import React from "react";
import {
	Grid,
	Card,
	CardContent,
	Typography,
	Box,
	CircularProgress,
} from "@mui/material";
import {
	BiQrScan,
	MdOutlineDevicesOther,
	AiOutlineScan,
	IoLocationSharp,
} from "react-icons/all";
export default function ChipComponent({analyticsData, showData}) {
	// console.log(analyticsData);
	// console.log(analyticsData.map((ele) => ele.scanDevices)[0].length);
	// console.log(
	// 	"scan",
	// 	analyticsData.map((ele) => ele.scanDevices.length).reduce((a, b) => a + b)
	// );
	return (
		<Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
			<Grid container spacing={2} sx={{height: "100%"}}>
				<Grid item xs={6} sm={6} md={6} lg={6} xl={3}>
					<Card
						sx={{
							backgroundColor: "#f8f8f8",
							border: "1px solid lightgrey",
							borderRadius: "4px",
							boxShadow: "0px 20px 27px 0px rgba(0, 0, 0, 0.05)",
						}}
						elevation={6}
					>
						<CardContent sx={{paddingBottom: "16px !important"}}>
							<Grid container>
								<Grid item xs={6}>
									<Typography variant="h5" color="textSecondary">
										Qrs
									</Typography>
									<Typography variant="h3" color="textPrimary">
										{showData ? (
											analyticsData.length
										) : (
											<CircularProgress
												style={{
													width: "31px",
													height: "31px",
													margin: "4px 0px",
												}}
											/>
										)}
									</Typography>
								</Grid>
								<Grid
									item
									xs={6}
									sx={{
										display: "flex",
										alignItems: "center",
										justifyContent: "flex-end",
									}}
								>
									<Box
										sx={{
											background:
												" linear-gradient(108.59deg, #FF800C -24.83%, #FFB800 73.21%)",
											borderRadius: "8px",
											height: "48px",
											width: "48px",
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
										}}
									>
										<BiQrScan
											style={{
												color: "white",
												width: "20px",
												height: "20px",
											}}
										/>
									</Box>
								</Grid>
							</Grid>
						</CardContent>
					</Card>
				</Grid>
				<Grid item xs={6} sm={6} md={6} lg={6} xl={3}>
					<Card
						sx={{
							backgroundColor: "#f8f8f8",
							border: "1px solid lightgrey",
							borderRadius: "4px",
							boxShadow: "0px 20px 27px 0px rgba(0, 0, 0, 0.05)",
							cursor: "pointer",
						}}
						elevation={6}
						onClick={() => window.scrollTo(0, 750)}
					>
						<CardContent sx={{paddingBottom: "16px !important"}}>
							<Grid container>
								<Grid item xs={6}>
									<Typography variant="h5" color="textSecondary">
										Scan
									</Typography>
									<Typography variant="h3" color="textPrimary">
										{showData ? (
											analyticsData && analyticsData.length > 0 ? (
												analyticsData
													.map((ele) => ele.scanDevices.length)
													.reduce((a, b) => a + b)
											) : (
												0
											)
										) : (
											<CircularProgress
												style={{
													width: "31px",
													height: "31px",
													margin: "4px 0px",
												}}
											/>
										)}
									</Typography>
								</Grid>
								<Grid
									item
									xs={6}
									sx={{
										display: "flex",
										alignItems: "center",
										justifyContent: "flex-end",
									}}
								>
									<Box
										sx={{
											background:
												" linear-gradient(108.59deg, #FF800C -24.83%, #FFB800 73.21%)",
											borderRadius: "8px",
											height: "48px",
											width: "48px",
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
										}}
									>
										<AiOutlineScan
											style={{
												color: "white",
												width: "20px",
												height: "20px",
											}}
										/>
									</Box>
								</Grid>
							</Grid>
						</CardContent>
					</Card>
				</Grid>
				<Grid item xs={6} sm={6} md={6} lg={6} xl={3}>
					<Card
						sx={{
							backgroundColor: "#f8f8f8",
							border: "1px solid lightgrey",
							borderRadius: "4px",
							boxShadow: "0px 20px 27px 0px rgba(0, 0, 0, 0.05)",
							cursor: "pointer",
						}}
						elevation={6}
						onClick={() => window.scrollTo(0, 1640)}
					>
						<CardContent sx={{paddingBottom: "16px !important"}}>
							<Grid container>
								<Grid item xs={8}>
									<Typography variant="h5" color="textSecondary">
										Scan Devices
									</Typography>
									<Typography variant="h3" color="textPrimary">
										{showData ? (
											analyticsData && analyticsData.length > 0 ? (
												analyticsData.map((ele) => ele.scanDevices)?.[0]?.length
											) : (
												0
											)
										) : (
											<CircularProgress
												style={{
													width: "31px",
													height: "31px",
													margin: "4px 0px",
												}}
											/>
										)}
									</Typography>
								</Grid>
								<Grid
									item
									xs={4}
									sx={{
										display: "flex",
										alignItems: "center",
										justifyContent: "flex-end",
									}}
								>
									<Box
										sx={{
											background:
												" linear-gradient(108.59deg, #FF800C -24.83%, #FFB800 73.21%)",
											borderRadius: "8px",
											height: "48px",
											width: "48px",
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
										}}
									>
										<MdOutlineDevicesOther
											style={{
												color: "white",
												width: "20px",
												height: "20px",
											}}
										/>
									</Box>
								</Grid>
							</Grid>
						</CardContent>
					</Card>
				</Grid>
				<Grid item xs={6} sm={6} md={6} lg={6} xl={3}>
					<Card
						sx={{
							backgroundColor: "#f8f8f8",
							border: "1px solid lightgrey",
							borderRadius: "4px",
							boxShadow: "0px 20px 27px 0px rgba(0, 0, 0, 0.05)",
							cursor: "pointer",
						}}
						elevation={6}
						onClick={() => window.scrollTo(0, 4500)}
					>
						<CardContent sx={{paddingBottom: "16px !important"}}>
							<Grid container>
								<Grid item xs={8}>
									<Typography variant="h5" color="textSecondary">
										Scan Location
									</Typography>
									<Typography variant="h3" color="textPrimary">
										{showData ? (
											analyticsData && analyticsData.length > 0 ? (
												analyticsData
													.map((ele) => [
														...new Set(
															ele.scanLocations
																.map((data) => data.city)
																.filter((ele) => ele)
														),
													])
													.flat().length
											) : (
												0
											)
										) : (
											<CircularProgress
												style={{
													width: "31px",
													height: "31px",
													margin: "4px 0px",
												}}
											/>
										)}
									</Typography>
								</Grid>
								<Grid
									item
									xs={4}
									sx={{
										display: "flex",
										alignItems: "center",
										justifyContent: "flex-end",
									}}
								>
									<Box
										sx={{
											background:
												" linear-gradient(108.59deg, #FF800C -24.83%, #FFB800 73.21%)",
											borderRadius: "8px",
											height: "48px",
											width: "48px",
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
										}}
									>
										<IoLocationSharp
											style={{
												color: "white",
												width: "20px",
												height: "20px",
											}}
										/>
									</Box>
								</Grid>
							</Grid>
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		</Grid>
	);
}
