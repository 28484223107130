import React from "react";
import {
	Container,
	Box,
	TextField,
	Typography,
	InputAdornment,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core-v4";
import {BiMenu} from "react-icons/all";

import QrContextWrapper from "../CreateQR/QrContext/QrContextWrapper";
import PageTitle from "../../../UI/PageTitle";
import QrContent from "../CreateQR/Components/QrContent";
import AuthContext from "../../../../Context/AuthContext";

import {createLead} from "../Services/LeadServices/ApiUrl";
import {useNavigate} from "react-router";

const useStyles = makeStyles((theme) => ({
	card: {
		width: "100%",
		"& div > div": {
			height: "40px",
			borderRadius: "5px",
		},
		"& .MuiFormControl-root > .MuiOutlinedInput-root > input ": {
			marginTop: "-5px",
			// borderLeft: "1px solid #999",
			// padding: "10px 5px 10px 10px",
		},
		"& .MuiFormControl-root > .MuiOutlinedInput-root > .MuiInputAdornment-root > .MuiButtonBase-root ":
			{
				marginTop: "-7px",
			},
	},
}));

const createLeadFieldsArray = [
	{type: "BackgroundColor", bgColor: "#E0E0E0", color: "#000000"},
	{
		type: "PageTitle",
		title: "",
		bgColor: "#FFFFFF",
		textColor: "#000000",
	},
	{type: "BannerImage", max: 3, images: []},
	{
		type: "DescriptionBox",
		text: "description",
		bgColor: "#FFFFFF",
		textColor: "#000000",
	},
	{
		type: "LeadForm",
		bgColor: "#FFFFFF",
		description: "",
		textColor: "#000000",
		questionBlock: [
			{
				question: "",
				type: false,
				required: true,
			},
		],
	},
	{
		type: "Button",
		value: "submit",
		deletable: true,
		label: "Submit",
		linkType: false,
		bgColor: "#FFFFFF",
		textColor: "#000000",
		borderColor: "#999999",
	},
	{
		type: "Button",
		value: "skip",
		deletable: true,
		label: "Skip",
		linkType: false,
		bgColor: "#FFFFFF",
		textColor: "#000000",
		borderColor: "#999999",
	},
];

const additionalContentArray = ["DescriptionBox"];

function CreateLead(props) {
	const navigate = useNavigate();
	const classes = useStyles();
	const {setAdditionalContent, setCategory} = React.useContext(AuthContext);

	const [title, setTitle] = React.useState();

	React.useEffect(() => {
		setCategory("Lead");
		setAdditionalContent(additionalContentArray);
	}, [setCategory, setAdditionalContent]);

	// ---------------------- Scroll Tostick Mobile ----------------------- //

	window.onscroll = function () {
		scrollFunction();
	};

	function scrollFunction() {
		if (
			document.body.scrollTop > 220 ||
			document.documentElement.scrollTop > 220
		) {
			try {
				document.getElementById("navbar").style.position = "fixed";
				document.getElementById("navbar").style.top = "0";
			} catch {
				return null;
			}
		} else {
			try {
				document.getElementById("navbar").style.position = "relative";
			} catch {
				return null;
			}
		}
	}

	const handleFinish = (data) => {
		let payload = {
			payload: {
				title: title,
				uiData: data,
			},
		};
		createLead(payload)
			.then((res) => {
				navigate(-1);
			})
			.catch((err) => {
				console.error(err);
			});
	};

	return (
		<QrContextWrapper>
			<Container maxWidth="xl" style={{marginTop: "30px"}}>
				<PageTitle
					title="Leads"
					subTitle={
						<div style={{display: "flex", flexDirection: "column"}}>
							<span>
								Lead Generation feature allows you to get contact and other
								information when people scan your QR Codes.
							</span>
							<span>
								Use this section to setup lead lists and forms, and to export
								captured leads data
							</span>
						</div>
					}
				/>
				<Box
					sx={{
						padding: "30px 30px 10px 30px",
						background: "#FFF",
					}}
				>
					<Typography sx={{color: "#444", fontSize: "18px", mb: 1}}>
						<b>Lead List Name</b>
					</Typography>
					<Box
						className={classes.card}
						sx={{
							width: "50%",
						}}
					>
						<TextField
							placeholder="Write here..."
							type="text"
							onChange={(e) => setTitle(e.target.value)}
							value={title}
							variant="outlined"
							color="primary"
							fullWidth
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<Box sx={{marginTop: "8px"}}>
											<BiMenu style={{fontSize: "23px"}} />
										</Box>
									</InputAdornment>
								),
							}}
						/>
					</Box>
				</Box>
				<QrContent
					uiData={createLeadFieldsArray}
					finishForm={true}
					handleFinish={handleFinish}
					btnTitle="Create"
				/>
			</Container>
		</QrContextWrapper>
	);
}

export default CreateLead;
