import * as React from "react";
import { Box } from "@material-ui/core";

import classes from "./Page2.module.css";

import interRemoveBgPreview from "../../../../../Assets/inter-removebg-preview1.svg";

function Page2() {
  return (
    <Box className={classes.box}>
      <Box className="d-flex flex-column align-items-center">
        <p className={classes.labelTitle}>Inter-operable QR</p>
        <p className={classes.labelText}>
          Interoperability is a characteristic of a product or system, whose
          interfaces are completely understood, to work with other products or
          systems, at present or in the future, in either implementation or
          access, without any restrictions ,interoperable quick response (QR){" "}
          <br /> code-based application would enable merchants and customers to
          transact without being restricted to specific wallets or banks.
        </p>
        <img
          className={classes.interRemoveBgPreview}
          alt="interRemoveBgPreview"
          src={interRemoveBgPreview}
        />
        <p className={classes.labelSubTitle}>Benefits</p>
      </Box>
      <Box className={classes.boxTable}>
        <Box className={classes.boxTableTopbar}>
          <label className={classes.boxTitleLabel}>Consumers</label>
          <label className={classes.boxTitleLabel}>Merchants</label>
        </Box>
        <Box className={classes.boxMiddelBar} />
        <Box className="d-flex justify-content-around py-2">
          <label className={classes.labelTabelText}>
            The Hotqrfor Fintech enables customers to transact money available
            in their bank accounts without having to worry about the physical
            availability of an active credit / debit card.
          </label>
          <label className={classes.labelTabelText}>
            The requirement of a Point of Sale (PoS) terminal is not required.
          </label>
        </Box>
        <Box className={classes.boxRowBar} />
        <Box className="d-flex justify-content-around py-2">
          <label className={classes.labelTabelText}>
            Users will not need to scan different QR codes at the same merchant
            provided by the different payment networks.
          </label>
          <label className={classes.labelTabelText}>
            Merchants will only need to display one QR code at the storefront or
            through the acquiring bank’s mobile application.
          </label>
        </Box>
        <Box className={classes.boxRowBar} />
        <Box className="d-flex justify-content-around py-2">
          <label className={classes.labelTabelText}>
            Users will not be restricted by wallet or bank preferences as they
            will be interoperable.
          </label>
          <label className={classes.labelTabelText}>
            The implementation of the Hotqrfor Fintech standards will be
            seamless and only happen at the backend, meaning merchants do not
            need to change their existing QR codes.
          </label>
        </Box>
        <Box className={classes.boxRowBar} />
        <Box className="d-flex justify-content-center py-3">
          <label className={classes.labelTabelTextLastNode}>
            The underlying specification for Hotqrfor Fintech can be implemented
            in other countries to deliver a globally interoperable solution.
          </label>
        </Box>
        <Box className={classes.boxRowBar} />
      </Box>
    </Box>
  );
}

export default Page2;
